import { RadioGroup } from "@headlessui/react";
import { useOutletContext, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Spinner from "./Spinner";
import { Order, Restaurants } from "../types";
import Price from "./Price";
import linkArrow from "../img/images/icon_new-window.svg";
import { getRestaurants } from "../api";
import { getErrorMessage, State } from "../constants";

export default function Option({
  setExpand,
}: {
  setExpand: React.Dispatch<React.SetStateAction<State>>;
}) {
  const [state, setState] = useOutletContext() as any;
  let [searchParams, setSearchParams] = useSearchParams();

  const [data, setData] = useState<Restaurants[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();

  const [shop, setShop] = useState<Restaurants>();

  let setRestaurants = (shop: any) => {
    setState((state: Order) => ({
      ...state,
      restaurant: { ...state.restaurant, shop },
    }));
  };

  let region = searchParams.get("destination") ?? state.destination.region_id;

  useEffect(() => {
    const getData = async () => {
      try {
        let restaurants = await getRestaurants(region);
        setData(restaurants);
        setError(undefined);
      } catch (err) {
        setError(getErrorMessage(err));
        setData([]);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, [region]);

  return (
    <>
      <div className="boxRight">
        <div className="boxRightTitle">夕食の変更</div>

        <div className="mt-5 px-2 md:px-8">
          {loading && <Spinner />}
          <SelectRestaurant
            value={state.restaurant?.shop}
            places={data}
            onChange={(d) => {
              setRestaurants(d);
              setShop(d);
            }}
          />
          <button
            onClick={() => setExpand(State.DEFAULT)}
            className="btn-enter mx-auto my-10"
          >
            この内容に変更する
          </button>
        </div>
      </div>
    </>
  );
}

function SelectRestaurant({
  value,
  places,
  onChange,
}: {
  value: Restaurants;
  places: Restaurants[];
  onChange: (value: Restaurants) => void;
}) {
  return (
    <>
      <RadioGroup value={value} onChange={onChange}>
        <RadioGroup.Label className="sr-only">restaurants</RadioGroup.Label>
        <div className="flex flex-col space-y-2.5">
          {places.map((shop) => (
            <RadioGroup.Option
              key={shop.name}
              value={shop}
              className={({ checked }) =>
                `${
                  checked
                    ? "bg-orange-light border-dGray"
                    : "bg-white border-gray"
                }
                boxRightCheckedRestaurant`
              }
            >
              <div className="w-full flex flex-col">
                <div className="flex items-center justify-between mb-2.5">
                  <div className="flex frex-row items-center space-x-2.5">
                    <RadioGroup.Label as="p" className="text-lg font-semibold">
                      {shop.name}
                    </RadioGroup.Label>
                    <div>({shop.tag})</div>
                  </div>
                </div>
                <div className="space-y-2.5">
                  <div className="boxRightOptionItems">
                    <div className="boxRightOptionItems">住所</div>
                    <div>{shop.address}</div>
                  </div>

                  <div className="boxRightOptionItems">
                    <div className="boxRightOptionItems">電話</div>
                    <div>{shop.tel}</div>
                  </div>
                  <div className="boxRightOptionItems">
                    <div className="boxRightOptionItems">HP</div>
                    <a href={shop.homepage} target="_blank">
                      <span>{shop.homepage}</span>
                      <img src={linkArrow} className="h-5 w-5" />
                    </a>
                  </div>
                  <div className="flex justify-end">
                    平均予算：
                    <Price value={shop.budget_min} />〜
                  </div>
                </div>
              </div>
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </>
  );
}

export function CheckIcon(props: any) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} fill="#0891b2" />
      <path
        d="M7 13l3 3 7-7"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
