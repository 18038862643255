import { RadioGroup } from "@headlessui/react";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import Price from "./Price";
import Spinner from "./Spinner";
import { Flight as IFlight } from "../types";

import { getFlights } from "../api";

import logoJAL from "../img/logoAir/JAL.png";
import logoANA from "../img/logoAir/ANA.jpg";
import logoAIRDO from "../img/logoAir/AIRDO_row.png";
import logoSKYMARK from "../img/logoAir/SKYMARK.jpg";
import logoSolaseed from "../img/logoAir/SolaseedAir_row.png";
import logoSTARFLYER from "../img/logoAir/STARFLYER_row_w.jpg";
import { minFareAdult } from "../utils";
import { ArrowNarrowRightIcon } from "@heroicons/react/outline";
import { getErrorMessage, State } from "../constants";

export enum Direction {
  Departure,
  Return,
}
export default function Flight({
  setExpand,
  direction,
}: {
  setExpand: React.Dispatch<React.SetStateAction<State>>;
  direction?: Direction;
}) {
  direction = direction ?? Direction.Departure;
  const [state, setState] = useOutletContext() as any;

  const [data, setData] = useState<IFlight[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();

  let setDepFlight = (flight: IFlight) => {
    setState((state: any) => {
      let cheapestFare = minFareAdult(flight);

      return {
        ...state,
        departure: {
          flight: flight.FlightInfo,
          fare: flight.FareItem.find(
            (fare) => fare.TicketAdult == cheapestFare
          )!,
        },
      };
    });
  };

  let setArrFlight = (flight: IFlight) => {
    setState((state: any) => {
      let cheapestFare = minFareAdult(flight);

      return {
        ...state,
        return: {
          flight: flight.FlightInfo,
          fare: flight.FareItem.find(
            (fare) => fare.TicketAdult == cheapestFare
          )!,
        },
      };
    });
  };

  let selectFlight;
  //if departure already selected we're selecting a return flight
  if (direction == Direction.Departure) {
    let selectedFlight = state.departure
      ? data.find(
          (flight) =>
            flight.FlightInfo.FlightNo == state.departure.flight.FlightNo
        )
      : undefined;
    selectFlight = (
      <SelectFlight
        value={selectedFlight}
        flights={data}
        onChange={setDepFlight}
      />
    );
  } else {
    let selectedFlight = state.return
      ? data.find(
          (flight) => flight.FlightInfo.FlightNo == state.return.flight.FlightNo
        )
      : undefined;
    selectFlight = (
      <SelectFlight
        value={selectedFlight}
        flights={data}
        onChange={setArrFlight}
      />
    );
  }

  // TODO: fetch these from state instead
  let date: Date, departure: string, arrival: string;
  if (direction == Direction.Departure) {
    departure = state.origin.region_id!;
    arrival = state.destination.region_id!;
    date = state.startDate;
  } else {
    departure = state.destination.region_id!;
    arrival = state.origin.region_id!;
    date = state.endDate;
  }

  useEffect(() => {
    const getData = async () => {
      try {
        let flights = await getFlights(
          date,
          departure,
          arrival,
          state.adults,
          state.children,
          state.infants
        );
        setData(flights);
        setError(undefined);
      } catch (err) {
        setError(getErrorMessage(err));
        setData([]);
      } finally {
        setLoading(false);
      }
    };
    setLoading(true);
    getData();
  }, [date, departure, arrival, state.adults, state.children, state.infants]);

  return (
    <>
      <div className="boxRight">
        <div className="boxRightTitle">
          フライト[{direction == Direction.Departure ? "往路" : "復路"}]の変更
        </div>
        <div className="mt-5 px-2 md:px-8">
          <div>{loading ? <Spinner /> : selectFlight}</div>

          {direction == Direction.Departure ? (
            <>
              {state.departure ? (
                <button
                  onClick={() => setExpand(State.DEFAULT)}
                  className="btn-enter mx-auto my-10"
                >
                  この内容に変更する
                </button>
              ) : (
                <div className="mb-5" />
              )}
            </>
          ) : (
            <>
              {state.return ? (
                <button
                  onClick={() => setExpand(State.DEFAULT)}
                  className="btn-enter mx-auto my-10"
                >
                  この内容に変更する
                </button>
              ) : (
                <div className="mb-5" />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

function SelectFlight({
  value,
  flights,
  onChange,
}: {
  value: IFlight | undefined;
  flights: IFlight[];
  onChange: (value: IFlight) => void;
}) {
  return (
    <>
      <RadioGroup value={value} onChange={onChange}>
        <RadioGroup.Label className="sr-only">flight info</RadioGroup.Label>
        <div className="flex flex-col space-y-2.5">
          {/* for sorting list */}
          {flights
            .sort((a, b) => {
              return a.FlightInfo.DepartingDatetime >
                b.FlightInfo.DepartingDatetime
                ? 1
                : -1;
            })
            .map((flight) => (
              <RadioGroup.Option
                key={flight.FlightInfo.FlightNo}
                value={flight}
                className={({ checked }) =>
                  `${
                    checked
                      ? "bg-orange-light border-dGray"
                      : "bg-white border-gray"
                  }
                  boxRightCheckedFlight`
                }
              >
                <div className="w-full flex flex-row">
                  <div className="boxRightFlightImg">
                    {flight.FlightInfo.CareerId == "1" ? (
                      <img className="w-auto h-16" src={logoJAL} />
                    ) : flight.FlightInfo.CareerId == "2" ? (
                      <img className="w-20 h-auto" src={logoANA} />
                    ) : flight.FlightInfo.CareerId == "0" ? (
                      <img className="w-20 h-auto" src={logoAIRDO} />
                    ) : flight.FlightInfo.CareerId == "0" ? (
                      <img className="w-20 h-auto" src={logoSKYMARK} />
                    ) : flight.FlightInfo.CareerId == "0" ? (
                      <img className="w-20 h-auto" src={logoSolaseed} />
                    ) : flight.FlightInfo.CareerId == "0" ? (
                      <img className="w-20 h-auto" src={logoSTARFLYER} />
                    ) : (
                      <div>{flight.FlightInfo.CareerId}</div>
                    )}
                  </div>

                  <div className="boxRightFlightInfo">
                    <RadioGroup.Label as="div" className="boxRightFlightLabel">
                      <div>
                        {flight.FlightInfo.DepartingAirportName}
                        {flight.FlightInfo.DepartingDatetime.slice(0, -3)}
                      </div>
                      <ArrowNarrowRightIcon className="hidden md:flex" />
                      <div>
                        {flight.FlightInfo.ArrivingAirportName}
                        {flight.FlightInfo.ArrivingDatetime.slice(0, -3)}
                      </div>
                    </RadioGroup.Label>
                    <RadioGroup.Description as="div" className="flex flex-col">
                      <div className="text-lg font-semibold">
                        {flight.FlightInfo.FlightNo}
                      </div>

                      <div className="flex flex-col md:flex-row md:justify-end space-x-1">
                        <Price value={minFareAdult(flight)} />
                        <div className="text-sm md:text-base">
                          (
                          {
                            flight.FareItem.find(
                              (item) =>
                                item.TicketAdult === minFareAdult(flight)
                            )?.TicketTypeName
                          }
                          )
                        </div>
                      </div>
                    </RadioGroup.Description>
                  </div>
                </div>
              </RadioGroup.Option>
            ))}
        </div>
      </RadioGroup>
    </>
  );
}
