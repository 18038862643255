import { RadioGroup } from "@headlessui/react";
import { useOutletContext } from "react-router-dom";
import { Order, HotelArea, HotelClass } from "../types";
import { useEffect, useState } from "react";
import Spinner from "./Spinner";
import Price from "./Price";
import { getHotels } from "../api";
import { formatISO } from "date-fns";
import { StarIcon } from "@heroicons/react/solid";
import {
  locations,
  bathChoice,
  breakfastChoice,
  smokingChoice,
  getErrorMessage,
  State,
} from "../constants";
import { SelectButton } from "./SelectButton";

export default function Hotel({
  setExpand,
}: {
  setExpand: React.Dispatch<React.SetStateAction<State>>;
}) {
  const [state, setState] = useOutletContext() as any;

  const [data, setData] = useState<HotelArea[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();

  let setHotelArea = (area: HotelArea) => {
    setState((state: Order) => ({
      ...state,
      hotel: { ...state.hotel, area },
    }));
  };

  let setHotelClass = (hotelClass: any) => {
    setState((state: Order) => ({
      ...state,
      hotel: {
        ...state.hotel,
        class: hotelClass,
      },
    }));
  };

  let setLocation = (location: any) => {
    setState((state: Order) => ({
      ...state,
      hotel: { ...state.hotel, room: { ...state.hotel?.room, location } },
    }));
  };

  let setBath = (bath: any) => {
    setState((state: Order) => ({
      ...state,
      hotel: { ...state.hotel, room: { ...state.hotel?.room, bath } },
    }));
  };

  let setBreakfast = (breakfast: any) => {
    setState((state: Order) => ({
      ...state,
      hotel: { ...state.hotel, room: { ...state.hotel?.room, breakfast } },
    }));
  };

  let setSmoking = (smoking: any) => {
    setState((state: Order) => ({
      ...state,
      hotel: { ...state.hotel, room: { ...state.hotel?.room, smoking } },
    }));
  };

  let region = state.destination.region_id ?? "";
  let stayDate = formatISO(state.startDate, { representation: "date" });

  useEffect(() => {
    const getData = async () => {
      try {
        let hotels = await getHotels(region, stayDate);
        setData(hotels);
        setError(undefined);
      } catch (err) {
        setError(getErrorMessage(err));
        setData([]);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, [region]);

  let selectedHotelArea = state.hotel
    ? data.find(
        (hotelArea) => hotelArea.hotelarea_id == state.hotel.area.hotelarea_id
      )
    : undefined;

  const isSelected =
    state.hotel?.room &&
    state.hotel?.class &&
    state.hotel?.room.location &&
    state.hotel?.room.bath &&
    state.hotel?.room.breakfast &&
    state.hotel?.room.smoking;

  return (
    <>
      <div className="boxRight">
        <div className="boxRightTitle">宿泊施設の変更</div>

        <div className="mt-5 px-2 md:px-8">
          <div className="space-y-5">
            {loading && <Spinner />}
            <div className="flex flex-col md:flex-row">
              <div className="boxRightItemName items-center">エリア</div>
              <div className="relative w-full md:w-11/12">
                <SetHotelArea
                  value={selectedHotelArea}
                  areas={data}
                  onChange={setHotelArea}
                />
              </div>
            </div>
            {state.hotel?.area && (
              <>
                <div className="flex flex-col md:flex-row">
                  <div className="boxRightItemName items-start">ランク</div>
                  <div className="relative w-full md:w-11/12">
                    <SelectHotel
                      value={state.hotel?.class}
                      ranks={state.hotel.area.hotelclass}
                      onChange={setHotelClass}
                    />
                  </div>
                </div>

                <div className="flex flex-col md:flex-row">
                  <div className="boxRightItemName items-center">位置</div>
                  <div className="relative w-full md:w-11/12">
                    <SelectButton
                      value={state.hotel.room?.location}
                      onChange={setLocation}
                      items={locations}
                    />
                  </div>
                </div>

                <div className="flex flex-col md:flex-row">
                  <div className="boxRightItemName items-center">大浴場</div>
                  <div className="relative w-full md:w-11/12">
                    <SelectButton
                      value={state.hotel.room?.bath}
                      onChange={setBath}
                      items={bathChoice}
                    />
                  </div>
                </div>

                <div className="flex flex-col md:flex-row">
                  <div className="boxRightItemName items-center">朝食</div>
                  <div className="relative w-full md:w-11/12">
                    <SelectButton
                      value={state.hotel.room?.breakfast}
                      onChange={setBreakfast}
                      items={breakfastChoice}
                    />
                  </div>
                </div>

                <div className="flex flex-col md:flex-row">
                  <div className="boxRightItemName items-center">たばこ</div>
                  <div className="relative w-full md:w-11/12">
                    <SelectButton
                      value={state.hotel.room?.smoking}
                      onChange={setSmoking}
                      items={smokingChoice}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          {isSelected ? (
            <button
              onClick={() => setExpand(State.DEFAULT)}
              className="btn-enter mx-auto my-10"
            >
              この内容に変更する
            </button>
          ) : (
            <div className="mb-5" />
          )}
        </div>
      </div>
    </>
  );
}

function SetHotelArea({
  value,
  areas,
  onChange,
}: {
  value: HotelArea | undefined;
  areas: HotelArea[];
  onChange: (value: HotelArea) => void;
}) {
  return (
    <>
      <RadioGroup value={value} onChange={onChange}>
        <RadioGroup.Label className="sr-only">hotel area</RadioGroup.Label>
        <div className="flex flex-row md:space-x-2.5 flex-wrap">
          {areas.map((area) => (
            <RadioGroup.Option
              key={area.label}
              value={area}
              className={({ checked }) =>
                `${
                  checked
                    ? "bg-orange-light border-dGray"
                    : "bg-white border-gray"
                }
                  boxRightChecked mb-2.5 mr-2.5 md:mr-0`
              }
            >
              <RadioGroup.Label as="p" className="text-color">
                {area.label}
              </RadioGroup.Label>
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </>
  );
}

function SelectHotel({
  value,
  ranks,
  onChange,
}: {
  value: HotelClass;
  ranks: HotelClass[];
  onChange: (value: HotelClass) => void;
}) {
  return (
    <>
      <RadioGroup value={value} onChange={onChange}>
        <RadioGroup.Label className="sr-only">hotel rank</RadioGroup.Label>
        <div className="flex flex-col md:space-y-2.5">
          {ranks.map((hotel) => (
            <RadioGroup.Option
              key={hotel.label}
              value={hotel}
              className={({ checked }) =>
                `${
                  checked
                    ? "bg-orange-light border-dGray"
                    : "bg-white border-gray"
                }
                boxRightHotelRank mb-2.5 md:mb-0`
              }
            >
              <div className="w-full flex flex-row justify-between">
                <div className="flex flex-row items-center text-sm md:text-base">
                  <div className="flex flex-row w-28">
                    {hotel.label == "エコノミー" ? (
                      <>
                        <StarIcon
                          className="rankStar text-orange"
                          aria-hidden="true"
                        />
                        <StarIcon
                          className="rankStar text-orange"
                          aria-hidden="true"
                        />
                      </>
                    ) : hotel.label == "スタンダード" ? (
                      <>
                        <StarIcon
                          className="rankStar text-orange"
                          aria-hidden="true"
                        />
                        <StarIcon
                          className="rankStar text-orange"
                          aria-hidden="true"
                        />
                        <StarIcon
                          className="rankStar text-orange"
                          aria-hidden="true"
                        />
                      </>
                    ) : hotel.label == "スーペリア" ? (
                      <>
                        <StarIcon
                          className="rankStar text-orange"
                          aria-hidden="true"
                        />
                        <StarIcon
                          className="rankStar text-orange"
                          aria-hidden="true"
                        />
                        <StarIcon
                          className="rankStar text-orange"
                          aria-hidden="true"
                        />
                        <StarIcon
                          className="rankStar text-orange"
                          aria-hidden="true"
                        />
                      </>
                    ) : (
                      <>
                        <StarIcon
                          className="rankStar text-orange"
                          aria-hidden="true"
                        />
                        <StarIcon
                          className="rankStar text-orange"
                          aria-hidden="true"
                        />
                        <StarIcon
                          className="rankStar text-orange"
                          aria-hidden="true"
                        />
                        <StarIcon
                          className="rankStar text-orange"
                          aria-hidden="true"
                        />
                        <StarIcon
                          className="rankStar text-orange"
                          aria-hidden="true"
                        />
                      </>
                    )}
                  </div>
                  <div className="font-medium">{hotel.label}</div>
                </div>
                <div className="text-sm md:text-base">
                  <Price value={hotel.estimate} />
                  〜／1名
                </div>
              </div>
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </>
  );
}
