export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const API_URL = process.env.REACT_APP_API_URL;
export const JID_URL = process.env.REACT_APP_JID_URL;
export const JID_ACCOUNT_URL = process.env.REACT_APP_JID_ACCOUNT_URL;
export const COOKIE_EID = process.env.REACT_APP_COOKIE_EID || "eid";

//https://jtravel-api-dev.jorudan.co.jp?region_id=osaka
//https://jtravel-api.jorudan.co.jp

export const schedules = [
    {
      id: "day",
      label: "日帰り",
    },
    {
      id: "stay",
      label: "宿泊",
    },
]

export const regions = [
    {
        prior: 0,
        region_id: "unselected",
        label: "選んでください",
    },
    {
        prior: 1,
        region_id: "tokyo",
        label: "東京",
    },
    {
        prior: 2,
        region_id: "osaka",
        label: "大阪",
    },
    {
        prior: 3,
        region_id: "fukuoka",
        label: "福岡",
    },
    {
        prior: 4,
        region_id: "sapporo",
        label: "札幌",
    },
    {
        prior: 5,
        region_id: "naha",
        label: "那覇",
    },
    {
        prior: 99,
        region_id: 'others',
        label: "その他",
    }
];

export const regionTokyoEast = [
    {
        prior: 0,
        region_id: "unselected",
        label: "選んでください",
    },
    {
        prior: 1,
        region_id: "tokyo",
        label: "東京",
    },
    {
        prior: 99,
        region_id: "ueno",
        label: "上野",
    },
    {
        prior: 99,
        region_id: "ohmiya",
        label: "大宮",
    },
    {
        prior: 99,
        region_id: 'others',
        label: "その他",
    }
];

export const regionTokyoCentral = [
    {
        prior: 0,
        region_id: "unselected",
        label: "選んでください",
    },
    {
        prior: 1,
        region_id: "tokyo",
        label: "東京",
    },
    {
        prior: 99,
        region_id: "shinagawa",
        label: "品川",
    },
    {
        prior: 99,
        region_id: 'others',
        label: "その他",
    }
];

export const regionTohoku = [
    {
        prior: 0,
        region_id: "unselected",
        label: "選んでください",
    },
    {
        prior: 99,
        region_id: "sendai",
        label: "仙台",
    },
    {
        prior: 99,
        region_id: "aizuwakamatsu",
        label: "会津若松",
    },
    {
        prior: 99,
        region_id: "yamagata",
        label: "山形",
    },
    {
        prior: 99,
        region_id: "tendo",
        label: "天童",
    },
    {
        prior: 99,
        region_id: 'others',
        label: "その他",
    }
];

export const regionHokuriku = [
    {
        prior: 0,
        region_id: "unselected",
        label: "選んでください",
    },
    {
        prior: 99,
        region_id: "toyama",
        label: "富山",
    },
    {
        prior: 99,
        region_id: "kanazawa",
        label: "金沢",
    },
    {
        prior: 99,
        region_id: 'others',
        label: "その他",
    }
];

export const regionShinshu = [
    {
        prior: 0,
        region_id: "unselected",
        label: "選んでください",
    },
    {
        prior: 99,
        region_id: "karuizawa",
        label: "軽井沢",
    },
    {
        prior: 99,
        region_id: "nagano",
        label: "長野",
    },
    {
        prior: 99,
        region_id: 'others',
        label: "その他",
    }
];

export const regionChubu = [
    {
        prior: 0,
        region_id: "unselected",
        label: "選んでください",
    },
    {
        prior: 99,
        region_id: "toyohashi",
        label: "豊橋",
    },
    {
        prior: 99,
        region_id: "nagoya",
        label: "名古屋",
    },
    {
        prior: 99,
        region_id: 'others',
        label: "その他",
    }
];

export const regionKansai = [
    {
        prior: 0,
        region_id: "unselected",
        label: "選んでください",
    },
    {
        prior: 99,
        region_id: "shin-osaka",
        label: "新大阪",
    },
    {
        prior: 99,
        region_id: "kyoto",
        label: "京都",
    },
    {
        prior: 99,
        region_id: "shinkobe",
        label: "新神戸",
    },
    {
        prior: 99,
        region_id: 'others',
        label: "その他",
    }
];

export const regionTokyo = [
    {
        prior: 0,
        region_id: "unselected",
        label: "選んでください",
    },
    {
        prior: 1,
        region_id: "tokyo",
        label: "東京",
    },
    {
        prior: 99,
        region_id: 'others',
        label: "その他",
    }
];

export const regionShinyoko = [
    {
        prior: 0,
        region_id: "unselected",
        label: "選んでください",
    },
    {
        prior: 99,
        region_id: "shin-yokohama",
        label: "新横浜",
    },
    {
        prior: 99,
        region_id: 'others',
        label: "その他",
    }
];

export const regionUeno = [
    {
        prior: 0,
        region_id: "unselected",
        label: "選んでください",
    },
    {
        prior: 99,
        region_id: "ueno",
        label: "上野",
    },
    {
        prior: 99,
        region_id: 'others',
        label: "その他",
    }
];

export const regionOmiya = [
    {
        prior: 0,
        region_id: "unselected",
        label: "選んでください",
    },
    {
        prior: 99,
        region_id: "omiya",
        label: "大宮",
    },
    {
        prior: 99,
        region_id: 'others',
        label: "その他",
    }
];

export const ranks = [
  {
    id: "economy",
    label: "エコノミー",
  },
  {
    id: "standard",
    label: "スタンダード",
  },
  {
    id: "superior",
    label: "スーペリア",
  },
  {
    id: "deluxe",
    label: "デラックス",
  },
];

export const transports = [
  {
    id: "train",
    label: "新幹線・特急",
  },
  {
    id: "air",
    label: "飛行機",
  },
  {
    id: "notransports",
    label: "宿泊のみ",
  },
];

export const outwardTimesForTrain = [
    {
        id: "first_train",
        label: "始発",
        time: "始発便〜8時発",
        since: "",
        until: "08:00:00",
      },
      {
        id: "morning",
        label: "朝",
        time: "8時〜10時発",
        since: "08:00:00",
        until: "10:00:00",
      },
      {
        id: "noon",
        label: "昼前",
        time: "10時以降〜12時発",
        since: "10:00:00",
        until: "12:00:00",
      },
];

export const returnTimesForTrain = [
    {
        id: "early_afternoon",
        label: "午後早め",
        time: "12時〜14時発",
        since: "12:00:00",
        until: "14:00:00",
      },
      {
        id: "late_afternoon",
        label: "午後遅め",
        time: "14時〜16時発",
        since: "14:00:00",
        until: "16:00:00",
      },
      {
        id: "evening",
        label: "夕方",
        time: "16時〜18時発",
        since: "16:00:00",
        until: "18:00:00",
      },
      {
        id: "early_night",
        label: "夜早め",
        time: "18時〜20時発",
        since: "18:00:00",
        until: "20:00:00",
      },
      {
        id: "late_night",
        label: "夜遅め",
        time: "20時〜22時発",
        since: "20:00:00",
        until: "22:00:00",
      },
      {
        id: "last_train",
        label: "最終",
        time: "22時〜終電",
        since: "22:00:00",
        until: ""
      },
];

export const outwardTimes = [
    {
        id: "first_flight",
        label: "始発",
        time: "始発便〜10時発",
        since: "",
        until: "10:00:00",
    },
    {
        id: "noon",
        label: "昼",
        time: "10時以降〜14時発",
        since: "10:00:00",
        until: "14:00:00",
    },
];

export const returnTimes = [
    {
        id: "evening",
        label: "夕方",
        time: "14時以降〜18時発",
        since: "14:00:00",
        until: "18:00:00",
    },
    {
        id: "night",
        label: "夜",
        time: "18時以降〜最終便",
        since: "18:00:00",
        until: "",
    },
];

export const _departureTimes = [
  {
    id: "first_flight",
    label: "始発",
    time: "始発便〜8時発",
    until: "08:00:00",
  },
  {
    id: "morning",
    label: "朝",
    time: "8時〜10時発",
    since: "08:00:00",
    until: "10:00:00",
  },
  {
    id: "noon",
    label: "昼前",
    time: "10時以降〜12時発",
    since: "10:00:00",
    until: "12:00:00",
  },
  {
    id: "early_afternoon",
    label: "午後早め",
    time: "12時〜14時発",
    since: "12:00:00",
    until: "14:00:00",
  },
  {
    id: "late_afternoon",
    label: "午後遅め",
    time: "14時〜16時発",
    since: "14:00:00",
    until: "16:00:00",
  },
  {
    id: "evening",
    label: "夕方",
    time: "16時〜18時発",
    since: "16:00:00",
    until: "18:00:00",
  },
  {
    id: "early_night",
    label: "夜早め",
    time: "18時〜20時発",
    since: "18:00:00",
    until: "20:00:00",
  },
  {
    id: "late_night",
    label: "夜遅め",
    time: "20時〜22時発",
    since: "20:00:00",
    until: "22:00:00",
  },
  {
    id: "last_flight",
    label: "最終",
    time: "22時〜終電",
    since: "22:00:00",
  },
];


export const departureTimes = [
    {
      id: "morning",
      label: "朝",
      time: "始発便〜10時発",
      until: "10:00:00",
    },
    {
      id: "noon",
      label: "昼",
      time: "10時以降〜14時発",
      since: "10:00:00",
      until: "14:00:00",
    },
    {
      id: "evening",
      label: "夕方",
      time: "14時以降〜18時発",
      since: "14:00:00",
      until: "18:00:00",
    },
    {
      id: "night",
      label: "夜",
      time: "18時以降〜最終便",
      since: "18:00:00",
    },
  ];

export const locations = [
  {
    id: "nearStation",
    label: "駅近",
  },
  {
    id: "downtown",
    label: "繁華街",
  },
  {
    id: "anywhere",
    label: "こだわらない",
  },
];

export const bathChoice = [
  {
    id: "with",
    label: "あり",
  },

  {
    id: "without",
    label: "こだわらない",
  },
];

export const breakfastChoice = [
  {
    id: "with",
    label: "あり",
  },

  {
    id: "without",
    label: "こだわらない",
  },
];

export const smokingChoice = [
  {
    id: "nonsmoking",
    label: "禁煙",
  },
  {
    id: "smoking",
    label: "喫煙",
  },
  {
    id: "either",
    label: "こだわらない",
  },
];

export const dinnerChoice = [
  {
    id: "advice",
    label: "相談する",
  },
  {
    id: "noadvice",
    label: "相談しない",
  },
];

export function getErrorMessage(error: unknown) {
  if (error instanceof Error) return error.message;
  return String(error);
}

export function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export const ERROR_REQUIRED = "必須項目です。";

export const renderError = ({ message }: { message: string }) => (
  <div className="text-red-500 mt-1 font-semibold">{message}</div>
);

export function getDiffDate(date1: Date, date2: Date) {
    const d1 = new Date(date1.getFullYear(), date1.getMonth() + 1, date1.getDate());
    const d2 = new Date(date2.getFullYear(), date2.getMonth() + 1, date2.getDate());

    return Math.floor((d2.getTime() - d1.getTime()) / 86400000);
}

export enum State {
  DEFAULT,
  SCHEDULE,
  DEPARTURE_FLIGHT,
  RETURN_FLIGHT,
  HOTEL,
  OPTION,
  TRANSPORT,
}
