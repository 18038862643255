import { CheckCircleIcon } from "@heroicons/react/solid";

export default function Complete() {
  return (
    <div className="max-w-4xl mx-auto flex flex-col items-center py-10 px-2 space-y-5 text-color">
      <CheckCircleIcon className="text-cyan-600 w-24 h-24" />
      <div className="text-xl md:text-3xl font-semibold">
        お申し込みありがとうございます。
      </div>
      <div>
        ご登録済みのメールアドレス宛に、「受付完了メール」を送信しました。
        <br />
        <span className="font-semibold text-cyan-600">
          travel@jorudan.co.jp
        </span>
        からのメールをご確認ください。ご登録済みのお客様情報は、マイページにて確認いただけます。
      </div>
    </div>
  );
}
