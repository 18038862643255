import { useLocation } from "react-router-dom";
import "../css/stepsBar.css";

export function StepsBar() {
  const location = useLocation();

  return (
    <>
      <div className="w-full">
        <div className="progressbar">
          <div
            id="step1"
            className={`item ${
              location.pathname === "/order/select" ||
              location.pathname === "/orderFree/select"
                ? "active"
                : ""
            }`}
          >
            STEP.1
            <br />
            プラン選択
          </div>
          <div
            id="step2"
            className={`item ${
              location.pathname === "/order/confirm" ||
              location.pathname === "/orderFree/confirm"
                ? "active"
                : ""
            }`}
          >
            STEP.2
            <br />
            内容確認
          </div>
          <div
            id="step3"
            className={`item ${
              location.pathname === "/order/inputInfo" ||
              location.pathname === "/orderFree/inputInfo"
                ? "active"
                : ""
            }`}
          >
            STEP.3
            <br />
            情報入力
          </div>
          <div
            id="step4"
            className={`item ${
              location.pathname === "/order/final" ||
              location.pathname === "/orderFree/final"
                ? "active"
                : ""
            }`}
          >
            STEP.4
            <br />
            最終確認
          </div>
          <div
            id="step5"
            className={`item ${
              location.pathname === "/order/complete" ||
              location.pathname === "/orderFree/complete"
                ? "active"
                : ""
            }`}
          >
            STEP.5
            <br />
            受付完了
          </div>
        </div>
      </div>
    </>
  );
}
