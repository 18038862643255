import { Outlet } from "react-router-dom";
import { HeadBlock } from "../components/HeadBlock";

export default function Info() {
  return (
    <>
      <HeadBlock description="" ogDescription="" />
      <div className="w-full max-w-screen-lg mx-auto my-4 md:my-8 px-2">
        <Outlet />
      </div>
    </>
  );
}
