import { Flight } from "./types";

export function minFare(flight: Flight): number {
  return Math.min.apply(
    null,
    flight.FareItem.map((fare) => fare.Ticket)
  );
}

export function minFareAdult(flight: Flight): number {
  return Math.min.apply(
    null,
    flight.FareItem.map((fare) => fare.TicketAdult)
  );
}

export function minFareChild(flight: Flight): number {
  return Math.min.apply(
    null,
    flight.FareItem.map((fare) => fare.TicketChild)
  );
}

export function isValidDate(d: Date) {
  return !isNaN(d.getTime());
}
