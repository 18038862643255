import style from "../../css/info.module.css";

export function JoukenKaigaibosyu() {
  return (
    <div className="w-full max-w-screen-lg mx-auto my-4 md:my-8 px-2">
      <h2 className={style.pageTitle}>[旅行条件書] 海外募集型企画旅行条件書</h2>
      <p className={style.content}>
        この旅行は、ジョルダン株式会社(以下「当社」という)が旅行を企画して実施するものであり、この旅行に参加されるお客様は、当社と募集型企画旅行契約(以下「契約」という)を締結することになります。
      </p>
      <p className={style.content}>
        また契約の内容・条件は、各コースごとに記載されている条件のほか、下記条件、出発前にお渡しする「行程ご案内」と称する確定書面(以下「行程ご案内」という)及び当社旅行業約款募集型企画旅行契約の部(以下「当社約款」という)によります。
      </p>

      <h3 className={style.title}>１．旅行契約の締結および適用範囲</h3>
      <p className={style.content}>
        (1)
        お申込まれた旅行のコースは、ジョルダン株式会社(以下「当社」といいます。)が企画・実施する旅行であり、この旅行に参加されるお客様は、当社と募集型企画旅行契約(以下「旅行契約」といいます。)を締結することになります。追加代金を支払って本体のコースに付加する「追加プラン」を組合わせたものは、本体とは別のコースとみなし本旅行条件書を適用します。また、「フリーチョイス」と呼称されるコースでは、ルート、旅行日数およびホテルを組合わせて一つのコースとみなします。
      </p>
      <p className={style.content}>
        (2)
        旅行契約の範囲は日本発着のものについては、パンフレット等に記載している出発地の空港を出発してから、当該空港に帰着するまでとなります。海外発着の旅行(以下「ランドオンリー」といいます。)については、出発前にお渡しする確定書面(以下｢旅行日程表｣といいます。)でご案内した集合場所から、解散場所までとなります。また、お客様のご希望により、日本国内の空港から本項(2)の発着空港までの区間を、当社手配の「国内線特別追加プラン」として利用する場合は、この部分は上記区間の手配が完了した時点以降、旅行契約の一部として扱います。
      </p>
      <p className={style.content}>
        (3)
        旅行契約の内容・条件は、本旅行条件書による他、インターネットホームページ(以下「ホームページ」といいます。)、パンフレット、旅行日程表および当社旅行業約款(募集型企画旅行契約の部、以下「約款」といいます。)によります。ただし、ランドオンリーは、当社特定海外旅行業約款募集型企画旅行契約の部(以下「特定約款」といいます。)によります。また、日程中に３泊以上のクルーズを含む旅行(日本発着時に船舶を利用する海外旅行を除きます。)であって、パンフレット上にその旨記載した旅行については、当社クルーズ船を利用する海外旅行に使用する旅行業約款募集型企画旅行の部(以下「クルーズ約款」といいます。)によります。「特定約款」と「クルーズ約款」は、第16項(旅行契約の解除・払戻し)の(1)(お客様の解除権)のお取消料部分以外は、当社約款と同内容となります。
      </p>
      <p className={style.content}>
        (4)
        約款でいう「添乗員」を本旅行条件書およびパンフレットでは「コンダクター」と表示します。
      </p>
      <p className={style.content}>
        (5)
        当社は、お客様が当社の定める旅行日程に従って運送・宿泊機関等の提供する旅行に関するサービス(以下「旅行サービス」といいます。)を受けられるように、手配し、旅程を管理することを引受けます。
      </p>

      <h3 className={style.title}>２．旅行契約のお申込み・予約</h3>
      <p className={style.content}>
        (1) [1]当社
        [2]旅行業法で規定された「受託旅行業者の営業所(受託契約によって定められた旅行業者代理業者を含み、以下[1][2]を併せて「当社ら」といいます。)のそれぞれにおいて、ご来店、電話、ファクシミリ、インターネットその他の通信手段にてお客様からの旅行契約のお申込みまたは予約を承ります。
      </p>
      <p className={style.content}>
        (2)
        当社らは、同一コースにおいて、参加しようとする複数のお客様が責任ある代表者を定めたときは、その方を契約責任者として旅行契約のお申込み・締結・解除等に関する一切の代理権を有しているものとみなし、その団体に係る旅行業務に関する取引は、契約責任者との間で行うことがあります。この場合、契約責任者は当社が定める日までに、構成者の名簿を当社に提出しなければなりません。また、当社は契約責任者が当該団体・グループに同行しない場合は、旅行開始後においては、あらかじめ契約責任者が選任した構成者を契約責任者とみなします。
      </p>
      <p className={style.content}>
        (3)
        ご来店の場合、所定の申込書(以下「申込書」といいます)の提出と、申込金のお支払いをもってお申込みいただきます。
      </p>
      <p className={style.content}>
        (4)
        当社らは、電話、郵便、ファクシミリ、インターネット、その他の通信手段による旅行契約の予約を承ります。この場合、予約の時点では第４項でいう旅行契約は成立しておらず、お客様は予約日の翌日から起算して原則として３日以内の当社らが定めた所定の期日(以下「所定日」といいます。)に申込書の提出と申込金のお支払いが必要です。
      </p>
      <p className={style.content}>
        (5)
        前(4)につき、所定日までに申込金のお支払いがない場合、当社らは、お客様に通知のうえ当該予約はなかったものとして取扱うことがあります。(通信契約【第22項】の場合を除きます。)
      </p>
      <p className={style.content}>
        (6)
        取消料対象期間外に申込まれた場合で、当時点において、満席、満室その他の事由で旅行契約の締結の承諾が直ちにできないときは、当社らはその旨を説明して以下の取扱いをします。
      </p>
      <p className={style.contentml2}>
        [1]
        お客様が旅行契約の締結を強く希望されるときは、前(3)または(4)に従い申込書の提出と申込金のお支払いをしていただきます。
      </p>
      <p className={style.contentml2}>
        [2]
        手配の完了等で当社が旅行契約の締結の承諾が可能となる時点(以下「契約締結可能時点」といいます。)が、取消料対象期間内に入ることが予想されるときは、当該期間に入る日よりも前にお客様にその旨を通知します。
      </p>
      <p className={style.contentml2}>
        [3]
        前[2]の通知時点でお客様が旅行契約の締結を引続き強く希望される場合は、お客様の旅行契約に対する待機可能期限(以下「契約待機可能期限」といいます。)を確認し、お客様をウェイティングのお客様として登録し、手配の完了に向けて努力します。
      </p>
      <p className={style.content}>
        (7)
        取消料対象期間内にお申込みされた場合、当時点において、満席、満室その他の事由で旅行契約の締結の承諾が直ちにできないときは、当社らはその旨を説明して以下の取扱いをします。
      </p>
      <p className={style.contentml2}>
        [1]
        お客様が旅行契約の締結を強く希望されるときは、前(3)または(4)に従い、申込書の提出と申込金のお支払いをしていただきます。(通信契約【第22項】の場合を除きます。)
      </p>
      <p className={style.contentml2}>
        [2]
        契約待機可能期限を確認した後に、お客様をウェイティングのお客様として登録し、手配の完了に向けて努力をします。
      </p>
      <p className={style.content}>
        (8) ウェイティングの登録は手配の完了を保証するものではありません。
      </p>
      <p className={style.content}>
        (9)
        申込金の額は以下とします。なお、申込金は後記する「お支払い対象旅行代金」「取消料」「違約金」のそれぞれ一部または全部として取扱います。また第４項に定めた旅行契約成立前に、お客様がお申込みを撤回されたときは、お預かりしている申込金を全額払戻します。
      </p>

      <table className={style.tblHalf}>
        <thead>
          <tr className={style.tblHalfTR}>
            <th>旅行代金(お1人様)</th>
            <th>申込金</th>
          </tr>
        </thead>
        <tbody>
          <tr className={style.tblHalfTR}>
            <td>15万円未満</td>
            <td>20,000円以上旅行代金まで</td>
          </tr>
          <tr className={style.tblHalfTR}>
            <td>15万円以上30万円未満</td>
            <td>30,000円以上旅行代金まで</td>
          </tr>
          <tr className={style.tblHalfTR}>
            <td>30万円以上</td>
            <td>50,000円以上旅行代金まで</td>
          </tr>
        </tbody>
      </table>

      <p className={`${style.content} ${style.mt4}`}>
        ※上記表内の「旅行代金」とは第６項の「お支払い対象旅行代金」をいいます。ただし、特定期間および特定コースではこれと異なる場合があり、その際はその旨詳細を別途パンフレットに表示します。
      </p>
      <p className={style.content}>
        (10)
        当社らは、申込手続き完了の場合、旅行契約成立前(後)における申込撤回(契約解除)等の連絡に係る当社らの営業日・営業時間・連絡先(電話・ファクシミリ等)および連絡方法を案内します。
      </p>

      <h3 className={style.title}>３．お申込条件・参加条件 </h3>
      <p className={style.content}>
        (1)
        お申込時点で未成年のお客様は、当社が別途定めた一定条件に該当する場合を除き保護者の同意書の提出が必要です。
      </p>
      <p className={style.content}>
        (2)
        旅行開始日時点で15歳未満のお客様は、特定コース(小・中学生のみが参加対象のツアー等)に参加する場合を除き当該参加者の保護者の同行が必要です。なお、保護者が同行できない場合は、特定コースを除き、当該保護者が指定した16歳以上のお客様の同行が必要です。(当該同行者が未成年の場合は前(1)が同様に適用となります。)
      </p>
      <p className={style.content}>
        (3)
        特定の目的をもつ旅行については参加者の性別、年齢、資格、技能その他の参加条件に合致しない場合は、お申込みをお断りすることがあります。
      </p>
      <p className={style.content}>
        (4)
        現在健康を損なわれているお客様、慢性疾患、妊娠中の方、または障害をお持ちのお客様で特別の配慮を必要とする場合は、その旨を旅行のお申込時点でお申出ください。当社は可能かつ合理的な範囲内でこれに応じます。なお、お客様からのお申出に基づき、当社がお客様のために講じた特別な措置に要する費用はお客様の負担とします。{" "}
      </p>
      <p className={style.content}>
        (5) 身体に障害をお持ちのお客様は所定の「お伺い書」の提出をお願いします。
      </p>
      <p className={style.content}>
        (6)
        妊娠中のお客様は、ご自身の責任においてご参加をしていただきます。ただし、妊娠36週以降(出産予定日の４週間以内)の航空機搭乗および出産予定日がはっきりしない場合は、航空会社所定の診断書の提出が必要です。また航空機搭乗が出産予定日の14日以内の場合は、産科医の同行が必要です。
      </p>
      <p className={style.content}>
        (7)
        いずれの場合も現地事情や運送・宿泊機関等の状況により、お申込をお断りさせていただくか、お客様のご負担で介助の為の同伴者の同行等を条件とさせていただく場合があります。また、ご参加の場合には旅行契約の内容の一部を変更させていただくことがあります。
      </p>
      <p className={style.content}>
        (8)
        他のお客様に迷惑を及ぼし、または団体行動の円滑な実施を妨げるおそれがあると当社が判断する場合は、お申込みをお断りすることがあります。
      </p>
      <p className={style.content}>
        (9)
        お客様の都合による別行動(主に航空機区間)はできません。ただし、別途当社らが手配旅行契約で別途料金をお支払いいただくことでお受けすることがあります。
      </p>
      <p className={style.content}>
        (10)
        お客様の都合により旅行の行程から離団される場合は、その旨、復帰の有無および復帰の予定日等の連絡が必要です。その場合、離団した部分の旅行費用(第９項(1)に記載されたもの等)の払戻しは行いません。
      </p>
      <p className={style.content}>
        (11) その他当社らの業務上の都合で、お申込みをお断りすることがあります。
      </p>

      <h3 className={style.title}>４．お客様との旅行契約成立時点 </h3>
      <p className={style.content}>
        (1)
        第２項(3)(4)の場合は、当社らが契約の締結を承諾し、かつ申込金を受理した時点で成立します。
      </p>
      <p className={style.content}>
        (2)
        第２項(6)(7)の場合は、同(6)[3]、(7)[2]の契約待機可能期限内に契約締結可能時点が到来し、かつこの時点までにお客様から当該申込の撤回の連絡がなく、当社らが契約締結が可能になった旨をお客様に連絡したときは、この時点で成立します。
      </p>
      <p className={style.content}>
        (3)
        電話またはご来店による事前のお申込みまたは予約が一切なく、ファクシミリ、インターネット、電子メールその他の通信手段にてお申込みまたは予約がなされた場合は以下の時点で成立します。
      </p>
      <p className={style.contentml2}>
        [1]
        事前に申込金のお支払いがあったときは、当社らが承諾した旨の通知を発した時
      </p>
      <p className={style.contentml2}>
        [2]
        事前に申込金のお支払いがないときは、当社らが申込金を受理した後に当社らが承諾した旨の通知を発した時
      </p>

      <h3 className={style.title}>５．契約書面および確定書面</h3>
      <p className={style.content}>
        (1)
        契約書面とは[1]パンフレット[2]本旅行条件書[3]旅行契約締結年月日を証する書面(ただし、第22項の通信契約のときを除きます。)をいい、確定書面とは出発前にお渡しする旅行日程表のことをいいます。
      </p>
      <p className={style.content}>
        (2)
        当社らは、旅行契約成立後、速やかに契約書面をお渡しします。ただし、既にお申込時点でこれらをお渡ししている場合は、この限りではありません。
      </p>
      <p className={style.content}>
        (3)
        当社が旅行契約により手配し旅程を管理する義務を負う旅行サービスは第1項(2)に示す募集型企画旅行の適用範囲の中で契約書面および確定書面に記載するところによります。
      </p>
      <p className={style.content}>
        (4)
        [1]旅行日程[2]宿泊機関の名称[3]最低限、日本発着時に利用する運送機関の名称およびその便名等[4]旅行サービスの提供を最初に受けるために集合場所および時刻を設定している場合には当該場所および時刻[5]後記第17項のコンダクターが同行しない場合の旅行地における当社との連絡方法等が契約書面に記載されていない場合には、これらを記載した旅行日程表をお渡しします。
      </p>
      <p className={style.content}>
        (5)
        旅行日程表については、遅くとも旅行開始日の前日までにお渡しします。(年末年始やゴールデンウイーク等の特定時期に出発するコースを除き、原則として旅行開始日の７日前までにはお渡しできるよう努力します。)なお、旅行のお申込みが旅行開始日の前日から起算して７日前以降になされた場合、旅行開始日当日にお渡しすることがあります。
      </p>
      <p className={style.content}>
        (6)
        当社らは、旅行日程表をお渡しする前であっても、当社の手配状況の確認を希望するお客様から問い合わせがあった場合は、迅速かつ適切にこれに回答します。
      </p>

      <h3 className={style.title}>６．お支払い対象旅行代金</h3>
      <p className={style.content}>
        「お支払い対象旅行代金」とは、以下[1][2]の合計金額から「割引代金」を差引いた金額(以下本旅行条件書内では単に「旅行代金」といいます。)をいい、「申込金」「取消料」「違約金」および第20項の「変更補償金」のお支払いの際の基準となります。「割引代金」とは第12項に記載するものをいいます。
      </p>
      <p className={style.contentml2}>
        [1]
        パンフレットの価格表示欄に「旅行代金」として表示された金額(以下「表示代金」といい、その内訳は第９項に定めます。)
      </p>
      <p className={style.contentml2}>
        [2]「追加代金」としてパンフレットに表示した金額(内訳は第11項に定めます。)
      </p>

      <h3 className={style.title}>７．旅行代金のお支払い期日</h3>
      <p className={style.content}>
        (1)
        第４項の旅行契約成立時点以降、旅行開始日の前日から起算してさかのぼって21日目に当たる日(以下「基準日」といいます。)よりも前にお支払いいただきます。
      </p>
      <p className={style.content}>
        (2)
        基準日以降にお申込みされた場合は、申込時点または旅行開始日前の指定期日までにお支払いいただきます。
      </p>

      <h3 className={style.title}>８．渡航書類の取得</h3>
      <p className={style.content}>
        (1)
        旅行に必要な旅券、査証(ビザ)、再入国許可および各種証明書(以下「渡航書類」といいます。)の取得については、お客様自身で行っていただきます。
      </p>
      <p className={style.content}>
        (2)
        日本国の旅券をお持ちのお客様の場合は、お申込みのコースに必要とされる旅券の残存期間および査証の必要な国名についてはパンフレット各コースのご注意欄に記載しています。これらはパンフレット作成時点の公的機関の情報に基づき記載しています。お申込時点の最新情報については販売店にご確認ください。日本国以外の旅券をお持ちのお客様は、自国の領事館、渡航先国の領事館および入国管理事務所にお問合わせください。
      </p>
      <p className={style.content}>
        (3)
        当社らの旅行業約款(渡航手続代行契約の部)の規定に基づき、当社と旅行契約を締結したお客様からの依頼によって、当社らは、以下の業務を行うことがあります。その場合、当社らは、当該約款に定める渡航書類の取得の代行手続き等に対する旅行業務取扱料金をいただきます。
      </p>
      <p className={style.contentml2}>[1] 渡航書類の取得に関する手続き</p>
      <p className={style.contentml2}>[2] 出入国手続き書類の作成</p>
      <p className={style.contentml2}>[3] その他前[1][2]に関連する業務</p>
      <p className={style.content}>
        (4)
        当社らは、前記(3)[1]～[3]の業務を行うことで、実際にお客様が渡航書類を取得できることおよび関係国への出入国が許可されることを保証するものではありません。従って、当社らの責に帰すべき事由によらず、お客様が渡航書類の取得ができず、または関係国への出入国が許可されなかったとしても、当社らはその責任を負うものではありません。
      </p>

      <h3 className={style.title}>９．「表示代金」に含まれるもの</h3>
      <p className={style.content}>
        (1) パンフレットに旅行日程として表示された以下のものが含まれています。
      </p>
      <p className={style.contentml2}>
        [1]
        航空運賃・料金(コースにより等級が異なります。また、ランドオンリーは、除きます。)
      </p>
      <p className={style.contentml2}>
        [2] 船舶、鉄道等上記[1]以外の利用運送機関の運賃料金
      </p>
      <p className={style.contentml2}>
        [3]
        送迎バス等の代金(空港、駅、埠頭と宿泊ホテル間)、都市間の移動バス等の代金。ただし、旅行日程に「お客様負担」と記載してある場合を除きます。
      </p>
      <p className={style.contentml2}>
        [4] 観光・視察の代金(バス等の代金、ガイド・通訳・入場代金等)
      </p>
      <p className={style.contentml2}>
        [5]
        ホテル等に係る宿泊代金、税金、サービス料金(２人部屋をお２人で使用することを基準とします。)
      </p>
      <p className={style.contentml2}>
        [6] 食事に係る代金(機内食は除外)、税金、サービス料金
      </p>
      <p className={style.contentml2}>
        [7]
        お１人につきスーツケース等１個の受託手荷物運送代金(お１人２０kg以内が原則ですが、クラス・方面によって異なりますので詳しくは係員におたずねください。)手荷物の運送は当該運送機関が行い、当社が運送機関に運送委託手続を代行するものです。
      </p>
      <p className={style.contentml2}>
        [8] コンダクター同行コースでのコンダクター同行代金
      </p>
      <p className={style.contentml2}>
        [9] 空港・駅・埠頭および宿泊ホテル等における送迎等のサービスに係る代金
      </p>
      <p className={style.contentml2}>
        [10]
        「フリーチョイス」と呼称されるコースが設定された場合の「基本代金」(ルート代金＋ホテル宿泊代金)と「追加代金」
      </p>
      <p className={style.contentml2}>
        [11] その他パンフレットの中で含まれる旨表示したもの
      </p>
      <p className={style.content}>
        (2)
        上記のものはお客様の都合により利用しなくても払戻しの対象外となります。
      </p>

      <h3 className={style.title}>10．「表示代金」に含まれないもの</h3>
      <p className={style.content}>
        [1]
        渡航手続諸経費(旅券・査証の取得料金、予防接種料金および渡航手続代行料金)
      </p>
      <p className={style.content}>
        [2] 日本国内における自宅から発着空港等までの交通費や宿泊費等
      </p>
      <p className={style.content}>
        [3] 日本国内の空港を利用する場合の空港施設使用料
      </p>
      <p className={style.content}>
        [4] 超過手荷物料金(規定の重量・容積・個数の超過分)
      </p>
      <p className={style.content}>
        [5]
        クリーニング、電話に係る料金、ホテルノボーイ、メイド等へのチップ、その他追加飲食等の個人的諸費用
      </p>
      <p className={style.content}>[6] 傷害・疾病に関する医療費等</p>
      <p className={style.content}>
        [7]
        日本国外の空港税、出国税およびこれに類する諸税(コースによっては「表示代金」に含まれることもあり、その場合にはパンフレット等に表示します。)
      </p>
      <p className={style.content}>
        [8]
        「オプショナルツアー」等と呼称し、現地にて現地旅行会社等が希望者のみを募って実施する小旅行
      </p>
      <p className={style.content}>
        [9]
        運送機関の課す付加運賃・料金(原価の水準の異常な変動に対応するため、一定の期間および一定の条件下に限りあらゆる旅行者に一律に課されるものに限る。)
      </p>
      <p className={style.content}>
        [10] その他パンフレットの中で「○○料金」と称するもの。
      </p>

      <h3 className={style.title}>11．追加代金</h3>
      <p className={style.content}>
        第６項[2]でいう「追加代金」とは(ただし、第９項(1)[10]でいう「追加代金」を除きます。)以下をいいます。
      </p>
      <p className={style.contentml2}>
        [1]
        お客様の希望により１人(２人)部屋を１人で使用することを保証するための追加代金
      </p>
      <p className={style.contentml2}>
        [2]
        １人または奇数人数で参加される際に、他のお客様との相部屋を行わない旨を当社が定め、その旨をパンフレットに表示したときの１人部屋または２人部屋を１人で使用した際に係る「１人部屋追加代金」
      </p>
      <p className={style.contentml2}>
        [3]
        たとえばスタンダードクラスルームからスイートルームへの変更のようなお部屋の等級アップに関する「グレードアップ追加代金」
      </p>
      <p className={style.contentml2}>[4] 「延泊プラン」による延泊代金</p>
      <p className={style.contentml2}>
        [5] 「カセットプラン」と称し、旅行契約の内容そのものとなる小旅行
      </p>
      <p className={style.contentml2}>
        [6]
        「C、Fクラス追加代金」と称する航空機使用座席の等級変更に要する差額運賃
      </p>
      <p className={style.contentml2}>
        [7] その他パンフレットの中で「○○追加代金」と称するもの
      </p>

      <h3 className={style.title}>12．割引代金</h3>
      <p className={style.content}>
        第６項でいう「割引代金」とは以下をいい、その一部を例示します。
      </p>
      <p className={style.contentml2}>
        [1]
        １つの部屋に３人以上のお客様が宿泊することを条件に設定した１人あたりのお部屋割引代金
      </p>
      <p className={style.contentml2}>
        [2] その他パンフレットの中で「○○割引代金」と称するもの
      </p>

      <h3 className={style.title}>13．旅行契約内容の変更</h3>
      <p className={style.content}>
        (1)
        当社は旅行契約の成立後であっても、天災地変、戦乱、暴動、運送・宿泊機関等の旅行サービス提供の中止、官公署の命令、当初の運送計画によらない運送サービスの提供その他の当社の関与し得ない事由が生じた場合において、また、お客様に固有の事情が生じた場合において、旅行の安全かつ円滑な実施を図るためやむを得ないときは、旅行契約の内容(以下「契約内容」といいます。)を変更することがあります。
      </p>
      <p className={style.content}>
        (2)
        前(1)の場合は、変更の事由に当社が関与し得ないことおよび契約内容の変更との相当因果関係を事前に説明します。ただし、緊急の場合においてやむを得ない場合は、変更後に説明します。
      </p>

      <h3 className={style.title}>14．旅行代金の額の変更</h3>
      <p className={style.content}>
        (1)
        利用する運送機関の運賃・料金が、著しい経済情勢の変化等により、通常想定される程度を大幅に超えて増額または減額される場合、当社は、その増額または減額される金額の範囲内で旅行代金の額を増加し、または減少することがあります。
      </p>
      <p className={style.content}>
        (2)
        前(1)により旅行代金を増額するときは、旅行開始日の前日から起算してさかのぼって15日目にあたる日より前にお客様にその旨を通知します。
      </p>
      <p className={style.content}>
        (3)
        当社は、前(1)により運賃・料金の減額がなされるときは、その減少額だけ旅行代金を減額します。
      </p>
      <p className={style.content}>
        (4)
        当社は、第13項に基づく契約内容の変更により旅行の実施に要する費用の減少または増加が生じる場合は、当該契約内容の変更の際にその範囲内において旅行代金の額を変更することがあります。この「旅行の実施に要する費用」には当該契約内容の変更のために提供を受けられなかった運送・宿泊機関等が提供する旅行サービスに対する取消料、違約料その他すでに支払い、またはこれから支払わねばならない費用を含みます。
      </p>
      <p className={style.content}>
        (5)
        前(4)により、旅行の実施に要する費用の増加が生じる場合で、運送・宿泊機関等が当該旅行サービスの提供を行っているにもかかわらず、運送・宿泊機関等の座席・部屋その他の諸施設の不足が発生したこと(以下「オーバーフロー」といいます。)によるときは旅行代金の額の変更をいたしません。
      </p>
      <p className={style.content}>
        (6)
        当社は、運送・宿泊機関等の利用人員により旅行代金が異なる旨をパンフレットに記載した場合において、旅行契約の成立後に当社の責に帰すべき事由によらず当該利用人員が変更となったときは、パンフレットに記載したところにより旅行代金の額を変更することがあります。
      </p>

      <h3 className={style.title}>15．お客様の交替</h3>
      <p className={style.content}>
        (1)
        お客様は、当社の承諾を得て、旅行契約上の地位を別の方に譲渡することができます。(ただし、コースにより、また時期により交替をお受けできないことがあります。)この場合、お客様は、第16項(1)[1](ア)に定めた取消料のお支払いに替え当社らに交替に要する手数料として、交替を受けるお客様１人あたり10,500円をお支払いいただきます。(ただし、取消料対象期間外の場合を除きます。)また、この交替のお申出が旅行開始日の前日から起算してさかのぼって３日目にあたる日以降の場合で、お客様の交替に伴う航空券の再発券に際し、航空運賃に差額が生じるときは、それらをお客様の負担とします。
      </p>
      <p className={style.content}>
        (2)
        旅行契約上の地位の譲渡の効力は前(1)の承諾を得て、かつ手数料を当社らが受理した時に生じます。(ただし、手数料不要の場合は承諾時に生じます。)
      </p>

      <h3 className={style.title}>16．旅行契約の解除・払戻し</h3>
      <p className={style.content}>(1) 旅行開始前</p>
      <p className={style.contentml2}>[1] 旅行開始前のお客様の解除権</p>
      <p className={style.contentml2}>
        (ア)
        お客様は第４項により旅行契約が成立した後に以下の区分により定められた取消料をお支払いいただくことにより、旅行契約を解除することが出来ます。
      </p>

      <table className={style.tbl}>
        <thead>
          <tr className={style.tblTR}>
            <th>旅行契約の区分</th>
            <th>適用する取消料</th>
          </tr>
        </thead>
        <tbody>
          <tr className={style.tblTR}>
            <td>
              日本出国時または帰国時に航空機を利用する募集型企画旅行契約(貸切り航空機を利用するコースを除きます。)
            </td>
            <td>＜表１＞</td>
          </tr>
          <tr className={style.tblTR}>
            <td>ランドオンリー(第１項(2))</td>
            <td>＜表１＞</td>
          </tr>
          <tr className={style.tblTR}>
            <td>
              旅程中に船舶を利用する旅行でパンフレットにクルーズ約款を適用する旨の明示があるもの
            </td>
            <td>当該パンフレットに明示する取消料によります。</td>
          </tr>
          <tr className={style.tblTR}>
            <td>日本発着時共に船舶を利用する募集型企画旅行契約</td>
            <td>当該船舶に係る取消料の規定によります。</td>
          </tr>
        </tbody>
      </table>

      <p className={`${style.content} ${style.mt4}`}>
        (注1)
        取消料が記載された表でいう「旅行契約の解除期日」(例、下記＜表１＞参照)とは、日本発着・現地発着であるかを問わず、お客様が当社らの営業日、営業時間内に解除する旨をお申出いただいた時を基準とします。(お申出はファクシミリ、電子メール等によるものも含まれます。)お申出の期日により取消料の額に差が生じることもありますので、当社らの営業日、営業時間、連絡先(電話番号、ファクシミリ等)および連絡方法はお客様自身でも申込時点に必ずご確認願います。
      </p>

      <p className={`${style.content} ${style.mt4} ${style.bold}`}>
        ＜表１＞日本出国時または帰国時に航空機を利用する募集型企画旅行契約およびランドオンリーに係る取消料表
      </p>
      <table className={style.tbl}>
        <thead>
          <tr className={style.tblTR3}>
            <th>変更補償金の支払いが必要となる変更</th>
            <th>旅行開始前</th>
            <th>旅行開始後</th>
          </tr>
        </thead>
        <tbody>
          <tr className={style.tblTR2}>
            <td>
              旅行開始日がピーク時の旅行である場合であって、旅行開始日の前日から起算してさかのぼって40日目にあたる日以降31日目にあたる日まで
            </td>
            <td colSpan={2}>
              旅行代金の10％
              <br />
              (最高50,000円まで)
            </td>
          </tr>
          <tr className={style.tblTR3}>
            <td rowSpan={4}>
              旅行開始日の前日から起算してさかのぼって30日目にあたる日以降3日目にあたる日まで
            </td>
            <td>30万円以上</td>
            <td>50,000円</td>
          </tr>
          <tr className={style.tblTR3}>
            <td>
              19万円以上
              <br />
              30万円未満
            </td>
            <td>30,000円</td>
          </tr>
          <tr className={style.tblTR3}>
            <td>
              10万円以上
              <br />
              19万円未満
            </td>
            <td>19,000円</td>
          </tr>
          <tr className={style.tblTR3}>
            <td>10万円未満</td>
            <td>旅行代金の19％</td>
          </tr>
          <tr className={style.tblTR3}>
            <td>旅行開始日の前々日および前日</td>
            <td colSpan={2}>旅行代金の30％</td>
          </tr>
          <tr className={style.tblTR3}>
            <td>旅行開始日当日</td>
            <td colSpan={2}>旅行代金の50％</td>
          </tr>
          <tr className={style.tblTR3}>
            <td>旅行開始後または無連絡不参加</td>
            <td colSpan={2}>旅行代金の100％</td>
          </tr>
        </tbody>
      </table>

      <p className={`${style.content} ${style.mt4}`}>
        (注2)
        「ピーク時」とは、12月20日から１月７日まで、４月27日から５月６日までおよび７月20日から８月31日までをいいます。
      </p>
      <p className={style.content}>
        (注3)
        上記表内の「旅行代金」とは第６項の「お支払い対象旅行代金」をいいます。特定期間および特定コースでは、取消料の額は当社の約款の規定する範囲内で変更となる場合がありその旨当該コースのパンフレットに表示します。
      </p>
      <p className={style.content}>
        (注4)
        追加代金を支払って本体のコースに付加して企画・実施する｢○○プラン｣等と呼称するものは当該プランの代金を旅行代金とみなし＜表１＞に基づき取消料を算出することがあります。その場合はこの旨をパンフレットに明示します。また、当該プランの「出発日」は本体の出発日とみなします。
      </p>
      <p className={style.content}>
        (注5)
        上記表内の「旅行開始後」とは、本条件書第19項「特別補償」に記載する、約款の別紙「特別補償規程」の第２条３項の定めによります。(例えば当社が「受付」を行う場合は、この受付完了時点以降を「旅行開始後」とし、｢受付」を行わない場合は、最初の運送機関が航空機であるときは、搭乗手続きの完了時以降を「旅行開始後」とします。)
      </p>
      <p className={style.content}>
        (イ)
        旅行契約成立後にコースまたは出発日を変更された場合も上記の取消料の対象となります。「フリーチョイス」と呼称されるコースでは、ルート、旅行日数およびホテルを組合わせて一つのコースとみなします。
      </p>
      <p className={style.content}>
        (ウ)
        各種ローン取扱手続上およびその他の渡航手続上の事由により、旅行契約解除の場合も上記の取消料の対象となります。
      </p>
      <p className={style.content}>
        (エ)
        以下に該当する場合は、取消料を支払うことなく旅行契約を解除することができます。
      </p>
      <p className={style.contentml2}>
        a．旅行契約内容が変更されたとき。ただし、その変更が第20項＜表２＞左欄に掲げるもの、その他の重要なものであるときに限ります。
      </p>
      <p className={style.contentml2}>
        b．第14項(1)に基づき旅行代金が増額されたとき。
      </p>
      <p className={style.contentml2}>
        c．天災地変、戦乱、暴動、運送・宿泊機関等の旅行サービス提供の中止、官公署の命令その他の事由が生じた場合であって、旅行の安全かつ円滑な実施が不可能となり、または不可能となるおそれが極めて大きいとき。
      </p>
      <p className={style.contentml2}>
        d．当社らがお客様に対し、第５項(5)の期日までに旅行日程表をお渡ししなかったとき。
      </p>
      <p className={style.contentml2}>
        e．当社の責に帰すべき事由により、パンフレットに記載した旅行日程に従った旅行の実施が不可能となったとき。
      </p>
      <p className={style.content}>
        (オ)
        当社らは前(ア)(イ)(ウ)により旅行契約が解除されたときは、既に受理している旅行代金(または申込金)から所定の取消料を差引き、残りを払戻します。また前(エ)により旅行契約が解除されたときは、既に受理している旅行代金(または申込金)を全額払戻します。
      </p>
      <p className={style.content}>
        (カ)
        旅行日程に含まれる地域について、外務省から「渡航の是非を検討してください。」以上の危険情報が出された場合は、当社は原則として旅行催行を中止いたします。ただし、お客様の安全確保について適切な対応が講じられると判断した場合には旅行を実施いたします。その場合(当社が旅行を実施する場合)、お客様が旅行契約を解除するときは、所定の取消料の対象になります。
      </p>

      <p className={`${style.content} ${style.mt4}`}>
        [2] 旅行開始前の当社の解除権
      </p>
      <p className={style.content}>
        (ア)
        お客様から第７項(1)(2)の期日までに旅行代金のお支払いがないときは、お客様が旅行に参加される意思がないものとみなし、当社らはその翌日に旅行契約を解除します。この場合は前[1](ア)の＜表１＞に定める解除期日に適用される取消料と同額の違約金をお支払いいただきます。
      </p>
      <p className={style.content}>
        (イ) 以下に該当する場合は、当社は旅行契約を解除することがあります。
      </p>
      <p className={style.contentml2}>
        a．お客様が当社のあらかじめ明示した性別、年齢、資格、技能その他の旅行参加条件を満たしていないことが判明したとき。
      </p>
      <p className={style.contentml2}>
        b．お客様が病気、必要な介助者の不在その他の事由により、旅行に耐えられないと認められるとき。
      </p>
      <p className={style.contentml2}>
        c．お客様が他のお客様に迷惑を及ぼし、または団体行動の円滑な実施を妨げるおそれがあると認められるとき。
      </p>
      <p className={style.contentml2}>
        d．お客様が契約内容に関し、合理的な範囲を超える負担を求めたとき。
      </p>
      <p className={style.contentml2}>
        e．パンフレットに表示した最少催行人員に達しなかったとき。この場合は旅行開始日の前日から起算してさかのぼって、23日目(ピーク時に旅行を開始するものについては33日目)にあたる日より前に、旅行の中止をご通知します。
      </p>
      <p className={style.contentml2}>
        f．スキーを目的とする旅行における必要な降雪量等の旅行実施条件であって契約の締結の際に明示したものが成就しないおそれが極めて大きいとき。
      </p>
      <p className={style.contentml2}>
        g．天災地変、戦乱、暴動、運送・宿泊機関等の旅行サービス提供の中止、官公署の命令その他の当社の関与し得ない事由が生じた場合において、パンフレットに記載した旅行日程に従った旅行の安全かつ円滑な実施が不可能となり、または不可能となるおそれが極めて大きいとき。
      </p>
      <p className={style.contentml2}>
        h．前ｇ.の「官公署の命令」の一例として、旅行日程に含まれる地域について、外務省から「渡航の是非を検討してください。」以上の危険情報が出されたとき。(ただし、お客様の安全確保について適切な対応が講じられると判断した場合には旅行を実施いたします。その場合のお取消料は、「(1)[1](カ)」によります。)
      </p>
      <p className={style.content}>
        (ウ)
        当社は、前(イ)により旅行契約を解除した場合は、既に受理している旅行代金(または申込金)の全額払戻します。
      </p>
      <p className={`${style.content} ${style.mt4}`}>(2)旅行開始後</p>
      <p className={style.content}>[1] 旅行開始後のお客様の解除・払戻し</p>
      <p className={style.contentml2}>
        (ア)
        お客様のご都合により途中で離団された場合は、お客様の権利放棄とみなし、離団部分に係る旅行費用の払戻しはいたしません。
      </p>
      <p className={style.contentml2}>
        (イ)
        お客様の責に帰さない事由により旅行日程表に従った旅行サービスの提供が受けられなくなった場合、または当社がその旨を告げたときは、お客様は取消料を支払うことなく当該不可能になった旅行サービス提供に係る部分の契約を解除することができます。
      </p>
      <p className={style.contentml2}>
        (ウ)
        前(イ)の場合、当社は旅行代金のうち、旅行サービスの提供が不可能になった部分に係る旅行費用を払戻します。ただしその事由が当社の責に帰さない場合は、お客様がいまだその提供を受けていない旅行サービスに係る部分の費用から、当社が当該サービス提供者に支払いまたはこれから支払うべき取消料・違約金その他の名目による費用を差引いて払戻します。
      </p>
      <p className={`${style.content} ${style.mt4}`}>
        [2] 旅行開始後の当社の解除・払戻し
      </p>
      <p className={style.content}>
        (ア)
        以下に該当する場合は、当社はお客様に事由を説明して旅行契約を解除することがあります。
      </p>
      <p className={style.contentml2}>
        a．お客様が病気、必要な介助者の不在その他の事由により、旅行の継続に耐えられないとき。
      </p>
      <p className={style.contentml2}>
        b．お客様が旅行を安全かつ円滑に実施するためのコンダクターその他の者による当社の指示に従わない場合、またはこれらの者もしくは同行する他の旅行者に対する暴行もしくは脅迫等により、団体行動の規律を乱し、旅行の安全かつ円滑な実施を妨げるとき。
      </p>
      <p className={style.contentml2}>
        c．天災地変、戦乱、暴動、運送・宿泊機関等の旅行サービス提供の中止、官公署の命令その他の当社の関与し得ない事由が生じた場合であって、旅行の継続が不可能となったとき。
      </p>
      <p className={style.contentml2}>
        d．前ｃ.の「官公署の命令」の一例として、旅行日程に含まれる地域について、外務省から「渡航の是非を検討してください。」以上の危険情報が出され旅行の継続が不可能になったとき。
      </p>
      <p className={style.content}>(イ) 解除の効果および払戻し</p>
      <p className={style.contentml2}>
        前[2](ア)により旅行契約の解除が行われたときであっても、お客様が既に提供を受けた旅行サービスに関する契約は有効に履行されたものとします。当社は旅行代金のうち、お客様がいまだその提供を受けていない旅行サービスに係る部分の費用から、当社が当該サービス提供者に支払いまたはこれから支払うべき取消料・違約金その他の名目による費用を差引いて払戻します。
      </p>
      <p className={style.content}>(ウ) 旅行代金の払戻し</p>
      <p className={style.contentml2}>
        当社は、第14項および第16項の規定により、お客様に対し払戻すべき金額が生じたときは、旅行開始前の解除による払戻しにあっては解除の翌日から起算して７日以内に、減額または旅行開始後の解除による払戻しにあってはパンフレットに記載した旅行終了日の翌日から起算して30日以内にお客様に対し当該金額を払戻します。
      </p>
      <p className={style.content}>
        (エ)
        前[2](ア)ａ.ｃ.により当社が旅行契約を解除した場合は、お客様の依頼に応じて出発地に戻るための必要な手配をします。この場合に要する一切の費用はお客様の負担となります。
      </p>

      <h3 className={style.title}>17. 旅程管理業務</h3>
      <p className={style.content}>
        (1)
        当社は、お客様の安全かつ円滑な旅行の実施を確保することに努力し、お客様に対し以下の業務を行います。
      </p>
      <p className={style.contentml2}>
        [1]
        お客様が旅行中旅行サービスを受けることができないおそれがあると認められる場合は、旅行契約に従った旅行サービスの提供を確実に受けられるために必要な措置を講じます。
      </p>
      <p className={style.contentml2}>
        [2]
        前[1]の措置を講じたにもかかわらず、旅行契約内容を変更せざるを得ないときは、代替サービスの手配を行います。
      </p>
      <p className={style.contentml2}>
        [3]
        前[2]の代替サービスの手配を行うにあたり、旅行日程を変更するときは、変更後の旅行日程が当初の旅行日程の趣旨にかなうものとなるよう努め､また旅行サービスの内容を変更するときは、変更後の旅行サービスが当初の旅行サービスと同様のものとなるよう努める等、旅行契約内容の変更を最小限にとどめるよう努力します。
      </p>
      <p className={style.content}>
        (2)
        コンダクターの同行するコースではコンダクターが、同行しないコースでは当社の係員が旅行を円滑に実施するための必要な業務を行います。なお、この業務は、旅行日程表に当社または手配代行者等の緊急連絡先を記載し、お客様からの連絡を受けてから行なう場合もあります。
      </p>
      <p className={style.content}>
        (3)
        お客様は旅行を円滑に実施するためコンダクターまたは現地係員の指示に従っていただきます。
      </p>
      <p className={style.content}>
        (4) コンダクターの業務は原則として８時から20時までとします。
      </p>

      <h3 className={style.title}>18. 当社の責任</h3>
      <p className={style.content}>
        (1)
        当社は、旅行契約の履行にあたって、当社または当社が手配を代行させた者(以下「手配代行者」といいます。)が故意または過失によりお客様に損害を与えたときは、お客様が被られた損害を賠償します。ただし、損害発生の翌日から起算して２年以内に当社に対して通知があったときに限ります。
      </p>
      <p className={style.content}>
        (2)
        お客様が天災地変、戦乱、暴動、運送・宿泊機関等のサービス提供の中止、運送機関の遅延・不通・スケジュール変更・経路変更などまたはこれによって生じる旅行日程の変更・目的地滞在時間の短縮、官公署の命令、自由行動中の事故、食中毒、盗難、その他当社または当社の手配代行者の関与し得ない事由により損害を被ったときは、当社は前(1)の場合を除き、お客様に対してその損害を賠償する責任を負いません。
      </p>
      <p className={style.content}>
        (3)
        手荷物について生じた前(1)の損害については、前(1)の定めにかかわらず、損害発生の翌日から起算して、21日以内に当社に対して通知があった場合に限り、お客様１人につき、15万円を限度(当社に故意または重大な過失がある場合を除きます。)として賠償します。
      </p>

      <h3 className={style.title}>19. 特別補償</h3>
      <p className={style.content}>
        (1)
        当社は、第18項(1)の定めに基づく当社の責任が生ずるか否かを問わず、約款の別紙「特別補償規程」で定めるところにより、お客様が募集型企画旅行中に急激かつ偶然な外来の事故によって生命、身体または手荷物の上に被った一定の損害について以下のとおり、あらかじめ定める額の補償金および見舞金を支払います。
      </p>
      <p className={style.contentml2}>[1] 死亡補償金：2,500万円</p>
      <p className={style.contentml2}>
        [2] 後遺障害補償金：程度に応じて死亡補償金の３－100％
      </p>
      <p className={style.contentml2}>
        [3] 入院見舞金：入院日数により４万円から40万円
      </p>
      <p className={style.contentml2}>
        [4] 通院見舞金：通院日数により２万円から10万円
      </p>
      <p className={style.contentml2}>
        [5] 携帯品損害補償金：お客様１名につき15万円を限度
      </p>
      <p className={style.content}>
        ただし、補償対象品の１個または１対については10万円を限度とし、現金、クレジットカード、貴重品、撮影ずみのフィルム、磁気テープ、磁気ディスク、シー・ディー・ロム、光ディスク等情報機器(コンピュータおよびその端末装置等の周辺機器)で直接処理を行える記録媒体に記録された情報、その他約款の「特別補償規程」第18条２項に定める品目については補償しません。
      </p>
      <p className={style.content}>
        (2)
        前(1)の損害については当社が第18項(1)の規定に基づく責任を負うときは、その責任に基づいて支払うべき損害賠償金の額の限度において、当社が支払うべき前(1)の補償金は、当該損害賠償金とみなします。
      </p>
      <p className={style.content}>
        (3)
        前(2)に規定する場合において、前(1)の規定に基づく当社の補償金支払い義務は、当社が第18項の規定に基づいて支払うべき損害賠償金(前(2)の規定により損害賠償金とみなされる補償金を含む。)に相当する額だけ減額します。
      </p>
      <p className={style.content}>
        (4)
        お客様が旅行中に被られた損害が、お客様の故意、酒酔い運転、疾病等のほか、旅行に含まれない場合で、自由行動中の山岳登はん、スカイダイビング、ハンググライダー搭乗、超軽量動力機搭乗、ジャイロプレーン搭乗、ヘリコプタースキー、氷河スキーその他これに類する危険な運動中の事故によるもの等約款の「特別補償規程」第３条および第５条に該当する場合は、当社は前(1)の補償金および見舞金を支払いません。ただし、当該運動が募集型企画旅行日程に含まれているときは、この限りではありません。
      </p>
      <p className={style.content}>
        (5)
        当社の募集型企画旅行参加中のお客様を対象として、別途の旅行代金を収受して当社が実施する旅行については、主たる旅行契約の内容の一部として取扱います。
      </p>
      <p className={style.content}>
        (6)
        ただし、パンフレットおよび旅行日程表において、当社の手配による旅行サービスの提供が一切行われない旨が明示された日(これを当社では「無手配日」といいます。)については、当該日にお客様が被った損害について補償金が支払われない旨を明示した場合に限り、募集型企画旅行参加中とはみなしません。
      </p>

      <h3 className={style.title}>20. 旅程保証</h3>
      <p className={style.content}>
        (1)
        当社は、以下の＜表２＞左欄に掲げる契約内容の重要な変更が生じた場合は、旅行代金に同表右欄に記載する率を乗じた額の変更補償金を旅行終了日の翌日から起算して30日以内に支払います。ただし、当該変更が次の[1][2][3]に該当する場合は変更補償金を支払いません。
      </p>
      <p className={style.content}>
        [1]
        ＜表２＞左欄に掲げる契約内容の重要な変更が生じた原因が以下によるものであることが明白な場合。ただし第14項(5)でいうオーバーフローが発生している場合を除きます。
      </p>
      <p className={style.contentml2}>
        (ア) 旅行日程に支障をもたらす悪天候を含む天災地変
      </p>
      <p className={style.contentml2}>(イ) 戦乱</p>
      <p className={style.contentml2}>(ウ) 暴動</p>
      <p className={style.contentml2}>(エ) 官公署の命令</p>
      <p className={style.contentml2}>
        (オ) 欠航、不通、休業等の運送・宿泊機関等の旅行サービス提供の中止
      </p>
      <p className={style.contentml2}>
        (カ)
        遅延、運送スケジュール変更等の当初の運行計画によらない運送サービスの提供
      </p>
      <p className={style.contentml2}>
        (キ) お客様の生命または身体の安全確保のため必要な措置
      </p>
      <p className={style.content}>
        [2] 第18項の規定に基づく当社の責任が明らかであるとき。
      </p>
      <p className={style.content}>
        [3]
        第16項の規定に基づいて募集型企画旅行契約が解除された場合の当該解除された部分に係る変更であるとき。
      </p>
      <p className={style.content}>
        (2)
        当社が支払うべき変更補償金の額は、お客様１名に対して１旅行契約につき旅行代金に15％を乗じた額をもって限度とします。また、お客様１名に対して１旅行契約につき支払うべき変更補償金の額が千円未満であるときは、変更補償金を支払いません。
      </p>
      <p className={style.content}>
        (3)
        当社は、お客様が同意された場合は、金銭による変更補償金の支払いに替え、同等価値以上の物品または旅行サービスの提供により補償を行うことがあります。
      </p>
      <p className={style.content}>
        (4)
        当社が前(1)の規定に基づき変更補償金を支払った後に、当該変更について当社に第18項(1)の規定に基づく責任が発生することが明らかになった場合には、お客様は当該変更に係る変更補償金を当社に返還していただきます。この場合、当社は、当社が支払うべき損害賠償金の額とお客様が返還すべき変更補償金の額を相殺した残額を支払います。
      </p>

      <p className={`${style.content} ${style.mt4} ${style.bold}`}>
        ＜表2＞ 変更補償金
      </p>
      <table className={style.tbl}>
        <thead>
          <tr className={style.tblTR}>
            <th rowSpan={2}>当社が変更補償金を支払う変更</th>
            <th colSpan={2}>
              変更補償金の額＝
              <br />
              お支払い対象旅行代金×1件につき下記の率
            </th>
          </tr>
          <tr>
            <th>旅行開始日前日までにお客様に通知した場合</th>
            <th>旅行開始日以降にお客様に通知した場合</th>
          </tr>
        </thead>
        <tbody>
          <tr className={style.tblTR2}>
            <td>[1] 契約書面に記載した旅行開始日または旅行終了日の変更</td>
            <td>1.5％</td>
            <td>3.0％</td>
          </tr>
          <tr className={style.tblTR2}>
            <td>
              [2]
              契約書面に記載した入場する観光地または観光施設(レストランを含みます。)その他の旅行の目的地の変更
            </td>
            <td>1.0％</td>
            <td>2.0％</td>
          </tr>
          <tr className={style.tblTR2}>
            <td>
              [3]
              契約書面に記載した運送機関の等級または設備のより低い料金のものへの変更(変更後の等級および設備の料金の合計額が等級および設備のそれを下回った場合に限ります。)
            </td>
            <td>1.0％</td>
            <td>2.0％</td>
          </tr>
          <tr className={style.tblTR2}>
            <td>
              [4]
              契約書面に記載した運送機関の種類(航空機・鉄道・船舶・自動車等)または会社名の変更
            </td>
            <td>1.0％</td>
            <td>2.0％</td>
          </tr>
          <tr className={style.tblTR2}>
            <td>
              [5]
              契約書面に記載した日本国内の旅行開始地たる空港または旅行終了地たる空港の異なる便への変更
            </td>
            <td>1.0％</td>
            <td>2.0％</td>
          </tr>
          <tr className={style.tblTR2}>
            <td>
              [6]
              契約書面に記載した日本国内と外国との間における直行便の乗継便または経由便への変更
            </td>
            <td>1.0％</td>
            <td>2.0％</td>
          </tr>
          <tr className={style.tblTR2}>
            <td>
              [7]
              契約書面に記載した宿泊機関の種類(ホテル・コンドミニアム等)または名称の変更
            </td>
            <td>1.0％</td>
            <td>2.0％</td>
          </tr>
          <tr className={style.tblTR2}>
            <td>
              [8]
              契約書面に記載した宿泊機関の客室の種類、設備、景観またはその他の客室の条件の変更
            </td>
            <td>1.0％</td>
            <td>2.0％</td>
          </tr>
          <tr className={style.tblTR2}>
            <td>
              [9]
              前各号に掲げる変更のうち契約書面のツアー・タイトル中に記載があった事項の変更
            </td>
            <td>2.5％</td>
            <td>5.0％</td>
          </tr>
        </tbody>
      </table>

      <p className={`${style.content} ${style.mt4}`}>
        (注1)
        旅行日程表が交付された後は、「パンフレット」は「旅行日程表」と読替えます。
      </p>
      <p className={style.content}>
        (注2)
        [1]については、「旅行開始日」「旅行終了日」それぞれ１件として算出します。
      </p>
      <p className={style.content}>
        (注3)
        [2]については「入場する観光地」「観光施設」それぞれ１件として算出します。
      </p>
      <p className={style.content}>
        (注4)
        [3]については、利用日数にかかわらず、１フライト・１乗車・１乗船ごとに１件として算出します。
      </p>
      <p className={style.content}>
        (注5)
        [4]については、１フライト・１乗車・１乗船ごとに１件としますが、「種類」「会社名」同時変更が発生しても合わせて１件として算出します。また一例としてA航空(Yクラス)からB航空(Cクラス)のように等級がより高いものへの変更を伴うときは、補償対象外とします。
      </p>
      <p className={style.content}>
        (注6)
        [7]の中で「種類」「名称」の同時変更が発生しても合わせて１泊ごとに１件として算出します。
      </p>
      <p className={style.content}>
        (注7)
        [8]の中で複数の同時変更が発生しても合わせて１件として算出します。また一例として１人部屋から２人部屋への変更、スタンダードルームからスイートルームへの変更のように変更の対象ごとに好条件の部屋への変更のときは補償対象外とします。
      </p>
      <p className={style.content}>
        (注8)
        [8]の中で「客室の種類」とは、スタンダード、デラックス、スイート、１人部屋、ツイン・ダブル等の２人部屋、３人部屋等のことをいいます。
      </p>
      <p className={style.content}>
        (注9)
        [8]の中で「客室の設備」とは、バス・シャワーおよびトイレの設備の有無のことをいい、「その他の客室の条件」とは、階数指定、隣部屋指定または禁煙部屋指定等のことをいいます。
      </p>
      <p className={style.content}>
        (注10)
        [8]の中で、下記の場合には、現地の慣習により変更発生とはみなしません。
      </p>
      <p className={style.content}>
        A
        カップル(ご夫婦・ハネムーナー・12歳未満の男女の組合せ・12歳未満のこどもと大人の組合わせ等)
      </p>
      <p className={style.content}>
        B 同性同士(12歳未満のこども同士または大人と12歳未満のこどもの組合わせ等)
      </p>

      <h3 className={style.title}>21. お客様の責任</h3>
      <p className={style.content}>
        (1)
        お客様の故意、過失、法令・公序良俗に反する行為またはお客様が当社の約款の規定を守らないことにより当社が損害を受けた場合は、当社はお客様から損害の賠償を申受けます。
      </p>
      <p className={style.content}>
        (2)
        お客様は、当社から提供される情報を活用し、お客様の権利・義務その他の募集型企画旅行契約の内容について理解するように努めなければなりません。
      </p>
      <p className={style.content}>
        (3)
        お客様は、旅行開始後に、パンフレットや旅行日程表に記載された旅行サービスについて、記載された旅行サービス内容と異なるものと認識したときは、旅行地において速やかに当社、当社の手配代行者または旅行サービス提供者にその旨を申出なければなりません。
      </p>

      <h3 className={style.title}>
        22. 通信契約による旅行契約を締結するときの旅行条件
      </h3>
      <p className={style.content}>
        (1)
        当社らは、当社らが提携するクレジットカード会社(以下「提携会社」といいます。)のカード会員(以下「会員」といいます。)より、会員の署名なくして旅行代金の一部(申込金)等のお支払いを受けること(以下「通信契約」といいます。)を条件に、電話、郵便、ファクシミリ、インターネットその他の通信手段による旅行のお申込みを受ける場合があります。
      </p>
      <p className={style.content}>
        (2)
        前(1)につき、当社らが提携会社と無署名取扱特約を含む加盟店契約がない等、または業務上の理由があるときは当社らは通信契約をお受けできない場合もあります。
      </p>
      <p className={style.content}>
        (3) 通信契約の旅行条件は、通常の旅行条件とは以下の点で異なります。
      </p>
      <p className={style.contentml2}>
        [1]
        通信契約の申込みに際しては、会員は「カード名」「会員番号」「カード有効期限」「会員連絡先」「電子メールアドレス」その他の通信契約を締結するために必要な一切の事項を当社らにお申出いただきます。
      </p>
      <p className={style.contentml2}>
        [2]
        通信契約による募集型企画旅行契約は、お客様より｢支払いの同意」、旅行条件等該当ページの「正常な印刷」(注)の２点がなされたと当社が確認したうえで、当社が契約の締結を承諾する旨の通知がお客様に到達した時に成立するものとします。ただし、契約の承諾の通知を電話または郵便で通知する場合は、その通知を発した時に成立します。
      </p>
      <p className={style.contentml2}>
        [3]
        通信契約での「カード利用日」は、会員および当社らが募集型企画旅行契約に基づく旅行代金等の支払いまたは払戻債務を履行すべき日とし、前者の場合は契約成立日、後者の場合は契約解除のお申出があった日となります。
      </p>
      <p className={style.contentml2}>
        [4]
        与信等の理由により会員のお申出のクレジットカードでお支払いできない場合、当社は通信契約を解除し、第16項(1)の取消料と同額の違約料を申受けます。ただし、当社が別途指定する日までに現金により旅行代金のお支払いをいただいた場合はこの限りではありません。
      </p>
      <p className={style.content}>
        (注)
        事前にお客様の承諾を得て電子的に書面を交付した場合はこの限りではありません。
      </p>

      <h3 className={style.title}>23. その他</h3>
      <p className={style.contentml2}>【海外旅行保険への加入について】</p>
      <p className={style.content}>
        ご旅行中、病気、けがをした場合、多額の治療費、移送費等がかかることがあります。また、事故の場合、加害者への損害賠償請求や賠償金の回収が大変困難である場合があります。これらを担保するため、お客様ご自身で十分な額の海外旅行保険に加入されることをお勧めします。
      </p>
      <p className={style.contentml2}>【危険情報・衛生情報】</p>
      <p className={style.content}>
        (1)
        渡航先(国または地域)によっては、「外務省海外危険情報」等、国・地域の渡航に関する情報が出されている場合があります。お申込みの際に販売店より「海外危険情報に関する書面」をお渡しします。
      </p>
      <p className={style.content}>
        また、
        <a href="http://www.anzen.mofa.go.jp" className={style.externalLink}>
          「外務省海外安全ホームページ」
        </a>
        でもご確認ください。
      </p>
      <p className={style.content}>
        (2) 渡航先の衛生状況については、
        <a href="http://www.anzen.mofa.go.jp" className={style.externalLink}>
          「厚生労働省検疫感染症情報ホームページ」
        </a>
        でもご確認ください。
      </p>
      <p className={`${style.contentml2} ${style.red}`}>
        【旅行契約に含まれない費用のご負担】
      </p>
      <p className={style.content}>
        (3)
        お客様が個人的な案内・買物等をコンダクター・現地係員等に依頼された場合のそれに伴う諸費用、お客様の怪我、疾病等の発生に伴う諸費用、お客様の不注意による荷物紛失・忘れ物回収に伴う諸費用、別行動手配に要した諸費用が生じたときは、それらの費用はお客様にご負担いただきます。
      </p>
      <p className={`${style.contentml2} ${style.red}`}>
        【お買物についてのご注意】
      </p>
      <p className={style.content}>
        (4)
        お客様の便宜を図るためにお土産店にご案内することがありますが、お買物に際しては、お客様の責任で購入していただきます。
      </p>
      <p className={`${style.contentml2} ${style.red}`}>
        【こども代金・幼児代金】
      </p>
      <p className={style.content}>
        (5)
        こども代金は、旅行開始日当日を基準に満２才以上12才未満のお客様に適用します。幼児代金は旅行開始日当日を基準に、満２才未満で航空座席を使用しないお客様に適用し別途ご案内します。また、幼児代金には滞在地上費は含まれず、現地にて実費精算となります。なお、大人１人が同伴できる幼児代金適用者は１人に限られます。幼児が航空機の座席を使用する場合は、こども代金が適用になります。
      </p>
      <p className={`${style.contentml2} ${style.red}`}>
        【オプショナルツアー】
      </p>
      <p className={style.content}>
        (6)
        当社がパンフレットに記載した「オプショナルツアー」とは、現地旅行会社等が現地旅行会社等の名で実施する小旅行で、当社が実施する募集型企画旅行ではありません。従ってお客様は別個の料金をお支払いいただいて任意に参加することができます。
      </p>
      <p className={style.contentml2}>
        [1] お申込みは原則的に現地となり、お支払いも現地となります。
      </p>
      <p className={style.contentml2}>
        [2]
        契約は現地の法令または慣習に基づいて現地旅行会社等が定めた旅行条件によって行われ、当社の旅行条件は適用されません。
      </p>
      <p className={style.contentml2}>
        [3] 契約の成立は、現地旅行会社等が承諾した時に成立します。
      </p>
      <p className={style.contentml2}>
        [4]
        契約成立後の解除・取消料については、お申込みの際、現地旅行会社等にご確認願います。
      </p>
      <p className={style.contentml2}>
        [5]
        現地旅行会社等が実施するオプショナルツアーは旅程保証の対象とはなりません。
      </p>
      <p className={`${style.contentml2} ${style.red}`}>
        【再発券に伴う諸費用】
      </p>
      <p className={style.content}>
        (7)
        旅行開始日の前日から起算してさかのぼって10日目にあたる日以降に以下の事項が発生した場合は、航空券の再発券処理に係る当社手数料として、当該事項の発生が10日目にあたる日以降４日目にあたる日までは4,200円、３日目にあたる日以降旅行開始当日までは10,500円を申受けます。なお、下記(ア)(イ)においては、航空会社・宿泊機関により受諾された時点、また(ウ)については、追加・変更・取消に伴う手配が完了した時点が発生の基準日となります。また、航空券の再発券に際し航空運賃に差額が生じる場合は、それをお客様の負担とします。
      </p>
      <p className={style.contentml2}>(ア) 氏名および性別の訂正</p>
      <p className={style.contentml2}>
        (イ) 大人・こども・幼児の年齢区分の訂正
      </p>
      <p className={style.contentml2}>
        (ウ)
        延泊プランの追加・取消・変更、「国内線特別追加プラン」の追加・取消・変更、航空機の変更をともなう「カセットプラン」等の追加・取消・変更および航空機座席クラスの変更
      </p>
      <p className={`${style.contentml2} ${style.red}`}>
        【マイレージサービス】
      </p>
      <p className={style.content}>
        (8)
        当社の募集型企画旅行にご参加いただくことにより、航空会社のマイレージサービスを受けられる場合がありますが、この場合、同サービスに関するお問合わせ、登録等はお客様ご自身で当該航空会社に行なっていただきます。利用航空会社の変更等により、お客様が当初受ける予定であった同サービスが受けられなくなった場合でも、当社はその理由の如何にかかわらず第18項(2)に従い責任を負いません。
      </p>
      <p className={`${style.contentml2} ${style.red}`}>【再旅行の実施】</p>
      <p className={style.content}>
        (9) 当社はいかなる場合においても旅行の再実施はいたしません。
      </p>
      <p className={`${style.contentml2} ${style.red}`}>
        【ご氏名の英文スペル記入上のご注意】
      </p>
      <p className={style.content}>
        (10)
        旅行お申込時点の氏名はパスポートに記載されているとおりのローマ字綴りで正確に当社らにお知らせください。氏名を誤ってお申込みされた場合には、航空券の再発券や、関係機関等への氏名訂正連絡等が必要となります。この訂正が旅行開始日の前日から起算してさかのぼって10日目にあたる日以降の場合は、前(7)の対象となり、当該訂正が関係機関等により受諾された時点が基準日となります。なお、関係機関等により、氏名の訂正が認められず、旅行契約を解除いただく場合があります。この場合にも第16項の当社所定の取消料の対象になります。
      </p>
      <p className={`${style.contentml2} ${style.red}`}>
        【緊急事態が生じた場合の保護措置と費用のご負担】
      </p>
      <p className={style.content}>
        (11)
        旅行中に、事故等が生じた場合は、直ちに旅行日程表等でお知らせする連絡先にご通知ください。
      </p>
      <p className={style.content}>
        (12)
        当社は、旅行中のお客様が疾病、傷害等により保護を要する状態にあると認められるときは、必要な措置を講じることがあります。この場合において、これが当社の責に帰すべき事由によるものでないときは、当該措置に要した費用はお客様の負担とし、お客様は当該費用を当社が指定する期日までに当社の指定する方法で支払わねばなりません。
      </p>
      <h3 className={style.title}>24. 個人情報のお取り扱いについて(重要)</h3>
      <p className={style.content}>
        (1)
        当社ホームページ記載の国内募集型企画旅行への旅行申込みの際に提出された申込書に記載された個人情報について、お客様との間の連絡のために利用させていただくほか、お客様がお申込みいただいた旅行において運送・宿泊機関等(主要なものについては各コース等に記載されています)の提供するサービスの手配及びそれらのサービスの受領のための手続きに必要な範囲内で利用させていただきます。＊このほか、当社では、[1]当社と提携する企業の商品やサービス、キャンペーンのご案内。[2]旅行参加後のご意見やご感想の提供のお願い。[3]アンケートのお願い。[4]特典サービスの提供。[5]統計資料の作成。に、お客様の個人情報を利用させていただくことがあります。
      </p>
      <p className={style.content}>
        (2)
        当社が取得する個人情報は、お客様の氏名、年齢、性別、電話番号、住所、メールアドレス、その他コースにより当社が旅行を実施するうえで必要となる最小限の範囲のお客様の個人情報とします。また介助者の同行、車椅子の手配等特別な配慮を必要とする場合で、当社が可能な範囲内でこれに応ずる(又は応じられない旨の回答をする)目的のため、上記以外の個人情報の取得をさせていただくことがありますが、これは当社が手配等をするうえで必要な範囲内とします。
      </p>
      <p className={style.content}>
        (3)
        当社が本項(2)の個人情報を取得することについてお客様の同意を得られない場合は、当社は、募集型企画旅行契約の締結に応じられないことがあります。また同意を得られないことにより、お客様のご希望される手配等が行えない場合があります。
      </p>
      <p className={style.content}>
        (4)
        当社は、お申し込みいただいた旅行の手配のために、運送・宿泊機関等に対し、お客様の氏名、年齢、性別、電話番号、その他手配をするために必要な範囲内での情報を、あらかじめ電子的方法等で送付することによって提供いたします。
      </p>
      <p className={style.content}>
        (5)
        当社は、旅行先において、お客様の手荷物運送等、免税店でのお買い物等の便宜のため、当社の保有するお客様の個人データを運送業者や免税店に提供することがあります。この場合、お客様の氏名、搭乗日及び航空便名等に係る個人データを、あらかじめ電子的方法等で送付することによって提供いたします。なお、これらの事業者への個人データの提供の停止を希望される場合は、お申し込み時にお申し出ください。
      </p>
      <p className={style.content}>
        (6)
        当社は、当社が保有するお客様の個人データのうち、氏名、住所、電話番号又はメールアドレス等のお客様へのご連絡にあたり必要となる最小限の範囲のものについて、当社グループ企業との間で、共同して利用させていただくことがあります。当社グループ企業は、それぞれの企業の営業案内、催し物内容等のご案内、ご購入いただいた商品の発送のために、これを利用させていただくことがあります。なお、当社グループ企業の名称及び個人データの管理について責任を有する者の氏名、又は名称は、プライバシーポリシー［個人情報に関するお問い合わせ・苦情のお申し出先］をご参照ください。
      </p>
      <p className={style.content}>
        (7)
        当社が保有するお客様の個人データの開示、その内容の訂正、追加若しくは削除、又はその利用の停止、消去若しくは第三者への提供の停止をご希望の方は、必要となる手続きについてご案内いたしますので、下記のお問い合わせ窓口までお申し出ください。その際、法令及び当社内規に従い、遅滞なく必要な措置を取らせていただきます。また、ご希望の全部又は一部に応じられない場合はその理由をご説明します。
      </p>
      <p className={style.content}>
        (8)
        万一、当社の個人情報の流出等の問題が発生した場合は、直ちにお客様にご連絡させていただき、安全の確保が保たれるまで問題が発生したシステムを一時停止いたします。また、速やかにホームページ等で事実関係等を公表させていただきます。
      </p>

      <h3 className={style.title}>25. 旅行条件・旅行代金の基準</h3>
      <p className={style.content}>
        本旅行条件の基準日と旅行代金の基準日については、ホームページに明示した日となります。
      </p>
    </div>
  );
}
