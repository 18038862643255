/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/outline";

import { Link } from "react-router-dom";
import { format } from "date-fns";

export default function Modal({ order, show }: { order: any; show: boolean }) {
  const [openModal, setOpenModal] = useState(show);

  useEffect(() => {
    // sync show with openModal
    setOpenModal(show);
  }, [show]);

  const cancelButtonRef = useRef(null);

  let origin = order.origin.region_id;
  let destination = order.destination.region_id;
  let startDate = format(order.startDate, "yyyy-MM-dd");
  let endDate = format(order.endDate, "yyyy-MM-dd");
  let depTimeOrigin = order.depTimeOrigin?.id;
  let depTimeDestination = order.depTimeDestination?.id;
  let hotelArea = order.hotel?.area?.hotelarea_id;
  let hotelClass = order.hotel?.class?.hotelclass_id;
  let location = order.hotel?.room?.location?.id;
  let bath = order.hotel?.room?.bath?.id;
  let breakfast = order.hotel?.room?.breakfast?.id;
  let smoking = order.hotel?.room?.smoking?.id;

  let adults = order.adults;
  let remarks = order.remarks;

  const linkTo = `/orderFree/select?order=${origin}&destination=${destination}&startDate=${startDate}&endDate=${endDate}&transports=air&depTimeOrigin=${depTimeOrigin}&depTimeDestination=${depTimeDestination}&hotelArea=${hotelArea}&hotelClass=${hotelClass}&location=${location}&bath=${bath}&breakfast=${breakfast}&smoking=${smoking}&adults=${adults}&rearks=${remarks}`;

  return (
    <Transition.Root show={openModal} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={openModal}
        onClose={() => {}}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      以下をご確認ください。
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        ご希望の検索条件に当てはまるフライト情報を取得できませんでした。
                        大変恐れ入りますが、下記「この検索条件で相談する」ボタンより、旅行相談へお進みください。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <Link to={linkTo} className="btn-enter mx-auto">
                  この検索条件で相談する
                </Link>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
