import style from "../../css/info.module.css";

export default function Charge() {
  return (
    <div className="w-full max-w-screen-lg mx-auto my-4 md:my-8 px-2">
      <h3 className={style.pageTitle}>[国内旅行] 手配旅行に関する取扱料金</h3>
      <table className={style.tblCharge}>
        <thead>
          <tr className={style.tblChargeTR}>
            <th>区分</th>
            <th colSpan={2}>内容</th>
            <th>料金</th>
          </tr>
        </thead>
        <tbody>
          <tr className={style.tblChargeTR}>
            <td rowSpan={5}>手配料金</td>
            <td rowSpan={2}>
              運送機関と宿泊機関等との
              <br className="hidden md:flex" />
              手配が複合した場合
            </td>
            <td>8人以上の団体手配旅行の場合</td>
            <td>旅行費用総額の20%</td>
          </tr>
          <tr className={style.tblChargeTR}>
            <td>個人(上記以外の場合)</td>
            <td>1件につき540円</td>
          </tr>
          <tr className={style.tblChargeTR}>
            <td rowSpan={2}>宿泊券のみの場合</td>
            <td>8人以上の団体手配旅行の場合</td>
            <td>旅行費用総額の20%</td>
          </tr>
          <tr className={style.tblChargeTR}>
            <td>宿泊券面額の20%</td>
            <td>1件につき1,080円</td>
          </tr>
          <tr className={style.tblChargeTR}>
            <td colSpan={2}>運送機関のみの場合</td>
            <td>1件につき1,080円</td>
          </tr>
          <tr className={style.tblChargeTR}>
            <td colSpan={3}>
              添乗サービス料金(宿泊、交通費等の旅行実費を除く)
            </td>
            <td>添乗員1人1日につき32,400円</td>
          </tr>

          <tr className={style.tblChargeTR}>
            <td rowSpan={4}>変更手続料金</td>
            <td rowSpan={2}>
              運送機関と宿泊機関等との
              <br className="hidden md:flex" />
              手配が複合した手配旅行の場合
            </td>
            <td>8人以上の団体手配旅行の場合</td>
            <td>
              変更に係わる部分の
              <br className="hidden md:flex" />
              変更前の旅行代金の20%以内
            </td>
          </tr>
          <tr className={style.tblChargeTR}>
            <td>個人(上記以外の場合)</td>
            <td>1件につき540円</td>
          </tr>
          <tr className={style.tblChargeTR}>
            <td colSpan={2}>運送機関の予約・手配の変更</td>
            <td>1件につき1,080円</td>
          </tr>
          <tr className={style.tblChargeTR}>
            <td colSpan={2}>
              宿泊手配の予約・手配の変更(宿泊券の切替えが必要な場合はそれを含む)
            </td>
            <td>1件につき540円</td>
          </tr>

          <tr className={style.tblChargeTR}>
            <td rowSpan={4}>取消手続料金</td>
            <td rowSpan={2}>
              運送機関と宿泊機関等との
              <br className="hidden md:flex" />
              手配が複合した場合
            </td>
            <td>8人以上の団体手配旅行の場合</td>
            <td>
              取消に係わる部分の
              <br className="hidden md:flex" />
              旅行代金の20%以内
            </td>
          </tr>
          <tr className={style.tblChargeTR}>
            <td>個人(上記以外の場合)</td>
            <td>1件につき1,080円</td>
          </tr>
          <tr className={style.tblChargeTR}>
            <td colSpan={2}>
              運送機関の手配の取消し
              <br className="hidden md:flex" />
              (未使用乗車船券の精算手続がある場合はそれを含む)
            </td>
            <td>1件につき1,080円</td>
          </tr>
          <tr className={style.tblChargeTR}>
            <td colSpan={2}>
              宿泊機関の手配の取消し
              <br className="hidden md:flex" />
              (未使用宿泊券の精算手続がある場合はそれを含む)
            </td>
            <td>1件につき1,080円</td>
          </tr>

          <tr className={style.tblChargeTR}>
            <td>連絡通信費</td>
            <td colSpan={2}>
              お客様の依頼により緊急に現地手配等の為の通信連絡を行った場合等
            </td>
            <td>
              1件につき540円
              <br className="hidden md:flex" />
              (電話料、電報料は別)
            </td>
          </tr>

          <tr className={style.tblChargeTRL}>
            <td colSpan={4}>
              <p>(注意)</p>
              <p>
                1.団体手配旅行とは、複数の旅行者が代表者を定めて同一行程による旅行をされる場合をいいます。
                <br />
                2.お客様の希望により変更又は取消しを行う場合は、運送機関、宿泊機関等の定める取消料のほか上記の変更手続料金、取消手続料金を申し受けます。
                <br />
                3.同一の宿泊機関に連泊する場合は、まとめて1件として扱います。
                <br />
                4.上記料金には消費税が含まれています。
              </p>
            </td>
          </tr>
        </tbody>
      </table>

      <h3 className={style.pageTitle}>[国内旅行] 相談料金</h3>
      <table className={style.tblCharge3}>
        <thead>
          <tr className={style.tblCharge3TRTH}>
            <th>区分</th>
            <th>内容</th>
            <th>料金</th>
          </tr>
        </thead>
        <tbody>
          <tr className={style.tblCharge3TR3}>
            <td rowSpan={5}>観光旅行</td>
            <td>(1)お客様の旅行計画作成のための相談</td>
            <td>
              基本料金(30分まで)2,160円
              <br className="hidden md:flex" />
              以降30分ごと1,080円
            </td>
          </tr>
          <tr className={style.tblCharge3TR}>
            <td>(2)旅行計画の作成</td>
            <td>旅行日程1日につき2,160円</td>
          </tr>
          <tr className={style.tblCharge3TR}>
            <td>
              (3)旅行に必要な費用の見積り(運送機関と宿泊機関等の手配が複合した旅行の場合)
            </td>
            <td>
              基本料金2,160円と
              <br className="hidden md:flex" />
              旅行日程1日につき540円
            </td>
          </tr>
          <tr className={style.tblCharge3TR}>
            <td>(4)運送機関の運賃・料金の見積り</td>
            <td>1件につき2,160円</td>
          </tr>
          <tr className={style.tblCharge3TR}>
            <td>(5)旅行地及び運送、宿泊機関等に関する情報提供</td>
            <td>資料(A4版)1枚につき1,080円</td>
          </tr>

          <tr className={style.tblCharge3TRC}>
            <td colSpan={2}>お客様の依頼による出張相談</td>
            <td>
              上記(1)から(5)までの料金に
              <br className="hidden md:flex" />
              5,400円増
            </td>
          </tr>

          <tr className={style.tblCharge3TRL}>
            <td colSpan={3}>
              <p>(注意)</p>
              <p>上記料金には消費税が含まれています。</p>
            </td>
          </tr>
        </tbody>
      </table>

      <h3 className={style.pageTitle}>[海外旅行] 手配料金に係わる取扱料金</h3>
      <table className={style.tblCharge}>
        <thead>
          <tr className={style.tblChargeTR}>
            <th>区分</th>
            <th colSpan={2}>内容</th>
            <th>料金</th>
          </tr>
        </thead>
        <tbody>
          <tr className={style.tblChargeTR}>
            <td rowSpan={3}>手配料金</td>
            <td rowSpan={2}>
              運送機関と宿泊機関との
              <br className="hidden md:flex" />
              手配が複合した場合
            </td>
            <td>8人以上の団体手配旅行の場合</td>
            <td>旅行費用総額の20%以内</td>
          </tr>
          <tr className={style.tblChargeTR}>
            <td>個人(上記以外の場合)</td>
            <td>1件につき2,160円</td>
          </tr>
          <tr className={style.tblChargeTR}>
            <td colSpan={2}>運送機関、宿泊期間の予約・手配</td>
            <td>1件につき2,160円</td>
          </tr>
          <tr className={style.tblChargeTR}>
            <td colSpan={3}>
              添乗サービス料金(宿泊、交通費等の旅行実費を除く)
            </td>
            <td>添乗員1人1日につき64,800円</td>
          </tr>

          <tr className={style.tblChargeTR}>
            <td rowSpan={4}>変更手続料金</td>
            <td rowSpan={2}>
              運送機関と宿泊機関等との
              <br className="hidden md:flex" />
              手配が複合した手配旅行の場合
            </td>
            <td>8人以上の団体手配旅行の場合</td>
            <td>
              変更に係わる部分の
              <br className="hidden md:flex" />
              変更前の旅行代金の20%
            </td>
          </tr>
          <tr className={style.tblChargeTR}>
            <td>個人(上記以外の場合)</td>
            <td>1件につき1,080円</td>
          </tr>
          <tr className={style.tblChargeTR}>
            <td colSpan={2}>運送機関の予約・手配の変更</td>
            <td>1件につき1,080円</td>
          </tr>
          <tr className={style.tblChargeTR}>
            <td colSpan={2}>宿泊機関の予約・手配の変更</td>
            <td>1件につき1,080円</td>
          </tr>

          <tr className={style.tblChargeTR}>
            <td rowSpan={4}>取消手続料金</td>
            <td rowSpan={2}>
              運送機関と宿泊機関等との
              <br className="hidden md:flex" />
              手配が複合した場合
            </td>
            <td>8人以上の団体手配旅行の場合</td>
            <td>
              取消に係わる部分の
              <br className="hidden md:flex" />
              旅行代金の20%
            </td>
          </tr>
          <tr className={style.tblChargeTR}>
            <td>個人(上記以外の場合)</td>
            <td>1件につき1,080円</td>
          </tr>
          <tr className={style.tblChargeTR}>
            <td colSpan={2}>未使用乗車船券の精算手配</td>
            <td>1件につき54,000円</td>
          </tr>
          <tr className={style.tblChargeTR}>
            <td colSpan={2}>宿泊手配の取消し</td>
            <td>1件につき2,160円</td>
          </tr>

          <tr className={style.tblChargeTR}>
            <td>連絡通信費</td>
            <td colSpan={2}>
              お客様の依頼により緊急に現地手配等の為の通信連絡を行った場合
            </td>
            <td>
              1件につき2,160円
              <br className="hidden md:flex" />
              (電話料、電報料は別)
            </td>
          </tr>

          <tr className={style.tblChargeTRL}>
            <td colSpan={4}>
              <p>(注意)</p>
              <p>
                1.団体手配旅行とは、複数の旅行者が代表者を定めて同一行程により旅行される場合をいいます。
                <br />
                2.お客様の希望により変更又は取消しを行う場合は、運送機関、宿泊機関等の定める取消料のほか、上記の変更手続料金、取消手続料金を申し受けます。
                <br />
                3.同一の宿泊機関に連泊する場合は、まとめて1件として扱います。
                <br />
                4.上記料金には消費税が含まれています。
              </p>
            </td>
          </tr>
        </tbody>
      </table>

      <h3 className={style.pageTitle}>[海外旅行] 渡航手続代行料金</h3>
      <table className={style.tblCharge3}>
        <thead>
          <tr className={style.tblCharge3TRTH}>
            <th>区分</th>
            <th>内容</th>
            <th>料金</th>
          </tr>
        </thead>
        <tbody>
          <tr className={style.tblCharge3TR3}>
            <td rowSpan={4}>旅券</td>
            <td>(1)申請手続(申請書類作成のみ)</td>
            <td>4,320円</td>
          </tr>
          <tr className={style.tblCharge3TR}>
            <td>(2) (1)と申請又は受領のための都道府県庁への同行案内</td>
            <td>(1)の料金に4,320円増(交通費は別)</td>
          </tr>
          <tr className={style.tblCharge3TR}>
            <td>(3) (1)と代理申請又は法令で認められている代理受理</td>
            <td>(1)の料金に5,400円増(交通費は別)</td>
          </tr>
          <tr className={style.tblCharge3TR}>
            <td>(4) (1)と緊急渡航手続</td>
            <td>(1)の料金に10,800円増</td>
          </tr>
          <tr className={style.tblCharge3TR3}>
            <td>出入国記録書</td>
            <td>(1)出入国記録書類の作成代行</td>
            <td>(1国につき)4,320円</td>
          </tr>
          <tr className={style.tblCharge3TR3}>
            <td rowSpan={6}>査証</td>
            <td>(1)観光性査証の申請書作成代行又は作成代行と申請・受領代行</td>
            <td>(1国につき)5,400円</td>
          </tr>
          <tr className={style.tblCharge3TR}>
            <td>
              (2)商用・業務性査証の申請書作成代行又は作成代行と申請・受領代行
            </td>
            <td>(1国につき)10,800円</td>
          </tr>
          <tr className={style.tblCharge3TR}>
            <td>(3)移民、留学、役務、長期滞在等特別な目的により渡航する場合</td>
            <td>(1国につき)16,200円</td>
          </tr>
          <tr className={style.tblCharge3TR}>
            <td>(4)査証所得手続代行者に依頼する場合の申請手続</td>
            <td>4,320円(手続代行者への実費は別)</td>
          </tr>
          <tr className={style.tblCharge3TR}>
            <td>(5)緊急査証手続</td>
            <td>(1)の料金に6,480円増</td>
          </tr>
          <tr className={style.tblCharge3TR}>
            <td>(6)査証免除の手続書類の作成</td>
            <td>(1国につき)5,400円</td>
          </tr>
          <tr className={style.tblCharge3TR3}>
            <td>検疫</td>
            <td>検疫所、保健所、診療所等への同行案内又は検印の取得代行</td>
            <td>2,160円(処置料、交通費は別)</td>
          </tr>
          <tr className={style.tblCharge3TR3}>
            <td>各種証明書</td>
            <td>
              警察証明書、兵役証明書、健康証明書、卒業証明書等の所得同行案内、署名認証の所得代行
            </td>
            <td>10,800円(交通費は別)</td>
          </tr>
          <tr className={style.tblCharge3TR3}>
            <td>再入国許可</td>
            <td>再入国許可の申請手続</td>
            <td>5,400円</td>
          </tr>
          <tr className={style.tblCharge3TR3}>
            <td>その他</td>
            <td>上記に含まれないもの</td>
            <td>実費</td>
          </tr>

          <tr className={style.tblCharge3TRL}>
            <td colSpan={3}>
              <p>(注意)</p>
              <p>
                1. 上記料金は1人又は1件を対象とした料金です。
                <br />
                2.上記の各該当料金は合算して申し受けます。
                <br />
                3.上記料金には消費税が含まれています。
              </p>
            </td>
          </tr>
        </tbody>
      </table>

      <h3 className={style.pageTitle}>[海外旅行] 相談料金</h3>
      <table className={style.tblCharge3}>
        <thead>
          <tr className={style.tblCharge3TRTH}>
            <th>区分</th>
            <th>内容</th>
            <th>料金</th>
          </tr>
        </thead>
        <tbody>
          <tr className={style.tblCharge3TR3}>
            <td rowSpan={5}>観光旅行</td>
            <td>(1)お客様の旅行計画作成のための相談</td>
            <td>
              基本料金(30分まで)2,160円
              <br className="hidden md:flex" />
              以降30分ごと1,080円
            </td>
          </tr>
          <tr className={style.tblCharge3TR}>
            <td>(2)旅行計画の作成</td>
            <td>旅行日程1日につき2,160円</td>
          </tr>
          <tr className={style.tblCharge3TR}>
            <td>
              (3)旅行に必要な費用の見積り(運送機関と宿泊機関等の手配が複合した旅行の場合)
            </td>
            <td>
              基本料金2,160円と
              <br className="hidden md:flex" />
              旅行日程1日につき540円
            </td>
          </tr>
          <tr className={style.tblCharge3TR}>
            <td>(4)運送機関の運賃・料金の見積り</td>
            <td>1件につき2,160円</td>
          </tr>
          <tr className={style.tblCharge3TR}>
            <td>(5)旅行地及び運送、宿泊機関等に関する情報提供</td>
            <td>資料(A4版)1枚につき1,080円</td>
          </tr>

          <tr className={style.tblCharge3TRC}>
            <td>その他の旅行</td>
            <td>留学、移民、国際結婚等特殊な目的を伴う渡航相談</td>
            <td>
              基本料金(30分まで)2,160円
              <br className="hidden md:flex" />
              以降30分ごと1,080円
            </td>
          </tr>
          <tr className={style.tblCharge3TRC}>
            <td colSpan={2}>お客様の依頼による出張相談</td>
            <td>
              上記(1)から(5)までの料金に
              <br className="hidden md:flex" />
              5,400円増
            </td>
          </tr>

          <tr className={style.tblCharge3TRL}>
            <td colSpan={3}>
              <p>(注意)</p>
              <p>上記料金には消費税が含まれています。</p>
            </td>
          </tr>
        </tbody>
      </table>

      <h3 className={style.pageTitle}>[海外旅行] その他の料金</h3>
      <table className={style.tblCharge3}>
        <thead>
          <tr className={style.tblCharge3TRTH}>
            <th>区分</th>
            <th>内容</th>
            <th>料金</th>
          </tr>
        </thead>
        <tbody>
          <tr className={style.tblCharge3TR3}>
            <td rowSpan={2}>空港等への送迎</td>
            <td>(1)空港等への送迎(ただし、お客様の依頼による場合のみ)</td>
            <td>
              (派遣した社員1名につき)
              <br className="hidden md:flex" />
              10,800円
              <br className="hidden md:flex" />
              (交通費、宿泊費は別)
            </td>
          </tr>
          <tr className={style.tblCharge3TR}>
            <td>
              (2)空港への送迎を深夜、早朝、日曜日、休祭日に行った場合
              <br className="hidden md:flex" />
              (ただし、お客様の依頼による場合のみ)
            </td>
            <td>
              (派遣した社員1名につき)
              <br className="hidden md:flex" />
              (1)の料金に16,200円増
              <br className="hidden md:flex" />
              (交通費、宿泊費は別)
            </td>
          </tr>

          <tr className={style.tblCharge3TRL}>
            <td colSpan={3}>
              <p>(注意)</p>
              <p>上記料金には消費税が含まれています。</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
