import style from "../../css/info.module.css";

export function JoukenKaigaiwaribiki() {
  return (
    <div className="w-full max-w-screen-lg mx-auto my-4 md:my-8 px-2">
      <h2 className={style.pageTitle}>
        [旅行条件書] 海外正規割引航空券(ＰＥＸ航空券)条件書
      </h2>
      <p className={style.content}>
        この書面は、旅行業法第12条の４に定める取引条件説明書面および同法第12条の５に定める契約書面の一部となります。
      </p>

      <h3 className={style.title}>１．手配旅行契約</h3>
      <p className={style.content}>
        (1) この旅行は、ジョルダン株式会社[東京都新宿区新宿2-5-10
        成信ビル／埼玉県新座市東北2-31-15
        太田ビル2階　観光庁長官登録旅行業第1678号
        一般社団法人日本旅行業協会(JATA)正会員](以下「当社」といいます。)が手配する旅行であり、この旅行に参加されるお客様は当社と手配旅行契約(以下「旅行契約」といいます。)を締結することになります。
      </p>
      <p className={style.content}>
        (2)
        旅行契約とは、当社がお客様の委託により、お客様のために代理、媒介または取次ぎをすることなどにより、お客様が運送・宿泊機関等の提供する運送、宿泊その他の旅行に関するサービス(以下｢旅行サービス｣といいます。)の提供を受ける事ができるように、手配を引き受ける契約をいいます。
      </p>
      <p className={style.content}>
        (3)
        当社が善良な管理者の注意をもって旅行サービスの手配をしたときは、旅行契約に基づく当社の債務の履行は終了します。適当等の事由により、運送・宿泊機関等との間で旅行サービスの提供をする契約を締結できなかった場合であっても、当社がその義務を果たしたときは、お客様は、当社に対し、当社所定の旅行業務取扱料金(以下「取扱料金」といいます。)を支払わなければなりません。
      </p>

      <h3 className={style.title}>
        ２．旅行の申込みと契約の成立、旅行代金のお支払い
      </h3>
      <p className={style.content}>
        (1)
        旅行契約は当社がお申込みを承諾し、申込金を受領した時に成立するものとします。
      </p>
      <p className={style.content}>
        (2)
        (A)申込金の支払いを受けることなく、契約を締結する旨の書面を交付した場合。｢来店の場合は書面をお渡しした時点｣、｢FAXの場合は発信した時点｣、｢Eメールの場合はお客様に到着した時点｣で契約成立となります。
      </p>
      <p className={style.content}>
        (B)旅行出発日までに旅行代金と引き換えに航空券をお渡しする場合。
      </p>
      <p className={style.content}>
        (3)
        申込金(お１人様30,000円以上全額まで。ただし、航空券代金が30,000円未満の場合は全額)は、当社がお申込みを受託した日から当社が指定する日までにお支払い下さい。また、ご出発前１ヶ月をきってからのお申込みの場合はご旅行代金全額をお支払い下さい。
      </p>
      <p className={style.content}>
        (4)
        申込金は旅行代金または変更手数料、取消手数料等の一部として取扱います。
      </p>
      <p className={style.content}>
        (5)
        旅行代金は旅行開始日の前日から起算してさかのぼって21日目にあたる日より前にお支払いいただきます。旅行開始日の前日から起算してさかのぼって21日目にあたる日以降にお申込みの場合は、旅行開始日前の当社が指定する期日までにお支払いいただきます。
      </p>
      <p className={style.content}>
        (6)
        通信契約を希望される場合には、当社提携のクレジットカード会社のカード会員で、電話、FAX、その他の通信手段により申込みを受け、「会員の署名なくして旅行代金や取消・変更にかかわる実費、および取消・変更手続に関わる弊社手数料等の支払いを受ける(以下、通信契約)」の場合は次によります。[1]通信契約において、当社がお申込みの受諾を電話で通知する場合は、その通知を発した時、また、当社がお申込みの受諾をFAX、およびEメールで通知する場合は、その通知がお客様に到着した時に契約成立します。[2]通信契約においては、お申込み時に「会員番号、カードの有効期間」等を通知していただきます。[3]カード利用日は、契約成立の日となります。なお、残額、または追加料金がある場合は、別途案内した日となります。
      </p>
      <p className={style.content}>
        (7)
        取消待ちの手配については、当社はお客様のご希望により取消待ちの航空券の手配を承ります。この場合でもお申込金(お一人様30,000円以上金額まで、ただし航空券代金が30,000円未満の場合は全額)を申し受け、当社との手配旅行契約は成立いたします。但し、取消待ち状態における手配旅行契約は、手配の完了を保証するものではありません。お客様に手配完了の連絡をさせていただく前でも、お客様より取消・変更のお申し出を頂いた場合は、旅行契約の解除とみなし、所定の取消・変更に関する実費、および弊社手数料を申し受けます。但し、上記の場合であっても、当社は取消・変更のお申し出を受けた時点で手配が完了していない場合は、取消・変更に関わる実費、および弊社手数料は収受いたしません。また、あらかじめお受けた時点で完了していない場合は、取消・変更にかかわる実費、および弊社手数料は収受いたしません。また、あらかじめお客様との間で定めた期限までに予約ができなかった場合は申込金全額を払戻します。
      </p>

      <h3 className={style.title}>３．申込条件</h3>
      <p className={style.content}>
        (1) 20歳未満の方は保護者の同意が必要です。
      </p>
      <p className={style.content}>
        (2) 15歳未満の方は保護者の同行を条件とする場合があります。
      </p>
      <p className={style.content}>
        (3)
        70歳以上の方、障害のある方、慢性疾患をお持ちの方、現在健康を害しておられる方、あるいは妊娠中など特別の配慮を必要とする方は、その旨を予約申込時にお申し出下さい。当社は、可能な範囲内でこれに応じます。なおこの場合、医師の診断書を提出していただく場合があります。また、現地事情や関係機関等の状況などにより、旅行の安全かつ円滑な実施の為の介助者や同伴者などを条件とさせていただくか、あるいはお申込みをお断りさせていただくことがあります。
      </p>
      <p className={style.content}>
        (4)
        その他当社の業務上の都合があるときは、お申込みをお断わりすることがあります。
      </p>

      <h3 className={style.title}>４．航空券代金(運賃・料金)</h3>
      <p className={style.content}>
        (1)
        予約時の運賃(運賃本体・付加運賃)、料金が適用となります。但し、航空会社の運賃(本体・付加運賃)、料金、IATA通貨換算率(国際航空運送協会が設定する運賃換算用の換算率)の変更及び天災など当社の関与し得ない事由で変更が生じた場合は、お申込み以降であっても代金が変更される場合があります。
      </p>
      <p className={style.content}>
        (2)
        航空券代金とは運賃本体(平日／週末運賃、日本国内・海外アドオン運賃、途中降機運賃等の合算額)、付加運賃(燃油サーチャージ等)と料金(航空保険特別料金等)の合計を言います。尚、付加運賃(燃油サーチャージ等)、航空保険特別料金は、空港諸税(空港施設使用料、通行税等)の金額と共に運賃本体とは別途にご案内します。
      </p>
      <p className={style.content}>
        (3) 海外正規割引航空券(liEX航空券)には、IATA liEXとZONE
        liEXがあります。IATA liEXは各航空会社共通運賃ですが、ZONE
        liEXは航空会社ごとの正規割引運賃であり、予約期間・発券制限・変更可否・取消・変更に関する実費などについて航空会社ごとに様々な条件が定められていますのでご注意ください。
      </p>

      <h3 className={style.title}>
        ５．空港諸税・付加運賃(燃油サーチャージ等)・料金のお支払
      </h3>
      <p className={style.content}>
        (1)
        これらの金額は予約成立時において確定した金額の日本円換算額をお支払いいただきます。それ以降の為替変動による、追加請求・返金はいたしませんが、予約後に新設・金額に変更があった場合は、追加請求、または返金いたします。
      </p>
      <p className={style.content}>
        (2)
        航空会社の課す付加運賃・料金、及び空港諸税等は航空券の適用運賃種別(大人、または子供料金等)に応じて請求します。
      </p>

      <h3 className={style.title}>６．ご利用条件</h3>
      <p className={style.content}>
        (1)
        ご予約どおりのご利用が代金適用の条件となります。例えばＦＩＸ往復航空券については、復路を現地で変更する事ができません等の決められた航空券のルールをお客様が勝手に変更する事はできません。
      </p>
      <p className={style.content}>
        (2)
        往復航空券をお求め頂いた航空券については事前に航空会社の承認を得ることなく片道のみを使用した場合(復路の権利放棄)は、航空会社から片道普通航空運賃、または当該航空券の往復の公示運賃(普通運賃またはIATA
        liEX航空運賃等)との差額徴収があります。その際は差額をお客様にお支払いいただくようになります。
      </p>
      <p className={style.content}>
        (3)
        お客様の取消、変更によりすでにお渡しした航空券を使用されない場合は必ず当社にお返し下さい。お返しいただけない場合には航空会社から公示運賃(普通運賃またはIATA
        liEX航空運賃等)との差額徴収があります。その際は差額をお客様にお支払いいただくようになります。(ただしE-Ticketの場合のその限りではありません)
      </p>
      <p className={style.content}>
        (4)
        航空会社のマイレージプログラム等の特典は航空会社が独自に行っているものですので当社にて特典利用のご希望は承っておりません。
      </p>

      <h3 className={style.title}>７．ご注意</h3>
      <p className={style.content}>
        (1) 国際線の搭乗手続きは出発の2時間前までに行って下さい。
      </p>
      <p className={style.content}>
        (2)
        予告なしに出発時刻が変更される場合がありますので、ご利用航空会社へ出発・搭乗手続き時刻をお問合せ下さい。
      </p>
      <p className={style.content}>
        (3)
        旅券(パスポート)の必要残存期間、および査証(ビザ)の有無については、国・地域により異なりますのでお客様ご自身で確認下さい。又、日本国籍・外国籍を問わず、渡航手続代行契約を締結しない限り、渡航手続に関してはお客様ご自身で行っていただきます。目的地だけではなく、経由地の査証(ビザ)についても注意が必要です。ご出発までに査証(ビザ)の情報を大使館・領事館・航空会社等にご確認下さい。特に外国籍のお客様についてはご注意下さい。
      </p>
      <p className={style.content}>
        (4)
        外国に入出国する際には、旅券(パスポート)と共に「出入国記録カード」、「税関申告書」等が必要になります。お客様ご自身でご用意いただくか、当社で渡航手続代行を致します。(別途代行料金お１人様2,000円・内税を申し受けます。)
      </p>
      <p className={style.content}>
        (5)
        お客様が旅行中に天災などの不可抗力、またはお客様の不注意(予約の再確認不足、集合時間の遅刻、航空券の紛失・盗難等)により被った損害につきましては、当社では責任を負いかねますので、予めご了承下さい。
      </p>
      <p className={style.content}>
        (6)
        現地滞在中に航空会社が倒産した場合、航空券自体の価値が消滅し救済会社が現れない限りお客様の負担での帰国となりますのでご了承下さい。
      </p>
      <p className={style.content}>
        (7)
        各航空会社は運送契約を締結した区間のみの責任を負います。異なる航空会社の乗り継ぎで何らかの事由で遅れた場合、お客様ご自身で代替便を手配し、帰国していただくことになりますので、できる限り同一航空会社での乗り継ぎをお勧めします。
      </p>
      <p className={style.content}>
        (8) 航空会社のFFli(FREQUENT FLYERS
        liROGRAM＝マイレージ)については、提携航空会社を含め、お客様と航空会社の会員プログラムにつき、お客様ご自身で確認下さい。
      </p>

      <h3 className={style.title}>８．変更・取消</h3>
      <p className={style.content}>
        (1)
        航空発券期限、発券後の変更可否、航空会社の取消・変更にかかわる実費は、航空会社が定める運賃条件によります。代表的な条件は次のとおりです。「発券期限」：予約完了後72時間以内、「変更可否」：予約完了後不可、「取消払戻手数料」：発券後出発まで数万円、出発後払戻不可
      </p>
      <p className={style.content}>
        (2)
        発券後の取消・払戻、再発券を伴う取消・変更は、航空会社の取消・変更にかかわる実費と、取消・変更にかかわる弊社手数料の合計額を申し受けます。なお、弊社の手数料はお一人様1件につき5,400円(内税)となります。
      </p>
      <p className={style.content}>
        (3)
        ご変更及び取消につきましては、取扱店の指定する営業時間内にお申込みの取扱店にお申し出下さい。
      </p>
      <p className={style.content}>
        (4)
        払戻に要する日数は、航空会社の審査期間等により次のとおりとなり、また個別の事情により航空会社が払戻に応じない場合がありますのでご了承願いします。
      </p>
      <p className={style.contentml2}>
        [1]未使用航空券：通常２ヶ月以上、一部海外航空会社では４ヶ月以上～12ヶ月
      </p>
      <p className={style.contentml2}>
        [2]一部使用済み航空券：通常２ヶ月以上、内容により４ヶ月以上
      </p>
      <p className={style.contentml2}>
        [3]紛失航空券：通常７ヶ月以上、一部航空会社では12ヶ月以上
      </p>

      <h3 className={style.title}>９．個人情報のお取り扱いについて(重要)</h3>
      <p className={style.content}>
        (1)
        当社ホームページ記載の旅行申込みの際に提出された申込書に記載された個人情報について、お客様との間の連絡のために利用させていただくほか、お客様がお申込みいただいた旅行において運送機関等の提供するサービスの手配及びそれらのサービスの受領のための手続きに必要な範囲内で利用させていただきます。＊このほか、当社では、[1]当社と提携する企業の商品やサービス、キャンペーンのご案内。[2]旅行参加後のご意見やご感想の提供のお願い。[3]アンケートのお願い。[4]特典サービスの提供。[5]統計資料の作成。に、お客様の個人情報を利用させていただくことがあります。
      </p>
      <p className={style.content}>
        (2)
        当社が取得する個人情報は、お客様の氏名、年齢、性別、電話番号、住所、メールアドレス、その他コースにより当社が旅行を実施するうえで必要となる最小限の範囲のお客様の個人情報とします。また介助者の同行、車椅子の手配等特別な配慮を必要とする場合で、当社が可能な範囲内でこれに応ずる(又は応じられない旨の回答をする)目的のため、上記以外の個人情報の取得をさせていただくことがありますが、これは当社が手配等をするうえで必要な範囲内とします。
      </p>
      <p className={style.content}>
        (3)
        当社が本項(2)の個人情報を取得することについてお客様の同意を得られない場合は、当社は、旅行契約の締結に応じられないことがあります。また同意を得られないことにより、お客様のご希望される手配等が行えない場合があります。
      </p>
      <p className={style.content}>
        (4)
        当社は、お申込みいただいた旅行の手配のために、運送機関等に対し、お客様の氏名、年齢、性別、電話番号、その他手配をするために必要な範囲内での情報を、あらかじめ電子的方法等で送付することによって提供いたします。
      </p>
      <p className={style.content}>
        (5)
        当社は、旅行先において、お客様の手荷物運送等、免税店でのお買い物等の便宜のため、当社の保有するお客様の個人データを運送業者や免税店に提供することがあります。この場合、お客様の氏名、搭乗日及び航空便名等に係る個人データを、あらかじめ電子的方法等で送付することによって提供いたします。なお、これらの事業者への個人データの提供の停止を希望される場合は、お申込み時にお申し出ください。
      </p>
      <p className={style.content}>
        (6)
        当社は、当社が保有するお客様の個人データのうち、氏名、住所、電話番号又はメールアドレス等のお客様へのご連絡にあたり必要となる最小限の範囲のものについて、当社グループ企業との間で、共同して利用させていただくことがあります。当社グループ企業は、それぞれの企業の営業案内、催し物内容等のご案内、ご購入いただいた商品の発送のために、これを利用させていただくことがあります。なお、当社グループ企業の名称及び個人データの管理について責任を有する者の氏名、又は名称は、プライバシーポリシー［個人情報に関するお問合わせ・苦情のお申し出先］をご参照ください。
      </p>
      <p className={style.content}>
        (7)
        当社が保有するお客様の個人データの開示、その内容の訂正、追加若しくは削除、又はその利用の停止、消去若しくは第三者への提供の停止をご希望の方は、必要となる手続きについてご案内いたしますので、お問合わせ窓口までお申し出ください。その際、法令及び当社内規に従い、遅滞なく必要な措置を取らせていただきます。また、ご希望の全部又は一部に応じられない場合はその理由をご説明します。
      </p>
      <p className={style.content}>
        (8)
        万一、当社の個人情報の流出等の問題が発生した場合は、直ちにお客様にご連絡させていただき、安全の確保が保たれるまで問題が発生したシステムを一時停止いたします。また、速やかにホームページ等で事実関係等を公表させていただきます。
      </p>

      <h3 className={style.title}>10．渡航準備について</h3>
      <p className={style.content}>
        (1)
        渡航先(国または地域)によっては、外務省より危険情報などの安全関係の海外渡航関連情報が出されている場合があります。お申込みの際にご確認ください。海外渡航関連情報は下記にてご確認いただけます。
      </p>
      <p className={style.contentml2}>
        外務省海外安全ホームページ【URL】
        <a
          href="http://www.pubanzen.mofa.go.jp/"
          className={style.externalLink}
        >
          http://www.pubanzen.mofa.go.jp/
        </a>
      </p>
      <p className={style.contentml2}>
        外務省安全相談センター【TEL】03-5501-8162
      </p>
      <p className={style.contentml2}>
        外務省安全情報FAXサービス【FAX】0570-023300
      </p>
      <p className={style.content}>
        (2)
        渡航先の衛生上状況については、厚生労働省「検疫感染症情報」でご確認ください。
      </p>
      <p className={style.contentml2}>
        厚生労働省海外感染症情報【URL】】
        <a href="http://www.forth.go.jp/" className={style.externalLink}>
          http://www.forth.go.jp/
        </a>
      </p>
      <p className={style.content}>(ご注意)</p>
      <p className={style.content}>何日前とは、出発日前日より起算します。</p>
      <p className={style.contentml2}>
        ・出発後の未使用区間航空券の払い戻しは理由の如何に関わらず、一切払い戻しができません。
      </p>
      <p className={style.contentml2}>
        ・旅客名はパスポートのローマ字記載と同一(姓/名
        ミドルネームの順番)であることが条件となります。ご予約旅客名と性別がパスポート記載内容と相違していた場合、既存のご予約は変更ではなく取消となり、再度新規予約扱いとなります。
      </p>
      <p className={style.contentml2}>
        ・ご搭乗者氏名の綴りの訂正、大人・子供の種別・性別の修正、旅行者交替は、「変更」ではなく、「取消」扱いとなります。
      </p>
      <p className={style.contentml2}>
        ・ご搭乗者氏名の綴りの訂正、大人・子供の種別・性別の修正、旅行者交替は、「変更」ではなく、「取消」扱いとなります。
      </p>
      <p className={style.contentml2}>
        ・お客様の事由により弊社から返金が生じる場合、お振り込み手数料はお客様のご負担とさせていただきます。
      </p>
      <p className={style.contentml2}>
        ・変更後の再変更や取消は、変更後の出発日を基準として変更・取消手数料を申し受けます。
      </p>
      <p className={style.contentml2}>
        ・航空会社により、上記以外の特例がある場合がございます。特例が適用される場合、別途ご案内します。
      </p>
      <p className={style.contentml2}>
        ・上記変更・取消手数料以外に、航空会社に対して支払う変更・取消手数料実費、および弊社手数料を頂戴する場合がございます。
      </p>
      <p className={style.contentml2}>
        ・変更・取消手数料を計算する場合は、各国TAX、日本国内空港使用料、航空保険料は含まれません。
      </p>
      <p className={style.contentml2}>
        ・キャンセル待ち(リクエスト)でご予約をお預りしている場合、お席が確保できた時点で上記規定の適応対象となります。
      </p>
      <p className={style.contentml2}>
        ・キャンセル待ち(リクエスト)解除をされる場合は必ず弊社担当までメールにてご連絡下さい。
      </p>
    </div>
  );
}
