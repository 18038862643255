import { useOutletContext } from "react-router-dom";
import { format } from "date-fns";
import calendar from "../img/images/icon_calendar.svg";
import hotel from "../img/images/icon_hotel.svg";
import flight from "../img/images/icon_flight.svg";
import mealOp from "../img/images/icon_meal.svg";
import remarks from "../img/images/icon-note.svg";
import transport from "../img/images/icon-move.svg";
import train from "../img/images/icon-train.svg";
import { Order } from "../types";
import { GrayDottedLine } from "./Lines";
import { ERROR_REQUIRED, renderError, State } from "../constants";
import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

export default function CartFree({
  setExpand,
}: {
  setExpand: React.Dispatch<React.SetStateAction<State>>;
}) {
  const [state, setState] = useOutletContext() as any;
  const diffDays = (state.endDate - state.startDate) / 86400000;

  const {
    register,
    formState: { errors },
  } = useFormContext();

  let setOriginName = (originName: string) => {
    setState((state: Order) => ({
      ...state,
      transportInfo: {
        ...state.transportInfo,
        originName: originName,
      },
    }));
  };

  let setOriginSeat = (originSeat: string) => {
    setState((state: Order) => ({
      ...state,
      transportInfo: {
        ...state.transportInfo,
        originSeat: originSeat,
      },
    }));
  };

  let setOriginDep = (originDep: string) => {
    setState((state: Order) => ({
      ...state,
      transportInfo: {
        ...state.transportInfo,
        originDep: originDep,
      },
    }));
  };

  let setOriginArr = (originArr: string) => {
    setState((state: Order) => ({
      ...state,
      transportInfo: {
        ...state.transportInfo,
        originArr: originArr,
      },
    }));
  };

  let setOriginDepTime = (originDepTime: string) => {
    setState((state: Order) => ({
      ...state,
      transportInfo: {
        ...state.transportInfo,
        originDepTime: originDepTime,
      },
    }));
  };

  let setOriginArrTime = (originArrTime: string) => {
    setState((state: Order) => ({
      ...state,
      transportInfo: {
        ...state.transportInfo,
        originArrTime: originArrTime,
      },
    }));
  };

  let setDestinationName = (destinationName: string) => {
    setState((state: Order) => ({
      ...state,
      transportInfo: {
        ...state.transportInfo,
        destinationName: destinationName,
      },
    }));
  };

  let setDestinationSeat = (destinationSeat: string) => {
    setState((state: Order) => ({
      ...state,
      transportInfo: {
        ...state.transportInfo,
        destinationSeat: destinationSeat,
      },
    }));
  };

  let setDestinationDep = (destinationDep: string) => {
    setState((state: Order) => ({
      ...state,
      transportInfo: {
        ...state.transportInfo,
        destinationDep: destinationDep,
      },
    }));
  };

  let setDestinationArr = (destinationArr: string) => {
    setState((state: Order) => ({
      ...state,
      transportInfo: {
        ...state.transportInfo,
        destinationArr: destinationArr,
      },
    }));
  };

  let setDestinationDepTime = (destinationDepTime: string) => {
    setState((state: Order) => ({
      ...state,
      transportInfo: {
        ...state.transportInfo,
        destinationDepTime: destinationDepTime,
      },
    }));
  };

  let setDestinationArrTime = (destinationArrTime: string) => {
    setState((state: Order) => ({
      ...state,
      transportInfo: {
        ...state.transportInfo,
        destinationArrTime: destinationArrTime,
      },
    }));
  };

  let setFreeRestaurant = (freeRestaurant: string) => {
    setState((state: Order) => ({
      ...state,
      freeRestaurant,
    }));
  };

  let setRemarks = (remarks: string) => {
    setState((state: Order) => ({
      ...state,
      remarks,
    }));
  };

  const isSelected =
    state.hotel?.room &&
    state.hotel?.class &&
    state.hotel?.room.location &&
    state.hotel?.room.bath &&
    state.hotel?.room.breakfast &&
    state.hotel?.room.smoking;

  return (
    <>
      <div className="flex flex-row justify-between">
        <div className="cartSubTitles">
          <img src={calendar} />
          <div>日程・人数</div>
        </div>
        <button onClick={() => setExpand(State.SCHEDULE)}>
          <div className="btnChange">変更</div>
        </button>
      </div>

      <GrayDottedLine />

      <div className="flex flex-row flex-wrap">
        <div className="cartItems">
          <div className="cartItemName">行き</div>
          <div className="space-x-2 font-semibold">
            <span>{format(state.startDate, "yyyy年MM月dd日")}</span>
            <span>
              {state.othersOrigin ? state.othersOrigin : state.origin.label}
            </span>
          </div>
        </div>

        <div className="cartItems">
          <div className="cartItemName">帰り</div>
          <div className="space-x-2 font-semibold">
            <span>{format(state.endDate, "yyyy年MM月dd日")}</span>
            <span>
              {state.othersDestination
                ? state.othersDestination
                : state.destination.label}
            </span>
          </div>
        </div>

        <div className="cartItems">
          <div>
            <div className="cartItemName">人数</div>
          </div>
          <div className="flex flex-col space-y-5">
            <div className="flex lex-row">
              <div className="w-28">大人(12歳以上)</div>：{state.adults}人
            </div>
            <div className="flex lex-row">
              <div className="w-28">小人(3-11歳)</div>：{state.children}人
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-row justify-between">
        <div className="cartSubTitles">
          <img src={transport} />
          <div>移動手段</div>
        </div>
        <button onClick={() => setExpand(State.TRANSPORT)}>
          <div className="btnChange">変更</div>
        </button>
      </div>

      <GrayDottedLine />

      {state.transport ? (
        <>
          <div className="flex flex-row flex-wrap">
            <div className="cartItems">
              <div className="cartItemName">移動手段</div>
              <div className="space-x-2 font-semibold">
                <span>{state.transport.label}</span>
              </div>
            </div>
          </div>
    
            <div className="flex flex-row flex-wrap">
              <div className="cartItems">
                <div className="cartItemName">往路出発</div>
                <div className="space-x-2 font-semibold">
                  <span>{state.depTimeOrigin?.time}</span>
                </div>
              </div>
              <div className="cartItems">
                <div>
                  <div className="cartItemName">復路出発</div>
                </div>
                <div className="space-x-2 font-semibold">
                  <span>{state.depTimeDestination?.time}</span>
                </div>
              </div>
            </div>
        </>
      ) : (
        <div>未選択</div>
      )}
      {state.transport.id == "train" ? (
        <>
          <div className="cartSubTitles">
            {state.transport.id === "air" ? (
              <img src={flight} />
            ) : (
              <img src={train} />
            )}
            <div>{state.transport.id === "air" ? "フライト" : "電車"}</div>
          </div>

          <GrayDottedLine />

          <div className="cartFlight">
            <div className="font-semibold mx-4">往路</div>
            <p>
              <span className="text-red-500 mr-1">*</span>の項目は必須項目です。
            </p>
          </div>
          <div className="flex flex-row flex-wrap">
            <div className="cartFreeItems">
              <div className="cartItemName">
                {state.transport.id === "air" ? "利用便" : "利用列車"}
              </div>
              <input
                id="originName"
                name="originName"
                type="text"
                value={state.transportInfo?.originName}
                onChange={(e) => {
                  let i = e.target.value;
                  setOriginName(i);
                }}
                placeholder={state.transport.id === "air" ? "便名" : "列車名"}
                className="cartFreeInput"
              />
            </div>
            <div className="cartFreeItems">
              <div className="cartItemName">座席</div>
              <input
                id="originSeat"
                name="originSeat"
                type="text"
                value={state.transportInfo?.originSeat}
                onChange={(e) => {
                  let i = e.target.value;
                  setOriginSeat(i);
                }}
                placeholder={
                  state.transport.id === "air"
                    ? "普通席、ファーストクラスなど"
                    : "指定席グリーン車、自由席など"
                }
                className="cartFreeInput"
              />
            </div>
            <div className="cartFreeItems">
              <div className="cartItemName">
                <span className="text-red-500 mr-1">*</span>出発地
              </div>
              <div className="w-full md:w-auto flex flex-col">
                <input
                  id="originDep"
                  type="text"
                  value={state.transportInfo?.originDep}
                  {...register("originDep", {
                    required: ERROR_REQUIRED,
                    onChange: (e) => {
                      let i = e.target.value;
                      setOriginDep(i);
                    },
                  })}
                  placeholder={
                    state.transport.id === "air"
                      ? "空港名、地名など"
                      : "駅名、地名など"
                  }
                  className="cartFreeInput"
                />
                <div className="text-sm">
                  <ErrorMessage
                    errors={errors}
                    name="originDep"
                    render={renderError}
                  />
                </div>
              </div>
            </div>
            <div className="cartFreeItems">
              <div className="cartItemName">
                <span className="text-red-500 mr-1">*</span>到着地
              </div>
              <div className="w-full md:w-auto flex flex-col">
                <input
                  id="originArr"
                  type="text"
                  value={state.transportInfo?.originArr}
                  placeholder={
                    state.transport.id === "air"
                      ? "空港名、地名など"
                      : "駅名、地名など"
                  }
                  className="cartFreeInput"
                  {...register("originArr", {
                    required: ERROR_REQUIRED,
                    onChange: (e) => {
                      let i = e.target.value;
                      setOriginArr(i);
                    },
                  })}
                />
                <div className="text-sm">
                  <ErrorMessage
                    errors={errors}
                    name="originArr"
                    render={renderError}
                  />
                </div>
              </div>
            </div>
            <div className="cartFreeItems">
              <div className="cartItemName">
                <span className="text-red-500 mr-1">*</span>出発時間
              </div>
              <div className="w-full md:w-auto flex flex-col">
                <input
                  id="originDepTime"
                  type="text"
                  value={state.transportInfo?.originDepTime}
                  placeholder="10時、10:30など"
                  className="cartFreeInput"
                  {...register("originDepTime", {
                    required: ERROR_REQUIRED,
                    onChange: (e) => {
                      let i = e.target.value;
                      setOriginDepTime(i);
                    },
                  })}
                />
                <div className="text-sm">
                  <ErrorMessage
                    errors={errors}
                    name="originDepTime"
                    render={renderError}
                  />
                </div>
              </div>
            </div>
            <div className="cartFreeItems">
              <div className="cartItemName">到着時間</div>
              <input
                id="originArrTime"
                name="originArrTime"
                type="text"
                value={state.transportInfo?.originArrTime}
                onChange={(e) => {
                  let i = e.target.value;
                  setOriginArrTime(i);
                }}
                placeholder="20時、20:30など"
                className="cartFreeInput"
              />
            </div>
          </div>

          <div className="cartFlight">
            <div className="font-semibold mx-4">復路</div>
            <p>
              <span className="text-red-500 mr-1">*</span>の項目は必須項目です。
            </p>
          </div>
          <div className="flex flex-row flex-wrap">
            <div className="cartFreeItems">
              <div className="cartItemName">
                {state.transport.id === "air" ? "利用便" : "利用列車"}
              </div>
              <input
                id="destinationName"
                name="destinationName"
                type="text"
                value={state.transportInfo?.destinationName}
                onChange={(e) => {
                  let i = e.target.value;
                  setDestinationName(i);
                }}
                placeholder={state.transport.id === "air" ? "便名" : "列車名"}
                className="cartFreeInput"
              />
            </div>
            <div className="cartFreeItems">
              <div className="cartItemName">座席</div>
              <input
                id="destinationSeat"
                name="destinationSeat"
                type="text"
                value={state.transportInfo?.destinationSeat}
                onChange={(e) => {
                  let i = e.target.value;
                  setDestinationSeat(i);
                }}
                placeholder={
                  state.transport.id === "air"
                    ? "普通席、ファーストクラスなど"
                    : "指定席グリーン車、自由席など"
                }
                className="cartFreeInput"
              />
            </div>
            <div className="cartFreeItems">
              <div className="cartItemName">
                <span className="text-red-500 mr-1">*</span>出発地
              </div>
              <div className="w-full md:w-auto flex flex-col">
                <input
                  id="destinationDep"
                  type="text"
                  value={state.transportInfo?.destinationDep}
                  placeholder={
                    state.transport.id === "air"
                      ? "空港名、地名など"
                      : "駅名、地名など"
                  }
                  className="cartFreeInput"
                  {...register("destinationDep", {
                    required: ERROR_REQUIRED,
                    onChange: (e) => {
                      let i = e.target.value;
                      setDestinationDep(i);
                    },
                  })}
                />
                <div className="text-sm">
                  <ErrorMessage
                    errors={errors}
                    name="destinationDep"
                    render={renderError}
                  />
                </div>
              </div>
            </div>
            <div className="cartFreeItems">
              <div className="cartItemName">
                <span className="text-red-500 mr-1">*</span>到着地
              </div>
              <div className="w-full md:w-auto flex flex-col">
                <input
                  id="destinationArr"
                  type="text"
                  value={state.transportInfo?.destinationArr}
                  placeholder={
                    state.transport.id === "air"
                      ? "空港名、地名など"
                      : "駅名、地名など"
                  }
                  className="cartFreeInput"
                  {...register("destinationArr", {
                    required: ERROR_REQUIRED,
                    onChange: (e) => {
                      let i = e.target.value;
                      setDestinationArr(i);
                    },
                  })}
                />
                <div className="text-sm">
                  <ErrorMessage
                    errors={errors}
                    name="destinationArr"
                    render={renderError}
                  />
                </div>
              </div>
            </div>
            <div className="cartFreeItems">
              <div className="cartItemName">
                <span className="text-red-500 mr-1">*</span>出発時間
              </div>
              <div className="w-full md:w-auto flex flex-col">
                <input
                  id="destinationDepTime"
                  type="text"
                  value={state.transportInfo?.destinationDepTime}
                  placeholder="10時、10:30など"
                  className="cartFreeInput"
                  {...register("destinationDepTime", {
                    required: ERROR_REQUIRED,
                    onChange: (e) => {
                      let i = e.target.value;
                      setDestinationDepTime(i);
                    },
                  })}
                />
                <div className="text-sm">
                  <ErrorMessage
                    errors={errors}
                    name="destinationDepTime"
                    render={renderError}
                  />
                </div>
              </div>
            </div>
            <div className="cartFreeItems">
              <div className="cartItemName">到着時間</div>
              <input
                id="destinationArrTime"
                name="destinationArrTime"
                type="text"
                value={state.transportInfo?.destinationArrTime}
                onChange={(e) => {
                  let i = e.target.value;
                  setDestinationArrTime(i);
                }}
                placeholder="20時、20:30など"
                className="cartFreeInput"
              />
            </div>
          </div>
        </>
      ) : null}

      {state.schedule === 'stay' && (
      <>
        <div className="flex flex-row justify-between">
            <div className="cartSubTitles">
            <img src={hotel} />
            <div>宿泊施設</div>
            {/* <span className="text-red-500">&#8251;必須項目</span> */}
            </div>
            <button onClick={() => setExpand(State.HOTEL)}>
            <div className="btnChange">{state.hotel?.room ? "変更" : "選択"}</div>
            </button>
        </div>

        <GrayDottedLine />
        

        {state.destination.region_id !== "others" &&
        state.hotel?.room &&
        state.hotel?.class ? (
            <>
            <div className="flex flex-row flex-wrap">
                <div className="cartItems">
                <div className="cartItemName">宿泊日</div>
                <div className="space-x-2 font-semibold">
                    <span>{format(state.startDate, "yyyy年MM月dd日")}</span>
                    <span>〜</span>
                    <span>{diffDays}泊</span>
                </div>
                </div>
                {isSelected && (
                <>
                    <div className="cartItems">
                    <div className="cartItemName">エリア</div>
                    <div className="font-semibold">
                        {state.hotel?.area.label
                        ? state.hotel?.area.label
                        : "指定なし"}
                    </div>
                    </div>
                    <div className="cartItems">
                    <div className="cartItemName">ランク</div>
                    <div>{state.hotel?.class.label}</div>
                    </div>
                    <div className="cartItems">
                    <div className="cartItemName">位置</div>
                    <div>{state.hotel?.room.location.label}</div>
                    </div>
                    <div className="cartItems">
                    <div className="cartItemName">大浴場</div>
                    <div>{state.hotel?.room.bath.label}</div>
                    </div>
                    <div className="cartItems">
                    <div className="cartItemName">朝食</div>
                    <div>{state.hotel?.room.breakfast.label}</div>
                    </div>
                    <div className="cartItems">
                    <div className="cartItemName">たばこ</div>
                    <div>{state.hotel?.room.smoking.label}</div>
                    </div>
                </>
                )}
            </div>
            </>
        ) : state.destination.region_id === "others" ? (
            <>
            <div className="flex flex-row flex-wrap">
                <div className="cartItems">
                <div className="cartItemName">宿泊日</div>
                <div className="space-x-2 font-semibold">
                    <span>{format(state.startDate, "yyyy年MM月dd日")}</span>
                    <span>〜</span>
                    <span>{diffDays}泊</span>
                </div>
                </div>
                <div className="cartItems">
                <div className="cartItemName">エリア</div>
                <div className="font-semibold">
                    {state.hotelArea ? state.hotelArea : "指定なし"}
                </div>
                </div>

                {state.othersHotelClass && (
                <>
                    <div className="cartItems">
                    <div className="cartItemName">ランク</div>
                    <div>{state.othersHotelClass.label}</div>
                    </div>
                    <div className="cartItems">
                    <div className="cartItemName">位置</div>
                    <div>{}</div>
                    </div>
                    <div className="cartItems">
                    <div className="cartItemName">大浴場</div>
                    <div>{state.bath.label}</div>
                    </div>
                    <div className="cartItems">
                    <div className="cartItemName">朝食</div>
                    <div>{state.breakfast.label}</div>
                    </div>
                    <div className="cartItems">
                    <div className="cartItemName">たばこ</div>
                    <div>{state.smoking.label}</div>
                    </div>
                </>
                )}
            </div>
            </>
        ) : (
            <div className="px-1 mb-2.5">未選択</div>
        )}
      </>
      )}

      {state.schedule === 'stay' && (
      <>
        <div className="cartSubTitles">
            <img src={mealOp} />
            <div>夕食</div>
            <span>&#8251;任意入力</span>
        </div>

        <GrayDottedLine />

        <div className="cartRemarks">
            <textarea
            id="remarks"
            value={state.freeRestaurant}
            onChange={(e) => {
                let i = e.target.value;
                setFreeRestaurant(i);
            }}
            placeholder="入力してください"
            />
        </div>
      </>
      )}

      <div className="cartSubTitles">
        <img src={remarks} />
        <div>備考</div>
        <span>&#8251;任意入力</span>
      </div>

      <GrayDottedLine />

      <div className="cartRemarks">
        <textarea
          id="remarks"
          value={state.remarks}
          onChange={(e) => {
            let i = e.target.value;
            setRemarks(i);
          }}
          placeholder="入力してください"
        />
      </div>
    </>
  );
}
