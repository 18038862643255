import { ArrowDownIcon, ArrowRightIcon } from "@heroicons/react/outline";
import { Link as JumpLink } from "react-scroll";

import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";

import style from "../../css/info.module.css";
import "../../css/guide.css";

import menuOp from "../../img/guide/guide-menu-op.svg";
import menuPc from "../../img/guide/guide-menu-pc.svg";
import menuMobile from "../../img/guide/guide-menu-mobile.svg";
import menuCredit from "../../img/guide/guide-menu-credit.svg";
import menuTicket from "../../img/guide/guide-menu-ticket.svg";
import menuReceipt from "../../img/guide/guide-menu-receipt.svg";
import menuCancel from "../../img/guide/guide-menu-cancel.svg";

import plan1a from "../../img/guide/guide-order-1a.png";
import plan1b from "../../img/guide/guide-order-1b.png";
import plan2a from "../../img/guide/guide-order-2a.png";
import plan2b from "../../img/guide/guide-order-2b.png";
import plan2c from "../../img/guide/guide-order-2c.png";
import plan3 from "../../img/guide/guide-order-3.png";
import plan4 from "../../img/guide/guide-order-4.png";
import plan5 from "../../img/guide/guide-order-5.png";
import plan6 from "../../img/guide/guide-order-6.png";

import planF1a from "../../img/guide/guide-orderF-1a.png";
import planF1b from "../../img/guide/guide-orderF-1b.png";
import planF2a from "../../img/guide/guide-orderF-2a.png";
import planF2b from "../../img/guide/guide-orderF-2b.png";
import planF3 from "../../img/guide/guide-orderF-3.png";
import planF5 from "../../img/guide/guide-orderF-5.png";

export default function Guide() {
  return (
    <div className="w-full max-w-screen-lg mx-auto my-4 md:my-8 px-2">
      <h2 className={style.pageTitle}>ご利用ガイド</h2>

      <div className="space-y-10">
        <div>
          <h3 className={style.title}>◆はじめに</h3>
          <p className={style.content}>
            弊社のサービスは、登録ユーザー様のみご利用可能となっております。ご利用前にユーザー登録をお願い致します。
            <br />
            また、ユーザー登録は20歳以上の方に限らせていただきます。19歳以下の方は、20歳以上の方とご一緒にお申し込みください。
          </p>
          {/* <div className="w-full">
            <div className="max-w-2xl mx-auto flex flex-row justify-around py-5">
              <JumpLink to="order" className="guideJumpLink">
                <div className="flex flex-row">
                  <img src={menuPc} />
                  <img src={menuMobile} />
                </div>
                <div>注文</div>
              </JumpLink>

              <JumpLink to="payment" className="guideJumpLink">
                <img src={menuCredit} />
                <div>決済</div>
              </JumpLink>

              <JumpLink to="arrange" className="guideJumpLink">
                <img src={menuOp} />
                <div>手配</div>
              </JumpLink>
            </div>
          </div> */}
        </div>

        <div>
          <h3 id="order" className={style.title}>
            ◆注文について
          </h3>
          <div className="space-y-5">
            <GuideOrder />
            <GuideOrderFree />
          </div>
        </div>

        <div>
          <h3 id="payment" className={style.title}>
            ◆決済について
          </h3>
          <GuidePayment />
        </div>

        <div>
          <h3 id="arrange" className={style.title}>
            ◆手配について
          </h3>
          <GuideArrange />
        </div>
      </div>
    </div>
  );
}

function GuideOrder() {
  return (
    <div className="space-y-2.5">
      <div>・通常注文</div>
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="guideOrderTitle">
              <span>プラン検索</span>
              <ChevronDownIcon
                className={`${
                  open ? "rotate-180 transform" : ""
                } h-5 w-5 text-color`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="guideOrderPanel">
              <p>
                <span>○条件を指定して探す</span>
                <br />
                ご希望の日付、場所、移動、宿泊、夕食をお選びください。
              </p>
              <div className="guideOrderPanelImgs">
                <img src={plan1a} />
                <div className="w-full flex justify-center items-center">
                  <ArrowRightIcon className="w-6 h-6 mt-[214px] hidden md:flex" />
                  <ArrowDownIcon className="w-6 h-6 flex md:hidden" />
                </div>
                <img src={plan1b} />
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="guideOrderTitle">
              <span>プラン選択</span>
              <ChevronDownIcon
                className={`${
                  open ? "rotate-180 transform" : ""
                } h-5 w-5 text-color`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="guideOrderPanel">
              <p>
                <span>○作成中のプラン内容を確認できます。</span>
                <br />
                各項目の「変更」ボタンより、お好きなフライトや宿泊エリアを選択すると、プランのカスタマイズが可能です。
              </p>
              <div className="guideOrderPanelImgs">
                <img src={plan2a} />
              </div>

              <p>
                <span>○旅行のプロに地元グルメの相談ができます。</span>
                <br />
                項目夕食の「選択」ボタンより、夕食の相談内容の選択が可能です。
                <br />
                選択肢以外でもご相談を承っております。ご希望の場合は、備考欄にご希望内容の詳細を入力してください。
              </p>
              <div className="guideOrderPanelImgs">
                <img src={plan2b} />
                <img src={plan2c} />
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="guideOrderTitle">
              <span>内容確認</span>
              <ChevronDownIcon
                className={`${
                  open ? "rotate-180 transform" : ""
                } h-5 w-5 text-color`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="guideOrderPanel">
              <p>
                <span>○お申込内容を確認できます。</span>
                <br />
                内容に問題がなければ、画面下の
                <span className="font-semibold">
                  「上記の内容で問題ないので、次へ進む」ボタンから、お客様情報の入力(※1)
                </span>
                へ進みます。
                <br />
                変更をご希望の場合は、画面下の「内容を変更する」ボタンから、プラン選択画面へ戻り、各項目の「変更」ボタンより、変更が可能です。
              </p>
              <div className="guideOrderPanelImgs">
                <img src={plan3} />
              </div>
              <p className="text-xs">
                ※1
                「お客様情報の入力へ進む」にはログインが必要になります。JIDが未登録の場合、ログインページの「はじめてご利用の方はこちら」ボタンから、登録が可能です。
              </p>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="guideOrderTitle">
              <span>情報入力</span>
              <ChevronDownIcon
                className={`${
                  open ? "rotate-180 transform" : ""
                } h-5 w-5 text-color`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="guideOrderPanel">
              <p>
                <span>
                  ○お申込みに必要なお客様情報の入力をお願いいたします。
                </span>
                <br />
                入力後、画面下の「最終確認へ進む」ボタンを押すと、お申込み内容の確認画面へ移動します。
              </p>
              <div className="guideOrderPanelImgs">
                <img src={plan4} />
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="guideOrderTitle">
              <span>最終確認</span>
              <ChevronDownIcon
                className={`${
                  open ? "rotate-180 transform" : ""
                } h-5 w-5 text-color`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="guideOrderPanel">
              <p>
                <span>○お申込内容の最終確認画面です。</span>
                <br />
                内容に問題がなければ、プライバシーポリシーをご確認の上、画面下の
                <span className="font-semibold">
                  「上記の内容で申し込む」ボタンを押してください。
                  <br />
                  この画面の内容でお申し込みを開始します。
                </span>
                <br />
                変更をご希望の場合は、画面下の「内容を変更する」ボタンから、プラン選択画面へ戻り、各項目の「変更」ボタンより、変更が可能です。
              </p>
              <div className="guideOrderPanelImgs">
                <img src={plan5} />
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="guideOrderTitle">
              <span>受付完了</span>
              <ChevronDownIcon
                className={`${
                  open ? "rotate-180 transform" : ""
                } h-5 w-5 text-color`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="guideOrderPanel">
              <p>
                <span>○受付完了画面です。</span>
                <br />
                ご登録済みのメールアドレス宛に、「受付完了メール」が送信されます。
                <br />
                <span className="font-emibold">travel@jorudan.co.jp</span>
                からのメールをご確認ください。ご登録済みのお客様情報は、マイページにて確認いただけます。
              </p>
              <div className="guideOrderPanelImgs">
                <img src={plan6} />
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
}

function GuideOrderFree() {
  return (
    <div className="space-y-2.5">
      <div>・旅行相談</div>
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="guideOrderTitle">
              <span>プラン検索</span>
              <ChevronDownIcon
                className={`${
                  open ? "rotate-180 transform" : ""
                } h-5 w-5 text-color`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="guideOrderPanel">
              <p>
                <span>○条件を指定して探す</span>
                <br />
                ご希望の日付、場所、移動、宿泊、夕食をお選びください。
              </p>
              <div className="guideOrderPanelImgs">
                <img src={planF1a} />
                <div className="w-full">
                  <ArrowRightIcon className="w-6 h-6 mx-auto mt-[214px] hidden md:flex" />
                  <ArrowDownIcon className="w-6 h-6 flex m-auto md:hidden" />
                </div>
                <img src={planF1b} />
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="guideOrderTitle">
              <span>プラン選択</span>
              <ChevronDownIcon
                className={`${
                  open ? "rotate-180 transform" : ""
                } h-5 w-5 text-color`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="guideOrderPanel">
              <p>
                <span>○作成中のプラン内容を確認できます。</span>
                <br />
                各項目の
                <span className="font-semibold">
                  「変更」ボタンより、お好きな移動手段や宿泊エリアを選択してください。
                </span>
                <br />
                移動手段が「新幹線・特急」または「飛行機」の場合は、往路・復路のご希望の詳細を入力してください。
                <br />
                夕食のご相談、その他ご要望等ございましたら、ご希望の内容を入力してください。
                <br />
                内容に問題がなければ、画面下の
                <span className="font-semibold">
                  「内容確認へ進む」ボタンから、
                </span>
                内容確認へお進みください。
              </p>
              <div className="guideOrderPanelImgs">
                <img src={planF2a} />
                <div className="w-full">
                  <ArrowRightIcon className="w-6 h-6 mx-auto mt-[214px] hidden md:flex" />
                  <ArrowDownIcon className="w-6 h-6 flex m-auto md:hidden" />
                </div>
                <img src={planF2b} />
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="guideOrderTitle">
              <span>内容確認</span>
              <ChevronDownIcon
                className={`${
                  open ? "rotate-180 transform" : ""
                } h-5 w-5 text-color`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="guideOrderPanel">
              <p>
                <span>○ご相談内容を確認できます。</span>
                <br />
                内容に問題がなければ、画面下の
                <span className="font-semibold">
                  「上記の内容で問題ないので、次へ進む」ボタンから、お客様情報の入力(※1)
                </span>
                へ進みます。
                <br />
                変更をご希望の場合は、画面下の「内容を変更する」ボタンから、プラン選択画面へ戻り、各項目の「変更」ボタンより、変更が可能です。
              </p>
              <div className="guideOrderPanelImgs">
                <img src={planF3} />
              </div>
              <p className="text-xs">
                ※1
                「お客様情報の入力へ進む」にはログインが必要になります。JIDが未登録の場合、ログインページの「はじめてご利用の方はこちら」ボタンから、登録が可能です。
              </p>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="guideOrderTitle">
              <span>情報入力</span>
              <ChevronDownIcon
                className={`${
                  open ? "rotate-180 transform" : ""
                } h-5 w-5 text-color`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="guideOrderPanel">
              <p>
                <span>
                  ○お申込みに必要なお客様情報の入力をお願いいたします。
                </span>
                <br />
                入力後、画面下の「最終確認へ進む」ボタンを押すと、お申込み内容の確認画面へ移動します。
              </p>
              <div className="guideOrderPanelImgs">
                <img src={plan4} />
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="guideOrderTitle">
              <span>最終確認</span>
              <ChevronDownIcon
                className={`${
                  open ? "rotate-180 transform" : ""
                } h-5 w-5 text-color`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="guideOrderPanel">
              <p>
                <span>○お申込内容の最終確認画面です。</span>
                <br />
                内容に問題がなければ、プライバシーポリシーをご確認の上、画面下の
                <span className="font-semibold">
                  「上記の内容で申し込む」ボタンを押してください。
                  <br />
                  この画面の内容でお申し込みを開始します。
                </span>
                <br />
                変更をご希望の場合は、画面下の「内容を変更する」ボタンから、プラン選択画面へ戻り、各項目の「変更」ボタンより、変更が可能です。
              </p>
              <div className="guideOrderPanelImgs">
                <img src={planF5} />
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="guideOrderTitle">
              <span>受付完了</span>
              <ChevronDownIcon
                className={`${
                  open ? "rotate-180 transform" : ""
                } h-5 w-5 text-color`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="guideOrderPanel">
              <p>
                <span>○受付完了画面です。</span>
                <br />
                ご登録済みのメールアドレス宛に、「受付完了メール」が送信されます。
                <br />
                <span className="font-emibold">travel@jorudan.co.jp</span>
                からのメールをご確認ください。ご登録済みのお客様情報は、マイページにて確認いただけます。
              </p>
              <div className="guideOrderPanelImgs">
                <img src={plan6} />
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
}

function GuidePayment() {
  return (
    <div className="spaec-y-5">
      <p className={style.content}>
        決済方法は、クレジットカード、銀行振込、コンビニ決済からお選びいただけます。
        <ul className="list-disc list-inside">
          <li>クレジットカード(VISA・MASTER・AMEX・JCB)</li>
          <li>銀行振込(三井住友・三菱UFJ・みずほ・ゆうちょ・ペイペイ)</li>
          <li>コンビニ決済(決済手数料別途￥350)</li>
        </ul>
      </p>
    </div>
  );
}

function GuideArrange() {
  return (
    <div className="spaec-y-5">
      <p className={style.content}>
        全て入金後手配となります。
        <br />
        旅行代金をお支払いいただき、空き状況をお調べした後に予約完了となります。
        <br />
        そのため、ご希望のプランを手配出来ない場合がございます。予めご了承ください。
      </p>
    </div>
  );
}
