import { Link } from "react-router-dom";
import logo from "../img/logo_blue.png";
import facebook from "../img/facebook_logo_b.png";
import twitter from "../img/twitter_logo_b.svg";
import instagram from "../img/ig_logo_w.png";

const menu = [
  {
    name: "規約",
    href: "/info/terms",
  },
  {
    name: "約款・条件書",
    href: "/info/conditions/keiyaku_bosyu",
  },
  {
    name: "旅行業務取扱料金表",
    href: "/info/charge",
  },
  {
    name: "会社案内",
    href: "/info/company",
  },
  {
    name: "プライバシーポリシー",
    href: "/info/privacy",
  },
  {
    name: "ご利用ガイド",
    href: "/info/guide",
  },
  {
    name: "よくある質問",
    href: "/info/help",
  },
];

export default function Footer() {
  return (
    <div className="w-full mt-auto">
      <div className="bg-cyan-900 hidden md:flex">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div className="flex justify-evenly items-center py-2 md:space-x-10">
            <div className="space-x-10">
              {menu.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-sm text-white hover:text-cyan-400"
                >
                  {item.name}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-cyan-900 md:bg-white">
        <div className="max-w-7xl mx-auto px-4 py-2 md:py-4 flex flex-col md:flex-row justify-between text-xs text-white md:text-slate-800">
          <div className="flex flex-row space-x-4">
            <div className="md:self-end">
              Copyright © 1996-2022 <br className="md:hidden" />
              Jorudan Co.,Ltd. All Rights Reserved
            </div>
            <a href="https://www.facebook.com/JorudanTravel/" target="_blank">
              <img src={facebook} className="h-7 md:h-10 w-7 md:w-10" />
            </a>
            <a href="https://twitter.com/JorudanTravel/" target="_blank">
              <img src={twitter} className="h-7 md:h-10 w-7 md:w-10" />
            </a>
            <a href="https://www.instagram.com/jorudantravel/" target="_blank">
              <div className="bg-[#E1306C] h-7 md:h-10 w-7 md:w-10 rounded-full flex justify-center items-center ">
                <img src={instagram} className="h-5 md:h-6 w-5 md:w-6" />
              </div>
            </a>
          </div>
          <div className="hidden md:flex flex-row ">
            <Link to="/">
              <span className="sr-only">ジョルダントラベル</span>
              <img
                className="h-5 w-auto mr-4 sm:h-10"
                src={logo}
                alt="ジョルダントラベル"
              />
            </Link>
            <div className="md:self-end">
              観光庁長官登録旅行業第1678号
              <br />
              一般社団法人日本旅行業協会（ＪＡＴＡ）正会員 ／ ＩＡＴＡ公認代理店
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
