import style from "../../css/info.module.css";

export function HosyouKitei() {
  return (
    <div className="w-full max-w-screen-lg mx-auto my-4 md:my-8 px-2">
      <h2 className={style.pageTitle}>[旅行業約款] 特別補償規程</h2>

      <h3 className={style.title}>■第一章 補償金等の支払い</h3>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (当社の支払責任)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第一条</span>
        当社は、当社が実施する企画旅行に参加する旅行者が、その企画旅行参加中に急激かつ偶然な外来の事故(以下「事故」といいます。)によって身体に傷害を被ったときに、本章から第四章までの規定により、旅行者又はその法定相続人に死亡補償金、後遺障害補償金、入院見舞金及び通院見舞金(以下「補償金等」といいます。)を支払います。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>２</span>
        前項の傷害には、身体外部から有毒ガス又は有毒物質を偶然かつ一時に吸入、吸収又は摂取したときに急激に生ずる中毒症状(継続的に吸入、吸収又は摂取した結果生ずる中毒症状を除きます。)を含みます。ただし、細菌性食物中毒は含みません。
      </p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (用語の定義)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第二条</span>
        この規程において「企画旅行」とは、標準旅行業約款募集型企画旅行契約の部第二条第一項及び受注型企画旅行契約の部第二条第一項に定めるものをいいます。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>２</span>
        この規程において「企画旅行参加中」とは、旅行者が企画旅行に参加する目的をもって当社があらかじめ手配した乗車券類等によって提供される当該企画旅行日程に定める最初の運送・宿泊機関等のサービスの提供を受けることを開始した時から最後の運送・宿泊機関等のサービスの提供を受けることを完了した時までの期間をいいます。ただし、旅行者があらかじめ定められた企画旅行の行程から離脱する場合において、離脱及び復帰の予定日時をあらかじめ当社に届け出ていたときは、離脱の時から復帰の予定の時までの間は「企画旅行参加中」とし、また、旅行者が離脱及び復帰の予定日時をあらかじめ当社に届け出ることなく離脱したとき又は復帰の予定なく離脱したときは、その離脱の時から復帰の時までの間又はその離脱した時から後は「企画旅行参加中」とはいたしません。また、当該企画旅行日程に、旅行者が当社の手配に係る運送・宿泊機関等のサービスの提供を一切受けない日(旅行地の標準時によります。)が定められている場合において、その旨及び当該日に生じた事故によって旅行者が被った損害に対しこの規程による補償金及び見舞金の支払いが行われない旨を契約書面に明示したときは、当該日は「企画旅行参加中」とはいたしません。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>３</span>
        前項の「サービスの提供を受けることを開始した時」とは、次の各号のいずれかの時をいいます。
      </p>
      <p className={style.content}>
        一 添乗員、当社の使用人又は代理人が受付を行う場合は、その受付完了時
      </p>
      <p className={style.contentml2}>
        ニ 前号の受付が行われない場合において、最初の運送・宿泊機関等が、
      </p>
      <p className={style.contentml2}>
        イ 航空機であるときは、搭乗手続の完了時
      </p>
      <p className={style.contentml2}>ロ 船舶であるときは、乗船手続の完了時</p>
      <p className={style.contentml2}>
        ハ 鉄道であるときは、改札の終了時又は改札のないときは当該列車乗車時
      </p>
      <p className={style.contentml2}>ニ 車両であるときは、乗車時</p>
      <p className={style.contentml2}>
        ホ 宿泊機関であるときは、当該施設への入場時
      </p>
      <p className={style.contentml2}>
        ヘ 宿泊機関以外の施設であるときは、当該施設の利用手続終了時とします。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>４</span>
        第二項の「サービスの提供を受けることを完了した時」とは、次の各号のいずれかの時をいいます。
      </p>
      <p className={style.content}>
        一 添乗員、当社の使用人又は代理人が解散を告げる場合は、その告げた時
      </p>
      <p className={style.content}>
        二 前号の解散の告知が行われない場合において、最後の運送・宿泊機関等が、
      </p>
      <p className={style.contentml2}>
        イ 航空機であるときは、乗客のみが入場できる飛行場構内からの退場時
      </p>
      <p className={style.contentml2}>ロ 船舶であるときは、下船時</p>
      <p className={style.contentml2}>
        ハ 鉄道であるときは、改札終了時又は改札のないときは当該列車降車時
      </p>
      <p className={style.contentml2}>ニ 車両であるときは、降車時</p>
      <p className={style.contentml2}>
        ホ 宿泊機関であるときは、当該施設からの退場時
      </p>
      <p className={style.contentml2}>
        ヘ 宿泊機関以外の施設であるときは、当該施設からの退場時とします。
      </p>

      <h3 className={style.title}>■第二章 補償金等を支払わない場合</h3>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (補償金等を支払わない場合－その一)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第三条</span>
        当社は、次の各号に掲げる事由によって生じた傷害に対しては補償金等を支払いません。
      </p>
      <p className={style.contentml2}>
        一
        旅行者の故意。ただし、当該旅行者以外の者が被った傷害については、この限りではありません。
      </p>
      <p className={style.contentml2}>
        二
        死亡補償金を受け取るべき者の故意。ただし、その者が死亡補償金の一部の受取人である場合には、他の者が受け取るべき金額については、この限りではありません。
      </p>
      <p className={style.contentml2}>
        三
        旅行者の自殺行為、犯罪行為又は闘争行為。ただし、当該旅行者以外の者が被った傷害については、この限りではありません。
      </p>
      <p className={style.contentml2}>
        四
        旅行者が法令に定められた運転資格を持たないで、又は酒に酔って正常な運転ができないおそれがある状態で自動車又は原動機付自転車を運転している間に生じた事故。ただし、当該旅行者以外の者が被った傷害については、この限りではありません。
      </p>
      <p className={style.contentml2}>
        五
        旅行者が故意に法令に違反する行為を行い、又は法令に違反するサービスの提供を受けている間に生じた事故。ただし、当該旅行者以外の者が被った損害については、この限りではありません。
      </p>
      <p className={style.contentml2}>
        六
        旅行者の脳疾患、疾病又は心神喪失。ただし、当該旅行者以外の者が被った傷害については、この限りではありません。
      </p>
      <p className={style.contentml2}>
        七
        旅行者の妊娠、出産、早産、流産又は外科的手術その他の医療処置。ただし、当社の補償すべき傷害を治療する場合には、この限りではありません。
      </p>
      <p className={style.contentml2}>
        八 旅行者の刑の執行又は拘留若しくは入監中に生じた事故
      </p>
      <p className={style.contentml2}>
        九
        戦争、外国の武力行使、革命、政権奪取、内乱、武装反乱その他これらに類似の事変又は暴動(この規程においては、群衆又は多数の者の集団の行動によって、全国又は一部の地区において著しく平穏が害され、治安維持上重大な事態と認められる状態をいいます。)
      </p>
      <p className={style.contentml2}>
        十
        核燃料物質(使用済燃料を含みます。以下同様とします。)若しくは核燃料物質によって汚染された物(原子核分裂生成物を含みます。)の放射性、爆発性その他の有害な特性又はこれらの特性による事故
      </p>
      <p className={style.contentml2}>
        十一
        前二号の事由に随伴して生じた事故又はこれらに伴う秩序の混乱に基づいて生じた事故
      </p>
      <p className={style.contentml2}>
        十二 第十号以外の放射線照射又は放射能汚染
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>２</span>
        当社は、原因のいかんを問わず、頸部症候群(いわゆる「むちうち症」)又は腰痛で他覚症状のないものに対して、補償金等を支払いません。
      </p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (補償金等を支払わない場合－その二)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第四条</span>
        当社は、国内旅行を目的とする企画旅行の場合においては、前条に定めるほか、次の各号に掲げる事由によって生じた傷害に対しても、補償金等を支払いません。
      </p>
      <p className={style.contentml2}>一 地震、噴火又は津波</p>
      <p className={style.contentml2}>
        二
        前号の事由に随伴して生じた事故又はこれらに伴う秩序の混乱に基づいて生じた事故
      </p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (補償金等を支払わない場合－その三)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第五条</span>
        当社は、次の各号に掲げる傷害に対しては、各号の行為が当社があらかじめ定めた企画旅行の旅行日程に含まれている場合でなければ、補償金等を支払いません。ただし、各号の行為が当該旅行日程に含まれている場合においては、旅行日程外の企画旅行参加中に、同種の行為によって生じた傷害に対しても、補償金等を支払います。
      </p>
      <p className={style.contentml2}>
        一 旅行者が別表第一に定める運動を行っている間に生じた傷害
      </p>
      <p className={style.contentml2}>
        二
        旅行者が自動車、原動機付自転車又はモーターボートによる競技、競争、興行(いずれも練習を含みます。)又は試運転(性能試験を目的とする運転又は操縦をいいます。)をしている間に生じた傷害。ただし、自動車又は原動機付自転車を用いて道路上でこれらのことを行っている間に生じた傷害については、企画旅行の旅行日程に含まれていなくとも補償金等を支払います。
      </p>
      <p className={style.contentml2}>
        三
        航空運送事業者が路線を定めて運行する航空機(定期便であると不定期便であるとを問いません。)以外の航空機を旅行者が操縦している間に生じた傷害。
      </p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (補償金等を支払わない場合－その四)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第五条の二</span>
        当社は、旅行者又は死亡補償金を受け取るべき者が次の各号に掲げるいずれかに該当する事由がある場合には、補償金等を支払わないことがあります。ただし、その者が死亡補償金の一部の受取
        人である場合には、他の者が受け取るべき金額については、この限りではありません。
      </p>
      <p className={style.contentml2}>
        一
        暴力団、暴力団員、暴力団準構成員、暴力団関係企業その他の反社会的勢力(以下「反社会的勢力」といいます。)に該当すると認められること。
      </p>
      <p className={style.contentml2}>
        二
        反社会的勢力に対して資金等を提供し、又は便宜を供与する等の関与をしていると認められること。
      </p>
      <p className={style.contentml2}>
        三 反社会的勢力を不当に利用していると認められること。
      </p>
      <p className={style.contentml2}>
        四
        その他反社会的勢力と社会的に非難されるべき関係を有していると認められること。
      </p>

      <h3 className={style.title}>■第三章 補償金等の種類及び支払額</h3>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (死亡補償金の支払い)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第六条</span>
        当社は、旅行者が第一条の傷害を被り、その直接の結果として、事故の日から百八十日以内に死亡した場合は、旅行者一名につき、海外旅行を目的とする企画旅行においては二千五百万円、国内旅行を目的とする企画旅行においては千五百万円(以下「補償金額」といいます。)を死亡補償金として旅行者の法定相続人に支払います。ただし、当該旅行者について、既に支払った後遺障害補償金がある場合は、補償金額から既に支払った金額を控除した残額を支払います。
      </p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (後遺障害補償金の支払い)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第七条</span>
        当社は、旅行者が第一条の傷害を被り、その直接の結果として、事故の日から百八十日以内に後遺障害(身体に残された将来においても回復できない機能の重大な障害又は身体の一部の欠損で、かつ、その原因となった傷害が治った後のものをいいます。以下同様とします。)が生じた場合は、旅行者一名につき、補償金額に別表第二の各号に掲げる割合を乗じた額を後遺障害補償金として旅行者に支払います。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>２</span>
        前項の規定にかかわらず、旅行者が事故の日から百八十日を超えてなお治療を要する状態にあるときは、当社は、事故の日から百八十一日目における医師の診断に基づき後遺障害の程度を認定して、後遺障害補償金を支払います。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>３</span>
        別表第二の各号に掲げていない後遺障害に対しては、旅行者の職業、年齢、社会的地位等に関係なく、身体の障害の程度に応じ、かつ、別表第二の各号の区分に準じ後遺障害補償金の支払額を決定します。ただし、別表第二の一(三)、一(四)、二(三)、四(四)及び五(二)に掲げる機能障害に至らない障害に対しては、後遺障害補償金を支払いません。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>４</span>
        同一事故により二種以上の後遺障害が生じた場合には、当社は、その各々に対し前三項を適用し、その合計額を支払います。ただし、別表第二の七、八及び九に規定する上肢(腕及び手)又は下肢(脚及び足)の後遺障害に対しては、一肢ごとの後遺障害補償金は、補償金額の六〇％をもって限度とします。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>５</span>
        前各項に基づいて当社が支払うべき後遺障害補償金の額は、旅行者一名に対して一企画旅行につき、補償金額をもって限度とします。
      </p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (入院見舞金の支払い)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第八条</span>
        当社は、旅行者が第一条の傷害を被り、その直接の結果として、平常の業務に従事すること又は平常の生活ができなくなり、かつ、入院(医師による治療が必要な場合において、自宅等での治療が困難なため、病院又は診療所に入り、常に医師の管理下において治療に専念することをいいます。以下この条において同様とします。)した場合は、その日数(以下「入院日数」といいます。)に対し、次の区分に従って入院見舞金を旅行者に支払います。
      </p>
      <p className={style.content}>一 海外旅行を目的とする企画旅行の場合</p>
      <p className={style.contentml2}>
        イ 入院日数百八十日以上の傷害を被ったとき。 四十万円
      </p>
      <p className={style.contentml2}>
        ロ 入院日数九十日以上百八十日未満の傷害を被ったとき。二十万円
      </p>
      <p className={style.contentml2}>
        ハ 入院日数七日以上九十日未満の傷害を被ったとき。 十万円
      </p>
      <p className={style.contentml2}>
        ニ 入院日数七日未満の傷害を被ったとき。 四万円
      </p>
      <p className={style.content}>二 国内旅行を目的とする企画旅行の場合</p>
      <p className={style.contentml2}>
        イ 入院日数百八十日以上の傷害を被ったとき。 二十万円
      </p>
      <p className={style.contentml2}>
        ロ 入院日数九十日以上百八十日未満の傷害を被ったとき。 十万円
      </p>
      <p className={style.contentml2}>
        ハ 入院日数七日以上九十日未満の傷害を被ったとき。 五万円
      </p>
      <p className={style.contentml2}>
        ニ 入院日数七日未満の傷害を被ったとき。 二万円
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>２</span>
        旅行者が入院しない場合においても、別表第三の各号のいずれかに該当し、かつ、医師の治療を受けたときは、その状態にある期間については、前項の規定の適用上、入院日数とみなします。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>３</span>
        当社は、旅行者一名について入院見舞金と死亡補償金又は入院見舞金と後遺障害補償金を重ねて支払うべき場合には、その合計額を支払います。
      </p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (通院見舞金の支払い)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第九条</span>
        当社は、旅行者が第一条の傷害を被り、その直接の結果として、平常の業務に従事すること又は平常の生活に支障が生じ、かつ、通院(医師による治療が必要な場合において、病院又は診療所に通い、医師の治療を受けること(往診を含みます。)をいいます。以下この条において同様とします。)した場合において、その日数(以下「通院日数」といいます。)が三日以上となったときは、当該日数に対し、次の区分に従って通院見舞金を旅行者に支払います。
      </p>
      <p className={style.content}>一 海外旅行を目的とする企画旅行の場合</p>
      <p className={style.contentml2}>
        イ 通院日数九十日以上の傷害を被ったとき。 十万円
      </p>
      <p className={style.contentml2}>
        ロ 通院日数七日以上九十日未満の傷害を被ったとき。 五万円
      </p>
      <p className={style.contentml2}>
        ハ 通院日数三日以上七日未満の傷害を被ったとき。 二万円
      </p>
      <p className={style.content}>二 国内旅行を目的とする企画旅行の場合</p>
      <p className={style.contentml2}>
        イ 通院日数九十日以上の傷害を被ったとき。 五万円
      </p>
      <p className={style.contentml2}>
        ロ 通院日数七日以上九十日未満の傷害を被ったとき。 二万五千円
      </p>
      <p className={style.contentml2}>
        ハ 通院日数三日以上七日未満の傷害を被ったとき。 一万円
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>２</span>
        旅行者が通院しない場合においても、骨折等の傷害を被った部位を固定するために医師の指示によりギプス等を常時装着した結果、平常の業務に従事すること又は平常の生活に著しい支障が生じたと当社が認めたときは、その状態にある期間については、前項の規定の適用上、通院日数とみなします。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>３</span>
        当社は、平常の業務に従事すること又は平常の生活に支障がない程度に傷害が治ったとき以降の通院に対しては、通院見舞金を支払いません。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>４</span>
        当社は、いかなる場合においても、事故の日から百八十日を経過した後の通院に対しては、通院見舞金を支払いません。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>５</span>
        当社は、旅行者一名について通院見舞金と死亡補償金又は通院見舞金と後遺障害補償金を重ねて支払うべき場合には、その合計額を支払います。
      </p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (入院見舞金及び通院見舞金の支払いに関する特則)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第十条</span>
        当社は、旅行者一名について入院日数及び通院日数がそれぞれ一日以上となった場合は、前二条の規定にかかわらず、次の各号に掲げる見舞金のうちいずれか金額の大きいもの(同額の場合には、第一号に掲げるもの)のみを支払います。
      </p>
      <p className={style.contentml2}>
        一 当該入院日数に対し当社が支払うべき入院見舞金
      </p>
      <p className={style.contentml2}>
        二
        当該通院日数(当社が入院見舞金を支払うべき期間中のものを除きます。)に当該入院日数を加えた日数を通院日数とみなした上で、当該日数に対し当社が支払うべき通院見舞金
      </p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (死亡の推定)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第十一条</span>
        旅行者が搭乗する航空機若しくは船舶が行方不明となってから、又は遭難してから三十日を経過してもなお旅行者が発見されないときは、航空機若しくは船舶が行方不明となった日又は遭難した日に、旅行者が第一条の傷害によって死亡したものと推定します。
      </p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (他の身体障害又は疾病の影響)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第十二条</span>
        旅行者が第一条の傷害を被ったとき既に存在していた身体障害若しくは疾病の影響により、又は第一条の傷害を被った後にその原因となった事故と関係なく発生した傷害若しくは疾病の影響により第一条の傷害が重大となったときは、その影響がなかった場合に相当する金額を決定してこれを支払います。
      </p>

      <h3 className={style.title}>
        ■第四章 事故の発生及び補償金等の請求の手続
      </h3>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (傷害程度等に関する説明等の請求)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第十三条</span>
        旅行者が第一条の傷害を被ったときは、当社は、旅行者又は死亡補償金を受け取るべき者に対し、傷害の程度、その原因となった事故の概要等について説明を求め、又は旅行者の身体の診療若しくは死体の検案を求めることがあります。この場合において、旅行者又は死亡補償金を受け取るべき者は、これらの求めに協力しなければなりません。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>２</span>
        旅行者又は死亡補償金を受け取るべき者は、当社の関知しない事由により第一条の傷害を被ったときは、傷害の程度、その原因となった事故の概要等について、当社に対し、当該事故の日から三十日以内に報告しなければなりません。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>３</span>
        旅行者又は死亡補償金を受け取るべき者が、当社の認める正当な理由なく前二項の規定に違反したとき又はその説明若しくは報告につき知っている事実を告げず、若しくは不実のことを告げたときは、当社は、補償金等を支払いません。
      </p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (補償金等の請求)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第十四条</span>
        旅行者又は死亡補償金を受け取るべき者が補償金等の支払いを受けようとするときは、当社に対し、当社所定の補償金等請求書及び次に掲げる書類を提出しなければなりません。
      </p>
      <p className={style.content}>一 死亡補償金請求の場合</p>
      <p className={style.contentml2}>
        イ 旅行者の戸籍謄本並びに法定相続人の戸籍謄本及び印鑑証明書
      </p>
      <p className={style.contentml2}>
        ロ 公の機関(やむを得ない場合には、第三者)の事故証明書
      </p>
      <p className={style.contentml2}>ハ 旅行者の死亡診断書又は死体検案書</p>
      <p className={style.content}>二 後遺障害補償金請求の場合</p>
      <p className={style.contentml2}>イ 旅行者の印鑑証明書</p>
      <p className={style.contentml2}>
        ロ 公の機関(やむを得ない場合には、第三者)の事故証明書
      </p>
      <p className={style.contentml2}>
        ハ 後遺障害の程度を証明する医師の診断書
      </p>
      <p className={style.content}>三 入院見舞金請求の場合</p>
      <p className={style.contentml2}>
        イ 公の機関(やむを得ない場合には、第三者)の事故証明書
      </p>
      <p className={style.contentml2}>ロ 傷害の程度を証明する医師の診断書</p>
      <p className={style.contentml2}>
        ハ 入院日数又は通院日数を記載した病院又は診療所の証明書類
      </p>
      <p className={style.content}>四 通院見舞金請求の場合</p>
      <p className={style.contentml2}>
        イ 公の機関(やむを得ない場合には、第三者)の事故証明書
      </p>
      <p className={style.contentml2}>ロ 傷害の程度を証明する医師の診断書</p>
      <p className={style.contentml2}>
        ハ 入院日数又は通院日数を記載した病院又は診療所の証明書類
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>２</span>
        当社は、前項以外の書類の提出を求めること又は前項の提出書類の一部の省略を認めることがあります。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>３</span>
        旅行者又は死亡補償金を受け取るべき者が第一項の規定に違反したとき又は提出書類につき知っている事実を告げず、若しくは不実のことを告げたときは、当社は、補償金等支払いません。
      </p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>(代位)</p>
      <p className={style.content}>
        <span className={style.boldmr2}>第十五条</span>
        当社が補償金等を支払った場合でも、旅行者又はその相続人が旅行者の被った傷害について第三者に対して有する損害賠償請求権は、当社に移転しません。
      </p>

      <h3 className={style.title}>■第五章 携帯品損害補償</h3>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (当社の支払責任)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第十六条</span>
        当社は、当社が実施する企画旅行に参加する旅行者が、その企画旅行参加中に生じた偶然な事故によってその所有の身の回り品(以下「補償対象品」といいます。)に損害を被ったときに、本章の規定により、携帯品損害補償金(以下「損害補償金」といいます。)を支払います。
      </p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (損害補償金を支払わない場合-その一)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第十七条</span>
        当社は、次の各号に掲げる事由によって生じた損害に対しては、損害補償金を支払いません。
      </p>
      <p className={style.contentml2}>
        一
        旅行者の故意。ただし、当該旅行者以外の者が被った損害については、この限りではありません。
      </p>
      <p className={style.contentml2}>
        二
        旅行者と世帯を同じくする親族の故意。ただし、旅行者に損害補償金を受け取らせる目的でなかった場合は、この限りではありません。
      </p>
      <p className={style.contentml2}>
        三
        旅行者の自殺行為、犯罪行為又は闘争行為。ただし、当該旅行者以外の者が被った損害については、この限りではありません。
      </p>
      <p className={style.contentml2}>
        四
        旅行者が法令に定められた運転資格を持たないで、又は酒に酔って正常な運転ができないおそれがある状態で自動車又は原動機付自転車を運転している間に生じた事故。ただし、当該旅行者以外の者が被った損害については、この限りではありません。
      </p>
      <p className={style.contentml2}>
        五
        旅行者が故意に法令に違反する行為を行い、又は法令に違反するサービスの提供を受けている間に生じた事故。ただし、当該旅行者以外の者が被った損害については、この限りではありません。
      </p>
      <p className={style.contentml2}>
        六
        差押え、徴発、没収、破壊等国又は公共団体の公権力の行使。ただし、火災消防又は避難に必要な処置としてなされた場合を除きます。六
        差押え、徴発、没収、破壊等国又は公共団体の公権力の行使。ただし、火災消防又は避難に必要な処置としてなされた場合を除きます。
      </p>
      <p className={style.contentml2}>
        七
        補償対象品の瑕疵。ただし、旅行者又はこれに代わって補償対象品を管理する者が相当の注意をもってしても発見し得なかった瑕疵を除きます。
      </p>
      <p className={style.contentml2}>
        八 補償対象品の自然の消耗、さび、かび、変色、ねずみ食い、虫食い等
      </p>
      <p className={style.contentml2}>
        九 単なる外観の損傷であって補償対象品の機能に支障をきたさない損害
      </p>
      <p className={style.contentml2}>
        十
        補償対象品である液体の流出。ただし、その結果として他の補償対象品に生じた損害については、この限りではありません。
      </p>
      <p className={style.contentml2}>十一 補償対象品の置き忘れ又は紛失</p>
      <p className={style.contentml2}>
        十二 第三条第一項第九号から第十二号までに掲げる事由
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>２</span>
        当社は、国内旅行を目的とする企画旅行の場合においては、前項に定めるほか、次の各号に掲げる事由によって生じた損害に対しても、損害補償金を支払いません。
      </p>
      <p className={style.contentml2}>一 地震、噴火又は津波</p>
      <p className={style.contentml2}>
        二
        前号の事由に随伴して生じた事故又はこれらに伴う秩序の混乱に基づいて生じた事故
      </p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (損害補償金を支払わない場合-その二)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第十七条の二</span>
        当社は、旅行者が次の各号に掲げるいずれかに該当する事由がある場合には、損害補償金
        を支払わないことがあります。
      </p>
      <p className={style.contentml2}>
        一 反社会的勢力に該当すると認められること。
      </p>
      <p className={style.contentml2}>
        二
        反社会的勢力に対して資金等を提供し、又は便宜を供与する等の関与をしていると認められること。
      </p>
      <p className={style.contentml2}>
        三 反社会的勢力を不当に利用していると認められること。
      </p>
      <p className={style.contentml2}>
        四
        法人である場合において、反社会的勢力がその法人を支配し、又はその法人の経営に実質的に関与
        していると認められること。
      </p>
      <p className={style.contentml2}>
        五
        その他反社会的勢力と社会的に非難されるべき関係を有していると認められること。
      </p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (補償対象品及びその範囲)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第十八条</span>
        補償対象品は、旅行者が企画旅行参加中に携行するその所有の身の回り品に限ります。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>２</span>
        前項の規定にかかわらず、次の各号に掲げるものは、補償対象品に含まれません。
      </p>
      <p className={style.contentml2}>
        一 現金、小切手その他の有価証券、印紙、切手その他これらに準ずるもの
      </p>
      <p className={style.contentml2}>
        二
        クレジットカード、クーポン券、航空券、パスポートその他これらに準ずるもの
      </p>
      <p className={style.contentml2}>
        三
        稿本、設計書、図案、帳簿その他これらに準ずるもの(磁気テープ、磁気ディスク、シー・ディー・ロム、光ディスク等情報機器(コンピュータ及びその端末装置等の周辺機器)で直接処理を行える記録媒体に記録されたものを含みます。)
      </p>
      <p className={style.contentml2}>
        四
        船舶(ヨット、モーターボート及びボートを含みます。)及び自動車、原動機付自転車及びこれらの付属品
      </p>
      <p className={style.contentml2}>
        五 山岳登はん用具、探検用具その他これらに類するもの
      </p>
      <p className={style.contentml2}>
        六 義歯、義肢、コンタクトレンズその他これらに類するもの
      </p>
      <p className={style.contentml2}>七 動物及び植物</p>
      <p className={style.contentml2}>八 その他当社があらかじめ指定するもの</p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (損害額及び損害補償金の支払額)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第十九条</span>
        当社が損害補償金を支払うべき損害の額(以下「損害額」といいます。)は、その損害が生じた地及び時における補償対象品の価額又は補償対象品を損害発生の直前の状態に復するに必要な修繕費及び次条第三項の費用の合計額のいずれか低い方の金額を基準として定めることとします。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>２</span>
        補償対象品の一個又は一対についての損害額が十万円を超えるときは、当社は、そのものの損害の額を十万円とみなして前項の規定を適用します。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>３</span>
        当社が支払うべき損害補償金の額は、旅行者一名に対して一企画旅行につき十五万円をもって限度とします。ただし、損害額が旅行者一名について一回の事故につき三千円を超えない場合は、当社は、損害補償金を支払いません。
      </p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (損害の防止等)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第二十条</span>
        旅行者は、補償対象品について第十六条に規定する損害が発生したことを知ったときは、次の事項を履行しなければなりません。
      </p>
      <p className={style.contentml2}>一 損害の防止軽減に努めること。</p>
      <p className={style.contentml2}>
        二
        損害の程度、原因となった事故の概要及び旅行者が損害を被った補償対象品についての保険契約の有無を、遅滞なく当社に通知すること。
      </p>
      <p className={style.contentml2}>
        三
        旅行者が他人から損害の賠償を受けることができる場合は、その権利の行使について必要な手続をとること。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>２</span>
        当社は、旅行者が正当な理由なく前項第一号に違反したときは、防止軽減することができたと認められる額を差し引いた残額を損害の額とみなし、同項第二号に違反したときは、損害補償金を支払わず、また、同項第三号に違反したときは、取得すべき権利の行使によって受けることができたと認められる額を差し引いた残額を損害の額とみなします。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>３</span>
        当社は、次に掲げる費用を支払います。
      </p>
      <p className={style.contentml2}>
        一
        第一項第一号に規定する損害の防止軽減のために要した費用のうちで当社が必要又は有益であったと認めたもの
      </p>
      <p className={style.contentml2}>
        二 第一項第三号に規定する手続のために必要な費用
      </p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (損害補償金の請求)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第二十一条</span>
        旅行者は、損害補償金の支払いを受けようとするときは、当社に対し、当社所定の損害補償金請求書及び次に掲げる書類を提出しなければなりません。
      </p>
      <p className={style.contentml2}>
        一 警察署又はこれに代わるべき第三者の事故証明書
      </p>
      <p className={style.contentml2}>
        二 補償対象品の損害の程度を証明する書類
      </p>
      <p className={style.contentml2}>三 その他当社の要求する書類</p>
      <p className={style.content}>
        <span className={style.boldmr2}>２</span>
        旅行者が前項の規定に違反したとき又は提出書類につき故意に不実のことを表示し、又はその書類を偽造若しくは変造したとき(第三者をしてなさしめたときも、同様とします。)は、当社は、損害補償金を支払いません。
      </p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (保険契約がある場合)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第二十二条</span>
        第十六条の損害に対して保険金を支払うべき保険契約がある場合は、当社は、当社が支払うべき損害補償金の額を減額することがあります。
      </p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>(代位)</p>
      <p className={style.content}>
        <span className={style.boldmr2}>第二十三条</span>
        当社が損害補償金を支払うべき損害について、旅行者が第三者に対して損害賠償請求権を有する場合には、その損害賠償請求権は、当社が旅行者に支払った損害補償金の額の限度内で当社に移転します。
      </p>

      <h3 className={style.title}>■別表第一(第五条第一号関係)</h3>
      <p className={style.content}>
        山岳登はん(ピッケル、アイゼン、ザイル、ハンマー等の登山用具を使用するもの)リュージュ
        ボブスレー スカイダイビング
        ハンググライダー搭乗超軽量動力機(モーターハンググライダー、マイクロライト機、ウルトラライト機等)搭乗ジャイロプレーン搭乗その他これらに類する危険な運動
      </p>

      <h3 className={`${style.content} ${style.mt4}`}>
        ■別表第二(第七条第一項、第三項及び第四項関係)
      </h3>
      <table className={style.tbl}>
        <tbody>
          <tr>
            <td colSpan={2} className={style.tblBgc}>
              一 眼の障害
            </td>
          </tr>
          <tr className={style.tblTR}>
            <td>(一) 両眼が失明したとき。</td>
            <td>100%</td>
          </tr>
          <tr className={style.tblTR}>
            <td>(二) 一眼が失明したとき。</td>
            <td>60%</td>
          </tr>
          <tr className={style.tblTR}>
            <td>(三) 一眼の矯正視力が〇・六以下となったとき。</td>
            <td>5%</td>
          </tr>
          <tr className={style.tblTR}>
            <td>
              (四)
              一眼の視野狭窄(正常視野の角度の合計の六〇％以下となった場合をいう。)となったとき。
            </td>
            <td>5%</td>
          </tr>
          <tr>
            <td colSpan={2} className={style.tblBgc}>
              二 耳の障害
            </td>
          </tr>
          <tr className={style.tblTR}>
            <td>(一) 両耳の聴力を全く失ったとき。</td>
            <td>80%</td>
          </tr>
          <tr className={style.tblTR}>
            <td>(二) 一耳の聴力を全く失ったとき。</td>
            <td>30%</td>
          </tr>
          <tr className={style.tblTR}>
            <td>
              (三)
              一耳の聴力が五〇センチメートル以上では通常の話声を解せないとき。
            </td>
            <td>5%</td>
          </tr>
          <tr>
            <td colSpan={2} className={style.tblBgc}>
              三 鼻の障害
            </td>
          </tr>
          <tr className={style.tblTR}>
            <td>鼻の機能に著しい障害を残すとき。</td>
            <td>20%</td>
          </tr>
          <tr>
            <td colSpan={2} className={style.tblBgc}>
              四 そしゃく、言語の障害
            </td>
          </tr>
          <tr className={style.tblTR}>
            <td>(一) そしゃく又は言語の機能を全く廃したとき。</td>
            <td>100%</td>
          </tr>
          <tr className={style.tblTR}>
            <td>(二) そしゃく又は言語の機能に著しい障害を残すとき。</td>
            <td>35%</td>
          </tr>
          <tr className={style.tblTR}>
            <td>(三) そしゃく又は言語の機能に障害を残すとき。</td>
            <td>15%</td>
          </tr>
          <tr className={style.tblTR}>
            <td>(四) 歯に五本以上の欠損を生じたとき。</td>
            <td>5%</td>
          </tr>
          <tr>
            <td colSpan={2} className={style.tblBgc}>
              五 外貌(顔面・頭部・頸部をいう。)の醜状
            </td>
          </tr>
          <tr className={style.tblTR}>
            <td>(一) 外貌に著しい醜状を残すとき。</td>
            <td>15%</td>
          </tr>
          <tr className={style.tblTR}>
            <td>
              (二)
              外貌に醜状(顔面においては直径二センチメートルの瘢痕、長さ三センチメートルの線状痕程度をいう。)を残すとき。
            </td>
            <td>3%</td>
          </tr>
          <tr>
            <td colSpan={2} className={style.tblBgc}>
              六 脊柱の障害
            </td>
          </tr>
          <tr className={style.tblTR}>
            <td>(一) 脊柱に著しい奇形又は著しい運動障害を残すとき。</td>
            <td>40%</td>
          </tr>
          <tr className={style.tblTR}>
            <td>(二) 脊柱に運動障害を残すとき。</td>
            <td>30%</td>
          </tr>
          <tr className={style.tblTR}>
            <td>(三) 脊柱に奇形を残すとき。</td>
            <td>15%</td>
          </tr>
          <tr>
            <td colSpan={2} className={style.tblBgc}>
              七 腕(手関節以上をいう。)、脚(足関節以上をいう。)の障害
            </td>
          </tr>
          <tr className={style.tblTR}>
            <td>(一) 一腕又は一脚を失ったとき。</td>
            <td>60%</td>
          </tr>
          <tr className={style.tblTR}>
            <td>
              (二)
              一腕又は一脚の三大関節中の二関節又は三関節の機能を全く廃したとき。
            </td>
            <td>50%</td>
          </tr>
          <tr className={style.tblTR}>
            <td>
              (三) 一腕又は一脚の三大関節中の一関節の機能を全く廃したとき。
            </td>
            <td>35%</td>
          </tr>
          <tr className={style.tblTR}>
            <td>(四) 一腕又は一脚の機能に障害を残すとき。</td>
            <td>5%</td>
          </tr>
          <tr>
            <td colSpan={2} className={style.tblBgc}>
              八 手指の障害
            </td>
          </tr>
          <tr className={style.tblTR}>
            <td>(一) 一手の母指を指関節(指節間関節)以上で失ったとき。</td>
            <td>20%</td>
          </tr>
          <tr className={style.tblTR}>
            <td>(二) 一手の母指の機能に著しい障害を残すとき。</td>
            <td>15%</td>
          </tr>
          <tr className={style.tblTR}>
            <td>
              (三) 母指以外の一指を第二指関節(遠位指節間関節)以上で失ったとき。
            </td>
            <td>8%</td>
          </tr>
          <tr className={style.tblTR}>
            <td>(四) 母指以外の一指の機能に著しい障害を残すとき。</td>
            <td>5%</td>
          </tr>
          <tr>
            <td colSpan={2} className={style.tblBgc}>
              九 足指の障害
            </td>
          </tr>
          <tr className={style.tblTR}>
            <td>(一) 一足の第一足指を趾関節(指節間関節)以上で失ったとき。</td>
            <td>10%</td>
          </tr>
          <tr className={style.tblTR}>
            <td>(二) 一足の第一足指の機能に著しい障害を残すとき。</td>
            <td>8%</td>
          </tr>
          <tr className={style.tblTR}>
            <td>
              (三)
              第一足指以外の一足指を第二趾関節(遠位指節間関節)以上で失ったとき。
            </td>
            <td>5%</td>
          </tr>
          <tr className={style.tblTR}>
            <td>(四) 第一足指以外の一足指の機能に著しい障害を残すとき。</td>
            <td>3%</td>
          </tr>
          <tr className={style.tblTR}>
            <td>
              十
              その他身体の著しい障害により終身自用を弁ずることができないとき。
            </td>
            <td>100%</td>
          </tr>
        </tbody>
      </table>
      <p className={style.content}>
        (注)
        第七号、第八号及び第九号の規定中「以上」とは、当該関節より心臓に近い部分をいいます。
      </p>
      <h3 className={style.title}>別表第三(第八条第二項関係)</h3>
      <p className={style.content}>
        一 両眼の矯正視力が〇・〇六以下になっていること。
      </p>
      <p className={style.content}>
        二 そしゃく又は言語の機能を失っていること。
      </p>
      <p className={style.content}>三 両耳の聴力を失っていること。</p>
      <p className={style.content}>
        四 両上肢の手関節以上のすべての関節の機能を失っていること。
      </p>
      <p className={style.content}>五 一下肢の機能を失っていること。</p>
      <p className={style.content}>
        六
        胸腹部臓器の障害のため身体の自由が主に摂食、洗面等の起居動作に限られていること。
      </p>
      <p className={style.content}>
        七
        神経系統又は精神の障害のため身体の自由が主に摂食、洗面等の起居動作に限られていること。
      </p>
      <p className={style.content}>
        八
        その他上記部位の合併障害等のため身体の自由が主に摂食、洗面等の起居動作に限られていること。
      </p>
      <p className={style.content}>
        (注) 第四号の規定中「以上」とは、当該関節より心臓に近い部分をいいます。
      </p>
    </div>
  );
}
