import { Outlet, useSearchParams } from "react-router-dom";
import { useLocalStorage } from "../App";
import { Order, TransportInfo } from "../types";
import { parseISO } from "date-fns";
import { useEffect, useState } from "react";
import { getHotels } from "../api";
import Spinner from "../components/Spinner";
import { StepsBar } from "../components/StepsBar";
import {
  locations,
  bathChoice,
  breakfastChoice,
  smokingChoice,
  regions,
  regionTokyoEast,
  regionTokyoCentral,
  regionTohoku,
  regionShinshu,
  regionHokuriku,
  regionChubu,
  regionKansai,
  getErrorMessage,
  ranks,
  transports,
  outwardTimesForTrain, 
  returnTimesForTrain,
  outwardTimes,
  returnTimes,
  departureTimes,
} from "../constants";

export default function OrderFreeFlow() {
    let [searchParams, setSearchParams] = useSearchParams();
    const params = Object.fromEntries(searchParams);

    let schedule = params.schedule;

    const departArea = params.departArea ?? 'default';
    const destArea = params.destArea ?? 'default';

    const setRegions = (region: string) => {
        switch(region) {
            case 'tokyo_east':
                return regionTokyoEast.map( list => ({...list}));
            
            case 'tokyo_central':
                return regionTokyoCentral.map( list => ({...list}));

            case 'tohoku':
                return regionTohoku.map( list => ({...list}));

            case 'shinshu':
                return regionShinshu.map( list => ({...list}));

            case 'hokuriku':
                return regionHokuriku.map( list => ({...list}));

            case 'chubu':
                return regionChubu.map( list => ({...list}));

            case 'kansai':
                return regionKansai.map( list => ({...list}));

            default:
                return regions.map( list => ({...list}));
        }
    }

    let departRegions = setRegions(departArea);

    if(departArea !== 'default') {
        departRegions = departRegions.filter(x => { return x.label !== 'その他' });
    }

    let destRegions = setRegions(destArea);

    let origin = departRegions.find((origin: any) => origin.region_id == params.origin!)!;
    let othersOrigin = params.othersOrigin!;
    let destination = destRegions.find((destination: any) => destination.region_id == params.destination!)!;
    let othersDestination = params.othersDestination!;
    let transport = transports.find((transport: any) => transport.id == params.transports)!;
    let hotelArea = params.hotelArea!;
    let hotelClass = params.hotelClass!;
    let othersHotelClass = ranks.find((hotelClass: any) => hotelClass.id == params.hotelClass!)!;
    let startDate = parseISO(params.startDate);
    let endDate = parseISO(params.endDate);
    let adults = params.adults ? parseInt(params.adults) : 0;
    let children = params.children ? parseInt(params.children) : 0;
    let location = locations.find((location: any) => location.id == params.location!);
    let bath = bathChoice.find((bath: any) => bath.id == params.bath!);
    let breakfast = breakfastChoice.find((breakfast: any) => breakfast.id == params.breakfast!);
    let smoking = smokingChoice.find((smoking: any) => smoking.id == params.smoking!);

    let departTimes: any[] = [];
    let destTimes: any[] = [];

    if(schedule === 'day') {
        if(transport.id === 'train') {
            departTimes = outwardTimesForTrain.map( list => ({...list}));
            destTimes = returnTimesForTrain.map( list => ({...list}));
        } else {
            departTimes = outwardTimes.map( list => ({...list}));
            destTimes = returnTimes.map( list => ({...list}));
        }
    } else if(schedule === 'stay') {
        if(transport.id === 'train') {
            departTimes = outwardTimesForTrain.concat(returnTimesForTrain);
            destTimes = outwardTimesForTrain.concat(returnTimesForTrain);
        } else {
            departTimes = outwardTimes.concat(returnTimes);
            destTimes = outwardTimes.concat(returnTimes);
        }
    } else {
        departTimes = departureTimes.map( list => ({...list}));
        destTimes = departureTimes.map( list => ({...list}));
    }

    let depTimeOrigin = departTimes.find(
        (depTime: any) => depTime.id == params.depTimeOrigin!
    );

    let depTimeDestination = destTimes.find(
        (depTime: any) => depTime.id == params.depTimeDestination!
    );

    const [loadingHotel, setLoadingHotel] = useState(false);
    const [loadingFlights, setLoadingFlights] = useState(false);
    const [error, setError] = useState<string>();

    const remarks = params.remarks ?? '';

    const transportInfo: TransportInfo = {
        originDep: origin && origin.region_id !== 'others' ? origin.label : othersOrigin,
        originArr: destination && destination.region_id !== 'others' ? destination.label : othersDestination,
        originDepTime: depTimeOrigin ? depTimeOrigin.time : '',
        destinationDep: destination && destination.region_id !== 'others' ? destination.label : othersDestination,
        destinationArr: origin && origin.region_id !== 'others' ? origin.label : othersOrigin,
        destinationDepTime: depTimeDestination ? depTimeDestination.time : ''
    }

    let defaultOrder = {
        schedule,
        startDate,
        endDate,
        origin,
        othersOrigin,
        destination,
        othersDestination,
        transport,
        transportInfo,
        depTimeOrigin,
        depTimeDestination,
        othersHotelClass,
        location,
        bath,
        breakfast,
        smoking,
        adults,
        children,
        remarks,
        departArea,
        destArea
    };

    const [order, setOrder] = useLocalStorage<Order>("order", defaultOrder);

    let region = params.destination ?? "";
    let stayDate = params.startDate ?? "";

    useEffect(() => {
        //if (params.destination !== "others") {
        if(destination && destination.prior !== 99) {
            const getData = async () => {
                try {
                    let hotels = await getHotels(region, stayDate);

                    let area = hotels.find((hotel: any) => hotel.hotelarea_id == hotelArea)!;
                    let hotelRank = area.hotelclass.find((hc: any) => hc.hotelclass_id == hotelClass);

                    let room = {
                        location,
                        bath,
                        breakfast,
                        smoking,
                    };

                    setOrder((state: Order) => ({
                        ...state,
                        hotel: { ...state.hotel, area, class: hotelRank, room },
                }));
                    setError(undefined);
                } catch (err) {
                    setError(getErrorMessage(err));
                } finally {
                    setLoadingHotel(false);
                }
            };

            if (!order.hotel?.area) {
                setLoadingHotel(true);
                getData();
            }
        }
    }, []);

    return (
        <>
            <div className="w-full max-w-7xl mx-auto my-5 px-2 md:px-6">
                <StepsBar />

                {loadingHotel || loadingFlights ? (
                    <Spinner message="旅行パッケージを作成中..." />
                ) : (
                    <Outlet context={[order, setOrder]} />
                )}
            </div>
        </>
    );
}
