import { useEffect, useState } from "react";
import { useAuth } from "../App";
import { Link, useNavigate } from "react-router-dom";
import { Account } from "../types";
import { getAccount } from "../api";

import lock from "../img/images/icon_key.svg";
import mail from "../img/images/icon_mail.svg";
import userIcon from "../img/images/icon_user.svg";
import { KeyIcon } from "@heroicons/react/solid";
import { getErrorMessage, JID_ACCOUNT_URL } from "../constants";
import { GrayDottedLine } from "../components/Lines";

export default function MyPage() {
  let { user } = useAuth();
  let navigate = useNavigate();
  const [account, setAccount] = useState<Account>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();

  useEffect(() => {
    const getData = async () => {
      try {
        let account = await getAccount(user?.eid);
        setAccount(account);
        setError(undefined);
      } catch (err) {
        setError(getErrorMessage(err));
        setAccount(account);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, []);

  let auth = useAuth();

  return (
    <div className="w-full max-w-7xl mx-auto my-10">
      <div className="cart">
        <div className="cartTitle">お客様情報</div>
        <div className="cartContents space-y-5">
          {account?.jid && (
            <>
              <div>
                <div className="flex flex-row justify-between">
                  <div className="cartSubTitles">
                    <img src={lock} />
                    <div>JID(ユーザーID)</div>
                  </div>
                  <a href={`${JID_ACCOUNT_URL}&c=upjid&p=input`}>
                    <div className="btnChange">変更</div>
                  </a>
                </div>
                <GrayDottedLine />
                <div>{account?.jid}</div>
              </div>

              <div>
                <div className="flex flex-row justify-between">
                  <div className="cartSubTitles">
                    <img src={mail} />
                    <div>メールアドレス</div>
                  </div>
                  <a href={`${JID_ACCOUNT_URL}&c=upmail&p=input`}>
                    <div className="btnChange">変更</div>
                  </a>
                </div>
                <GrayDottedLine />
                <div>{account?.email}</div>
              </div>

              <div>
                <div className="flex flex-row justify-between">
                  <div className="cartSubTitles">
                    <KeyIcon className="h-6 w-6" />
                    <div>パスワード</div>
                  </div>
                  <a href={`${JID_ACCOUNT_URL}&c=uppass&p=input`}>
                    <div className="btnChange">変更</div>
                  </a>
                </div>
                <GrayDottedLine />
                <div>設定済み</div>
              </div>
            </>
          )}

          <div>
            <div className="flex flex-row justify-between">
              <div className="cartSubTitles">
                <img src={userIcon} />
                <div>基本情報</div>
              </div>
              <Link to="/inputInfo" className="btnChange">
                変更
              </Link>
            </div>

            <GrayDottedLine />
            <div className="flex flex-row flex-wrap">
              <div className="cartItems">
                <div className="cartItemName">お名前</div>
                <div className="space-x-2">
                  {account?.jid ? (
                    <>
                      <span>{account?.profile.last_name}</span>
                      <span>{account?.profile.first_name}</span>
                    </>
                  ) : (
                    <span>未設定</span>
                  )}
                </div>
              </div>

              <div className="cartItems">
                <div className="cartItemName">カナ</div>
                <div className="space-x-2">
                  {account?.jid ? (
                    <>
                      <span>{account?.profile.last_name_reading}</span>
                      <span>{account?.profile.first_name_reading}</span>
                    </>
                  ) : (
                    <span>未設定</span>
                  )}
                </div>
              </div>

              <div className="cartItems">
                <div className="cartItemName">性別</div>
                <div>
                  {account?.jid ? (
                    <>
                      {account?.profile.gender === "male" ? (
                        <>男性</>
                      ) : (
                        <>女性</>
                      )}
                    </>
                  ) : (
                    <>未設定</>
                  )}
                </div>
              </div>

              <div className="cartItems">
                <div className="cartItemName">生年月日</div>
                <div>{account?.profile.birth ?? "未設定"}</div>
              </div>

              <div className="cartItems">
                <div className="cartItemName">お電話</div>
                <div>{account?.profile.tel ?? "未設定"}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center pt-10">
        {auth.user && (
          <button
            className="flex justify-center items-center mt-2 md:mt-0 mr-2.5 md:mr-0 w-full md:w-[384px] h-[60px] relative rounded-lg shadow-md px-1 md:px-2 py-1.5 cursor-pointer focus:outline-none border-2 bg-white border-gray"
            onClick={() => {
              auth.signout(() => navigate("/"));
            }}
          >
            ログアウト
          </button>
        )}
      </div>
    </div>
  );
}
