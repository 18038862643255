import { Link, useLocation, useOutletContext } from "react-router-dom";
import { format } from "date-fns";

import calendar from "../../img/images/icon_calendar.svg";
import hotel from "../../img/images/icon_hotel.svg";
import flight from "../../img/images/icon_flight.svg";
import mealOp from "../../img/images/icon_meal.svg";
import linkArrow from "../../img/images/icon_new-window.svg";
import remarks from "../../img/images/icon-note.svg";
import { GrayDottedLine } from "../../components/Lines";
import { Amountdetails } from "../../components/Amountdetails";

export default function Confirm() {
  const { search } = useLocation();
  const [state, setState] = useOutletContext() as any;
  const diffDays = (state.endDate - state.startDate) / 86400000;

  return (
    <>
      <div className="p-1 space-y-4 w-full">
        <div className="commentArea">
          <p>
            <span className="font-semibold text-orange">
              お申込内容をご確認ください。
            </span>
            <br />
            内容に問題がなければ、画面下の
            <span className="font-semibold">
              「上記の内容で問題ないので、次へ進む」ボタンから、お客様情報の入力
            </span>
            をお願いいたします。変更をご希望の場合は、画面下の「内容を変更する」ボタンから前の画面へ戻ることができます。
          </p>
        </div>
        <div className="cart">
          <div className="cartTitle">お申込み内容</div>
          <div className="cartContents">
            <div className="cartSubTitles">
              <img src={calendar} />
              <div>日程・人数</div>
            </div>
            <GrayDottedLine />
            <div className="flex flex-row flex-wrap">
              <div className="cartItems">
                <div className="cartItemName">行き</div>
                <div className="space-x-2 font-semibold">
                  <span>{format(state.startDate, "yyyy年MM月dd日")}</span>
                  <span>{state.origin.label}</span>
                </div>
              </div>
              <div className="cartItems">
                <div className="cartItemName">帰り</div>
                <div className="space-x-2 font-semibold">
                  <span>{format(state.endDate, "yyyy年MM月dd日")}</span>
                  <span>{state.destination.label}</span>
                </div>
              </div>
              <div className="cartItems">
                <div>
                  <div className="cartItemName">人数</div>
                </div>
                <div className="flex flex-col space-y-5">
                  <div className="flex lex-row">
                    <div className="w-28">大人(12歳以上)</div>：{state.adults}人
                  </div>
                  <div className="flex lex-row">
                    <div className="w-28">小人(3-11歳)</div>：{state.children}人
                  </div>
                  <div className="flex lex-row">
                    <div className="w-28">幼児(0-2歳)</div>：{state.infants}人
                  </div>
                </div>
              </div>
            </div>

            <div className="cartSubTitles">
              <img src={flight} />
              <div>フライト</div>
            </div>

            <GrayDottedLine />

            <div className="cartFlight">
              <div className="font-semibold mx-4">往路</div>
            </div>

            {state.departure ? (
              <div className="flex flex-row flex-wrap">
                <div className="cartItems">
                  <div className="cartItemName">利用日</div>
                  <div className="font-semibold">
                    {format(state.startDate, "yyyy年MM月dd日")}
                  </div>
                </div>
                <div className="cartItems">
                  <div className="cartItemName">利用便</div>
                  <div className="space-x-2">
                    <span className="font-semibold">
                      {state.departure.flight.FlightNo}
                    </span>
                    <span>
                      (券種：
                      {state.departure.fare.TicketTypeName})
                    </span>
                  </div>
                </div>
                <div className="cartItems">
                  <div className="cartItemName">出発</div>
                  <div className="space-x-1">
                    <span>{state.departure.flight.DepartingAirportName}</span>
                    <span>
                      {state.departure.flight.DepartingDatetime.slice(0, -3)}
                    </span>
                  </div>
                </div>
                <div className="cartItems">
                  <div className="cartItemName">到着</div>
                  <div className="space-x-1">
                    <span>{state.departure.flight.ArrivingAirportName}</span>
                    <span>
                      {state.departure.flight.ArrivingDatetime.slice(0, -3)}
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <div>利用なし</div>
            )}

            <div className="cartFlight">
              <div className="font-semibold mx-4">復路</div>
            </div>
            {state.return ? (
              <div className="flex flex-row flex-wrap">
                <div className="cartItems">
                  <div className="cartItemName">利用日</div>
                  <div className="font-semibold">
                    {format(state.endDate, "yyyy年MM月dd日")}
                  </div>
                </div>
                <div className="cartItems">
                  <div className="cartItemName">利用便</div>
                  <div className="space-x-2">
                    <span className="font-semibold">
                      {state.return.flight.FlightNo}
                    </span>
                    <span>
                      (券種：
                      {state.return.fare.TicketTypeName})
                    </span>
                  </div>
                </div>
                <div className="cartItems">
                  <div className="cartItemName">出発</div>
                  <div className="space-x-1">
                    <span>{state.return.flight.DepartingAirportName}</span>
                    <span>
                      {state.return.flight.DepartingDatetime.slice(0, -3)}
                    </span>
                  </div>
                </div>
                <div className="cartItems">
                  <div className="cartItemName">到着</div>
                  <div className="space-x-1">
                    <span>{state.return.flight.ArrivingAirportName}</span>
                    <span>
                      {state.return.flight.ArrivingDatetime.slice(0, -3)}
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <div>利用なし</div>
            )}

            <div className="cartSubTitles">
              <img src={hotel} />
              <div>宿泊施設</div>
            </div>

            <GrayDottedLine />

            <div className="flex flex-row flex-wrap">
              <div className="cartItems">
                <div className="cartItemName">宿泊日</div>
                <div className="space-x-2 font-semibold">
                  <span>{format(state.startDate, "yyyy年MM月dd日")}</span>
                  <span>〜</span>
                  <span>{diffDays}泊</span>
                </div>
              </div>
              {state.hotel?.room && state.hotel?.class && (
                <div className="cartItems">
                  <div className="cartItemName">エリア</div>
                  <div className="font-semibold">{state.hotel?.area.label}</div>
                </div>
              )}
              {state.hotel?.room && state.hotel?.class && (
                <div className="cartItems">
                  <div className="cartItemName">ランク</div>
                  <div>{state.hotel?.class.label}</div>
                </div>
              )}
              {state.hotel?.room && state.hotel?.class && (
                <div className="cartItems">
                  <div className="cartItemName">位置</div>
                  <div>{state.hotel?.room.location.label}</div>
                </div>
              )}
              {state.hotel?.room && state.hotel?.class && (
                <div className="cartItems">
                  <div className="cartItemName">大浴場</div>
                  <div>{state.hotel?.room.bath.label}</div>
                </div>
              )}
              {state.hotel?.room && state.hotel?.class && (
                <div className="cartItems">
                  <div className="cartItemName">朝食</div>
                  <div>{state.hotel?.room.breakfast.label}</div>
                </div>
              )}
              {state.hotel?.room && state.hotel?.class && (
                <div className="cartItems">
                  <div className="cartItemName">たばこ</div>
                  <div>{state.hotel?.room.smoking.label}</div>
                </div>
              )}
            </div>

            <div className="cartSubTitles">
              <img src={mealOp} />
              <div>夕食</div>
            </div>
            <GrayDottedLine />

            {state.restaurant ? (
              <div className="flex flex-row flex-wrap">
                <div className="cartItems">
                  <div className="cartItemName">店名</div>
                  <div>{state.restaurant.shop.name}</div>
                </div>
                <div className="cartItems">
                  <div className="cartItemName">住所</div>
                  <div>{state.restaurant.shop.address}</div>
                </div>
                <div className="cartItems">
                  <div className="cartItemName">電話</div>
                  <div>{state.restaurant.shop.tel}</div>
                </div>
                <div className="cartItems">
                  <div className="cartItemName">HP</div>
                  <div>
                    <a
                      href={state.restaurant.shop.homepage}
                      target="_blank"
                      className="text-cyan-600 hover:text-cyan-500 font-semibold underline flex flex-row space-x-2 items-center"
                    >
                      <span>{state.restaurant.shop.homepage}</span>
                      <img src={linkArrow} className="h-5 w-5" />
                    </a>
                  </div>
                </div>
              </div>
            ) : (
              <div>利用なし</div>
            )}

            <div className="cartSubTitles">
              <img src={remarks} />
              <div>備考</div>
            </div>
            <GrayDottedLine />

            <div className="cartRemarks">
              <p>{state.remarks ? <>{state.remarks}</> : <>備考なし</>}</p>
            </div>

            <Amountdetails state={state} />
          </div>
        </div>
      </div>

      <div className="py-8 md:px-14">
        <div className="flex flex-col md:flex-row md:justify-evenly items-center space-y-5 md:space-y-0">
          <Link to={`/order/select${search}`} className="btn-back">
            内容を変更する
          </Link>

          <Link to="/order/inputInfo" className="btn-enter text-center">
            上記の内容で問題ないので、
            <br className="flex md:hidden" />
            次へ進む
          </Link>
        </div>
      </div>
    </>
  );
}
