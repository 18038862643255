import style from "../../css/info.module.css";

export function JoukenTehai() {
  return (
    <div className="w-full max-w-screen-lg mx-auto my-4 md:my-8 px-2">
      <h2 className={style.pageTitle}>[旅行条件書] 国内手配旅行条件書</h2>
      <p className={style.content}>
        当社が､お客様のご希望により旅行の手配をお引き受けする場合は､旅行日程表(コース表)､旅行条件書(または見積書)に記載されたもの以外は次のとおりとなります｡この取引条件説明書面は旅行契約が成立した場合は契約書面の一部となります｡(お申し込みの際には、このご旅行条件書の掲載内容を必ずお読みし、印刷・保存して下さい)
      </p>

      <h3 className={style.title}>１．手配旅行契約</h3>
      <p className={style.content}>
        (1)「手配旅行契約」(以下「契約」といいます。)とは、当社がお客様の委託により、お客様のために代理、媒介または取次をすること等により、お客様が旅行サービスの提供を
        受けることができるように手配することを引き受ける契約をいいます。
      </p>
      <p className={style.content}>
        (2)
        当社が善良な管理者の注意をもって旅行サービスの手配をしたときは、手配旅行契約に基づく当社の債務の履行は終了します。したがって、満員、休業、条件不適当等の事由により運送・宿泊機関等との間で旅行サービスの提供に関する契約ができなかった場合であっても、当社がその義務を果たしたときは、当社はお客様より当社所定の旅行業務取扱料金(以下「取扱料金」といいます。)を申し受けます。
      </p>
      <p className={style.content}>
        (3)
        本旅行条件説明書に定めのない事項については、別途お渡しする乗車券・宿泊券等に記載するほか、当社旅行業約款(手配旅行契約の部)によります。
      </p>

      <h3 className={style.title}>２．旅行契約の申込みと成立</h3>
      <p className={style.content}>
        (1)
        当社所定の申込書にご記入のうえ、旅行費用の20％相当額以上全額までの申込金を添えてお申込みください。申込金は、旅行費用、取扱料金または取消料等の全部または一部に充当します。
      </p>
      <p className={style.content}>
        (2)
        同一行程により旅行するお客様が契約責任者(代表者)を定めて申込まれた場合、当社は、契約の締結及び解除等に関する一切の代理権を契約責任者が有しているものとみなします。
      </p>
      <p className={style.content}>
        (3)
        契約は当社が契約の締結を承諾し、本項(1)の申込金を受領した時に成立するものといたします。
      </p>
      <p className={style.content}>
        (4)
        当社は、本項(3)にかかわらず、書面による特約をもって、申込金の支払いを受けることなく旅行契約の締結を承諾することがあります。この場合、契約の成立時期は当該書面において明らかにします。
      </p>
      <p className={style.content}>
        (5)
        当社は、乗車券または宿泊券等の手配のみを目的とする契約にあっては、口頭によるお申込みを受付けることがあります。この場合、契約は、当社が契約の締結を承諾した時に成立するものとします。
      </p>
      <p className={style.content}>
        (6)
        当社は、手配旅行契約の成立後速やかに、お客様に旅行日程、旅行サービスの内容、旅行代金その他の旅行条件および当社の責任に関する事項を記載した契約書面を交付します。ただし、当社が手配する全ての旅行サービスについて乗車券、宿泊券その他の旅行サービスの提供を受ける権利を表示した書面を交付するときは、契約書面を交付しないことがあります。
      </p>
      <p className={style.content}>
        (7)
        当社は、業務上の都合があるときは、旅行契約の締結に応じないことがあります。
      </p>

      <h3 className={style.title}>３．旅行代金</h3>
      <p className={style.content}>
        (1)
        旅行代金とは、第４項(1)①に定める旅行費用と旅行業務取扱料金を合算したものをいいます。
      </p>
      <p className={style.content}>
        (2)
        旅行代金は、当社が乗車券や宿泊券等のクーポンをお渡しする際にお支払いいただきます。
      </p>
      <p className={style.content}>
        (3)
        当社は、旅行開始前において、利用する運送・宿泊機関等の運賃・料金の改定、為替相場の変動等その他の事由により旅行代金の変動を生じた場合は、旅行代金を変更させていただきます。この場合旅行代金の増加または減少はお客様に帰属します。
      </p>

      <h3 className={style.title}>４．旅行業務取扱料金</h3>
      <p className={style.content}>
        当社は、旅行の手配、クーポン類の発行、旅行相談等の業務に対し、次の旅行業務取扱料金を申し受けます。以下に記載のないものは、当社旅行業務取扱料金表によります。
      </p>

      <p className={`${style.content} ${style.mt4}`}>(1) 取扱料金(税込)</p>
      <table className={style.tblHalf}>
        <thead>
          <tr className={style.tblHalfTR}>
            <th>お申込み内容</th>
            <th>料金(消費税を含む)</th>
          </tr>
        </thead>
        <tbody>
          <tr className={style.tblHalfTR}>
            <td>
              1)
              運送･宿泊機関等の旅行サービス提供機関を単一に手配する場合またはクーポン券類を確認発券する場合
            </td>
            <td>
              1手配につき540円(同一宿泊機関に連泊の場合は1手配として取扱います)
            </td>
          </tr>
          <tr className={style.tblHalfTR}>
            <td>2) 運送･宿泊等の複合手配旅行</td>
            <td>旅行費用総額の20％以内</td>
          </tr>
          <tr className={style.tblHalfTR}>
            <td>3) 宿泊利用券等によるノークーポン宿泊の予約代行等</td>
            <td>1手配につき1,080円</td>
          </tr>
        </tbody>
      </table>
      <p className={`${style.content} ${style.mt4}`}>
        ①旅行費用とは、旅行サービスの提供を受けるため運賃・宿泊料その他の名目で運送・宿泊機関等に支払う費用をいいます。
      </p>
      <p className={style.content}>
        ②同一の手配を同時に行う場合は、複数名でも「１手配」と数えます。手配日、利用日、利用区間、宿泊・運送機関等が異なる場合は、それぞれを「１手配」と数えます。
      </p>
      <p className={style.content}>
        ③「運送機関を単一に手配する」とは、航空・ＪＲを除く私鉄・バス・フェリー等を手配することをいいます。
      </p>
      <p className={style.content}>
        ④「確認発券」とは、お客様が直接予約された内容を当社が確認後、クーポンを発行することをいいます。
      </p>
      <p className={style.content}>
        ⑤通信連絡、郵送料等の実費は別途申し受けます。
      </p>
      <p className={style.content}>
        ⑥上記のうち定額料金には消費税を含みます。所定の料率により取扱料金を申し受ける場合は、別途消費税がかかります。
      </p>

      <p className={`${style.content} ${style.mt4}`}>(2) 旅行相談料金</p>
      <table className={style.tblHalf}>
        <thead>
          <tr className={style.tblHalfTR}>
            <th>お申込み内容</th>
            <th>料金(消費税を含む)</th>
          </tr>
        </thead>
        <tbody>
          <tr className={style.tblHalfTR}>
            <td>旅行計画作成のための相談・助言</td>
            <td>
              基本料金30分まで2,160円
              <br />
              以降30分ごと1,080円
            </td>
          </tr>
          <tr className={style.tblHalfTR}>
            <td>旅行日程の作成</td>
            <td>日程表1件につき2,160円</td>
          </tr>
          <tr className={style.tblHalfTR}>
            <td>旅行費用見積書の作成</td>
            <td>見積書1件につき2,160円</td>
          </tr>
          <tr className={style.tblHalfTR}>
            <td>
              旅行地及び運送・宿泊機関等の旅行サービス提供機関に関する情報提供
            </td>
            <td>資料(A4判)1枚につき1,080円</td>
          </tr>
          <tr className={style.tblHalfTR}>
            <td>お客様のご依頼による出張相談</td>
            <td>上記①～④の料金にそれぞれ5,400円増し</td>
          </tr>
        </tbody>
      </table>

      <h3 className={style.title}>５．旅行契約内容の変更</h3>
      <p className={style.content}>
        (1)
        お客様は、当社に対し旅行契約内容の変更を求めることができます。この場合、当社は可能な限り旅行契約内容の変更の求めに応じます。契約内容の変更によって生じる旅行代金の増減はお客様に帰属します。
      </p>
      <p className={style.content}>
        (2)
        旅行契約内容の変更のために、運送・宿泊機関等に支払うべき取消料、違約料その他の手配の変更に要する費用があるときは、お客様にこれをご負担いただくほか、当社は次の変更手続料金を申し受けます。
      </p>

      <table className={style.tblHalf}>
        <tbody>
          <tr className={style.tblHalfTR}>
            <td>旅行サービス提供機関ごと</td>
            <td>1手配につき540円(消費税を含む)</td>
          </tr>
        </tbody>
      </table>

      <h3 className={style.title}>６．お客様による旅行契約の解除と払戻し</h3>
      <p className={style.content}>
        (1)
        お客様のご都合により旅行契約を解除される場合は、次の料金を申し受け、残額があればこれを払い戻します。
      </p>
      <p className={style.contentml2}>
        ①第４項に定める取扱料金及び本項③の旅行サービスの手配の取消に係る以下の取消手続料金。
      </p>
      <p className={style.contentml2}>
        ②お客様が既に提供を受けられた旅行サービスに係る旅行費用。
      </p>
      <p className={style.contentml2}>
        ③お客様がいまだ提供を受けておられない旅行サービスに係る取消料、違約料その他の旅行サービス提供機関に支払う費用。
      </p>

      <table className={style.tblHalf}>
        <tbody>
          <tr className={style.tblHalfTR}>
            <td>旅行サービス提供機関ごと</td>
            <td>1手配につき540円(消費税を含む)</td>
          </tr>
        </tbody>
      </table>

      <p className={`${style.content} ${style.mt4}`}>
        (2)
        当社の責に帰すべき事由により旅行サービスの手配が不可能となったときは、お客様は旅行契約を解除することができます。この場合、当社は、お客様が既に提供を受けられた旅行サービスに係る旅行費用を除き、残額を払い戻します。
      </p>

      <h3 className={style.title}>７．当社による旅行契約の解除と払戻し</h3>
      <p className={style.content}>
        お客様が所定の期日までに旅行代金を支払わないときは、当社は旅行契約を解除することがあります。この場合、当社は第４項に定める取扱料金および第６項(1)に定める取消手続料金ならびに旅行サービス提供機関等に対し支払う取消料・違約料その他の費用を申し受けます。
      </p>

      <h3 className={style.title}>８．旅行代金の精算</h3>
      <p className={style.content}>
        当社は、実際に要した旅行代金と収受した旅行代金とが合致しないときは、旅行終了後速やかに精算いたします。
      </p>

      <h3 className={style.title}>９．当社の責任</h3>
      <p className={style.content}>
        (1)
        当社は、旅行契約の履行にあたって、当社または当社が手配を代行させる者(以下「手配代行者」といいます。)の故意または過失によりお客様に損害を与えたときは、損害発生の翌日から起算して２年以内に当社に対して通知があった場合に限り、その損害を賠償します。
      </p>
      <p className={style.content}>
        (2)
        お客様が次に例示するような事由により損害を被られたときは、当社は本項(1)の場合を除き、当該損害を賠償する責任を負いません。
      </p>
      <p className={style.contentml2}>
        ア．天災地変、戦乱、暴動、テロ、官公署の命令、感染症による隔離またはこれらのために生じる旅行日程の変更もしくは旅行の中止
      </p>
      <p className={style.contentml2}>
        イ．運送・宿泊機関等のサービス提供の中止・事故・火災またはこれらのために生じる旅行日程の変更もしくは旅行の中止
      </p>
      <p className={style.contentml2}>ウ．自由行動中の事故</p>
      <p className={style.contentml2}>エ．食中毒</p>
      <p className={style.contentml2}>オ．盗難</p>
      <p className={style.contentml2}>
        カ．運送機関の遅延、不通、スケジュール変更、経路変更またはこれらによって生じる旅行日程の変更もしくは目的地滞在時間の短縮
      </p>
      <p className={style.content}>
        (3)
        手荷物について生じた本項(1)の損害については、損害発生の翌日から起算して14日以内に当社に対して通知があった場合に限り、その損害を賠償します。ただし、当社の賠償額はお１人様あたり15万円まで(当社に故意又は重大な過失がある場合を除きます。)とします。
      </p>

      <h3 className={style.title}>10．お客様の責任</h3>
      <p className={style.content}>
        (1)
        お客様の故意または過失により当社が損害を被ったときは、当社は当該お客様から損害の賠償を申し受けます。
      </p>
      <p className={style.content}>
        (2)
        お客様は、当社から提供される情報を活用し、お客様の権利・義務その他旅行契約の内容について理解に努めなければなりません。
      </p>
      <p className={style.content}>
        (3)
        旅行開始後に、契約書面に記載された内容と実際に提供されたサービスが異なると認識したとき、旅行中に事故等が発生したときは、旅行地において速やかに当社または旅行サービス提供機関にその旨を申し出てください。{" "}
      </p>

      <h3 className={style.title}>
        11．通信契約により旅行契約の締結を希望されるお客様との旅行条件
      </h3>
      <p className={style.content}>
        当社は、当社が提携するクレジットカード会社(以下「提携会社」といいます。)のカード会員(以下「会員」といいます。)より、「会員の署名なくして旅行代金などのお支払いをうけること」(以下「通信契約」といいます。)を条件に、電話、郵便、ファクシミリ、インターネットその他の通信手段による旅行の申込みを受ける場合があります。但し、当社が提携会社と通信契約に関わる加盟店契約がない、または業務上の理由などによりお受けできない場合もあります。通信契約の旅行条件は、通常の旅行条件とは以下の点で異なります。
      </p>
      <p className={style.content}>
        (1)
        本項でいう「カード利用日」とは、会員及び当社が旅行契約に基づく旅行代金等の支払い、又は払戻し債務を履行すべき日をいい、前者は契約成立日、後者は契約解除のお申し出のあった日となります。但し、契約解除日が旅行代金お支払い後であった場合は、解除申し出日の翌日から起算して７日以内をカード利用日として、旅行代金から取消料を差し引いた額を払い戻します。
      </p>
      <p className={style.content}>
        (2)
        申込みに際し、会員は、会員番号及び依頼しようとする旅行サービスの内容等を当社にお申し出いただきます。
      </p>
      <p className={style.content}>
        (3)
        通信契約は、当社が契約の締結を承諾する旨を発した時に成立します。但し、当該契約の申込みを承諾する旨の通知をＥメール、ファクシミリ等で行なう場合は、その通知が会員に到達した時に成立するものとします。
      </p>
      <p className={style.content}>
        (4)
        与信等の理由により当該クレジットカードでのお支払いができない場合、当社は通信契約を解除し、第６項に定める費用及び取扱料金等を申し受けます。但し、当社が別途指定する日までに現金により旅行代金をお支払いいただいた場合はこの限りではありません。
      </p>

      <h3 className={style.title}>12．個人情報の取扱いについて</h3>
      <p className={style.content}>
        (1)
        当社は、旅行申込の際に提出された申込書、参加者名簿、旅行お伺い書等に記載された個人情報について、お客様との間の連絡のために利用させていただくほか、お客様がお申し込みいただいた旅行における運送・宿泊機関等が提供するサービスの手配・受領のための手続に必要な範囲内で利用させていただきます。
      </p>
      <p className={style.content}>
        (2)
        当社は、旅行先におけるお客様のお買い物等の便宜をはかるため、お客様より申込み時に提供を受けた個人情報を免税品店などの土産物店に提供することがあります。不都合のある場合は、出発前までに当社へお申し出ください。
      </p>

      <h3 className={style.title}>13．計算の基礎</h3>
      <p className={style.content}>
        この旅行条件は、旅行契約締結年月日の時点において有効な運賃・料金を基準としております。
      </p>

      <h3 className={style.title}>〔国内旅行傷害保険のおすすめ〕</h3>
      <p className={style.content}>
        当社では、ご旅行を最後まで楽しいものとしていただくために、旅行に参加されるお客様に国内旅行傷害保険をおすすめいたします。
      </p>
    </div>
  );
}
