import "../css/common.css";
import "../css/search.css";
import { useState, forwardRef, useEffect, Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { PlusSmIcon, MinusSmIcon } from "@heroicons/react/outline";

import calendar from "../img/images/icon_calendar.svg";
import arrow from "../img/images/about-icon-arrow.png";

//calender
import DatePicker, { registerLocale } from "react-datepicker";
import { format, formatISO, parseISO } from "date-fns";
import ja from "date-fns/locale/ja";
import { HotelArea } from "../types";
import { classNames, regions, State } from "../constants";

registerLocale("ja", ja);

export default function SelectSearchArea({
  query,
  setExpand,
  onSubmit,
}: {
  query?: any;
  setExpand: React.Dispatch<React.SetStateAction<State>>;
  onSubmit: (value: any, free: boolean) => void;
}) {
  query = query ?? {};

  let origin = query.origin
    ? regions.find((origin) => origin.region_id === query.origin)
    : regions[0];

  let destination = query.destination
    ? regions.find((destination) => destination.region_id === query.destination)
    : regions[0];

  const [departure, setDeparture] = useState<any>(origin);
  const [arrival, setArrival] = useState<any>(destination);

  let defaultAdults = query.adults ? parseInt(query.adults) : 1;
  let defaultChildren = query.children ? parseInt(query.children) : 0;

  const [adults, setCountAdults] = useState(defaultAdults);
  const [children, setCountChildren] = useState(defaultChildren);

  let start = new Date();
  let end = new Date();
  start.setDate(start.getDate() + 3);
  end.setDate(end.getDate() + 4);

  let defaultStartDate = query.startDate ? parseISO(query.startDate) : start;
  let defaultEndDate = query.endDate ? parseISO(query.endDate) : end;

  const [startDate, setStartDate] = useState<Date>(defaultStartDate);
  const [endDate, setEndDate] = useState<Date>(defaultEndDate);

  useEffect(() => {
    if (startDate > endDate) setStartDate(endDate);
  }, [endDate]);

  useEffect(() => {
    if (startDate > endDate) setEndDate(startDate);
  }, [startDate]);

  let searchParams = new URLSearchParams();

  if (departure.prior !== 0) {
    searchParams.append("origin", departure.region_id);
  }
  if (arrival.prior !== 0) {
    searchParams.append("destination", arrival.region_id);
  }
  searchParams.append(
    "startDate",
    formatISO(startDate, { representation: "date" })
  );
  searchParams.append(
    "endDate",
    formatISO(endDate, { representation: "date" })
  );
  if (adults !== 0) {
    searchParams.append("adults", adults.toString());
  }
  if (children !== 0) {
    searchParams.append("children", children.toString());
  }

  let validate = (): string | null => {
    if (startDate === endDate) {
      return "行きと帰りは異なる日付を選択してください。";
    }
    if (departure === arrival) {
      return "出発地と到着地は異なるエリアを指定してください。";
    }
    if (adults === 0) {
      return "人数を選択してください";
    }

    return null;
  };

  let [error, setError] = useState<string>();

  return (
    <>
      <div className="boxRight">
        <div className="boxRightTitle">日程・場所・人数の変更</div>
        <div className="mt-5 px-2 md:px-8">
          <div className="space-y-2.5">
            <div className="w-full flex flex-row justify-around items-center space-x-4">
              <div className="block text-base font-semibold w-10">行き</div>
              <div className="relative w-full md:w-4/5">
                <DateSelect
                  selected={startDate}
                  onChange={(date) => setStartDate(date!)}
                  startDate={startDate}
                  endDate={endDate}
                  popperClassName="react-datepicker-left"
                />
              </div>
            </div>
            <div className="w-full flex flex-row justify-around items-center space-x-4">
              <div className="block text-base font-semibold w-10">帰り</div>
              <div className="relative w-full md:w-4/5">
                <DateSelect
                  selected={endDate}
                  onChange={(date) => setEndDate(date!)}
                  startDate={startDate}
                  endDate={endDate}
                  popperClassName="react-datepicker-right"
                />
              </div>
            </div>
            <div className="w-full flex flex-row justify-around items-center space-x-4">
              <div className="block text-base font-semibold w-10">出発</div>
              <div className="relative w-full md:w-4/5">
                <Selectbox
                  options={regions}
                  value={departure}
                  onChange={setDeparture}
                />
              </div>
            </div>
            <div className="w-full flex flex-row justify-around items-center space-x-4">
              <div className="block text-base font-semibold w-10">到着</div>
              <div className="relative w-full md:w-4/5">
                <Selectbox
                  options={regions}
                  value={arrival}
                  onChange={setArrival}
                />
              </div>
            </div>
          </div>
          <div className="mt-5">
            <div className="flex flex-col text-base space-y-2.5">
              <Counter
                value={adults}
                label="大人 (12歳以上)"
                onChange={setCountAdults}
              />
              <Counter
                value={children}
                label="小人 (12歳未満)"
                onChange={setCountChildren}
              />
            </div>
          </div>

          <div className="my-10 px-3">
            {error && (
              <div className="mb-2 text-sm md:text-base text-red-600 text-center">
                {error}
              </div>
            )}

            <button
              onClick={() => {
                let error = validate();
                if (error) {
                  setError(error);
                } else {
                  onSubmit(searchParams, false);
                  setExpand(State.DEFAULT);
                }
              }}
              className="btn-enter mx-auto"
            >
              この内容に変更する
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

interface Item {
  prior: number;
  region_id: string;
  label: string;
}

function Selectbox({
  value,
  options,
  onChange,
}: {
  value: Item;
  options: Item[];
  onChange: (value: Item) => void;
}) {
  return (
    <Listbox value={value} onChange={onChange}>
      {({ open }) => (
        <>
          <Listbox.Button className="inputBtnText">
            <span>{value.label}</span>
            <img src={arrow} className="w-5 h-auto rotate-90" />
          </Listbox.Button>
          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-lg py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none">
              {options.map((area) => (
                <div key={area.prior}>
                  {area.prior !== 0 && area.prior !== 6 && (
                    <Listbox.Option
                      value={area}
                      disabled={area.prior == 0}
                      className={({ active }) =>
                        classNames(
                          active
                            ? "text-color bg-orange-light"
                            : "text-gray-00",
                          "cursor-default select-none relative py-2 pl-3 pr-9"
                        )
                      }
                    >
                      {({ selected }) => (
                        <>
                          <div className="flex items-center">
                            <span
                              className={classNames(
                                selected ? "font-semibold" : "font-normal",
                                "ml-1 md:ml-3 block truncate text-sm"
                              )}
                            >
                              {area.label}
                            </span>
                          </div>
                        </>
                      )}
                    </Listbox.Option>
                  )}
                </div>
              ))}
            </Listbox.Options>
          </Transition>
        </>
      )}
    </Listbox>
  );
}

function DateSelect({
  selected,
  onChange,
  startDate,
  endDate,
  popperClassName,
}: {
  selected: any;
  onChange: (value: any) => void;
  startDate: any;
  endDate: any;
  popperClassName: string;
}) {
  let dateStart = new Date();
  dateStart.setDate(dateStart.getDate() + 3);
  return (
    <>
      <DatePicker
        minDate={dateStart}
        locale="ja"
        selected={selected}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        nextMonthButtonLabel=">"
        previousMonthButtonLabel="<"
        popperClassName={popperClassName}
        customInput={<ButtonInput />}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div className="flex items-center justify-between px-2 py-2">
            <span className="text-lg text-color">
              {format(date, "yyyy/MM")}
            </span>
            <div className="space-x-2">
              <button
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
                type="button"
                className={`
                    ${
                      prevMonthButtonDisabled && "cursor-not-allowed opacity-50"
                    } calChangeMonth
                  `}
              >
                <ChevronLeftIcon className="w-5 h-5 text-gray-600" />
              </button>
              <button
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
                type="button"
                className={`
                    ${
                      nextMonthButtonDisabled && "cursor-not-allowed opacity-50"
                    } calChangeMonth
                  `}
              >
                <ChevronRightIcon className="w-5 h-5 text-gray-600" />
              </button>
            </div>
          </div>
        )}
      />
    </>
  );
}

// type ButtonProps = JSX.IntrinsicElements["button"];
const ButtonInput = forwardRef<HTMLButtonElement, any>(
  ({ value, onClick }, ref) => (
    <button onClick={onClick} ref={ref} type="button" className="inputBtnText">
      <span>{format(new Date(value), "yyyy年MM月dd日")}</span>
      <img src={calendar} className="w-5 h-5" />
    </button>
  )
);

function Counter({
  value,
  label,
  onChange,
}: {
  value: number;
  label: string;
  onChange: (value: any) => void;
}) {
  return (
    <div className="flex flex-row justify-around">
      <div className="w-44 md:w-32 font-semibold flex items-center">
        {label}
      </div>
      <div className="w-full md:w-4/6 flex justify-end">
        <div className="flex flex-row justify-between items-center w-40 py-1 px-1 bg-white border-2 border-gray rounded-lg cursor-default focus:outline-none focus:ring-1 sm:text-sm">
          <button onClick={() => onChange(Math.max(value - 1, 0))}>
            <MinusSmIcon
              className={`w-8 h-8 bg-dGray text-white rounded-lg p-2 ${
                value <= 0 && "bg-lGray cursor-not-allowed"
              }`}
            />
          </button>
          <div>{value}</div>
          <button onClick={() => onChange(value + 1)}>
            <PlusSmIcon className="w-8 h-8 bg-dGray text-white rounded-lg p-2" />
          </button>
        </div>
      </div>
    </div>
  );
}
