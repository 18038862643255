import { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { format } from "date-fns";
import calendar from "../img/images/icon_calendar.svg";
import hotel from "../img/images/icon_hotel.svg";
import flight from "../img/images/icon_flight.svg";
import mealOp from "../img/images/icon_meal.svg";
import linkArrow from "../img/images/icon_new-window.svg";
import remarks from "../img/images/icon-note.svg";
import { Order } from "../types";
import { GrayDottedLine } from "./Lines";
import { Amountdetails } from "./Amountdetails";
import { State } from "../constants";

export default function Cart({
  setExpand,
}: {
  setExpand: React.Dispatch<React.SetStateAction<State>>;
}) {
  const [state, setState] = useOutletContext() as any;

  const diffDays = (state.endDate - state.startDate) / 86400000;
  const totalPeople = state.adults + state.children;

  const depTicket = state.departure?.fare.TicketAdult ?? 0;
  const retTicket = state.return?.fare.TicketAdult ?? 0;
  const depTicketChild =
    state.children !== 0 ? state.departure?.fare.TicketChild : 0;
  const retTicketChild =
    state.children !== 0 ? state.return?.fare.TicketChild : 0;
  const hotelPrice = state.hotel?.class?.estimate ?? 0;
  const hotelTotal = hotelPrice * diffDays * totalPeople;

  const [totalPrice, _] = useState(
    hotelTotal + depTicket + depTicketChild + retTicket + retTicketChild
  );

  let setRemarks = (remarks: string) => {
    setState((state: Order) => ({
      ...state,
      remarks,
    }));
  };

  const isSelected =
    state.hotel?.room &&
    state.hotel?.class &&
    state.hotel?.room.location &&
    state.hotel?.room.bath &&
    state.hotel?.room.breakfast &&
    state.hotel?.room.smoking;

  return (
    <>
      <div className="flex flex-row justify-between">
        <div className="cartSubTitles">
          <img src={calendar} />
          <div>日程・人数</div>
        </div>
        <button onClick={() => setExpand(State.SCHEDULE)}>
          <div className="btnChange">変更</div>
        </button>
      </div>

      <GrayDottedLine />

      <div className="flex flex-row flex-wrap">
        <div className="cartItems">
          <div className="cartItemName">行き</div>
          <div className="space-x-2 font-semibold">
            <span>{format(state.startDate, "yyyy年MM月dd日")}</span>
            <span>{state.origin.label}</span>
          </div>
        </div>
        <div className="cartItems">
          <div className="cartItemName">帰り</div>
          <div className="space-x-2 font-semibold">
            <span>{format(state.endDate, "yyyy年MM月dd日")}</span>
            <span>{state.destination.label}</span>
          </div>
        </div>
        <div className="cartItems">
          <div>
            <div className="cartItemName">人数</div>
          </div>
          <div className="flex flex-col space-y-5">
            <div className="flex lex-row">
              <div className="w-28">大人(12歳以上)</div>：{state.adults}人
            </div>
            <div className="flex lex-row">
              <div className="w-28">小人(3-11歳)</div>：{state.children}人
            </div>
            <div className="flex lex-row">
              <div className="w-28">幼児(0-2歳)</div>：{state.infants}人
            </div>
          </div>
        </div>
      </div>

      <div className="cartSubTitles">
        <img src={flight} />
        <div>フライト</div>
        <span className="text-sm md:text-base">※任意選択</span>
      </div>

      <GrayDottedLine />

      <div className="cartFlight">
        <div className="font-semibold mx-4">往路</div>
        {state.departure ? (
          <>
            <div>
              <button onClick={() => setExpand(State.DEPARTURE_FLIGHT)}>
                <div className="btnChange">変更</div>
              </button>
              <button
                onClick={() =>
                  setState((state: any) => ({
                    ...state,
                    departure: null,
                  }))
                }
                className="btnDelete"
              >
                削除
              </button>
            </div>
          </>
        ) : (
          <div>
            <button onClick={() => setExpand(State.DEPARTURE_FLIGHT)}>
              <div className="btnChange">選択</div>
            </button>
          </div>
        )}
      </div>
      {state.departure ? (
        <>
          <div className="flex flex-row flex-wrap">
            <div className="cartItems">
              <div className="cartItemName">利用日</div>
              <div className="font-semibold">
                {format(state.startDate, "yyyy年MM月dd日")}
              </div>
            </div>
            <div className="cartItems">
              <div className="cartItemName">利用便</div>
              <div className="space-x-2">
                <span className="font-semibold">
                  {state.departure.flight.FlightNo}
                </span>
                <span>
                  (券種：
                  {state.departure.fare.TicketTypeName})
                </span>
              </div>
            </div>
            <div className="cartItems">
              <div className="cartItemName">出発</div>
              <div className="space-x-1">
                <span>{state.departure.flight.DepartingAirportName}</span>
                <span>
                  {state.departure.flight.DepartingDatetime.slice(0, -3)}
                </span>
              </div>
            </div>
            <div className="cartItems">
              <div className="cartItemName">到着</div>
              <div className="space-x-1">
                <span>{state.departure.flight.ArrivingAirportName}</span>
                <span>
                  {state.departure.flight.ArrivingDatetime.slice(0, -3)}
                </span>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="px-1 mb-2.5">未選択</div>
      )}

      <div className="cartFlight">
        <div className="font-semibold mx-4">復路</div>
        {state.return ? (
          <>
            <div>
              <button onClick={() => setExpand(State.RETURN_FLIGHT)}>
                <div className="btnChange">変更</div>
              </button>
              <button
                onClick={() =>
                  setState((state: any) => ({ ...state, return: null }))
                }
                className="btnDelete"
              >
                削除
              </button>
            </div>
          </>
        ) : (
          <div>
            <button onClick={() => setExpand(State.RETURN_FLIGHT)}>
              <div className="btnChange">選択</div>
            </button>
          </div>
        )}
      </div>
      {state.return ? (
        <>
          <div className="flex flex-row flex-wrap">
            <div className="cartItems">
              <div className="cartItemName">利用日</div>
              <div className="font-semibold">
                {format(state.endDate, "yyyy年MM月dd日")}
              </div>
            </div>
            <div className="cartItems">
              <div className="cartItemName">利用便</div>
              <div className="space-x-2">
                <span className="font-semibold">
                  {state.return.flight.FlightNo}
                </span>
                <span>
                  (券種：
                  {state.return.fare.TicketTypeName})
                </span>
              </div>
            </div>
            <div className="cartItems">
              <div className="cartItemName">出発</div>
              <div className="space-x-1">
                <span>{state.return.flight.DepartingAirportName}</span>
                <span>
                  {state.return.flight.DepartingDatetime.slice(0, -3)}
                </span>
              </div>
            </div>
            <div className="cartItems">
              <div className="cartItemName">到着</div>
              <div className="space-x-1">
                <span>{state.return.flight.ArrivingAirportName}</span>
                <span>{state.return.flight.ArrivingDatetime.slice(0, -3)}</span>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="px-1 mb-2.5">未選択</div>
      )}

      <div className="flex flex-row justify-between">
        <div className="cartSubTitles">
          <img src={hotel} />
          <div>宿泊施設</div>
        </div>
        <button onClick={() => setExpand(State.HOTEL)}>
          <div className="btnChange">{state.hotel?.room ? "変更" : "選択"}</div>
        </button>
      </div>

      <GrayDottedLine />

      {state.hotel?.room && state.hotel?.class ? (
        <>
          <div className="flex flex-row flex-wrap">
            <div className="cartItems">
              <div className="cartItemName">宿泊日</div>
              <div className="space-x-2 font-semibold">
                <span>{format(state.startDate, "yyyy年MM月dd日")}</span>
                <span>〜</span>
                <span>{diffDays}泊</span>
              </div>
            </div>
            {isSelected && (
              <div className="cartItems">
                <div className="cartItemName">エリア</div>
                <div className="font-semibold">{state.hotel?.area.label}</div>
              </div>
            )}
            {isSelected && (
              <div className="cartItems">
                <div className="cartItemName">ランク</div>
                <div>{state.hotel?.class.label}</div>
              </div>
            )}
            {isSelected && (
              <div className="cartItems">
                <div className="cartItemName">位置</div>
                <div>{state.hotel?.room.location.label}</div>
              </div>
            )}
            {isSelected && (
              <div className="cartItems">
                <div className="cartItemName">大浴場</div>
                <div>{state.hotel?.room.bath.label}</div>
              </div>
            )}
            {isSelected && (
              <div className="cartItems">
                <div className="cartItemName">朝食</div>
                <div>{state.hotel?.room.breakfast.label}</div>
              </div>
            )}
            {isSelected && (
              <div className="cartItems">
                <div className="cartItemName">たばこ</div>
                <div>{state.hotel?.room.smoking.label}</div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="px-1 mb-2.5">未選択</div>
      )}

      <div className="flex flex-row justify-between">
        <div className="cartSubTitles">
          <img src={mealOp} />
          <div>夕食</div>
          <span className="hidden md:flex text-base">&#8251;任意選択</span>
        </div>

        {state.restaurant ? (
          <>
            <div className="space-x-2">
              <button onClick={() => setExpand(State.OPTION)}>
                <div className="btnChange">変更</div>
              </button>
              <button
                onClick={() =>
                  setState((state: any) => ({ ...state, restaurant: null }))
                }
                className="btnDelete"
              >
                削除
              </button>
            </div>
          </>
        ) : (
          <div>
            <button onClick={() => setExpand(State.OPTION)}>
              <div className="btnChange">選択</div>
            </button>
          </div>
        )}
      </div>

      <div className="flex md:hidden text-sm ml-8">&#8251;任意選択</div>

      <GrayDottedLine />

      {state.restaurant ? (
        <>
          <div className="flex flex-row flex-wrap">
            <div className="cartItems">
              <div className="cartItemName">店名</div>
              <div>{state.restaurant.shop.name}</div>
            </div>
            <div className="cartItems">
              <div className="cartItemName">住所</div>
              <div>{state.restaurant.shop.address}</div>
            </div>
            <div className="cartItems">
              <div className="cartItemName">電話</div>
              <div>{state.restaurant.shop.tel}</div>
            </div>
            <div className="cartItems">
              <div className="cartItemName">HP</div>
              <div>
                <a
                  href={state.restaurant.shop.homepage}
                  target="_blank"
                  className="break-all text-cyan-600 hover:text-cyan-500 font-semibold underline flex flex-row space-x-2 items-center"
                >
                  <span>{state.restaurant.shop.homepage}</span>
                  <img src={linkArrow} className="h-5 w-5" />
                </a>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="text-cyan-800 text-base font-semibold text-center">
          食事もまとめて予約が便利！
          <br className="flex md:hidden" />
          おすすめグルメをご紹介。
        </div>
      )}

      <div className="cartSubTitles">
        <img src={remarks} />
        <div>備考</div>
        <span className="hidden md:flex text-base">&#8251;任意入力</span>
      </div>

      <GrayDottedLine />

      <div className="cartRemarks">
        <textarea
          id="remarks"
          value={state.remarks}
          onChange={(e) => {
            let i = e.target.value;
            setRemarks(i);
          }}
          placeholder="入力してください"
        />
      </div>

      {totalPrice > 0 && <Amountdetails state={state} />}
    </>
  );
}
