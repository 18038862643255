import { VFC } from "react";
import { Helmet } from "react-helmet";
import { BASE_URL } from "../constants";

type Props = {
  title?: string;
  description?: string;
  ogTitle?: string;
  ogType?: string;
  ogUrl?: string;
  ogImage?: string;
  ogDescription?: string;
};

export const HeadBlock: VFC<Props> = (props) => {
  const { title, description, ogTitle, ogType, ogUrl, ogImage, ogDescription } =
    props;
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="theme-color" content="#3291b1" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta
        name="keywords"
        content="ジョルダントラベル,国内旅行,出張,パッケージ,出張パック,旅行相談,旅行窓口,ホテル,航空券,比較,ジョルダン,乗換案内"
      />

      <title>{title ?? "ジョルダントラベル"}</title>
      <meta
        name="description"
        content={
          description ??
          "最安値航空券も一発検索！旅行パッケージ作成・こだわり条件のご相談、旅行のプロにおまかせください。「ジョルダンMaaSパッケージ」なら飛行機・新幹線・特急・ホテル・食事もまとめてご予約可能です。"
        }
      />
      <head prefix="og: http://ogp.me/ns#" />
      <meta property="og:image" content={ogImage ?? `${BASE_URL}ogimage.png`} />
      <meta property="og:locale" content="ja_JP" />
      <meta property="og:site_name" content="ジョルダントラベル" />
      <meta
        property="og:title"
        content={ogTitle ?? "ジョルダントラベル | 旅行パッケージ作成"}
      />
      <meta property="og:type" content={ogType ?? "website"} />

      <meta property="og:url" content={ogUrl ?? BASE_URL} />
      <meta
        property="og:description"
        content={
          ogDescription ??
          "最安値航空券も一発検索！旅行パッケージ作成・こだわり条件のご相談、旅行のプロにおまかせください。「ジョルダンMaaSパッケージ」なら飛行機・新幹線・特急・ホテル・食事もまとめてご予約可能です。"
        }
      />

      <meta
        property="article:publisher"
        content="https://www.facebook.com/JorudanTravel/"
      />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@JorudanTravel" />
    </Helmet>
  );
};
