import style from "../../css/info.module.css";

export default function Company() {
  return (
    <div className="w-full max-w-screen-lg mx-auto my-4 md:my-8 px-2">
      <h3 className={style.pageTitle}>会社案内</h3>
      <table className={style.tblCompany}>
        <tbody>
          <tr>
            <td className={style.tblCompanyTD}>
              <div>
                <span>名称</span>
              </div>
              <div>ジョルダン株式会社（ジョルダントラベル）</div>
            </td>
          </tr>
          <tr>
            <td className={style.tblCompanyTD}>
              <div>
                <span>代表者</span>
              </div>
              <div>代表取締役社長　佐藤俊和</div>
            </td>
          </tr>
          <tr>
            <td className={style.tblCompanyTD}>
              <div>
                <span>所在地</span>
              </div>
              <div>
                本社：〒160-0022 東京都新宿区新宿2-5-10 アーバンセンター新宿6階
                <br />
                埼玉オフィス：〒353-0004 埼玉県志木市本町5-21-14　JSKビル5階
              </div>
            </td>
          </tr>
          <tr>
            <td className={style.tblCompanyTD}>
              <div>
                <span>資本金</span>
              </div>
              <div>2億7,737万5,000円 (2019年9月30日現在)</div>
            </td>
          </tr>
          <tr>
            <td className={style.tblCompanyTD}>
              <div>
                <span>社員数</span>
              </div>
              <div>連結193名 単体158名 (2019年9月30日現在)</div>
            </td>
          </tr>
          <tr>
            <td className={style.tblCompanyTD}>
              <div>
                <span>決算期</span>
              </div>
              <div>年1回 9月30日</div>
            </td>
          </tr>
          <tr>
            <td className={style.tblCompanyTD}>
              <div>
                <span>株式情報</span>
              </div>
              <div>証券コード 3710（JPX）</div>
            </td>
          </tr>
          <tr>
            <td className={style.tblCompanyTD}>
              <div>
                <span>代理店契約</span>
              </div>
              <div>
                東日本旅客鉄道株式会社
                <br />
                全日本空輸株式会社
                <br />
                株式会社ＡＩＲ　ＤＯ
                <br />
                日本航空株式会社
                <br />
                スカイマーク株式会社
                <br />
                株式会社ソラシドエア
                <br />
                株式会社スターフライヤー
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <h3 className={style.pageTitle}>旅行業登録票</h3>
      <table className={style.tblCompany}>
        <tbody>
          <tr>
            <td className={style.tblCompanyTD}>
              <div>
                <span>名称</span>
              </div>
              <div>ジョルダン株式会社（ジョルダントラベル）</div>
            </td>
          </tr>
          <tr>
            <td className={style.tblCompanyTD}>
              <div>
                <span>所在地</span>
              </div>
              <div>
                本社：〒160-0022 東京都新宿区新宿2-5-10 アーバンセンター新宿6階
                <br />
                埼玉オフィス：〒353-0004 埼玉県志木市本町5-21-14　JSKビル5階
                <br />
                TEL.03-5369-4056 FAX.03-3350-9113
              </div>
            </td>
          </tr>
          <tr>
            <td className={style.tblCompanyTD}>
              <div>
                <span>業務範囲</span>
              </div>
              <div>国内旅行・海外旅行</div>
            </td>
          </tr>
          <tr>
            <td className={style.tblCompanyTD}>
              <div>
                <span>旅行業登録番号</span>
              </div>
              <div>観光庁長官登録旅行業第1678号</div>
            </td>
          </tr>
          <tr>
            <td className={style.tblCompanyTD}>
              <div>
                <span>登録年月日</span>
              </div>
              <div>平成15年7月9日</div>
            </td>
          </tr>
          <tr>
            <td className={style.tblCompanyTD}>
              <div>
                <span>有効期限</span>
              </div>
              <div>令和5年7月9日～令和10年7月8日</div>
            </td>
          </tr>
          <tr>
            <td className={style.tblCompanyTD}>
              <div>
                <span>営業所名称</span>
              </div>
              <div>ジョルダントラベルサポートセンター</div>
            </td>
          </tr>
          <tr>
            <td className={style.tblCompanyTD}>
              <div>
                <span>総合旅行業務取扱管理者</span>
              </div>
              <div>
                長谷川 慶治
                <br />
                旅行業務取扱管理者とは、お客様の旅行を取り扱う営業所での取引の責任者です。
                <br />
                この旅行の契約に関し担当者からの説明にご不明な点がありましたら、ご遠慮なく表記の総合旅行業務取扱管理者にご質問ください。
              </div>
            </td>
          </tr>
          <tr>
            <td className={style.tblCompanyTD}>
              <div>
                <span>所属旅行業会</span>
              </div>
              <div>
                一般社団法人 日本旅行業協会（ＪＡＴＡ）正会員
              </div>
            </td>
          </tr>
          <tr>
            <td className={style.tblCompanyTD}>
              <div>
                <span>受託取扱企画旅行会社名（50音順）</span>
              </div>
              <div>
                株式会社オリオンツアー
                <br />
                株式会社JTB
                <br />
                株式会社ナショナルランド
                <br />
                株式会社はとバス
                <br />
                近畿日本ツーリスト株式会社
                <br />
                スカイパックツアーズ株式会社
                <br />
                東海汽船株式会社
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <h3 className={style.pageTitle}>特定商取引法に基づく表記</h3>
      <table className={style.tblCompany}>
        <tbody>
          <tr>
            <td className={style.tblCompanyTD}>
              <div>
                <span>販売事業者</span>
              </div>
              <div>ジョルダン株式会社（ジョルダントラベル）</div>
            </td>
          </tr>
          <tr>
            <td className={style.tblCompanyTD}>
              <div>
                <span>所在地</span>
              </div>
              <div>
                本社：〒160-0022 東京都新宿区新宿2-5-10 アーバンセンター新宿6階
                <br />
                埼玉オフィス：〒353-0004 埼玉県志木市本町5-21-14　JSKビル5階
              </div>
            </td>
          </tr>
          <tr>
            <td className={style.tblCompanyTD}>
              <div>
                <span>電話番号</span>
              </div>
              <div>TEL.03-5369-4056</div>
            </td>
          </tr>
          <tr>
            <td className={style.tblCompanyTD}>
              <div>
                <span>対応時間</span>
              </div>
              <div>平日9時30分～12時／13時～17時</div>
            </td>
          </tr>
          <tr>
            <td className={style.tblCompanyTD}>
              <div>
                <span>旅行代金の支払方法</span>
              </div>
              <div>銀行振込、クレジットカード</div>
            </td>
          </tr>
          <tr>
            <td className={style.tblCompanyTD}>
              <div>
                <span>商品代金以外の必要料金</span>
              </div>
              <div>
                送料 配送1回毎に600円
                <br />
                (サイト内にて別記掲示がある場合は、その定めるところによります。)
              </div>
            </td>
          </tr>
          <tr>
            <td className={style.tblCompanyTD}>
              <div>
                <span>引渡し時期</span>
              </div>
              <div>
                旅行出発日の10～7日前にお送り致します。(それ以後の場合通常1～3日程度かかります。)
                <br />
                出荷完了時にメールにてご連絡します。
              </div>
            </td>
          </tr>
          <tr>
            <td className={style.tblCompanyTD}>
              <div>
                <span>キャンセル</span>
              </div>
              <div>
                所定の手数料をお支払いいただくことで旅行開始日当日まで承ることが出来ます。(サイト内の旅行条件書に定めるところによります。)
                <br />
                その場合、キャンセルの対象となるチケットは弊社まで返送いただくことに形となります。(送料はお客様負担となります。)
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <h3 className={style.pageTitle}>お客様相談窓口</h3>
      <table className={style.tblCompany}>
        <tbody>
          <tr>
            <td className={style.tblCompanyTD}>
              <div>
                <span>担当</span>
              </div>
              <div>ジョルダン株式会社　伊藤 友一</div>
            </td>
          </tr>
          <tr>
            <td className={style.tblCompanyTD}>
              <div>
                <span>連絡先</span>
              </div>
              <div>TEL:03-5369-4056 FAX:03-3350-9113 </div>
            </td>
          </tr>
          <tr>
            <td className={style.tblCompanyTD}>
              <div>
                <span>対応時間</span>
              </div>
              <div>平日9時30分～12時／13時～17時</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
