import style from "../../css/info.module.css";

export function KeiyakuTokou() {
  return (
    <div className="w-full max-w-screen-lg mx-auto my-4 md:my-8 px-2">
      <h2 className={style.pageTitle}>[旅行業約款] 渡航手続代行契約</h2>

      <p className={`${style.content} ${style.red} ${style.mt4}`}>(適用範囲)</p>
      <p className={style.content}>
        <span className={style.boldmr2}>第一条</span>
        当社が旅行者との間で締結する渡航手続代行契約は、この約款の定めるところによります。この約款に定めのない事項については、法令又は一般に確立された慣習によります。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>２</span>
        当社が法令に反せず、かつ、旅行者の不利にならない範囲で書面により特約を結んだときは、前項の規定にかかわらず、その特約が優先します。
      </p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (渡航手続代行契約を締結する旅行者)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第二条</span>
        当社が渡航手続代行契約を締結する旅行者は、当社と募集型企画旅行契約、受注型企画旅行契約若しくは手配旅行契約を締結した旅行者又は当社が受託している他の旅行業者の募集型企画旅行について当社が代理して契約を締結した旅行者とします。
      </p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (渡航手続代行契約の定義)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第三条</span>
        この約款で「渡航手続代行契約」とは、当社が渡航手続の代行に対する旅行業務取扱料金(以下「渡航手続代行料金」といいます。)を収受することを約して、旅行者の委託により、次に掲げる業務(以下「代行業務」といいます。)を行うことを引き受ける契約をいいます。
      </p>
      <p className={style.contentml2}>
        一 旅券、査証、再入国許可及び各種証明書の取得に関する手続
      </p>
      <p className={style.contentml2}>二 出入国手続書類の作成</p>
      <p className={style.contentml2}>三 その他前各号に関連する業務</p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (契約の成立)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第四条</span>
        当社と渡航手続代行契約を締結しようとする旅行者は、当社所定の申込書に所定の事項を記入の上、当社に提出しなければなりません。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>２</span>
        渡航手続代行契約は、当社が契約の締結を承諾し、前項の申込書を受理した時に成立するものとします。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>３</span>
        当社は、前二項の規定にかかわらず、申込書の提出を受けることなく電話、郵便、ファクシミリ、インターネットその他の通信手段による渡航手続代行契約の申込みを受け付けることがあります。この場
        合において、渡航手続代行契約は、当社が契約の締結を承諾した時に成立するものとします。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>４</span>
        当社は、次に掲げる場合において、渡航手続代行契約の締結に応じないことがあります。
      </p>
      <p className={style.contentml2}>
        一
        旅行者が、暴力団員、暴力団準構成員、暴力団関係者、暴力団関係企業又は総会屋等その他の反社会的勢力であると認められるとき。{" "}
      </p>
      <p className={style.contentml2}>
        二
        旅行者が、当社に対して暴力的な要求行為、不当な要求行為、取引に関して脅迫的な言動若しくは暴力を用いる行為又はこれらに準ずる行為を行ったとき。
      </p>
      <p className={style.contentml2}>
        三
        旅行者が、風説を流布し、偽計を用い若しくは威力を用いて当社の信用を毀損し若しくは当社の業務を妨害する行為又はこれらに準ずる行為を行ったとき。
      </p>
      <p className={style.contentml2}>
        四 その他当社の業務上の都合があるとき。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>５</span>
        当社は、渡航手続代行契約の成立後速やかに、旅行者に、当該渡航手続代行契約により引き受けた代行業務(以下「受託業務」といいます。)の内容、渡航手続代行料金の額、その収受の方法、当社の責任その他必要な事項を記載した書面を交付します。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>６</span>
        当社は、あらかじめ旅行者の承諾を得て、前項の書面の交付に代えて、情報通信の技術を利用する方法により当該書面に記載すべき事項(以下この条において「記載事項」といいます。)を提供したときは、旅行者の使用する通信機器に備えられたファイルに記載事項が記録されたことを確認します。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>７</span>
        前項の場合において、旅行者の使用に係る通信機器に記載事項を記録するためのファイルが備えられていないときは、当社の使用する通信機器に備えられたファイル(専ら当該旅行者の用に供するものに限ります。)に記載事項を記録し、旅行者が記載事項を閲覧したことを確認します。
      </p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>(守秘義務)</p>
      <p className={style.content}>
        <span className={style.boldmr2}>第五条</span>
        当社は、受託業務を行うに当たって知り得た情報を他に漏らすことのないようにいたします。
      </p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (旅行者の義務)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第六条</span>
        旅行者は、当社が定める期日までに、渡航手続代行料金を支払わなければなりません。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>２</span>
        旅行者は、当社が定める期日までに、受託業務に必要な書類、資料その他の物(以下「渡航手続書類等」といいます。)を当社に提出しなければなりません。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>３</span>
        当社が、受託業務を行うに当たって、本邦の官公署、在日外国公館その他の者に、手数料、査証料、委託料その他の料金(以下「査証料等」といいます。)を支払わなければならないときは、旅行者は、当社が定める期日までに当社に対して当該査証料等を支払わなければなりません。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>４</span>
        受託業務を行うに当たって、郵送費、交通費その他の費用が生じたときは、旅行者は、当社が定める期日までに当社に対して当該費用を支払わなければなりません。
      </p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (契約の解除)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第七条</span>
        旅行者は、いつでも渡航手続代行契約の全部又は一部を解除することができます。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>２</span>
        当社は、次に掲げる場合において、渡航手続代行契約を解除することがあります。
      </p>
      <p className={style.contentml2}>
        一 旅行者が、所定の期日までに渡航手続書類等を提出しないとき。
      </p>
      <p className={style.contentml2}>
        二 当社が、旅行者から提出された渡航手続書類等に不備があると認めたとき。
      </p>
      <p className={style.contentml2}>
        三
        旅行者が、渡航手続代行料金、査証料等又は前条第四項の費用を所定の期日までに支払わないとき。
      </p>
      <p className={style.contentml2}>
        四
        旅行者が第四条第四項第一号から第三号までのいずれかに該当することが判明したとき。
      </p>
      <p className={style.contentml2}>
        五
        第三条第一号の代行業務を引き受けた場合において、旅行者が、当社の責に帰すべき事由によらず、旅券、査証又は再入国許可(以下「旅券等」といいます。)を取得できないおそれが極めて大きいと当社が認めるとき。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>３</span>
        前二項の規定に基づいて渡航手続代行契約が解除されたときは、旅行者は、既に支払った査証料等及び前条第四項の費用を負担するほか、当社に対し、当社が既に行った受託業務に係る渡航手続代行料金を支払わなければなりません。
      </p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (当社の責任)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第八条</span>
        当社は、渡航手続代行契約の履行に当たって、当社が故意又は過失により旅行者に損害を与えたときは、その損害を賠償する責に任じます。ただし、損害発生の翌日から起算して六月以内に当社に対して通知があったときに限ります。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>２</span>
        当社は、渡航手続代行契約により、実際に旅行者が旅券等を取得できること及び関係国への出入国が許可されることを保証するものではありません。したがって、当社の責に帰すべき事由によらず、旅行者が旅券等の取得ができず、又は関係国への出入国が許可されなかったとしても、当社はその責任を負うものではありません。
      </p>
    </div>
  );
}
