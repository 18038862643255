import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { RadioGroup } from "@headlessui/react";
import { formatISO } from "date-fns";

import { useEffect, useState } from "react";
import { Account } from "../../types";
import { useAuth } from "../../App";

import { getAccount } from "../../api";
import { useLocation, useNavigate } from "react-router-dom";

import lock from "../../img/images/icon_key.svg";
import mail from "../../img/images/icon_mail.svg";
import user from "../../img/images/icon_user.svg";

import { GrayDottedLine } from "../../components/Lines";
import { isValidDate } from "../../utils";
import {
  API_URL,
  ERROR_REQUIRED,
  getErrorMessage,
  renderError,
} from "../../constants";

export default function InputInfo() {
  let { user } = useAuth();
  let navigate = useNavigate();
  const [account, setAccount] = useState<Account>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();

  useEffect(() => {
    const getData = async () => {
      try {
        let account = await getAccount(user?.eid);
        setAccount(account);
        methods.reset(account.profile);
        setError(undefined);
      } catch (err) {
        setError(getErrorMessage(err));
        setAccount(account);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, []);

  const methods = useForm();

  const location = useLocation();
  let nextURL =
    location.pathname === "/order/inputInfo"
      ? "/order/final"
      : location.pathname === "/orderFree/inputInfo"
      ? "/orderFree/final"
      : "/mypage";

  const onSubmit = async (profile: any) => {
    let method, url;
    if (account) {
      method = "PUT";
      url = `${API_URL}/account/profile`;
    } else {
      method = "POST";
      url = `${API_URL}/account`;
    }

    try {
      // await updateProfile(profile)

      const response = await fetch(url, {
        method,
        headers: {
          "x-jorudan-eid": user?.eid ?? "",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          last_name: profile.last_name,
          first_name: profile.first_name,
          last_name_reading: profile.last_name_reading,
          first_name_reading: profile.first_name_reading,
          gender: profile.gender,
          birth: formatISO(profile.birth, { representation: "date" }),
          tel: profile.tel,
        }),
      });
      if (!response.ok) {
        throw new Error(
          `This is an HTTP error: The status is ${response.status}`
        );
      }
      let actualData = await response.json();
      setAccount(actualData.account);
      setError(undefined);

      navigate(nextURL);
    } catch (err) {
      setError(getErrorMessage(err));
      setAccount(account);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="p-1 space-y-4 w-full">
        {(location.pathname === "/order/inputInfo" || location.pathname === "/orderFree/inputInfo") ? (
          <div className="commentArea">
            <p>
              <span className="font-semibold">
                お申込みに必要なお客様情報の入力をお願いいたします。
              </span>
              <br />
              入力後、画面下の「最終確認へ進む」ボタンを押すと、お申込み内容の確認画面へ移動します。
            </p>
          </div>
        ) : (
          <div className="commentArea">
            <p>
              <span className="font-semibold">
                お客様情報の変更を行います。
              </span>
              <br />
              入力後、画面下の「この内容で登録」ボタンを押すと、変更が完了いたします。
            </p>
          </div>
        )}

        <FormProvider {...methods}>
          {/* pass all methods into the context */}
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="cart">
              <div className="cartTitle">お客様情報</div>
              <div className="cartContents">
                <UserInfo account={account} />
              </div>
            </div>

            <button type="submit" className="btn-enter mx-auto my-10">
              {location.pathname === "/inputInfo"
                ? "この内容で登録"
                : "最終確認へ進む"}
            </button>
          </form>
        </FormProvider>
      </div>
    </>
  );
}

function UserInfo({ account }: { account: Account | undefined }) {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext();

  const location = useLocation();

  return (
    <>
      <div className="space-y-5">
        {location.pathname !== "/inputInfo" && (
          <>
            {account?.jid && (
              <>
                <div>
                  <div className="cartSubTitles">
                    <img src={lock} />
                    <div>JID(ユーザーID)</div>
                  </div>
                  <GrayDottedLine />
                  <div>{account?.jid}</div>
                </div>

                <div>
                  <div className="cartSubTitles">
                    <img src={mail} />
                    <div>メールアドレス</div>
                  </div>
                  <GrayDottedLine />
                  <div>{account?.email}</div>
                </div>
              </>
            )}
          </>
        )}

        <div>
          <div className="cartSubTitles">
            <img src={user} />
            <div>お名前</div>
          </div>
          <GrayDottedLine />
          <div className="orderInputArea">
            <div className="orderInputNames">
              <div className="orderInputName orderInput">
                <label htmlFor="last_name">姓</label>
                <div>
                  <input
                    type="text"
                    defaultValue={account?.profile.last_name}
                    {...register("last_name", {
                      required: ERROR_REQUIRED,
                    })}
                    name="last_name"
                    id="last_name"
                    placeholder="姓"
                    className="orderInputH"
                  />
                  <ErrorMessage
                    errors={errors}
                    name="last_name"
                    render={renderError}
                  />
                </div>
              </div>
              <div className="orderInputName orderInput">
                <label htmlFor="first_name">名</label>
                <div>
                  <input
                    type="text"
                    defaultValue={account?.profile.first_name}
                    {...register("first_name", {
                      required: ERROR_REQUIRED,
                      // minLength: { value: 5, message: "test" },
                    })}
                    name="first_name"
                    id="first_name"
                    placeholder="名"
                    className="orderInputH"
                  />
                  <ErrorMessage
                    errors={errors}
                    name="first_name"
                    render={renderError}
                  />
                </div>
              </div>
            </div>
            <div className="orderInputNames">
              <div className="orderInputName orderInput">
                <label htmlFor="last_name_reading">セイ</label>
                <div>
                  <input
                    type="text"
                    defaultValue={account?.profile.last_name_reading}
                    {...register("last_name_reading", {
                      required: ERROR_REQUIRED,
                    })}
                    name="last_name_reading"
                    id="last_name_reading"
                    placeholder="セイ"
                    className="orderInputH"
                  />
                  <ErrorMessage
                    errors={errors}
                    name="last_name_reading"
                    render={renderError}
                  />
                </div>
              </div>
              <div className="orderInputName orderInput">
                <label htmlFor="first_name_reading">メイ</label>
                <div>
                  <input
                    type="text"
                    defaultValue={account?.profile.first_name_reading}
                    {...register("first_name_reading", {
                      required: ERROR_REQUIRED,
                    })}
                    name="first_name_reading"
                    id="first_name_reading"
                    placeholder="メイ"
                    className="orderInputH"
                  />
                  <ErrorMessage
                    errors={errors}
                    name="first_name_reading"
                    render={renderError}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="cartSubTitles">
            <img src={user} />
            <div>
              性別
              <span className="text-sm md:text-base ml-4 font-normal">
                &#8251;お手配の際に必要なため、どちらかをご選択ください。
              </span>
            </div>
          </div>
          <GrayDottedLine />

          <div className="orderInputArea">
            <label htmlFor="gender" className="sr-only">
              性別
            </label>
            <Controller
              // defaultValue={account?.profile.gender}
              rules={{
                required: ERROR_REQUIRED,
              }}
              name="gender"
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SelectGender
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                />
              )}
            />
            <ErrorMessage errors={errors} name="gender" render={renderError} />
          </div>
        </div>

        <div>
          <div className="cartSubTitles">
            <img src={user} />
            <div>
              生年月日
              <span className="text-sm md:text-base ml-4 font-normal">
                例:2001年1月1日生まれの場合「2001/01/01」とご入力ください。
              </span>
            </div>
          </div>
          <GrayDottedLine />

          <div className="orderInputArea">
            <label htmlFor="birth" className="sr-only">
              生年月日
            </label>
            <div className="orderInput md:ml-14">
              <input
                type="date"
                defaultValue={account?.profile.birth}
                {...register("birth", {
                  validate: (value) => isValidDate(value) || ERROR_REQUIRED,
                  valueAsDate: true,
                })}
                name="birth"
                id="birth"
                placeholder="生年月日"
                className="orderInputF"
              />
              <ErrorMessage errors={errors} name="birth" render={renderError} />
            </div>
          </div>
        </div>

        <div>
          <div className="cartSubTitles">
            <img src={user} />
            <div>
              お電話番号
              <span className="text-sm md:text-base ml-4 font-normal">
                &#8251;ハイフンやカッコを入れず、数字のみをご入力ください。
              </span>
            </div>
          </div>
          <GrayDottedLine />

          <div className="orderInputArea">
            <label htmlFor="tel" className="sr-only">
              連絡先
            </label>
            <div className="orderInput md:ml-14">
              <input
                type="text"
                defaultValue={account?.profile.tel}
                {...register("tel", {
                  required: ERROR_REQUIRED,
                })}
                name="tel"
                id="tel"
                placeholder="連絡先"
                className="orderInputF"
              />
              <ErrorMessage errors={errors} name="tel" render={renderError} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function SelectGender<T>({ onChange, onBlur, value }: any) {
  const genders = [
    {
      id: 1,
      sex: "male",
      label: "男性",
    },
    {
      id: 2,
      sex: "female",
      label: "女性",
    },
  ];
  return (
    <RadioGroup value={value} onChange={onChange} onBlur={onBlur}>
      <RadioGroup.Label className="sr-only">gender</RadioGroup.Label>
      <div className="flex flex-row space-x-5 md:ml-14">
        {genders.map((gender) => (
          <RadioGroup.Option
            key={gender.id}
            value={gender.sex}
            className={({ checked }) =>
              `${
                checked
                  ? "bg-orange-light border-dGray"
                  : "bg-white border-gray"
              }
              boxRightChecked w-40`
            }
          >
            <RadioGroup.Label as="p" className="text-color">
              {gender.label}
            </RadioGroup.Label>
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}
