import style from "../../css/info.module.css";

export default function Help() {
  return (
    <div className="w-full max-w-screen-lg mx-auto my-4 md:my-8 px-2">
      <h2 className={style.pageTitle}>よくある質問</h2>

      <div className="space-y-10">
        <div>
          <h3 className={style.helpTitle}>ユーザー登録について</h3>
          <div className={style.helpQAarea}>
            <div className={style.helpQA}>
              <p>Q.メールが届かない</p>
              <div>
                入力したメールアドレスが間違っている可能性がございます。再度、新規登録の手続きをお願いいたします。
                <br />
                また、メール受信制限をされている方は、[
                <span className="text-cyan-600 font-semibold">
                  @jorudan.co.jp
                </span>
                ]からのメール受信許可設定をお願いします。
              </div>
            </div>
          </div>
        </div>
        <div>
          <h3 className={style.helpTitle}>ご注文について</h3>
          <div className={style.helpQAarea}>
            <div className={style.helpQA}>
              <p>Q.空き状況を知りたい</p>
              <div>
                空き状況の確認だけでも結構です。
                <br />
                トップページにある「お気軽に！旅行相談」、もしくはお電話にてお気軽にお問い合わせください。
              </div>
            </div>

            <div className={style.helpQA}>
              <p>Q.申込み・問い合わせが出来ているか心配</p>
              <div>
                ご注文・お問い合わせいただいた後にお送りしている「受付完了メール」をご確認ください。
              </div>
            </div>

            <div className={style.helpQA}>
              <p>Q.いつまで注文可能か</p>
              <div>
                出発日の3日前(17:00)まで予約可能3日前(17:00)まで予約可能となります。
                <br />
                3日前が土曜・日曜・祝日等休業日の場合は、前営業日17時までとなります。ご注意ください。
              </div>
            </div>

            <div className={style.helpQA}>
              <p>Q.宿泊せずに交通機関のみ利用したい</p>
              <div>
                トップページにある「お気軽に！旅行相談」、もしくはお電話にてお気軽にお問い合わせください。
              </div>
            </div>
          </div>
        </div>

        <div>
          <h3 className={style.helpTitle}>お支払方法について</h3>
          <div className={style.helpQAarea}>
            <div className={style.helpQA}>
              <p>Q.クレジットカードで支払いたい</p>
              <div>
                可能です。クレジットカードでのお支払いを希望される場合は、「クレジットカード決済」と担当者にお伝えください。
                <br />
                ご利用いただけるカード会社は、VISA、MASTERCARD、JCB、AMEX、DINERSです。
                <br />
                尚、本人確認のためお電話を差し上げる場合もございますのでご了承ください。
              </div>
            </div>

            <div className={style.helpQA}>
              <p>Q.領収書が欲しい</p>
              <div>
                発行いたします。「領収書を希望」と担当者にお伝えください。
              </div>
            </div>
          </div>
        </div>

        <div>
          <h3 className={style.helpTitle}>
            注文後から出発まで（注文の変更・キャンセル、紛失・欠航）
          </h3>
          <div className={style.helpQAarea}>
            <div className={style.helpQA}>
              <p>Q.キャンセルしたい</p>
              <div>
                [
                <span className="text-cyan-600 font-semibold">
                  travel@jorudan.co.jp
                </span>
                ]宛に、キャンセルのご連絡をお願いいたします。
                <br />
                また、お電話からもキャンセルを承っております。
                TEL:03-5369-4056(平日9:30~12:00/13:00~17:00)
              </div>
            </div>

            <div className={style.helpQA}>
              <p>Q.チケットを紛失してしまいました。どうすれば良いか</p>
              <div>
                <span className="font-semibold">【新幹線の場合】</span>
                <p className="mb-2.5">
                  ご乗車が出来ません。お客様ご自身で再度購入していただき、ご乗車いただくことになります。
                  <br />
                  その際、乗車証明（二重購入証明書）を各運送機関様より受け取ってください。
                  <br />
                  後日、紛失したチケットが見つかった場合、弊社宛にお送りいただいた後ご返金させていただきます。
                </p>
                <span className="font-semibold">【飛行機の場合】</span>
                <p>
                  弊社へご連絡ください。再度チケット番号をお伝えします。
                  <br />
                  もしくは、空港カウンターにてご本人様確認が出来る身分証をご提示いただき、ご搭乗手続きを行ってください。
                </p>
              </div>
            </div>

            <div className={style.helpQA}>
              <p>Q.台風が来ているようですが、どのようにすれば良いか</p>
              <div>
                運休・欠航は各運航会社の判断となります。運休・欠航の発表前にキャンセルされますとキャンセル料が発生いたしますのでご注意ください。
                <br />
                尚、ご予約いただいている列車・便が運休・欠航となった場合、別の便・交通手段へ振り替えとなることがございます。航空会社係員・駅員にご相談ください。
                <br />
                また、ご利用の列車・便が運休・欠航・遅延した場合、ご旅行代金の一部が返金されることがございます。詳しくは弊社までお問い合わせください。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
