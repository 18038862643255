import { Link, useLocation, useOutletContext } from "react-router-dom";
import { format } from "date-fns";

import calendar from "../../img/images/icon_calendar.svg";
import hotel from "../../img/images/icon_hotel.svg";
import transport from "../../img/images/icon-move.svg";
import train from "../../img/images/icon-train.svg";
import flight from "../../img/images/icon_flight.svg";
import mealOp from "../../img/images/icon_meal.svg";
import remarks from "../../img/images/icon-note.svg";
import { GrayDottedLine } from "../../components/Lines";

export default function ConfirmFree() {
  const { search } = useLocation();
  const [state, setState] = useOutletContext() as any;
  const diffDays = (state.endDate - state.startDate) / 86400000;

  return (
    <>
      <div className="p-1 space-y-4 w-full">
        <div className="commentArea">
          <p>
            <span className="font-semibold text-orange">
              ご相談内容をご確認ください。
            </span>
            <br />
            内容に問題がなければ、画面下の
            <span className="font-semibold">
              「上記の内容で問題ないので、次へ進む」ボタンから、お客様情報の入力
            </span>
            をお願いいたします。変更をご希望の場合は、画面下の「内容を変更する」ボタンから前の画面へ戻ることができます。
          </p>
        </div>
        <div className="cart">
          <div className="cartTitle">ご相談内容</div>
          <div className="cartContents">
            <div className="cartSubTitles">
              <img src={calendar} />
              <div>日程・人数</div>
            </div>

            <GrayDottedLine />

            <div className="flex flex-row flex-wrap">
              <div className="cartItems">
                <div className="cartItemName">行き</div>
                <div className="space-x-2 font-semibold">
                  <span>{format(state.startDate, "yyyy年MM月dd日")}</span>
                  <span>
                    {state.othersOrigin
                      ? state.othersOrigin
                      : state.origin.label}
                  </span>
                </div>
              </div>
              <div className="cartItems">
                <div className="cartItemName">帰り</div>
                <div className="space-x-2 font-semibold">
                  <span>{format(state.endDate, "yyyy年MM月dd日")}</span>
                  <span>
                    {state.othersDestination
                      ? state.othersDestination
                      : state.destination.label}
                  </span>
                </div>
              </div>
              <div className="cartItems">
                <div>
                  <div className="cartItemName">人数</div>
                </div>
                <div className="flex flex-col space-y-5">
                  <div className="flex lex-row">
                    <div className="w-28">大人(12歳以上)</div>：{state.adults}人
                  </div>
                  <div className="flex lex-row">
                    <div className="w-28">小人(3-11歳)</div>：{state.children}人
                  </div>
                </div>
              </div>
            </div>

            <div className="cartSubTitles">
              <img src={transport} />
              <div>移動手段</div>
            </div>

            <GrayDottedLine />

            <div className="cartItems">
              <div className="cartItemName">移動手段</div>
              <div className="space-x-2 font-semibold">
                {state.transport.label}
              </div>
            </div>
            {state.transport.id === "air" && (
              <div className="flex flex-row flex-wrap">
                <div className="cartItems">
                  <div className="cartItemName">往路出発</div>
                  <div className="space-x-2 font-semibold">
                    <span>{state.depTimeOrigin?.time}</span>
                  </div>
                </div>
                <div className="cartItems">
                  <div>
                    <div className="cartItemName">復路出発</div>
                  </div>
                  <div className="space-x-2 font-semibold">
                    <span>{state.depTimeDestination?.time}</span>
                  </div>
                </div>
              </div>
            )}

            {state.transport.id == "train" ? (
              <>
                <div className="cartSubTitles">
                  {state.transport.id === "air" ? (
                    <img src={flight} />
                  ) : (
                    <img src={train} />
                  )}
                  <div>
                    {state.transport.id === "air" ? "フライト" : "電車"}
                  </div>{" "}
                </div>

                <GrayDottedLine />

                <div className="cartFlight">
                  <div className="font-semibold mx-4">往路</div>
                </div>

                <div className="flex flex-row flex-wrap">
                  <div className="cartItems">
                    <div className="cartItemName">
                      {state.transport.id === "air" ? "利用便" : "利用列車"}
                    </div>
                    <div>{state.transportInfo?.originName ?? "指定なし"}</div>
                  </div>
                  <div className="cartItems">
                    <div className="cartItemName">座席</div>
                    <div>{state.transportInfo?.originSeat ?? "指定なし"}</div>
                  </div>
                  <div className="cartItems">
                    <div className="cartItemName">出発地</div>
                    <div>{state.transportInfo?.originDep}</div>
                  </div>
                  <div className="cartItems">
                    <div className="cartItemName">到着地</div>
                    <div>{state.transportInfo?.originArr}</div>
                  </div>
                  <div className="cartItems">
                    <div className="cartItemName">出発時間</div>
                    <div>
                      {state.transportInfo?.originDepTime ?? "指定なし"}
                    </div>
                  </div>
                  <div className="cartItems">
                    <div className="cartItemName">到着時間</div>
                    <div>
                      {state.transportInfo?.originArrTime ?? "指定なし"}
                    </div>
                  </div>
                </div>

                <div className="cartFlight">
                  <div className="font-semibold mx-4">復路</div>
                </div>
                <div className="flex flex-row flex-wrap">
                  <div className="cartItems">
                    <div className="cartItemName">
                      {state.transport.id === "air" ? "利用便" : "利用列車"}
                    </div>
                    <div>
                      {state.transportInfo?.destinationName ?? "指定なし"}
                    </div>
                  </div>
                  <div className="cartItems">
                    <div className="cartItemName">座席</div>
                    <div>
                      {state.transportInfo?.destinationSeat ?? "指定なし"}
                    </div>
                  </div>
                  <div className="cartItems">
                    <div className="cartItemName">出発地</div>
                    <div>{state.transportInfo?.destinationDep}</div>
                  </div>
                  <div className="cartItems">
                    <div className="cartItemName">到着地</div>
                    <div>{state.transportInfo?.destinationArr}</div>
                  </div>
                  <div className="cartItems">
                    <div className="cartItemName">出発時間</div>
                    <div>
                      {state.transportInfo?.destinationDepTime ?? "指定なし"}
                    </div>
                  </div>
                  <div className="cartItems">
                    <div className="cartItemName">到着時間</div>
                    <div>
                      {state.transportInfo?.destinationArrTime ?? "指定なし"}
                    </div>
                  </div>
                </div>
              </>
            ) : null}

            {state.schedule === 'stay' && (
            <>
                <div className="cartSubTitles">
                <img src={hotel} />
                <div>宿泊施設</div>
                </div>

                <GrayDottedLine />

                <div className="flex flex-row flex-wrap">
                <div className="cartItems">
                    <div className="cartItemName">宿泊日</div>
                    <div className="space-x-2 font-semibold">
                    <span>{format(state.startDate, "yyyy年MM月dd日")}</span>
                    <span>〜</span>
                    <span>{diffDays}泊</span>
                    </div>
                </div>
                <div className="cartItems">
                    <div className="cartItemName">エリア</div>
                    <div className="font-semibold">
                    {state.destination.region_id === "others" ? (
                        <>{state.hotelArea ? state.hotelArea : "指定なし"}</>
                    ) : (
                        state.hotel?.area.label
                    )}
                    </div>
                </div>
                <div className="cartItems">
                    <div className="cartItemName">ランク</div>
                    <div className="font-semibold">
                    {state.destination.region_id === "others"
                        ? state.othersHotelClass.label
                        : state.hotel?.class.label}
                    </div>
                </div>
                <div className="cartItems">
                    <div className="cartItemName">位置</div>
                    <div className="font-semibold">
                    {state.destination.region_id === "others"
                        ? state.location.label
                        : state.hotel?.room.location.label}
                    </div>
                </div>
                <div className="cartItems">
                    <div className="cartItemName">大浴場</div>
                    <div className="font-semibold">
                    {state.destination.region_id === "others"
                        ? state.bath.label
                        : state.hotel?.room.bath.label}
                    </div>
                </div>
                <div className="cartItems">
                    <div className="cartItemName">朝食</div>
                    <div className="font-semibold">
                    {state.destination.region_id === "others"
                        ? state.breakfast.label
                        : state.hotel?.room.breakfast.label}
                    </div>
                </div>
                <div className="cartItems">
                    <div className="cartItemName">たばこ</div>
                    <div className="font-semibold">
                    {state.destination.region_id === "others"
                        ? state.smoking.label
                        : state.hotel?.room.smoking.label}
                    </div>
                </div>
                </div>

                <div className="cartSubTitles">
                <img src={mealOp} />
                <div>夕食</div>
                </div>
                <GrayDottedLine />
            </>
            )}

            <div className="cartRemarks">
              <p>
                {state.freeRestaurant ? (
                  <>{state.freeRestaurant}</>
                ) : (
                  <>入力なし</>
                )}
              </p>
            </div>

            <div className="cartSubTitles">
              <img src={remarks} />
              <div>備考</div>
            </div>
            <GrayDottedLine />

            <div className="cartRemarks">
              <p>{state.remarks ? <>{state.remarks}</> : <>備考なし</>}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="py-8 md:px-14">
        <div className="flex flex-col md:flex-row md:justify-evenly items-center space-y-5 md:space-y-0">
          <Link to={`/orderFree/select`} className="btn-back">
            内容を変更する
          </Link>

          <Link to="/orderFree/inputInfo" className="btn-enter text-center">
            上記の内容で問題ないので、
            <br className="flex md:hidden" />
            次へ進む
          </Link>
        </div>
      </div>
    </>
  );
}
