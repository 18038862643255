import "../css/common.css";
import "../css/search.css";
import { useState, forwardRef, useEffect, Fragment } from "react";
import { useOutletContext } from "react-router-dom";
import { Listbox, Transition } from "@headlessui/react";
import { HotelArea, Order } from "../types";
import {
  API_URL,
  classNames,
  getErrorMessage,
  regions,
  regionTokyoEast,
  regionTokyoCentral,
  regionTohoku,
  regionShinshu,
  regionHokuriku,
  regionChubu,
  regionKansai,
  regionTokyo,
  regionShinyoko,
  regionUeno,
  regionOmiya,
  State,
} from "../constants";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { PlusSmIcon, MinusSmIcon } from "@heroicons/react/outline";
import calendar from "../img/images/icon_calendar.svg";
import arrow from "../img/images/about-icon-arrow.png";

//calender
import DatePicker, { registerLocale } from "react-datepicker";
import { format } from "date-fns";
import ja from "date-fns/locale/ja";

registerLocale("ja", ja);

type ContextType = { state: Order | null };

export default function SelectSearchAreaFree({
  expand,
  setExpand,
}: {
  expand?: boolean;
  setExpand: React.Dispatch<React.SetStateAction<State>>;
}) {
  expand = expand ?? true;

  const [state, setState] =
    useOutletContext<[Order, React.Dispatch<React.SetStateAction<Order>>]>();

  let validate = (): string | null => {
    if (startDate === endDate && state.schedule !== 'day') {
      return "行きと帰りは異なる日付を選択してください。";
    }

    if (!othersOrigin && departure.prior === 99 && arrival.label === 'その他') {
      return "出発：希望の出発地を入力してください";
    }

    if (!othersDestination && arrival.prior === 99 && arrival.label === 'その他') {
      return "到着：希望の到着地を入力してください";
    }

    if (departure.region_id === arrival.region_id) {
      return "出発地と到着地は異なるエリアを指定してください。";
    }

    if (adults === 0) {
      return "人数を選択してください";
    }

    return null;
  };

  let [error, setError] = useState<string>();

  let onSubmit = () => {
    let error = validate();
    if (error) {
      setError(error);
    } else {
      setState((state: Order) => ({
        ...state,
        origin: departure,
        othersOrigin:
          departure.region_id == "others" ? othersOrigin : undefined,
        destination: arrival,
        othersDestination:
          arrival?.region_id == "others" ? othersDestination : undefined,
        startDate,
        endDate,
        adults,
        children,
        hotel: undefined,
      }));

      setExpand(State.DEFAULT);
    }
  };

  const schedule = state.schedule;
  const [departure, setDeparture] = useState<any>(state.origin);
  const [arrival, setArrival] = useState<any>(state.destination);
  const [adults, setCountAdults] = useState(state.adults);
  const [children, setCountChildren] = useState(state.children);
  const [areas, setAreas] = useState<HotelArea[]>([]);
  const [loading, setLoading] = useState(true);

  let departRegion = state.departArea ?? 'default';
  let destRegion = state.destArea ?? 'default';

    const setRegions = (region: string) => {
        switch(region) {
            case 'tokyo_east':
                return regionTokyoEast.map( list => ({...list}));
            
            case 'tokyo_central':
                return regionTokyoCentral.map( list => ({...list}));

            case 'tohoku':
                return regionTohoku.map( list => ({...list}));

            case 'shinshu':
                return regionShinshu.map( list => ({...list}));

            case 'hokuriku':
                return regionHokuriku.map( list => ({...list}));

            case 'chubu':
                return regionChubu.map( list => ({...list}));

            case 'kansai':
                return regionKansai.map( list => ({...list}));

            case 'tokyo':
                return regionTokyo.map( list => ({...list}));

            case 'shinyoko':
                return regionShinyoko.map( list => ({...list}));

            case 'ueno':
                return regionUeno.map( list => ({...list}));

            case 'omiya':
                return regionOmiya.map( list => ({...list}));

            default:
                return regions.map( list => ({...list}));
        }
    }

    let departRegions = setRegions(departRegion);

    if(departRegion !== 'default') {
        departRegions = departRegions.filter(x => { return x.label !== 'その他' });
    }

    let destRegions = setRegions(destRegion);

  const [area, setArea] = useState<any>(regions[0]);
  const [othersOrigin, setOthersOrigin] = useState<string>(
    state.othersOrigin ?? ""
  );
  const [othersDestination, setOthersDestination] = useState<string>(
    state.othersDestination ?? ""
  );

  let start = new Date();
  let end = new Date();
  start.setDate(start.getDate() + 5);
  end.setDate(end.getDate() + 6);

  const [startDate, setStartDate] = useState<Date>(
    state.startDate ?? new Date()
  );
  const [endDate, setEndDate] = useState<Date>(state.endDate ?? new Date());

  useEffect(() => {
    if (startDate > endDate) setStartDate(endDate);
  }, [endDate]);

  useEffect(() => {
    if (startDate > endDate) setEndDate(startDate);
  }, [startDate]);

  useEffect(() => {
    if (
      !arrival ||
      arrival.region_id == "unselected" ||
      arrival.region_id == "others"
    ) {
      return;
    }

    const getData = async () => {
      try {
        if(arrival.prior === 99) {
            return;
        }

        const response = await fetch(
          `${API_URL}/hotelareas?` +
            new URLSearchParams({ region_id: arrival.region_id }),
          { method: "GET" }
        );
        if (!response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${response.status}`
          );
        }
        let actualData = await response.json();
        let areas = actualData.hotelarea;
        setAreas(areas);
        setArea(areas[0]);
        setError(undefined);
      } catch (err) {
        setError(getErrorMessage(err));
        setAreas([]);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, [arrival]);

  let view;
  if (expand) {
    view = (
      <>
        <div className="boxRight">
          <div className="boxRightTitle">日程・場所・人数の変更</div>
          <div className="mt-5 px-2 md:px-8">
            <div className="space-y-2.5">
              <div className="w-full flex flex-row justify-around items-center space-x-4">
                {schedule === 'stay' && (
                <div className="block text-base font-semibold w-10">行き</div>
                )}
                <div className="relative w-full md:w-4/5">
                  <DateSelect
                    selected={startDate}
                    onChange={(date) => setStartDate(date!)}
                    startDate={startDate}
                    endDate={endDate}
                    popperClassName="react-datepicker-left"
                  />
                </div>
              </div>

              {schedule === 'stay' && (
              <>
              <div className="w-full flex flex-row justify-around items-center space-x-4">
                <div className="block text-base font-semibold w-10">帰り</div>
                <div className="relative w-full md:w-4/5">
                  <DateSelect
                    selected={endDate}
                    onChange={(date) => setEndDate(date!)}
                    startDate={startDate}
                    endDate={endDate}
                    popperClassName="react-datepicker-right"
                  />
                </div>
              </div>
              </>
              )}

              <div className="w-full flex flex-row justify-around items-center space-x-4">
                <div className="block text-base font-semibold w-10">出発</div>
                <div className="relative w-full md:w-4/5">
                  <Selectbox
                    options={departRegions}
                    value={departure}
                    onChange={setDeparture}
                  />
                  {departure.region_id == "others" && (
                    <div className="transportArea mt-2.5">
                      <input
                        id="othersOrigin"
                        name="origin"
                        type="text"
                        value={othersOrigin}
                        onChange={(e) => setOthersOrigin(e.target.value)}
                        placeholder="駅名、地名など"
                        className="w-full md:w-[290px] px-3 py-2 border-2 border-gray placeholder-gray-500 rounded-lg focus:outline-none focus:ring-slate-700 focus:border-slate-700"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="w-full flex flex-row justify-around items-center space-x-4">
                <div className="block text-base font-semibold w-10">到着</div>
                <div className="relative w-full md:w-4/5">
                  <Selectbox
                    options={destRegions}
                    value={arrival}
                    onChange={setArrival}
                  />
                  {arrival.region_id == "others" && (
                    <div className="transportArea mt-2.5">
                      <input
                        id="othersDestination"
                        name="destination"
                        type="text"
                        value={othersDestination}
                        onChange={(e) => setOthersDestination(e.target.value)}
                        placeholder="駅名、地名など"
                        className=" w-full md:w-[290px] px-3 py-2 border-2 border-gray placeholder-gray-500 rounded-lg focus:outline-none focus:ring-slate-700 focus:border-slate-700"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-5">
              <div className="flex flex-col text-base space-y-2.5">
                <Counter
                  value={adults}
                  label="大人 (12歳以上)"
                  isAdults={true}
                  onChange={setCountAdults}
                />
                <Counter
                  value={children}
                  label="小人 (12歳未満)"
                  isAdults={false}
                  onChange={setCountChildren}
                />
              </div>
            </div>

            <div className="my-10 px-3">
              {error && (
                <div className="mb-2 text-sm md:text-base text-red-600 text-center">
                  {error}
                </div>
              )}

              <button onClick={onSubmit} className="btn-enter mx-auto">
                この内容に変更する
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }

  return <>{view}</>;
}

interface Item {
  prior: number;
  region_id: string;
  label: string;
}

function Selectbox({
  value,
  options,
  onChange,
}: {
  value: Item;
  options: Item[];
  onChange: (value: Item) => void;
}) {
  return (
    <>
      <Listbox value={value} onChange={onChange}>
        {({ open }) => (
          <>
            <Listbox.Button className="inputBtnText">
              <span>{value.label}</span>
              <img src={arrow} className="w-5 h-auto rotate-90" />
            </Listbox.Button>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-lg py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none">
                {options.map((area) => (
                  <div key={area.prior}>
                    {area.prior !== 0 && (
                      <Listbox.Option
                        key={area.prior}
                        value={area}
                        disabled={area.prior == 0}
                        className={({ active }) =>
                          classNames(
                            active
                              ? "text-color bg-orange-light"
                              : "text-gray-00",
                            "cursor-default select-none relative py-2 pl-3 pr-9"
                          )
                        }
                      >
                        {({ selected }) => (
                          <>
                            <div className="flex items-center">
                              <span
                                className={classNames(
                                  selected ? "font-semibold" : "font-normal",
                                  "ml-1 md:ml-3 block truncate text-sm"
                                )}
                              >
                                {area.label}
                              </span>
                            </div>
                          </>
                        )}
                      </Listbox.Option>
                    )}
                  </div>
                ))}
              </Listbox.Options>
            </Transition>
          </>
        )}
      </Listbox>
    </>
  );
}

function DateSelect({
  selected,
  onChange,
  startDate,
  endDate,
  popperClassName,
}: {
  selected: any;
  onChange: (value: any) => void;
  startDate: any;
  endDate: any;
  popperClassName: string;
}) {
  let dateStart = new Date();
  dateStart.setDate(dateStart.getDate() + 3);
  return (
    <>
      <DatePicker
        minDate={dateStart}
        locale="ja"
        selected={selected}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        nextMonthButtonLabel=">"
        previousMonthButtonLabel="<"
        popperClassName={popperClassName}
        customInput={<ButtonInput />}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div className="flex items-center justify-between px-2 py-2">
            <span className="text-lg text-color">
              {format(date, "yyyy/MM")}
            </span>
            <div className="space-x-2">
              <button
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
                type="button"
                className={`
                    ${
                      prevMonthButtonDisabled && "cursor-not-allowed opacity-50"
                    } calChangeMonth
                  `}
              >
                <ChevronLeftIcon className="w-5 h-5 text-gray-600" />
              </button>
              <button
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
                type="button"
                className={`
                    ${
                      nextMonthButtonDisabled && "cursor-not-allowed opacity-50"
                    } calChangeMonth
                  `}
              >
                <ChevronRightIcon className="w-5 h-5 text-gray-600" />
              </button>
            </div>
          </div>
        )}
      />
    </>
  );
}

// type ButtonProps = JSX.IntrinsicElements["button"];
const ButtonInput = forwardRef<HTMLButtonElement, any>(
  ({ value, onClick }, ref) => (
    <button onClick={onClick} ref={ref} type="button" className="inputBtnText">
      <span>{format(new Date(value), "yyyy年MM月dd日")}</span>
      <img src={calendar} className="w-5 h-5" />
    </button>
  )
);

function Counter({
  value,
  label,
  isAdults,
  onChange,
}: {
  value: number;
  label: string;
  isAdults: boolean;
  onChange: (value: any) => void;
}) {
  const minNum = (isAdults ? 1 : 0);

  return (
    <div className="flex flex-row justify-around">
      <div className="w-44 md:w-32 font-semibold flex items-center">
        {label}
      </div>
      <div className="w-full md:w-4/6 flex justify-end">
        <div className="flex flex-row justify-between items-center w-40 py-1 px-1 bg-white border-2 border-gray rounded-lg cursor-default focus:outline-none focus:ring-1 sm:text-sm">
          <button onClick={() => onChange(Math.max(value - 1, minNum))}>
            <MinusSmIcon
              className={`w-8 h-8 bg-dGray text-white rounded-lg p-2 ${
                value <= 0 && "bg-lGray cursor-not-allowed"
              }`}
            />
          </button>
          <div>{value}</div>
          <button onClick={() => onChange(Math.min(value + 1, 6))}>
            <PlusSmIcon className="w-8 h-8 bg-dGray text-white rounded-lg p-2" />
          </button>
        </div>
      </div>
    </div>
  );
}
