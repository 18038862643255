import "../css/common.css";
import "../css/search.css";
import { useState, forwardRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Link as Scroll } from "react-scroll";
import { RadioGroup } from "@headlessui/react";
import { ChevronLeftIcon, ChevronRightIcon, StarIcon } from "@heroicons/react/solid";
import { PlusSmIcon, MinusSmIcon } from "@heroicons/react/outline";

import calendar from "../img/images/icon_calendar.svg";
import arrowGray from "../img/images/icon-arrow-gray.png";

//calender
import DatePicker, { registerLocale } from "react-datepicker";
import { format, formatISO, parseISO } from "date-fns";
import ja from "date-fns/locale/ja";
import { HotelArea, HotelClass } from "../types";
import {
  API_URL,
  bathChoice,
  breakfastChoice,
  dinnerChoice,
  getErrorMessage,
  locations,
  ranks,
  schedules,
  regions,
  regionTokyoEast,
  regionTokyoCentral,
  regionTohoku,
  regionShinshu,
  regionHokuriku,
  regionChubu,
  regionKansai,
  regionTokyo,
  regionShinyoko,
  regionUeno,
  regionOmiya,
  smokingChoice,
  State,
  transports,
  getDiffDate,
} from "../constants";
import { SelectButton } from "./SelectButton";
import { GrayDottedLine } from "./Lines";
import { DepartureTime } from "./DepartureTime";

registerLocale("ja", ja);

export default function SearchArea({
    query,
    onSubmit,
}: {
    query?: any;
    onSubmit: (value: any, free: boolean) => void;
}) {
    query = query ?? {};

    const search = new URLSearchParams(useLocation().search);

    //STEP 1
    let defaultSchedule: any = '';

    if(search.get('mode')) {
        if(search.get('mode') === 'dayTrip') {
            defaultSchedule = schedules[0];
        } else if(search.get('mode') === 'stayTrip') {
            defaultSchedule = schedules[1];
        }
    }

    const [schedule, setSchedule] = useState<any>(defaultSchedule);
    
    //STEP 2
    const now = new Date();
    const start = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const end = new Date(now.getFullYear(), now.getMonth(), now.getDate());

    let later = search.get('later') ? Number(search.get('later')) : 5

    if(isNaN(later)) {
        later = 5;
    } else {
        if(later <= 0) {
            later = 5;
        }
    }

    start.setDate(start.getDate() + later);
    end.setDate(end.getDate() + (later + 1));

    const defaultStartDate = query.startDate ? parseISO(query.startDate) : start;
    const defaultEndDate = query.endDate ? parseISO(query.endDate) : end;

    const [startDate, setStartDate] = useState<Date>(defaultStartDate);
    const [endDate, setEndDate] = useState<Date>(defaultEndDate);

    useEffect(() => {
        if (startDate > endDate) setStartDate(endDate);
    }, [endDate]);

    useEffect(() => {
        if (startDate > endDate) setEndDate(startDate);
    }, [startDate]);

    //STEP 3
    const defaultAdults = query.adults ? parseInt(query.adults) : 1;
    const defaultChildren = query.children ? parseInt(query.children) : 0;
    //let defaultInfants = query.infants ? parseInt(query.infants) : 0;

    const [adults, setCountAdults] = useState(defaultAdults);
    const [children, setCountChildren] = useState(defaultChildren);
    //const [infants, setCountInfants] = useState(defaultInfants);

    //STEP 4
    const useDepartArea: string = search.get('depart') ? search.get('depart')! : 'default';
    const useDestArea: string = search.get('dest') ? search.get('dest')! : 'default';

    const setRegions = (region: string) => {
        switch(region) {
            case 'tokyo_east':
                return regionTokyoEast.map( list => ({...list}));
            
            case 'tokyo_central':
                return regionTokyoCentral.map( list => ({...list}));

            case 'tohoku':
                return regionTohoku.map( list => ({...list}));

            case 'shinshu':
                return regionShinshu.map( list => ({...list}));

            case 'hokuriku':
                return regionHokuriku.map( list => ({...list}));

            case 'chubu':
                return regionChubu.map( list => ({...list}));

            case 'kansai':
                return regionKansai.map( list => ({...list}));

            case 'tokyo':
                return regionTokyo.map( list => ({...list}));

            case 'shinyoko':
                return regionShinyoko.map( list => ({...list}));

            case 'ueno':
                return regionUeno.map( list => ({...list}));

            case 'omiya':
                return regionOmiya.map( list => ({...list}));

            default:
                return regions.map( list => ({...list}));
        }
    }

    let tmp = setRegions(useDepartArea);

    if(useDepartArea !== 'default') {
        tmp = tmp.filter(x => { return x.label !== 'その他' });
    }

    const [useDepartRegions, setUseDepartRegions] = useState<any>(tmp);
    const [useDestRegions, setUseDestRegions] = useState<any>(setRegions(useDestArea));

    /*
    let originRegions = useDepartArea in regions ? regions[useDepartArea].map( list => ({...list}))
                                                 : regions.default.map( list => ({...list}));
    let destinationRegions = useDestArea in regions ? regions[useDestArea].map( list => ({...list}))
                                                 : regions.default.map( list => ({...list}));
     */

    const [departure, setDeparture] = useState<any>(useDepartRegions[0]);
    const [arrival, setArrival] = useState<any>(useDestRegions[0]);

    let defaultOtherOrigin = query.othersOrigin;
    let defaultOthersDestination = query.othersDestination;

    const [othersOrigin, setOthersOrigin] = useState<string>(defaultOtherOrigin);
    const [othersDestination, setOthersDestination] = useState<string>(defaultOthersDestination);

    const [areas, setAreas] = useState<HotelArea[]>([]);
    const [loading, setLoading] = useState(true);

    const [area, setArea] = useState<any>(useDestRegions[0]);

    let hotelRank = area.hotelclass
        ? area.hotelclass.find((hc: any) => hc.hotelclass_id === "standard")
        : ranks[1];

    useEffect(() => {
        setRank(hotelRank);
    }, [hotelRank]);

    //STEP 5
    let defaultTransport: any = '';

    if(search.get('way')) {
        if(search.get('way') === 'shinkansen') {
            defaultTransport = transports[0];
        } else if(search.get('way') === 'air') {    
            defaultTransport = transports[1];
        } else if(search.get('way') === 'stayonly') {
            defaultTransport = transports[2];
        }
    }

    const [transport, setTransport] = useState<any>(defaultTransport);

    const [depTimeOrigin, setDepTimeOrigin] = useState<any>("");
    const [depTimeDestination, setDepTimeDestination] = useState<any>("");

    //STEP 6
    const [rank, setRank] = useState<any>(hotelRank);

    const [location, setLocation] = useState<any>(locations[2]);
    const [bath, setBath] = useState<any>(bathChoice[1]);
    const [breakfast, setBreakfast] = useState<any>(breakfastChoice[1]);
    const [smoking, setSmoking] = useState<any>(smokingChoice[0]);

    const [initFlag, setInitFlag] = useState<boolean>(false);

    //note
    const note = search.get('note');

    useEffect(() => {
        if(!initFlag) {
            return;
        }

        initParams();

    }, [initFlag]);

    let searchParams = new URLSearchParams();

    if(schedule.id) {
        searchParams.append("schedule", schedule.id);
    }

    if (departure.prior !== 0) {
        searchParams.append("origin", departure.region_id);
    }

    if (othersOrigin) {
        searchParams.append("othersOrigin", othersOrigin);
    }

    if (arrival.prior !== 0) {
        searchParams.append("destination", arrival.region_id);
    }

    if (othersDestination) {
        searchParams.append("othersDestination", othersDestination);
    }

    searchParams.append(
        "startDate",
        formatISO(startDate, { representation: "date" })
    );

    searchParams.append(
        "endDate",
        (schedule.id === 'day' ? formatISO(startDate, { representation: "date" })
                               : formatISO(endDate, { representation: "date" }))
    );

    if (transport.id !== undefined) {
        searchParams.append("transports", transport.id);
    }

    if (depTimeOrigin.id !== undefined) {
        searchParams.append("depTimeOrigin", depTimeOrigin.id);
    }

    if (depTimeDestination.id !== undefined) {
        searchParams.append("depTimeDestination", depTimeDestination.id);
    }

    if(schedule.id === 'stay') {
        if (area.prior !== 0) {
            searchParams.append("hotelArea", area.hotelarea_id);
        }

        if (rank.hotelclass_id !== undefined) {
            searchParams.append("hotelClass", rank.hotelclass_id);
        }

        if (rank.id !== undefined) {
            searchParams.append("hotelClass", rank.id);
        }

        if (location.id !== undefined) {
            searchParams.append("location", location.id);
        }

        if (bath.id !== undefined) {
            searchParams.append("bath", bath.id);
        }

        if (breakfast.id !== undefined) {
            searchParams.append("breakfast", breakfast.id);
        }

        if (smoking.id !== undefined) {
            searchParams.append("smoking", smoking.id);
        }
    }

    if (adults !== 0) {
        searchParams.append("adults", adults.toString());
    }

    if (children !== 0) {
        searchParams.append("children", children.toString());
    }

    if(note) {
        searchParams.append("remarks", note);
    }

    if(useDepartArea) {
        searchParams.append("departArea", useDepartArea);
    }

    if(useDestArea) {
        searchParams.append("destArea", useDestArea);
    }

    useEffect(() => {
        if (!arrival || arrival.region_id === "unselected" || arrival.region_id === "others" || arrival.prior === 99) {
            return;
        }

        const getData = async () => {
            try {
                const response = await fetch(`${API_URL}/hotelareas?` + new URLSearchParams({ region_id: arrival.region_id }),
                    {
                        method: "GET" 
                    });

                if (!response.ok) {
                    throw new Error(
                        `This is an HTTP error: The status is ${response.status}`
                    );
                }

                let actualData = await response.json();
                let areas = actualData.hotelarea;

                setAreas(areas);
                setArea(areas[0]);
                setError(undefined);
            } catch (err) {
                setError(getErrorMessage(err));
                setAreas([]);
            } finally {
                setLoading(false);
            }
        };

        getData();
    }, [arrival]);

    let validate = (): string | null => {
        if (startDate === endDate && schedule.id !== 'day') {
            return "行きと帰りは異なる日付を選択してください。";
        }

        if (departure.prior === 0) {
            return "出発地を選択してください";
        }

        if (!othersOrigin && departure.prior === 99 && departure.label === 'その他') {
            return "出発：希望の出発地を入力してください";
        }

        if (arrival.prior === 0) {
            return "到着地を選択してください";
        }

        if (!othersDestination && arrival.prior === 99 && arrival.label === 'その他') {
            return "到着：希望の到着地を入力してください";
        }

        if(departure.prior ===  arrival.prior) {
            if(departure.prior === 99) {
                if(departure.label === arrival.label) {
                    if(departure.label === 'その他') {
                        if(othersOrigin === othersDestination) {
                            return "出発地と到着地は異なるエリアを指定してください。";
                        }
                    } else {
                        return "出発地と到着地は異なるエリアを指定してください。";
                    }
                }
            } else {
                return "出発地と到着地は異なるエリアを指定してください。";
            }
        }

        if (!transport.id) {
            return "移動手段を選んでください";
        }

        if (!depTimeOrigin.id) {
            return "希望の往路出発時間を選んでください";
        }

        if (!depTimeDestination.id) {
            return "希望の復路出発時間を選んでください";
        }

        if (!rank.hotelclass_id && arrival.prior !== 99) {
            return "宿泊：ランクを選んでください";
        }

        if (!location.id) {
            return "宿泊：位置を選んでください";
        }

        if (!bath.id) {
            return "宿泊：大浴場を選んでください";
        }

        if (!breakfast.id) {
            return "宿泊：朝食を選んでください";
        }

        if (!smoking.id) {
            return "宿泊：たばこを選んでください";
        }

        return null;
    };

    let [error, setError] = useState<string>();

    let handleSubmit = () => {
        let error = validate();
        
        if (error) {
            setError(error);
        } else {
            let free = true;
            
            //if(departure.prior !== 99 && arrival.prior !== 99 && transport.id === "air") {
            //    free = false;
            //}

            onSubmit(searchParams, free);
        }
    };

    const initParams = () => {
        setSchedule('');

        setStartDate(defaultStartDate);
        setEndDate(defaultEndDate);

        setCountAdults(1);
        setCountChildren(0);

        setDepTimeOrigin('');
        setDepTimeDestination('');

        setUseDepartRegions(setRegions('default'));
        setUseDestRegions(setRegions('default'));

        setDeparture(useDepartRegions[0]);
        setArrival(useDestRegions[0]);

        setArea(useDestRegions[0]);

        setRank(ranks[1]);

        setTransport('');
        setLocation(locations[2]);
        setBath(bathChoice[1]);
        setBreakfast(breakfastChoice[1]);
        setSmoking(smokingChoice[0]);

        setInitFlag(false);
    }

    return (
        <>
            <div className="space-y-5 px-2 md:px-0">
                <div className="steps">
                    <div className="stepNumber">
                        <div>
                            <span>STEP</span>1
                        </div>
                        <div>予定</div>
                    </div>

                    <div className="stepSelect">
                        <div className="flex flex-col">
                            <div className="selectArea">
                                <SelectButton
                                    value={schedule}
                                    onChange={setSchedule}
                                    items={schedules}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="steps">
                    <div className="stepNumber">
                        <div>
                            <span>STEP</span>2
                        </div>
                        <div>日程</div>
                    </div>

            
                    <div className="stepSelect">
                        {schedule.id !== "day" ? (
                        <div className="selectArea">
                            <div className="selectDates">
                                <div className="selectLabel">行き</div>
                                <div className="selectDate">
                                    <DateSelect
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date!)}
                                        startDate={startDate}
                                        endDate={endDate}
                                        popperClassName="react-datepicker-left"
                                    />
                                </div>
                            </div>

                            <img
                                src={arrowGray}
                                className="mx-4 mt-4 md:mt-0 w-6 h-auto rotate-90 md:rotate-0"
                            />

                            <div className="selectDates">
                                <div className="selectLabel">帰り</div>
                                <div className="selectDate">
                                    <DateSelect
                                        selected={endDate}
                                        onChange={(date) => setEndDate(date!)}
                                        startDate={startDate}
                                        endDate={endDate}
                                        popperClassName="react-datepicker-right"
                                    />
                                </div>
                            </div>
                        </div>

                        ) : (
                        <div className="selectArea">
                            <div className="selectDates">
                                <div className="selectDate">
                                    <DateSelect
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date!)}
                                        startDate={startDate}
                                        endDate={endDate}
                                        popperClassName="react-datepicker-left"
                                    />
                                </div>
                            </div>
                        </div>
                        )}

                        {schedule.id === "stay" && (
                        <div className="transportArea">
                            <div className="stepSelectText pb-2.5 py-2.5">
                                泊数
                                {getDiffDate(startDate, endDate)}泊
                                {getDiffDate(startDate, endDate) + 1}日
                            </div>
                        </div>
                        )}

                        {schedule.id === "stay" && (
                        <div className="transportArea">
                            <div className="stepSelectText text-cyan-600 pb-2">
                                帰着日延長などのご要望は、次の画面の「備考」で承ります
                            </div>
                        </div>
                        )}
                    </div>
                </div>


                <div className="steps">
                    <div className="stepNumber">
                        <div>
                            <span>STEP</span>3
                        </div>
                        <div>人数</div>
                    </div>

            
                    <div className="stepSelect">
                        <div className="flex flex-col">
                            <div className="selectArea">
                                <div className="selectLabel">大人</div>
                                <NumberSelect
                                    value={adults}
                                    maxNum={6}
                                    minNum={1}
                                    onChange={(e) => setCountAdults(e.target.value)}/>

                                <div className="selectLabel">小人</div>
                                <NumberSelect
                                    value={children}
                                    maxNum={6}
                                    minNum={0}
                                    onChange={(e) => setCountChildren(e.target.value)}/>
                                
                            </div>
                        </div>
                    </div>
                </div>


                <div className="steps">
                    <div className="stepNumber">
                        <div>
                            <span>STEP</span>4
                        </div>
                        <div>場所</div>
                    </div>

                    <div className="stepSelect">
                        <div className="flex flex-col">
                            <div className="selectArea">
                                <div className="selectLabel" style={{ width: `52px` }}>
                                    出発
                                </div>
                                <Selectbox
                                    options={useDepartRegions}
                                    value={departure}
                                    onChange={setDeparture}
                                    key={useDepartRegions}
                                />
                            </div>

                            {(departure.prior === 99 && departure.region_id == "others" && departure.label === 'その他') && (
                            <div className="transportArea md:ml-[60px]">
                                <label
                                    htmlFor="origin"
                                    className="stepSelectText text-cyan-600 pb-2"
                                >
                                    希望の出発地を入力してください
                                </label>
                                <input
                                    id="othersOrigin"
                                    name="origin"
                                    type="text"
                                    value={othersOrigin}
                                    onChange={(e) => setOthersOrigin(e.target.value)}
                                    placeholder="駅名、地名など"
                                    className="w-full md:w-[750px] px-3 py-2 border-2 border-gray placeholder-gray-500 rounded-lg focus:outline-none focus:ring-slate-700 focus:border-slate-700"
                                />
                            </div>
                            )}

                            <GrayDottedLine />

                            <div className="selectArea">
                                <div className="selectLabel" style={{ width: `52px` }}>
                                    到着
                                </div>
                                <Selectbox
                                    options={useDestRegions}
                                    value={arrival}
                                    onChange={setArrival}
                                    key={useDestRegions}
                                />
                            </div>

                            {(arrival.prior === 99 && arrival.region_id == "others" && arrival.label === 'その他') && (
                            <div className="transportArea md:ml-[60px]">
                                <label
                                    htmlFor="destination"
                                    className="stepSelectText text-cyan-600 pb-2"
                                >
                                    希望の到着地を入力してください
                                </label>
                                <input
                                    id="othersDestination"
                                    name="destination"
                                    type="text"
                                    value={othersDestination}
                                    onChange={(e) => setOthersDestination(e.target.value)}
                                    placeholder="駅名、地名など"
                                    className=" w-full md:w-[750px] px-3 py-2 border-2 border-gray placeholder-gray-500 rounded-lg focus:outline-none focus:ring-slate-700 focus:border-slate-700"
                                />
                            </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="steps">
                    <div className="stepNumber">
                        <div>
                            <span>STEP</span>5
                        </div>
                        <div>移動</div>
                    </div>
                    <div className="stepSelect">
                        <div className="md:ml-[55px]">
                            <SelectButton
                                value={transport}
                                onChange={setTransport}
                                items={transports}
                                schedule={schedule.id}
                            />
                        </div>

                        {(schedule && transport) && (
                        <div className="transportArea">
                            <div className="stepSelectText text-cyan-600 py-2.5 md:ml-[60px]">
                                希望の出発時間を選んでください
                            </div>
                            
                            <div className="selectArea">
                                <div className="selectLabel">往路</div>
                                <DepartureTime
                                    value={depTimeOrigin}
                                    onChange={setDepTimeOrigin}
                                    schedule={schedule.id}
                                    transport={transport}
                                    roundTrip={"outward"}/>
                            </div>

                            <div className="selectArea mt-2.5">
                                <div className="selectLabel">復路</div>
                                <DepartureTime
                                    value={depTimeDestination}
                                    onChange={setDepTimeDestination}
                                    schedule={schedule.id}
                                    transport={transport}
                                    roundTrip={"return"}/>
                            </div>
                        </div>
                        )}
                    </div>
                </div>

                { schedule.id !== 'day' && (
                <div className="steps">
                    <div className="stepNumber">
                        <div>
                            <span>STEP</span>6
                        </div>
                        <div>宿泊</div>
                    </div>

                    <div className="stepSelect">
                        <div className="flex flex-col">
                            {arrival.prior === 0 ? (
                            <div className="stepSelectText pb-2.5">
                                「場所」を選ぶと、宿泊施設のグレードを選べます
                            </div>
                            ) : (
                            <div className="stepSelectText text-cyan-600 pb-2.5">
                                宿泊施設のランクを選んでください
                            </div>
                            )}

                            <div className="selectArea">
                                {arrival.prior === 0 ? (
                                <div className="selectLabel text-lGray">ランク</div>
                                ) : (
                                <div className="selectLabel">ランク</div>
                                )}

                                <RankSelectbox
                                    value={rank}
                                    ranks={
                                        area.region_id !== "unselected" ||
                                        (area.region_id !== "unselected" && arrival.prior === 99)
                                        ? area.hotelclass
                                        : ranks
                                    }
                                    onChange={(e) => {
                                        setRank(e);
                                    }}
                                    arrival={arrival}
                                    disabled={
                                        arrival.prior !== 99 && area.region_id === "unselected"
                                    }
                                />
                            </div>

                            <GrayDottedLine />

                            <div className="selectArea">
                                {arrival.prior === 0 ? (
                                <div className="selectLabel text-lGray">位置</div>
                                ) : (
                                <div className="selectLabel">位置</div>
                                )}
                                <SelectButton
                                    value={location}
                                    onChange={setLocation}
                                    disabled={
                                        arrival.prior !== 99 && area.region_id === "unselected"
                                    }
                                    items={locations}
                                />
                            </div>

                            <GrayDottedLine />

                            <div className="selectArea">
                                {arrival.prior === 0 ? (
                                <div className="selectLabel text-lGray">大浴場</div>
                                ) : (
                                <div className="selectLabel">大浴場</div>
                                )}
                                <SelectButton
                                    value={bath}
                                    onChange={setBath}
                                    disabled={
                                        arrival.prior !== 99 && area.region_id === "unselected"
                                    }
                                    items={bathChoice}
                                />
                            </div>

                            <GrayDottedLine />

                            <div className="selectArea">
                                {arrival.prior === 0 ? (
                                <div className="selectLabel text-lGray">朝食</div>
                                ) : (
                                <div className="selectLabel">朝食</div>
                                )}
                                <SelectButton
                                    value={breakfast}
                                    onChange={setBreakfast}
                                    disabled={
                                        arrival.prior !== 6 && area.region_id === "unselected"
                                    }
                                    items={breakfastChoice}
                                />
                            </div>

                            <GrayDottedLine />

                            <div className="selectArea">
                                {arrival.prior === 0 ? (
                                <div className="selectLabel text-lGray">たばこ</div>
                                ) : (
                                <div className="selectLabel">たばこ</div>
                                )}
                                <SelectButton
                                    value={smoking}
                                    onChange={setSmoking}
                                    disabled={
                                        arrival.prior !== 99 && area.region_id === "unselected"
                                    }
                                    items={smokingChoice}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                )}

                { schedule.id !== 'day' && (
                <div className="steps">
                    <div className="stepNumber">
                        <div>
                            <span>STEP</span>7
                        </div>
                        <div>夕食</div>
                    </div>
                    <div className="stepSelect">
                        <div className="flex flex-col">
                            <div className="stepSelectText pb-2.5">
                                旅行のプロに地元グルメの相談ができます!
                            </div>

                            <OptionMeal />
                        </div>
                    </div>
                </div>
                )}
            </div>

            <div className="mt-10 px-3">
                {error && (
                <div className="mb-2 text-sm md:text-base text-red-600 text-center">
                    {error}
                </div>
                )}

                <button onClick={handleSubmit} className="btn-enter mx-auto">
                    この内容で作成
                </button>

                <Scroll to="search" smooth={true}>
                    <button onClick={() => setInitFlag(true)} className="btn-enter mx-auto btn-allclear">
                        <p>選択内容をクリアして</p>
                        <p>他の区間で検索</p>
                    </button>
                </Scroll>
            </div>
        </>
    );
}

interface Item {
  prior: number;
  region_id: string;
  label: string;
}

function Selectbox({
  value,
  options,
  onChange,
}: {
  value: Item;
  options: Item[];
  onChange: (value: Item) => void;
}) {
  return (
    <>
      <RadioGroup value={value} onChange={onChange}>
        <RadioGroup.Label className="sr-only">
          origin and destination
        </RadioGroup.Label>
        <div className="areasOption">
          {options.map((area) => (
            <div key={area.label}>
              {area.prior !== 0 && (
                <RadioGroup.Option
                  value={area}
                  className={({ checked }) => `${checked ? "bg-orange-light border-dGray" : "bg-white border-gray"} selectBtn mb-2.5`}
                >
                  <div className="font-medium">{area.label}</div>
                </RadioGroup.Option>
              )}
            </div>
          ))}
        </div>
      </RadioGroup>
    </>
  );
}

function AreaSelectbox({ areas }: { areas: HotelArea[] }) {
  return (
    <>
      {areas.map((item) => (
        <div>{item.label}</div>
      ))}
    </>
  );
}

function RankSelectbox({
  value,
  ranks,
  onChange,
  arrival,
  disabled,
}: {
  value: HotelClass;
  ranks: HotelClass[];
  onChange: (value: HotelClass) => void;
  arrival: any;
  disabled?: boolean;
}) {
  return (
    <>
      <RadioGroup value={value} onChange={onChange} disabled={disabled}>
        <RadioGroup.Label className="sr-only">hotel rank</RadioGroup.Label>
        <div className="areasOption">
          {arrival.prior === 4 && (
            <div className="selectBtn bg-lGray border-gray text-lGray">
              <div className="flex flex-col items-center">
                <div className="flex flex-row justify-center">
                  <StarIcon
                    className="rankStar text-lGray"
                    aria-hidden="true"
                  />
                  <StarIcon
                    className="rankStar text-lGray"
                    aria-hidden="true"
                  />
                </div>
                <div className="font-medium">エコノミー</div>
              </div>
            </div>
          )}
          {ranks.map((hotel) => (
            <div key={hotel.label}>
              <RadioGroup.Option
                value={hotel}
                disabled={disabled}
                className={({ checked, disabled }) =>
                  `${disabled ? "bg-lGray border-gray text-lGray" : checked ? "bg-orange-light border-dGray" : "bg-white border-gray"} selectBtn`
                }>
                {({ disabled }) => (
                  <div className="flex flex-col items-center">
                    <div className="flex flex-row justify-center">
                      {hotel.label == "エコノミー" ? (
                        <>
                          <StarIcon
                            className={disabled ? "rankStar text-lGray" : "rankStar text-orange"}
                            aria-hidden="true"/>
                          <StarIcon
                            className={disabled ? "rankStar text-lGray" : "rankStar text-orange"}
                            aria-hidden="true"/>
                        </>
                      ) : hotel.label == "スタンダード" ? (
                        <>
                          <StarIcon
                            className={
                              disabled
                                ? "rankStar text-lGray"
                                : "rankStar text-orange"
                            }
                            aria-hidden="true"
                          />
                          <StarIcon
                            className={
                              disabled
                                ? "rankStar text-lGray"
                                : "rankStar text-orange"
                            }
                            aria-hidden="true"
                          />
                          <StarIcon
                            className={
                              disabled
                                ? "rankStar text-lGray"
                                : "rankStar text-orange"
                            }
                            aria-hidden="true"
                          />
                        </>
                      ) : hotel.label == "スーペリア" ? (
                        <>
                          <StarIcon
                            className={
                              disabled
                                ? "rankStar text-lGray"
                                : "rankStar text-orange"
                            }
                            aria-hidden="true"
                          />
                          <StarIcon
                            className={
                              disabled
                                ? "rankStar text-lGray"
                                : "rankStar text-orange"
                            }
                            aria-hidden="true"
                          />
                          <StarIcon
                            className={
                              disabled
                                ? "rankStar text-lGray"
                                : "rankStar text-orange"
                            }
                            aria-hidden="true"
                          />
                          <StarIcon
                            className={
                              disabled
                                ? "rankStar text-lGray"
                                : "rankStar text-orange"
                            }
                            aria-hidden="true"
                          />
                        </>
                      ) : (
                        <>
                          <StarIcon
                            className={
                              disabled
                                ? "rankStar text-lGray"
                                : "rankStar text-orange"
                            }
                            aria-hidden="true"
                          />
                          <StarIcon
                            className={
                              disabled
                                ? "rankStar text-lGray"
                                : "rankStar text-orange"
                            }
                            aria-hidden="true"
                          />
                          <StarIcon
                            className={
                              disabled
                                ? "rankStar text-lGray"
                                : "rankStar text-orange"
                            }
                            aria-hidden="true"
                          />
                          <StarIcon
                            className={
                              disabled
                                ? "rankStar text-lGray"
                                : "rankStar text-orange"
                            }
                            aria-hidden="true"
                          />
                          <StarIcon
                            className={
                              disabled
                                ? "rankStar text-lGray"
                                : "rankStar text-orange"
                            }
                            aria-hidden="true"
                          />
                        </>
                      )}
                    </div>
                    <div className="font-medium">{hotel.label}</div>
                  </div>
                )}
              </RadioGroup.Option>
            </div>
          ))}
        </div>
      </RadioGroup>
    </>
  );
}

function NumberSelect({
    value,
    maxNum,
    minNum,
    onChange
}: {
    value: number;
    maxNum: number;
    minNum: number;
    onChange: (value: any) => void;
}) {
    const list = [];

    for(let i = minNum; i <= maxNum; i++) {
        list.push(i);
    }

    return (
        <>
            <select className="selectNop inputBtnText" value={value} onChange={onChange}>
                {list.map(n => <option value={n} key={n}>{n}名</option> )}
            </select>
        </>
    );
}

function DateSelect({
  selected,
  onChange,
  startDate,
  endDate,
  popperClassName,
}: {
  selected: any;
  onChange: (value: any) => void;
  startDate: any;
  endDate: any;
  popperClassName: string;
}) {
  let dateStart = new Date();
  dateStart.setDate(dateStart.getDate() + 3);
  return (
    <>
      <DatePicker
        minDate={dateStart}
        locale="ja"
        selected={selected}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        nextMonthButtonLabel=">"
        previousMonthButtonLabel="<"
        popperClassName={popperClassName}
        customInput={<ButtonInput />}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div className="flex items-center justify-between px-2 py-2">
            <span className="text-lg text-color">
              {format(date, "yyyy/MM")}
            </span>
            <div className="space-x-2">
              <button
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
                type="button"
                className={`
                    ${
                      prevMonthButtonDisabled && "cursor-not-allowed opacity-50"
                    } calChangeMonth
                  `}
              >
                <ChevronLeftIcon className="w-5 h-5 text-gray-600" />
              </button>
              <button
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
                type="button"
                className={`
                    ${
                      nextMonthButtonDisabled && "cursor-not-allowed opacity-50"
                    } calChangeMonth
                  `}
              >
                <ChevronRightIcon className="w-5 h-5 text-gray-600" />
              </button>
            </div>
          </div>
        )}
      />
    </>
  );
}

// type ButtonProps = JSX.IntrinsicElements["button"];
const ButtonInput = forwardRef<HTMLButtonElement, any>(
  ({ value, onClick }, ref) => (
    <button onClick={onClick} ref={ref} type="button" className="inputBtnText">
      <span>{format(new Date(value), "yyyy年MM月dd日")}</span>
      <img src={calendar} className="w-5 h-5" />
    </button>
  )
);

function Counter({
  value,
  label,
  onChange,
}: {
  value: number;
  label: string;
  onChange: (value: any) => void;
}) {
  return (
    <div className="flex flex-row justify-around">
      <div className="w-44 md:w-32 font-semibold flex items-center">
        {label}
      </div>
      <div className="w-full md:w-4/6 flex justify-end">
        <div className="flex flex-row justify-between items-center w-40 py-1 px-1 bg-white border-2 border-gray rounded-lg cursor-default focus:outline-none focus:ring-1 sm:text-sm">
          <button onClick={() => onChange(Math.max(value - 1, 0))}>
            <MinusSmIcon
              className={`w-8 h-8 bg-dGray text-white rounded-lg p-2 ${
                value <= 0 && "bg-lGray cursor-not-allowed"
              }`}
            />
          </button>
          <div>{value}</div>
          <button onClick={() => onChange(value + 1)}>
            <PlusSmIcon className="w-8 h-8 bg-dGray text-white rounded-lg p-2" />
          </button>
        </div>
      </div>
    </div>
  );
}

function OptionMeal() {
  const [plan, setPlan] = useState("noadvice");

  return (
    <RadioGroup value={plan} onChange={setPlan}>
      <RadioGroup.Label className="sr-only">Option Meal</RadioGroup.Label>
      <div className="space-x-4 md:space-x-2 flex flex-row md:ml-[60px]">
        {dinnerChoice.map((item) => (
          <RadioGroup.Option
            key={item.id}
            value={item.id}
            className={({ checked }) =>
              `${
                checked
                  ? "bg-orange-light border-dGray"
                  : "bg-white border-gray"
              }
              selectBtn`
            }
          >
            <div className="font-medium text-sm md:text-base py-2 w-40 text-center">
              {item.label}
            </div>
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}
