import style from "../../css/info.module.css";

export default function Terms() {
  return (
    <div className="w-full max-w-screen-lg mx-auto my-4 md:my-8 px-2">
      <h2 className={style.pageTitle}>利用規約</h2>
      <p className={style.content}>
        「ジョルダントラベル」をご利用されるにあたっての利用規約です。
      </p>
      <p className={style.content}>
        「ジョルダン株式会社(以下「弊社」)」は、弊社の運営する「旅行予約・申込サービス」(以下「当サービス」)のサービスをお客様にご利用いただく際、次の「利用規約」(以下「本規約」)を設けております。本規約にご同意の上、当サービスのご利用をお願い致します。
      </p>
      <h3 className={style.title}>第１条：基本的事項の遵守</h3>
      <p className={style.content}>
        当サービスのご利用に際し、お客様にはインターネット利用の一般的なマナーやモラル、及び技術的ルールを遵守していただきます。
      </p>
      <h3 className={style.title}>第２条：基本的事項に反する場合の措置</h3>
      <p className={style.content}>
        第三者に迷惑・不利益を与える等の行為、申込み情報を偽る行為、弊社のサービスに支障をきたす恐れのある行為、また本規約に著しく反するなど、弊社が不適当と判断する行為を行うお客様には、当サービスのご利用をお断りする場合があります。
      </p>
      <h3 className={style.title}>第３条：当サービスのご利用条件</h3>
      <p className={style.content}>
        当サービスを利用して、旅行商品のご予約・お申込みができるのは、本規約に同意したお客様のみです。
      </p>
      <h3 className={style.title}>第４条：サービスについて</h3>
      <p className={style.content}>
        当サービス上で弊社が提供、もしくは紹介する商品のお客様のお申込みは当サービスホームページ上で行うことができます。
      </p>
      <h3 className={style.title}>第５条：サービス利用上の注意点</h3>
      <p className={style.content}>
        当サービスが提供する商品の予約・申込みにおいて、座席、宿泊施設、およびその他商品等は、満席、満室など、その状況によって予約またはお申込みができない場合があります。
      </p>
      <h3 className={style.title}>第６条：お客様の自己責任</h3>
      <p className={style.content}>
        お客様が当サービスを利用するにあたり、自ら行った行為、及び自己の申込み情報等によりなされた一切の行為及びそれらの結果について、自らの行為の有無、過失の有無を問わずその責任を負担するものとします。
      </p>
      <p className={style.content}>
        また、当サービスの利用にあたり第三者に損害を与えた場合、お客様は自己の責任と負担において当該第三者との紛争を解決するものとします。お客様が本規約に違反して弊社に損害を与えた場合、弊社は当該のお客様に対して被った全ての損害の賠償を請求することができるものとします。
      </p>
      <h3 className={style.title}>
        第７条：予約・お申込み内容の取り消し・変更
      </h3>
      <p className={style.content}>
        弊社は、当サービスを利用してお申込みされたお客様からの予約・申込み内容の取り消し・変更手続きはお客様からの弊社に対する電子メール、または電話連絡により行うものとします。
      </p>
      <h3 className={style.title}>
        第８条：当サービスお申込みに際し必要なもの
      </h3>
      <p className={style.content}>
        当サービスを利用しての商品の予約・申込みを行うためには、インターネットメールを受信可能な電子メールアドレスを保有していることが条件となります。{" "}
      </p>
      <h3 className={style.title}>第９条：お申込みに際しての入力情報</h3>
      <p className={style.content}>
        当サービスを利用しての商品の予約・申込みを行う際には、個人データの入力を正確に行ってください。また、予約・申込みは、利用者本人が行うものとし代理登録は一切認めないものとします。予約・申込みの際には、下記１から５にご留意ください。
      </p>
      <ul className={style.list}>
        <li>
          氏名、フリガナ：お客様の本名とします。ニックネーム等、本人確認ができないものは一切認めないものとします。
        </li>
        <li>
          郵便番号、住所：お客様本人が現在お住まいの自宅住所を登録してください。勤務先、学校、私書箱等、前記以外でのお申込みはお断りいたします。
        </li>
        <li>
          電話番号、携帯番号：お客様が現在お住まいの自宅住所の電話番号、及び携帯電話番号を記入してください。勤務先、学校、コールセンター等、上記以外でのお申込みはお断りいたします。
        </li>
        <li>年齢、性別：お客様本人の年齢、及び性別とします。</li>
        <li>
          メールアドレス：お客様ご本人の保有する電子メールアドレスを入力してください。
        </li>
      </ul>
      <h3 className={style.title}>第10条：お申込み内容の開示</h3>
      <p className={style.content}>
        １.
        弊社は、当サービスでお申込みされたお客様のデータを本人の同意を得ずに第三者に開示することはありません。但し、以下の場合は本人の同意なく申込み内容を開示することがあります。
      </p>
      <span className={style.smList}>
        ａ.
        お申込者が第三者に不利益を及ぼすと判断される場合、弊社はお申込み情報を当該第三者や警察、または関係諸機関に通知することができます。
      </span>
      <br />
      <span className={style.smList}>
        ｂ.
        裁判所またはこれに準じた権限を有する機関から、お申込み情報についての開示を求められた場合、情報を開示することができます。
      </span>
      <p className={style.content}>
        ２.
        弊社は、お客様の利便性の向上を目的として第三者に対しお申込み情報を引き渡す旨を、当サービスホームページまたは電子メールにてお申込みされたお客様に同意を得た上で、お申込み情報を第三者に開示する場合があります。但し、第三者に引き渡されたお申込み情報に基づきお客様に提供、及び販売されるサービスは、この提供及び販売を行う第三者の示す条件によります。
      </p>
      <h3 className={style.title}>第11条：提供サービス・情報の条件</h3>
      <p className={style.content}>
        当サービスで、当サービス以外の著作者によって行われる情報提供の内容に関しては、著作権者の明示のある場合はその著作権者が示す情報提供の条件によります。
      </p>
      <h3 className={style.title}>第12条：当サービスのシステムの変更</h3>
      <p className={style.content}>
        弊社は、当サービスに関するシステムや内容の変更が必要であると判断した場合には、事前に通知することなく必要な変更を行うことができるものとします。
      </p>
      <h3 className={style.title}>第13条：当サービスの停止</h3>
      <p className={style.content}>
        弊社は、システムの保守を行う場合や運営上一時的に中止する必要があると判断した場合には、事前に通知することなく当サービスを中止することできるものとします。
      </p>
      <h3 className={style.title}>第14条：利用規約の変更</h3>
      <p className={style.content}>
        弊社は、事前の通知をすることなく本規約を変更することがあります。内容変更後は変更後の内容のみを有効とします。
      </p>
      <h3 className={style.title}>第15条：利用規約の発効</h3>
      <p className={style.content}>
        本規約は、日本標準時2003年8月1日より有効とします。
      </p>
      <h3 className={style.title}>第16条：当社の指示</h3>
      <p className={style.content}>
        お客様は旅行開始後旅行終了までの間、旅行参加者として行動していただくときは自由行動時間中を除き、旅行を安全かつ円滑に実施するための当社らの指示に従っていただきます。
      </p>
    </div>
  );
}
