import { RadioGroup } from "@headlessui/react";
import { RoomItem } from "../types";

export function SelectButton({
  value,
  onChange,
  disabled,
  items,
  schedule,
}: {
  value: RoomItem;
  onChange: (value: RoomItem) => void;
  disabled?: boolean;
  items: RoomItem[];
  schedule?: any;
}) {

  return (
    <>
      <RadioGroup value={value} onChange={onChange} disabled={disabled}>
        <RadioGroup.Label className="sr-only">Locations</RadioGroup.Label>
        <div className="flex flex-row flex-wrap md:flex-nowrap justify-start md:space-x-2">
          {items.map((item) => (
            <RadioGroup.Option
              key={item.id}
              value={item}
              className={({ checked, disabled }) =>
                `${
                  disabled
                    ? "bg-lGray border-gray text-lGray"
                    : checked
                    ? "bg-orange-light border-dGray"
                    : "bg-white border-gray"
                }
                  selectBtn`
              }
              disabled={item.id === 'notransports' && schedule && schedule === 'day'}
            >
              <div className="font-medium text-base py-2 w-full text-center">
                {item.label}
              </div>
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </>
  );
}
