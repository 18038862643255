import { ArrowNarrowRightIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <>
      <div className="flex items-center justify-center flex-1 text-color">
        <div className="max-w-xl w-full space-y-8 m-2">
          <div className="text-center">
            <div className="font-bold">
              <div className="text-cyan-600 text-sm">404 ERROR</div>
              <div className="text-5xl pt-3 pb-2">Page not found.</div>
            </div>
            <div className="text-slate-500 text-base mb-8">
              <div>お探しのページは見つかりませんでした。</div>
              <div className="mt-4">
                あなたがアクセスしようとしたページは削除・変更されたか、
                <br className="hidden md:flex" />
                URLが変更されているため、見つかることができませんでした。
              </div>
            </div>

            <div className="flex justify-center">
              <Link
                to="/"
                className="w-28 text-cyan-600 hover:text-cyan-500 font-bold flex flex-row items-center justify-center"
              >
                TOPへ戻る
                <ArrowNarrowRightIcon className="ml-1 w-4 h-4" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
