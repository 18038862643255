export function GrayDottedLine() {
  return (
    <div className="py-2.5">
      <div className="border-t-2 border-gray-200 border-dotted" />
    </div>
  );
}

export function DarkLine() {
  return (
    <div className="py-4">
      <div className="border-t-2 border-dGray" />
    </div>
  );
}
