import { NavLink, Outlet, useParams } from "react-router-dom";
import style from "../../css/info.module.css";
import { KeiyakuBosyu } from "../../components/condition/KeiyakuBosyu";
import { KeiyakuOrder } from "../../components/condition/KeiyakuOrder";
import { HosyouKitei } from "../../components/condition/HosyouKitei";
import { KeiyakuTehai } from "../../components/condition/KeiyakuTehai";
import { KeiyakuTokou } from "../../components/condition/KeiyakuTokou";
import { KeiyakuSoudan } from "../../components/condition/KeiyakuSoudan";
import { JoukenBosyu } from "../../components/condition/JoukenBosyu";
import { JoukenTehai } from "../../components/condition/JoukenTehai";
import { JoukenKaigaibosyu } from "../../components/condition/JoukenKaigaibosyu";
import { JoukenKaigaiseiki } from "../../components/condition/JoukenKaigaiseiki";
import { JoukenKaigaiwaribiki } from "../../components/condition/JoukenKaigaiwaribiki";

const conditions = [
  {
    id: "keiyaku_bosyu",
    name: "募集型企画旅行契約",
    content: <KeiyakuBosyu />,
  },
  {
    id: "keiyaku_order",
    name: "受注型企画旅行契約",
    content: <KeiyakuOrder />,
  },
  {
    id: "hosyoukitei",
    name: "特別補償規程",
    content: <HosyouKitei />,
  },
  {
    id: "keiyaku_tehai",
    name: "手配旅行契約",
    content: <KeiyakuTehai />,
  },
  {
    id: "keiyaku_tokou",
    name: "渡航手続代行契約",
    content: <KeiyakuTokou />,
  },
  {
    id: "keiyaku_soudan",
    name: "旅行相談契約",
    content: <KeiyakuSoudan />,
  },
  {
    id: "jouken_bosyu",
    name: "国内募集型企画旅行条件書",
    content: <JoukenBosyu />,
  },
  {
    id: "jouken_tehai",
    name: "国内手配旅行条件書",
    content: <JoukenTehai />,
  },
  {
    id: "jouken_kaigaibosyu",
    name: "海外募集型企画旅行条件書",
    content: <JoukenKaigaibosyu />,
  },
  {
    id: "jouken_kaigaiseiki",
    name: "海外正規航空券条件書",
    content: <JoukenKaigaiseiki />,
  },
  {
    id: "jouken_kaigaiwaribiki",
    name: "海外正規割引航空券条件書",
    content: <JoukenKaigaiwaribiki />,
  },
];

export function getConditions() {
  return conditions;
}

export function getCondition(id: string) {
  return conditions.find((condition) => condition.id === id);
}

export function Conditions() {
  return (
    <div className="w-full max-w-screen-lg mx-auto my-0 md:my-8 md:px-2">
      <ConditionMenu />
      <Outlet />
    </div>
  );
}

function ConditionMenu() {
  return (
    <div className="w-full mx-auto flex flex-row flex-wrap md:justify-evenly text-sm">
      {conditions.map((condition) => (
        <NavLink
          key={condition.id}
          to={`/info/conditions/${condition.id}`}
          className={({ isActive }) =>
            isActive ? style.conditionMenuActive : style.conditionMenu
          }
        >
          {condition.name}
        </NavLink>
      ))}
    </div>
  );
}

export function Condition() {
  let params = useParams();
  let condition = getCondition(params.conditionId!)!;
  return <>{condition.content}</>;
}
