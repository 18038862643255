import { useState } from "react";
import { parseISO } from "date-fns";
import {
  Link,
  useLocation,
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import { Direction } from "../../components/Flight";
import Flight from "../../components/Flight";
import Hotel from "../../components/Hotel";
import Option from "../../components/Option";
import SelectSearchArea from "../../components/SelectSearchArea";

import Cart from "../../components/Cart";

import "../../css/common.css";
import "../../css/order.css";
import { regions, State } from "../../constants";

export default function Select() {
  const [state, setState] = useOutletContext() as any;
  const [expand, setExpand] = useState<State>(State.DEFAULT);
  const location = useLocation();
  const navigate = useNavigate();

  let [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams);

  let onSubmit = (searchParams: any) => {
    const params = Object.fromEntries(searchParams);
    let origin = regions.find(
      (origin: any) => origin.region_id == params.origin!
    )!;
    let destination = regions.find(
      (destination: any) => destination.region_id == params.destination!
    )!;
    let hotelArea = params.hotelArea ? params.hotelArea : undefined;
    let startDate = parseISO(params.startDate);
    let endDate = parseISO(params.endDate);
    let adults = params.adults ? parseInt(params.adults) : 0;
    let children = params.children ? parseInt(params.children) : 0;

    let defaultOrder = {
      origin,
      destination,
      hotelArea,
      startDate,
      endDate,
      adults,
      children,
    };
    setState(defaultOrder);

    navigate(
      {
        pathname: location.pathname,
        search: searchParams.toString(),
      },
      { replace: true }
    );
  };

  return (
    <>
      {expand === State.DEFAULT ? (
        <div className="p-1 grow space-y-4 w-full">
          <div className="commentArea">
            <p>
              <span className="font-semibold">大人1名様ご利用時</span>の「
              <span className="font-semibold text-orange">最安値</span>
              フライト 」「
              <span className="font-semibold text-orange">主要なエリア</span>
              のホテル」を選択して、プランを作成しました！ 各項目の 「
              <span className="font-semibold">変更</span>
              」ボタンより、お好きなフライトや宿泊エリアを選択すると、プランのカスタマイズが可能です。
            </p>
          </div>

          <div className="cart">
            <div className="cartTitle">作成中の内容</div>
            <div className="cartContents">
              <Cart setExpand={setExpand} />
            </div>
          </div>

          {state.hotel?.room && (
            <div className="display flex justify-center py-8">
              <Link
                to={`/order/confirm${location.search}`}
                className="btn-enter"
              >
                内容確認へ進む
              </Link>
            </div>
          )}
        </div>
      ) : (
        <>
          <div className="commentArea">
            <p>
              ご希望の条件を選択してください。「この内容に変更する」ボタンを押すと、選択した内容が反映されます。
            </p>
          </div>

          <div className="pb-10 md:flex md:flex-row">
            <div className="hidden md:inline space-y-4 w-2/5">
              <div className="cart sticky top-0">
                <div className="cartTitle">作成中の内容</div>
                <div className="cartContents">
                  <Cart setExpand={setExpand} />
                </div>
              </div>
            </div>
            <div className="w-full md:w-3/5 md:ml-4">
              {expand === State.SCHEDULE && (
                <SelectSearchArea
                  query={params}
                  setExpand={setExpand}
                  onSubmit={onSubmit}
                />
              )}
              {expand === State.HOTEL && <Hotel setExpand={setExpand} />}
              {expand === State.DEPARTURE_FLIGHT && (
                <Flight setExpand={setExpand} direction={Direction.Departure} />
              )}
              {expand === State.RETURN_FLIGHT && (
                <Flight setExpand={setExpand} direction={Direction.Return} />
              )}
              {expand === State.OPTION && <Option setExpand={setExpand} />}
            </div>
          </div>
        </>
      )}
    </>
  );
}
