import { RadioGroup } from "@headlessui/react";
import { useOutletContext } from "react-router-dom";
import { Order, RoomItem } from "../types";
import { StarIcon } from "@heroicons/react/solid";
import {
  locations,
  bathChoice,
  breakfastChoice,
  smokingChoice,
  State,
  ranks,
} from "../constants";
import { SelectButton } from "./SelectButton";

export default function HotelFree({
  setExpand,
}: {
  setExpand: React.Dispatch<React.SetStateAction<State>>;
}) {
  const [state, setState] = useOutletContext() as any;

  let setFreeHotelArea = (hotelArea: string) => {
    setState((state: Order) => ({
      ...state,
      hotelArea,
    }));
  };

  let setFreeHotelClasss = (othersHotelClass: any) => {
    setState((state: Order) => ({
      ...state,
      othersHotelClass,
    }));
  };

  let setFreeLocation = (location: any) => {
    setState((state: Order) => ({
      ...state,
      location,
    }));
  };

  let setFreeBath = (bath: any) => {
    setState((state: Order) => ({
      ...state,
      bath,
    }));
  };

  let setFreeBreakfast = (breakfast: any) => {
    setState((state: Order) => ({
      ...state,
      breakfast,
    }));
  };

  let setFreeSmoking = (smoking: any) => {
    setState((state: Order) => ({
      ...state,
      smoking,
    }));
  };

  const isSelected =
    state.hotelArea &&
    state.location &&
    state.bath &&
    state.breakfast &&
    state.smoking;

  return (
    <>
      <div className="boxRight">
        <div className="boxRightTitle">宿泊施設の変更</div>

        <div className="mt-5 px-2 md:px-4">
          <div className="space-y-5">
            <div className="flex flex-col md:flex-row">
              <div className="boxRightItemName items-center">エリア</div>
              <div className="relative w-full md:w-11/12">
                <input
                  id="destinationArr"
                  name="destinationArr"
                  type="text"
                  value={state.hotelArea}
                  onChange={(e) => {
                    let i = e.target.value;
                    setFreeHotelArea(i);
                  }}
                  placeholder="最寄り駅名、地名など"
                  className="w-full px-3 py-2 border-2 border-gray placeholder-gray-500 rounded-lg focus:outline-none focus:ring-slate-700 focus:border-slate-700"
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row">
              <div className="boxRightItemName items-start">ランク</div>
              <div className="relative w-full md:w-11/12">
                <SelectHotelClassFree
                  value={state.othersHotelClass}
                  onChange={setFreeHotelClasss}
                  items={ranks}
                />
              </div>
            </div>

            <div className="flex flex-col md:flex-row">
              <div className="boxRightItemName items-center">位置</div>
              <div className="relative w-full md:w-11/12">
                <SelectButton
                  value={state.location}
                  onChange={setFreeLocation}
                  items={locations}
                />
              </div>
            </div>

            <div className="flex flex-col md:flex-row">
              <div className="boxRightItemName items-center">大浴場</div>
              <div className="relative w-full md:w-11/12">
                <SelectButton
                  value={state.bath}
                  onChange={setFreeBath}
                  items={bathChoice}
                />
              </div>
            </div>

            <div className="flex flex-col md:flex-row">
              <div className="boxRightItemName items-center">朝食</div>
              <div className="relative w-full md:w-11/12">
                <SelectButton
                  value={state.breakfast}
                  onChange={setFreeBreakfast}
                  items={breakfastChoice}
                />
              </div>
            </div>

            <div className="flex flex-col md:flex-row">
              <div className="boxRightItemName items-center">たばこ</div>
              <div className="relative w-full md:w-11/12">
                <SelectButton
                  value={state.smoking}
                  onChange={setFreeSmoking}
                  items={smokingChoice}
                />
              </div>
            </div>
          </div>
          {isSelected ? (
            <button
              onClick={() => setExpand(State.DEFAULT)}
              className="btn-enter mx-auto my-10"
            >
              この内容に変更する
            </button>
          ) : (
            <div className="mb-5" />
          )}
        </div>
      </div>
    </>
  );
}

function SelectHotelClassFree({
  value,
  onChange,
  items,
}: {
  value: RoomItem;
  onChange: (value: RoomItem) => void;
  items: RoomItem[];
}) {
  return (
    <>
      <RadioGroup value={value} onChange={onChange}>
        <RadioGroup.Label className="sr-only">hotel rank</RadioGroup.Label>
        <div className="areasOption">
          {items.map((item) => (
            <div key={item.label}>
              <RadioGroup.Option
                value={item}
                className={({ checked }) =>
                  `${
                    checked
                      ? "bg-orange-light border-dGray"
                      : "bg-white border-gray"
                  }
                  selectBtn`
                }
              >
                <div className="flex flex-col items-center">
                  <div className="flex flex-row justify-center">
                    {item.label == "エコノミー" ? (
                      <>
                        <StarIcon
                          className="rankStar text-orange"
                          aria-hidden="true"
                        />
                        <StarIcon
                          className="rankStar text-orange"
                          aria-hidden="true"
                        />
                      </>
                    ) : item.label == "スタンダード" ? (
                      <>
                        <StarIcon
                          className="rankStar text-orange"
                          aria-hidden="true"
                        />
                        <StarIcon
                          className="rankStar text-orange"
                          aria-hidden="true"
                        />
                        <StarIcon
                          className="rankStar text-orange"
                          aria-hidden="true"
                        />
                      </>
                    ) : item.label == "スーペリア" ? (
                      <>
                        <StarIcon
                          className="rankStar text-orange"
                          aria-hidden="true"
                        />
                        <StarIcon
                          className="rankStar text-orange"
                          aria-hidden="true"
                        />
                        <StarIcon
                          className="rankStar text-orange"
                          aria-hidden="true"
                        />
                        <StarIcon
                          className="rankStar text-orange"
                          aria-hidden="true"
                        />
                      </>
                    ) : (
                      <>
                        <StarIcon
                          className="rankStar text-orange"
                          aria-hidden="true"
                        />
                        <StarIcon
                          className="rankStar text-orange"
                          aria-hidden="true"
                        />
                        <StarIcon
                          className="rankStar text-orange"
                          aria-hidden="true"
                        />
                        <StarIcon
                          className="rankStar text-orange"
                          aria-hidden="true"
                        />
                        <StarIcon
                          className="rankStar text-orange"
                          aria-hidden="true"
                        />
                      </>
                    )}
                  </div>
                  <div className="font-medium">{item.label}</div>
                </div>
              </RadioGroup.Option>
            </div>
          ))}
        </div>
      </RadioGroup>
    </>
  );
}
