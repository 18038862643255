import { Outlet, useSearchParams } from "react-router-dom";
import { useLocalStorage } from "../App";
import { Flight, Order } from "../types";
import { parseISO } from "date-fns";
import { useEffect, useState } from "react";
import { getFlights, getHotels } from "../api";
import Spinner from "../components/Spinner";
import { minFareAdult } from "../utils";
import { StepsBar } from "../components/StepsBar";
import {
  locations,
  bathChoice,
  breakfastChoice,
  smokingChoice,
  regions,
  getErrorMessage,
  departureTimes,
  transports,
} from "../constants";
import Modal from "../components/Modal";

export default function OrderFlow() {
  let [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams);

  let schedule = params.schedule;
  let origin = regions.find(
    (origin: any) => origin.region_id == params.origin!
  )!;
  let destination = regions.find(
    (destination: any) => destination.region_id == params.destination!
  )!;
  let hotelArea = params.hotelArea!;
  let hotelClass = params.hotelClass!;
  let startDate = parseISO(params.startDate);
  let endDate = parseISO(params.endDate);
  let transport = transports.find(
    (transport: any) => transport.id == params.transports!
  );
  let adults = params.adults ? parseInt(params.adults) : 1;
  let children = params.children ? parseInt(params.children) : 0;
  let location = locations.find(
    (location: any) => location.id == params.location!
  );
  let bath = bathChoice.find((bath: any) => bath.id == params.bath!);
  let breakfast = breakfastChoice.find(
    (breakfast: any) => breakfast.id == params.breakfast!
  );
  let smoking = smokingChoice.find(
    (smoking: any) => smoking.id == params.smoking!
  );

  let depTimeOrigin = departureTimes.find(
    (depTime: any) => depTime.id == params.depTimeOrigin!
  );
  let depTimeDestination = departureTimes.find(
    (depTime: any) => depTime.id == params.depTimeDestination!
  );

  const [loadingHotel, setLoadingHotel] = useState(false);
  const [loadingFlights, setLoadingFlights] = useState(false);
  const [error, setError] = useState<string>();

  const remarks = params.remarks ?? '';

  let defaultOrder = {
    schedule,
    origin,
    destination,
    startDate,
    endDate,
    adults,
    children,
    transport,
    depTimeOrigin,
    depTimeDestination,
    remarks,
  };

  const [order, setOrder] = useLocalStorage<Order>("order", defaultOrder);

  let region = params.destination ?? "";
  let stayDate = params.startDate ?? "";

  useEffect(() => {
    const getData = async () => {
      try {
        let hotels = await getHotels(region, stayDate);

        let area = hotels.find(
          (hotel: any) => hotel.hotelarea_id == hotelArea
        )!;
        let hotelRank = area.hotelclass.find(
          (hc: any) => hc.hotelclass_id == hotelClass
        );

        let room = {
          location,
          bath,
          breakfast,
          smoking,
        };

        setOrder((state: Order) => ({
          ...state,
          hotel: { ...state.hotel, area, class: hotelRank, room },
        }));
        setError(undefined);
      } catch (err) {
        setError(getErrorMessage(err));
      } finally {
        setLoadingHotel(false);
      }
    };
    
    if (!order.hotel?.area) {
      setLoadingHotel(true);
      getData();
    }
  }, []);

  useEffect(() => {
    if (params.transports === "air") {
      const getData = async () => {
        try {
          let departingFlights = await getFlights(
            startDate,
            origin.region_id,
            destination.region_id,
            adults,
            children,
            0,
            depTimeOrigin?.since,
            depTimeOrigin?.until
          );

          let returnFlights = await getFlights(
            endDate,
            destination.region_id,
            origin.region_id,
            adults,
            children,
            0,
            depTimeDestination?.since,
            depTimeDestination?.until
          );
          let cheapestFlight = (flights: Flight[]): Flight => {
            return flights.reduce((prev, flight) =>
              minFareAdult(prev) < minFareAdult(flight) ? prev : flight
            );
          };

          let departureFlight = cheapestFlight(departingFlights);
          let returnFlight = cheapestFlight(returnFlights);

          let cheapestDepartingFare = minFareAdult(departureFlight);
          let cheapestReturnFare = minFareAdult(returnFlight);

          setOrder((state: Order) => ({
            ...state,
            departure: {
              flight: departureFlight.FlightInfo,
              fare: departureFlight.FareItem.find(
                (fare) => fare.TicketAdult == cheapestDepartingFare
              )!,
            },
            return: {
              flight: returnFlight.FlightInfo,
              fare: returnFlight.FareItem.find(
                (fare) => fare.TicketAdult == cheapestReturnFare
              )!,
            },
          }));
          setError(undefined);
        } catch (err) {
          setError(getErrorMessage(err));
        } finally {
          setLoadingFlights(false);
        }
      };
      if (!order.departure && !order.return) {
        setLoadingFlights(true);
        getData();
      }
    }
  }, []);

  return (
    <>
      <div className="w-full max-w-7xl mx-auto my-5 px-2 md:px-6">
        <StepsBar />

        {order && <Modal order={order} show={error !== undefined} />}

        {loadingHotel || loadingFlights ? (
          <Spinner message="旅行パッケージを作成中..." />
        ) : (
          <Outlet context={[order, setOrder]} />
        )}
      </div>
    </>
  );
}
