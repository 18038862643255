import style from "../../css/info.module.css";

export function KeiyakuSoudan() {
  return (
    <div className="w-full max-w-screen-lg mx-auto my-4 md:my-8 px-2">
      <h2 className={style.pageTitle}>[旅行業約款] 旅行相談契約</h2>

      <p className={`${style.content} ${style.red} ${style.mt4}`}>(適用範囲)</p>
      <p className={style.content}>
        <span className={style.boldmr2}>第一条</span>
        当社が旅行者との間で締結する旅行相談契約は、この約款の定めるところによります。この約款に定めのない事項については、法令又は一般に確立された慣習によります。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>２</span>
        当社が法令に反せず、かつ、旅行者に不利にならない範囲で書面により特約を結んだときは、前項の規定にかかわらず、その特約が優先します。
      </p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (旅行相談契約の定義)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第二条</span>
        この約款で「旅行相談契約」とは、当社が相談に対する旅行業務取扱料金(以下「相談料金」といいます。)を収受することを約して、旅行者の委託により、次に掲げる業務を行うことを引き受ける契約をいいます。
      </p>
      <p className={style.contentml2}>
        一 旅行者が旅行の計画を作成するために必要な助言
      </p>
      <p className={style.contentml2}>二 旅行の計画の作成</p>
      <p className={style.contentml2}>三 旅行に必要な経費の見積り</p>
      <p className={style.contentml2}>
        四 旅行地及び運送・宿泊機関等に関する情報提供
      </p>
      <p className={style.contentml2}>五 その他旅行に必要な助言及び情報提供</p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (契約の成立)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第三条</span>
        当社と旅行相談契約を締結しようとする旅行者は、所定の事項を記入した申込書を当社に提出しなければなりません。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>２</span>
        旅行相談契約は、当社が契約の締結を承諾し、前項の申込書を受理した時に成立するものとします。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>３</span>
        当社は、前二項の規定にかかわらず、申込書の提出を受けることなく電話、郵便、ファクシミリその他の通信手段による旅行相談契約の申込みを受け付けることがあります。この場合において、旅行相談契約は、当社が契約の締結を承諾した時に成立するものとします。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>４</span>
        当社は、次に掲げる場合において、旅行相談契約の締結に応じないことがあります。
      </p>
      <p className={style.contentml2}>
        一
        旅行者の相談内容が公序良俗に反し、若しくは旅行地において施行されている法令に違反するおそれがあるものであるとき。
      </p>
      <p className={style.contentml2}>
        二
        旅行者が、暴力団員、暴力団準構成員、暴力団関係者、暴力団関係企業又は総会屋等その他の反社会的勢力であると認められるとき。
      </p>
      <p className={style.contentml2}>
        三
        旅行者が、当社に対して暴力的な要求行為、不当な要求行為、取引に関して脅迫的な言動若しくは暴力を用いる行為又はこれらに準ずる行為を行ったとき。
      </p>
      <p className={style.contentml2}>
        四
        旅行者が、風説を流布し、偽計を用い若しくは威力を用いて当社の信用を毀損し若しくは当社の業務を妨害する行為又はこれらに準ずる行為を行ったとき。
      </p>
      <p className={style.contentml2}>
        五 その他当社の業務上の都合があるとき。
      </p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>(相談料金)</p>
      <p className={style.content}>
        <span className={style.boldmr2}>第四条</span>
        当社が第二条に掲げる業務を行ったときは、旅行者は、当社に対し、当社が定める期日までに、当社所定の相談料金を支払わなければなりません。
      </p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (当社の責任)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第五条</span>
        当社は、旅行相談契約の履行に当たって、当社が故意又は過失により旅行者に損害を与えたときは、その損害を賠償する責に任じます。ただし、損害発生の翌日から起算して六月以内に当社に対して通知があったときに限ります。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>２</span>
        当社は、当社が作成した旅行の計画に記載した運送・宿泊機関等について、実際に手配が可能であることを保証するものではありません。したがって、満員等の事由により、運送・宿泊機関等との間で当該機関が提供する運送、宿泊その他の旅行に関するサービスの提供をする契約を締結できなかったとしても、当社はその責任を負うものではありません。
      </p>
    </div>
  );
}
