import { Link } from "react-router-dom";
import "../css/header.css";
import {
  MenuIcon,
  XIcon,
  UserCircleIcon,
  QuestionMarkCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";
import { Popover, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useAuth } from "../App";
import logo from "../img/logo_white.png";
import { BASE_URL, JID_URL } from "../constants";

const openMenu = [
  {
    name: "ご利用ガイド",
    href: "/info/guide",
    icon: InformationCircleIcon,
  },
  {
    name: "よくある質問",
    href: "/info/help",
    icon: QuestionMarkCircleIcon,
  },
];

const openMenuB = [
  {
    name: "規約",
    href: "/info/terms",
  },
  {
    name: "約款・条件書",
    href: "/info/conditions/keiyaku_bosyu",
  },
  {
    name: "旅行業務取扱料金表",
    href: "/info/charge",
  },
  {
    name: "会社案内",
    href: "/info/company",
  },
  {
    name: "プライバシーポリシー",
    href: "/info/privacy",
  },
];

export default function Header() {
  let auth = useAuth();
  return (
    <Popover className="bg-cyan-600">
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <div className="flex justify-between items-center py-3.5 md:py-[13px] md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link to="/">
              <span className="sr-only">jorudan travel</span>
              <img
                className="h-5 w-auto sm:h-10"
                src={logo}
                alt="ジョルダントラベル"
              />
              <span className="text-white font-semibold ml-8 md:ml-[106px] text-sm md:text-base">
                MaaSパッケージ
              </span>
            </Link>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="menuIcon">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <div className="hidden md:flex space-x-10">
            <Link to="/info/guide" className="menu">
              ご利用ガイド
            </Link>
            <Link to="/info/help" className="menu">
              よくある質問
            </Link>
          </div>
          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            {!auth.user && (
              <a
                href={`${JID_URL}login.cgi?serviceid=jtravel&NextUrl=${BASE_URL}`}
                className="logIn"
              >
                マイページ
              </a>
            )}
            <div className="hidden md:flex space-x-10">
              {auth.user && (
                <Link to="/mypage" className="logIn">
                  マイページ
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-50">
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex justify-end">
                <div className="-mr-2">
                  <div className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-500 hover:text-gray-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </div>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-8">
                  {auth.user ? (
                    <Link
                      to="/mypage"
                      className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                    >
                      <UserCircleIcon
                        className="flex-shrink-0 h-6 w-6 text-cyan-600"
                        aria-hidden="true"
                      />
                      <span className="ml-3 text-base font-medium text-gray-900">
                        マイページ
                      </span>
                    </Link>
                  ) : (
                    <>
                      <a
                        href={`${JID_URL}login.cgi?serviceid=jtravel&NextUrl=${BASE_URL}`}
                        className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                      >
                        <UserCircleIcon
                          className="flex-shrink-0 h-6 w-6 text-cyan-600"
                          aria-hidden="true"
                        />
                        <span className="ml-3 text-base font-medium text-gray-900">
                          マイページ
                        </span>
                      </a>
                    </>
                  )}

                  {openMenu.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                    >
                      <item.icon
                        className="flex-shrink-0 h-6 w-6 text-cyan-600"
                        aria-hidden="true"
                      />
                      <span className="ml-3 text-base font-medium text-gray-900">
                        {item.name}
                      </span>
                    </a>
                  ))}
                </nav>
              </div>
            </div>
            <div className="py-6 px-4 space-y-6">
              <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                {openMenuB.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="text-base font-medium text-gray-900 hover:text-gray-700"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </Popover>
  );
}
