import style from "../../css/info.module.css";

export default function Privacy() {
  return (
    <div className="w-full max-w-screen-lg mx-auto my-4 md:my-8 px-2">
      <h2 className={style.pageTitle}>プライバシーポリシー</h2>
      <p className={style.content}>
        お客様がこのサイトをご利用した際に、ジョルダン株式会社(ジョルダントラベル)に対して提示される可能性がある個人情報についての私どもの方針を説明します。
      </p>
      <h3 className={style.title}>◆お客様情報保護の取り組み</h3>
      <p className={style.content}>
        (1)
        ジョルダン株式会社トラベル事業部(以下当社という)は、お客様の個人情報を適切に管理するため、顧客個人情報取扱管理者を置き、その管理者に適切な管理を行わせております。また、個人情報保護のため、諸法令、社会規範等を遵守しております。
      </p>
      <p className={style.content}>
        (2)
        当社がお客様にお伺いする情報は、お客様の名前、住所、電話番号、メールアドレスなど、当社のサービスを提供する際に必要となるお客様の個人情報が主なものになります。また、お客様の希望されるサービス提供等の目的でそれ以外の質問をさせていただく場合がありますが、これは必要最低限の項目を除いてお客様の任意でご提供いただくものです。
      </p>
      <p className={style.content}>
        (3)
        当社はお客様の個人情報をサービスの種類によって、第三者に通知する場合があることをあらかじめご了承ください。たとえば、運送機関・宿泊施設などにお客様の名前等を知らせる場合がこれにあたります。
      </p>
      <p className={style.content}>
        (4)
        お客様がご自身の個人情報の照会・修正等を希望される場合には、お客様ご本人であることを確認した上で、合理的な範囲で対応させていただきます。
        <br />
        なお、当社はお客様の個人情報に関し適用される法令、規範を遵守するとともに、上記の取り組みを適宜見直し、改善していきます。また、事故等の発生に関連し、警察および国土交通省・外務省その他官公署からの要請により、個人情報の発表に協力する場合があります。
        <br />
        万一、個人情報の流出等の問題が発生した場合には、直ちに当事者にご連絡をさせていただき、安全の確保が保たれるまで当該サービスを一時停止いたします。また、当事者にご連絡がつき次第、速やかにホームページ等で一般的に公開を致す所存です。
      </p>
      <h3 className={style.title}>◆個人データの利用目的</h3>
      <p className={style.content}>
        旅行申込みの際にご提出いただいた個人データについて、お客様との連絡のために利用させていただくほか、お客様がお申し込みの旅行において運送・宿泊機関等の提供するサービスの手配および受領のために必要な範囲内で利用させていただきます。また、当社では、サイトの利用動向の分析のため収集した統計個人情報（個人を特定できない情報）を開示する場合などに利用させていただくことがあります。
      </p>
      <h3 className={style.title}>◆個人データの開示、訂正、利用停止について</h3>
      <p className={style.content}>
        個人データの開示、訂正、利用停止等をご希望の方は、手続きについてご案内しますので、顧客個人情報取扱管理者までお申し出下さい。その際、請求された方がご本人であることを確認させていただいた上で、特別な理由のない限り書面にてお答えします。また、応じられない場合は理由を説明します。なお、お預かりした個人情報が不正確である場合には、正確なものに変更させていただきます。
      </p>
      <p className={style.content}>
        当社からのダイレクトメールや電子メールでのご案内について、お客様が希望されない場合は、顧客個人情報取扱管理者までお申し出下さい。取扱いを停止させていただきます。
      </p>

      <h3 className={style.title}>
        ◆個人情報に関するお問い合わせ先・苦情の申し出先
      </h3>
      <p className={style.content}>
        個人情報の取扱いに関するお問い合わせ・苦情は、顧客個人情報取扱管理者までお申し出下さい。
      </p>
      <span className={style.smList}>[顧客個人情報取扱管理者 ]</span>
      <br />
      <span className={style.smList}>
        本社：東京都新宿区新宿2-5-10 成信ビル
      </span>
      <br />
      <span className={style.smList}>
        埼玉オフィス：埼玉県新座市東北2-31-15 太田ビル2階
      </span>
      <br />
      <span className={style.smList}>ジョルダン株式会社 伊藤友一</span>
    </div>
  );
}
