import style from "../../css/info.module.css";

export function KeiyakuOrder() {
  return (
    <div className="w-full max-w-screen-lg mx-auto my-4 md:my-8 px-2">
      <h2 className={style.pageTitle}>[旅行業約款] 受注型企画旅行契約</h2>

      <h3 className={style.title}>■第一章 総則</h3>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>(適用範囲)</p>
      <p className={style.content}>
        <span className={style.boldmr2}>第一条</span>
        当社が旅行者との間で締結する受注型企画旅行に関する契約(以下「受注型企画旅行契約」といいます。)は、この約款の定めるところによります。この約款に定めのない事項については、法令又は一般に確立された慣習によります。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>２</span>
        当社が法令に反せず、かつ、旅行者の不利にならない範囲で書面により特約を結んだときは、前項の規定にかかわらず、その特約が優先します。
      </p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (用語の定義)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第二条</span>
        この約款で「受注型企画旅行」とは、当社が、旅行者からの依頼により、旅行の目的地及び日程、旅行者が提供を受けることができる運送又は宿泊のサービスの内容並びに旅行者が当社に支払うべき旅行代金の額を定めた旅行に関する計画を作成し、これにより実施する旅行をいいます。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>２</span>
        この約款で「国内旅行」とは、本邦内のみの旅行をいい、「海外旅行」とは、国内旅行以外の旅行をいいます。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>３</span>
        この部で「通信契約」とは、当社が提携するクレジットカード会社(以下「提携会社」といいます。)のカード会員との間で電話、郵便、ファクシミリ、インターネットその他の通信手段による申込みを受けて締結する受注型企画旅行契約であって、当社が旅行者に対して有する受注型企画旅行契約に基づく旅行代金等に係る債権又は債務を、当該債権又は債務が履行されるべき日以降に別に定める提携会社のカード会員規約に従って決済することについて、旅行者があらかじめ承諾し、かつ当該受注型企画旅行契約の旅行代金等を第十二条第二項、第十六条第一項後段、第十九条第二項に定める方法により支払うことを内容とする受注型企画旅行契約をいいます。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>４</span>
        この約款で「カード利用日」とは、旅行者又は当社が受注型企画旅行契約に基づく旅行代金等の支払又は払戻債務を履行すべき日をいいます。
      </p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (旅行契約の内容)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第三条</span>
        当社は、受注型企画旅行契約において、旅行者が当社の定める旅行日程に従って、運送・宿泊機関等の提供する運送、宿泊その他の旅行に関するサービス(以下「旅行サービス」といいます。)の提供を受けることができるように、手配し、旅程を管理することを引き受けます。
      </p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (手配代行者)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第四条</span>
        当社は、受注型企画旅行契約の履行に当たって、手配の全部又は一部を本邦内又は本邦外の他の旅行業者、手配を業として行う者その他の補助者に代行させることがあります。
      </p>

      <h3 className={style.title}>■第二章 契約の締結</h3>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (企画書面の交付)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第五条</span>
        当社は、当社に受注型企画旅行契約の申込みをしようとする旅行者からの依頼があったときは、当社の業務上の都合があるときを除き、当該依頼の内容に沿って作成した旅行日程、旅行サービスの内容、旅行代金その他の旅行条件に関する企画の内容を記載した書面(以下「企画書面」といいます。)を交付します。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>２</span>
        当社は、前項の企画書面において、旅行代金の内訳として企画に関する取扱料金(以下「企画料金」といいます。)の金額を明示することがあります。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第六条</span>
        前条第一項の企画書面に記載された企画の内容に関し、当社に受注型企画旅行契約の申込みをしようとする旅行者は、当社所定の申込書(以下「申込書」といいます。)に所定の事項を記入の上、当社が別に定める金額の申込金とともに、当社に提出しなければなりません。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>２</span>
        前条第一項の企画書面に記載された企画の内容に関し、当社に通信契約の申込みをしようとする旅行者は、前項の規定にかかわらず、会員番号その他の事項を当社に通知しなければなりません。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>３</span>
        第一項の申込金は、旅行代金(その内訳として金額が明示された企画料金を含みます。)又は取消料若しくは違約料の一部として取り扱います。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>４</span>
        受注型企画旅行の参加に際し、特別な配慮を必要とする旅行者は、契約の申込時に申し出てください。このとき、当社は可能な範囲内でこれに応じます。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>５</span>
        前項の申出に基づき、当社が旅行者のために講じた特別な措置に要する費用は、旅行者の負担とします。
      </p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (契約締結の拒否)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第七条</span>
        当社は、次に掲げる場合において、受注型企画旅行契約の締結に応じないことがあります。
      </p>
      <p className={style.contentml2}>
        一
        旅行者が他の旅行者に迷惑を及ぼし、又は団体行動の円滑な実施を妨げるおそれがあるとき。
      </p>
      <p className={style.contentml2}>
        二
        通信契約を締結しようとする場合であって、旅行者の有するクレジットカードが無効である等、旅行者が旅行代金等に係る債務の一部又は全部を提携会社のカード会員規約に従って決済できないとき。
      </p>
      <p className={style.contentml2}>
        三
        旅行者が、暴力団員、暴力団準構成員、暴力団関係者、暴力団関係企業又は総会屋等その他の反社会的勢力であると認められるとき。
      </p>
      <p className={style.contentml2}>
        四
        旅行者が、当社に対して暴力的な要求行為、不当な要求行為、取引に関して脅迫的な言動若しくは暴力を用いる行為又はこれらに準ずる行為を行ったとき。
      </p>
      <p className={style.contentml2}>
        五
        旅行者が、風説を流布し、偽計を用い若しくは威力を用いて当社の信用を毀損し若しくは当社の業務を妨害する行為又はこれらに準ずる行為を行ったとき。
      </p>
      <p className={style.content}>六 その他当社の業務上の都合があるとき。</p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (契約の成立時期)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第八条</span>
        受注型企画旅行契約は、当社が契約の締結を承諾し、第六条第一項の申込金を受理した時に成立するものとします。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>２</span>
        通信契約は、前項の規定にかかわらず、当社が契約の締結を承諾する旨の通知を発した時に成立するものとします。ただし、当該契約において電子承諾通知を発する場合は、当該通知が旅行者に到達した時に成立するものとします。
      </p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (契約書面の交付)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第九条</span>
        当社は、前条の定める契約の成立後速やかに、旅行者に、旅行日程、旅行サービスの内容、旅行代金その他の旅行条件及び当社の責任に関する事項を記載した書面(以下「契約書面」といいます。)を交付します。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>２</span>
        当社は、第五条第一項の企画書面において企画料金の金額を明示した場合は、当該金額を前項の契約書面において明示します。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>３</span>
        当社が受注型企画旅行契約により手配し旅程を管理する義務を負う旅行サービスの範囲は、第一項の契約書面に記載するところによります。
      </p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>(確定書面)</p>
      <p className={style.content}>
        <span className={style.boldmr2}>第十条</span>
        前条第一項の契約書面において、確定された旅行日程、運送若しくは宿泊機関の名称を記載できない場合には、当該契約書面において利用予定の宿泊機関及び旅行計画上重要な運送機関の名称を限定して列挙した上で、当該契約書面交付後、旅行開始日の前日(旅行開始日の前日から起算してさかのぼって七日目に当たる日以降に受注型企画旅行契約の申込みがなされた場合にあっては、旅行開始日)までの当該契約書面に定める日までに、これらの確定状況を記載した書面(以下「確定書面」といいます。)を交付します。前条第一項の契約書面において、確定された旅行日程、運送若しくは宿泊機関の名称を記載できない場合には、当該契約書面において利用予定の宿泊機関及び旅行計画上重要な運送機関の名称を限定して列挙した上で、当該契約書面交付後、旅行開始日の前日(旅行開始日の前日から起算してさかのぼって七日目に当たる日以降に受注型企画旅行契約の申込みがなされた場合にあっては、旅行開始日)までの当該契約書面に定める日までに、これらの確定状況を記載した書面(以下「確定書面」といいます。)を交付します。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>２</span>
        前項の場合において、手配状況の確認を希望する旅行者から問い合わせがあったときは、確定書面の交付前であっても、当社は迅速かつ適切にこれに回答します。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>３</span>
        第一項の確定書面を交付した場合には、前条第三項の規定により当社が手配し旅程を管理する義務を負う旅行サービスの範囲は、当該確定書面に記載するところに特定されます。
      </p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (情報通信の技術を利用する方法)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第十一条</span>
        当社は、あらかじめ旅行者の承諾を得て、企画書面、受注型企画旅行契約を締結しようとするときに旅行者に交付する旅行日程、旅行サービスの内容、旅行代金その他の旅行条件及び当社の責任に関する事項を記載した書面、契約書面又は確定書面の交付に代えて、情報通信の技術を利用する方法により当該書面に記載すべき事項(以下この条において「記載事項」といいます。)を提供したときは、旅行者の使用する通信機器に備えられたファイルに記載事項が記録されたことを確認します。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>２</span>
        前項の場合において、旅行者の使用に係る通信機器に記載事項を記録するためのファイルが備えられていないときは、当社の使用する通信機器に備えられたファイル(専ら当該旅行者の用に供するものに限ります。)に記載事項を記録し、旅行者が記載事項を閲覧したことを確認します。
      </p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>(旅行代金)</p>
      <p className={style.content}>
        <span className={style.boldmr2}>第十二条</span>
        旅行者は、旅行開始日までの契約書面に記載する期日までに、当社に対し、契約書面に記載する金額の旅行代金を支払わなければなりません。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>２</span>
        通信契約を締結したときは、当社は、提携会社のカードにより所定の伝票への旅行者の署名なくして契約書面に記載する金額の旅行代金の支払いを受けます。また、カード利用日は旅行契約成立日とします。
      </p>

      <h3 className={style.title}>■第三章 契約の変更</h3>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (契約内容の変更)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第十三条</span>
        旅行者は、当社に対し、旅行日程、旅行サービスの内容その他の受注型企画旅行契約の内容(以下「契約内容」といいます。)を変更するよう求めることができます。この場合において、当社は、可能な限り旅行者の求めに応じます。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>２</span>
        当社は、天災地変、戦乱、暴動、運送・宿泊機関等の旅行サービス提供の中止、官公署の命令、当初の運行計画によらない運送サービスの提供その他の当社の関与し得ない事由が生じた場合において、旅行の安全かつ円滑な実施を図るためやむを得ないときは、旅行者にあらかじめ速やかに当該事由が関与し得ないものである理由及び当該事由との因果関係を説明して、契約内容を変更することがあります。ただし、緊急の場合において、やむを得ないときは、変更後に説明します。
      </p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (旅行代金の額の変更)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第十四条</span>
        受注型企画旅行を実施するに当たり利用する運送機関について適用を受ける運賃・料金(以下この条において「適用運賃・料金」といいます。)が、著しい経済情勢の変化等により、受注型企画旅行の企画書面の交付の際に明示した時点において有効なものとして公示されている適用運賃・料金に比べて、通常想定される程度を大幅に超えて増額又は減額される場合においては、当社は、その増額又は減額される金額の範囲内で旅行代金の額を増加し、又は減少することができます。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>２</span>
        当社は、前項の定めるところにより旅行代金を増額するときは、旅行開始日の前日から起算して
        さかのぼって十五日目に当たる日より前に旅行者にその旨を通知します。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>３</span>
        当社は、第一項の定める適用運賃・料金の減額がなされるときは、同項の定めるところにより、その減少額だけ旅行代金を減額します。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>４</span>
        当社は、前条の規定に基づく契約内容の変更により旅行の実施に要する費用(当該契約内容の変更のためにその提供を受けなかった旅行サービスに対して取消料、違約料その他既に支払い、又はこれから支払わなければならない費用を含みます。)の減少又は増加が生じる場合(費用の増加が、運送・宿泊機関等が当該旅行サービスの提供を行っているにもかかわらず、運送・宿泊機関等の座席、部屋その他の諸設備の不足が発生したことによる場合を除きます。)には、当該契約内容の変更の際にその範囲内において旅行代金の額を変更することがあります。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>５</span>
        当社は、運送・宿泊機関等の利用人員により旅行代金が異なる旨を契約書面に記載した場合において、受注型企画旅行契約の成立後に当社の責に帰すべき事由によらず当該利用人員が変更になったときは、契約書面に記載したところにより旅行代金の額を変更することがあります。
      </p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (旅行者の交替)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第十五条</span>
        当社と受注型企画旅行契約を締結した旅行者は、当社の承諾を得て、契約上の地位を第三者に譲り渡すことができます。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>２</span>
        旅行者は、前項に定める当社の承諾を求めようとするときは、当社所定の用紙に所定の事項を記入の上、所定の金額の手数料とともに、当社に提出しなければなりません。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>３</span>
        第一項の契約上の地位の譲渡は、当社の承諾があった時に効力を生ずるものとし、以後、旅行契約上の地位を譲り受けた第三者は、旅行者の当該受注型企画旅行契約に関する一切の権利及び義務を承継するものとします。
      </p>

      <h3 className={style.title}>■第四章 契約の解除</h3>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (旅行者の解除権)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第十六条</span>
        旅行者は、いつでも別表第一に定める取消料を当社に支払って受注型企画旅行契約を解除することができます。通信契約を解除する場合にあっては、当社は、提携会社のカードにより所定の伝票への旅行者の署名なくして取消料の支払いを受けます。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>２</span>
        旅行者は、次に掲げる場合において、前項の規定にかかわらず、旅行開始前に取消料を支払うことなく受注型企画旅行契約を解除することができます。
      </p>
      <p className={style.contentml2}>
        一
        当社によって契約内容が変更されたとき。ただし、その変更が別表第二上欄に掲げるものその他の重要なものであるときに限ります。
      </p>
      <p className={style.contentml2}>
        二 第十四条第一項の規定に基づいて旅行代金が増額されたとき。
      </p>
      <p className={style.contentml2}>
        三
        天災地変、戦乱、暴動、運送・宿泊機関等の旅行サービス提供の中止、官公署の命令その他の事由が生じた場合において、旅行の安全かつ円滑な実施が不可能となり、又は不可能となるおそれが極めて大きいとき。
      </p>
      <p className={style.contentml2}>
        四
        当社が旅行者に対し、第十条第一項の期日までに、確定書面を交付しなかったとき。
      </p>
      <p className={style.contentml2}>
        五
        当社の責に帰すべき事由により、契約書面に記載した旅行日程に従った旅行の実施が不可能となったとき。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>３</span>
        旅行者は、旅行開始後において、当該旅行者の責に帰すべき事由によらず契約書面に記載した旅行サービスを受領することができなくなったとき又は当社がその旨を告げたときは、第一項の規定にかかわらず、取消料を支払うことなく、旅行サービスの当該受領することができなくなった部分の契約を解除することができます。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>４</span>
        前項の場合において、当社は、旅行代金のうち旅行サービスの当該受領することができなくなった部分に係る金額を旅行者に払い戻します。ただし、前項の場合が当社の責に帰すべき事由によらない場合においては、当該金額から、当該旅行サービスに対して取消料、違約料その他の既に支払い、又はこれから支払わなければならない費用に係る金額を差し引いたものを旅行者に払い戻します。
      </p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (当社の解除権等－旅行開始前の解除)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第十七条</span>
        当社は、次に掲げる場合において、旅行者に理由を説明して、旅行開始前に受注型企画旅行契約を解除することがあります。
      </p>
      <p className={style.contentml2}>
        一
        旅行者が病気、必要な介助者の不在その他の事由により、当該旅行に耐えられないと認められるとき。
      </p>
      <p className={style.contentml2}>
        二
        旅行者が他の旅行者に迷惑を及ぼし、又は団体旅行の円滑な実施を妨げるおそれがあると認められるとき。
      </p>
      <p className={style.contentml2}>
        三 旅行者が、契約内容に関し合理的な範囲を超える負担を求めたとき。
      </p>
      <p className={style.contentml2}>
        四
        スキーを目的とする旅行における必要な降雪量等の旅行実施条件であって契約の締結の際に明示したものが成就しないおそれが極めて大きいとき。
      </p>
      <p className={style.contentml2}>
        五
        天災地変、戦乱、暴動、運送・宿泊機関等の旅行サービス提供の中止、官公署の命令その他の当社の関与し得ない事由が生じた場合において、契約書面に記載した旅行日程に従った旅行の安全かつ円滑な実施が不可能となり、又は不可能となるおそれが極めて大きいとき。
      </p>
      <p className={style.contentml2}>
        六
        通信契約を締結した場合であって、旅行者の有するクレジットカードが無効になる等、旅行者が旅行代金等に係る債務の一部又は全部を提携会社のカード会員規約に従って決済できなくなったとき。
      </p>
      <p className={style.contentml2}>
        七
        旅行者が第七条第三号から第五号までのいずれかに該当することが判明したとき。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>２</span>
        旅行者が第十二条第一項の契約書面に記載する期日までに旅行代金を支払わないときは、当該期日の翌日において旅行者が受注型企画旅行契約を解除したものとします。この場合において、旅行者は、当社に対し、前条第一項に定める取消料に相当する額の違約料を支払わなければなりません。
      </p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (当社の解除権－旅行開始後の解除)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第十八条</span>
        当社は、次に掲げる場合において、旅行開始後であっても、旅行者に理由を説明して、受注型企画旅行契約の一部を解除することがあります。
      </p>
      <p className={style.contentml2}>
        一
        旅行者が病気、必要な介助者の不在その他の事由により旅行の継続に耐えられないとき。
      </p>
      <p className={style.contentml2}>
        二
        旅行者が旅行を安全かつ円滑に実施するための添乗員その他の者による当社の指示への違背、これらの者又は同行する他の旅行者に対する暴行又は脅迫等により団体行動の規律を乱し、当該旅行の安全かつ円滑な実施を妨げるとき。
      </p>
      <p className={style.contentml2}>
        三
        旅行者が第七条第三号から第五号までのいずれかに該当することが判明したとき。
      </p>
      <p className={style.contentml2}>
        四
        天災地変、戦乱、暴動、運送・宿泊機関等の旅行サービス提供の中止、官公署の命令その他の当社の関与し得ない事由が生じた場合であって、旅行の継続が不可能となったとき。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>２</span>
        当社が前項の規定に基づいて受注型企画旅行契約を解除したときは、当社と旅行者との間の契約関係は、将来に向かってのみ消滅します。この場合において、旅行者が既に提供を受けた旅行サービスに関する当社の債務については、有効な弁済がなされたものとします。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>３</span>
        前項の場合において、当社は、旅行代金のうち旅行者がいまだその提供を受けていない旅行サービスに係る部分に係る金額から、当該旅行サービスに対して取消料、違約料その他の既に支払い、又はこれから支払わなければならない費用に係る金額を差し引いたものを旅行者に払い戻します。
      </p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (旅行代金の払戻し)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第十九条</span>
        当社は、第十四条第三項から第五項までの規定により旅行代金が減額された場合又は前三条の規定により受注型企画旅行契約が解除された場合において、旅行者に対し払い戻すべき金額が生じたときは、旅行開始前の解除による払戻しにあっては解除の翌日から起算して七日以内に、減額又は旅行開始後の解除による払戻しにあっては契約書面に記載した旅行終了日の翌日から起算して三十日以内に旅行者に対し当該金額を払い戻します。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>２</span>
        当社は、旅行者と通信契約を締結した場合であって、第十四条第三項から第五項までの規定により旅行代金が減額された場合又は前三条の規定により通信契約が解除された場合において、旅行者に対し払い戻すべき金額が生じたときは、提携会社のカード会員規約に従って、旅行者に対し当該金額を払い戻します。この場合において、当社は、旅行開始前の解除による払戻しにあっては解除の翌日から起算して七日以内に、減額又は旅行開始後の解除による払戻しにあっては契約書面に記載した旅行終了日の翌日から起算して三十日以内に旅行者に対し払い戻すべき額を通知するものとし、旅行者に当該通知を行った日をカード利用日とします。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>３</span>
        前二項の規定は第二十八条又は第三十一条第一項に規定するところにより旅行者又は当社が損害賠償請求権を行使することを妨げるものではありません。
      </p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (契約解除後の帰路手配)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第二十条</span>
        当社は、第十八条第一項第一号又は第三号の規定によって旅行開始後に受注型企画旅行契約を解除したときは、旅行者の求めに応じて、旅行者が当該旅行の出発地に戻るために必要な旅行サービスの手配を引き受けます。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>２</span>
        前項の場合において、出発地に戻るための旅行に要する一切の費用は、旅行者の負担とします。
      </p>

      <h3 className={style.title}>■第五章 団体・グループ契約</h3>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (団体・グループ契約)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第二十一条</span>
        当社は、同じ行程を同時に旅行する複数の旅行者がその責任ある代表者(以下「契約責任者」といいます。)を定めて申し込んだ受注型企画旅行契約の締結については、本章の規定を適用します。
      </p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (契約責任者)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第二十二条</span>
        当社は、特約を結んだ場合を除き、契約責任者はその団体・グループを構成する旅行者(以下「構成者」といいます。)の受注型企画旅行契約の締結に関する一切の代理権を有しているものとみなし、当該団体・グループに係る旅行業務に関する取引及び第二十六条第一項の業務は、当該契約責任者との間で行います。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>２</span>
        契約責任者は、当社が定める日までに、構成者の名簿を当社に提出しなければなりません。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>３</span>
        当社は、契約責任者が構成者に対して現に負い、又は将来負うことが予測される債務又は義務については、何らの責任を負うものではありません。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>４</span>
        当社は、契約責任者が団体・グループに同行しない場合、旅行開始後においては、あらかじめ契約責任者が選任した構成者を契約責任者とみなします。
      </p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (契約成立の特則)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第二十三条</span>
        当社は、契約責任者と受注型企画旅行契約を締結する場合において、第六条第一項の規定にかかわらず、申込金の支払いを受けることなく受注型企画旅行契約の締結を承諾することがあります。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>２</span>
        前項の規定に基づき申込金の支払いを受けることなく受注型企画旅行契約を締結する場合には、当社は、契約責任者にその旨を記載した書面を交付するものとし、受注型企画旅行契約は、当社が当該書面を交付した時に成立するものとします。
      </p>

      <h3 className={style.title}>■第六章 旅程管理</h3>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>(旅程管理)</p>
      <p className={style.content}>
        <span className={style.boldmr2}>第二十四条</span>
        当社は、旅行者の安全かつ円滑な旅行の実施を確保することに努力し、旅行者に対し次に掲げる業務を行います。ただし、当社が旅行者とこれと異なる特約を結んだ場合には、この限りではありません。
      </p>
      <p className={style.contentml2}>
        一
        旅行者が旅行中旅行サービスを受けることができないおそれがあると認められるときは、受注型企画旅行契約に従った旅行サービスの提供を確実に受けられるために必要な措置を講ずること。
      </p>
      <p className={style.contentml2}>
        二
        前号の措置を講じたにもかかわらず、契約内容を変更せざるを得ないときは、代替サービスの手配を行うこと。この際、旅行日程を変更するときは、変更後の旅行日程が当初の旅行日程の趣旨にかなうものとなるよう努めること、また、旅行サービスの内容を変更するときは、変更後の旅行サービスが当初の旅行サービスと同様のものとなるよう努めること等、契約内容の変更を最小限にとどめるよう努力すること。
      </p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (当社の指示)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第二十五条</span>
        旅行者は、旅行開始後旅行終了までの間において、団体で行動するときは、旅行を安全かつ円滑に実施するための当社の指示に従わなければなりません。
      </p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (添乗員等の業務)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第二十六条</span>
        当社は、旅行の内容により添乗員その他の者を同行させて第二十四条各号に掲げる業務その他当該受注型企画旅行に付随して当社が必要と認める業務の全部又は一部を行わせることがあります。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>２</span>
        前項の添乗員その他の者が同項の業務に従事する時間帯は、原則として八時から二十時までとします。
      </p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>(保護措置)</p>
      <p className={style.content}>
        <span className={style.boldmr2}>第二十七条</span>
        当社は、旅行中の旅行者が、疾病、傷害等により保護を要する状態にあると認めたときは、必要な措置を講ずることがあります。この場合において、これが当社の責に帰すべき事由によるものでないときは、当該措置に要した費用は旅行者の負担とし、旅行者は当該費用を当社が指定する期日までに当社の指定する方法で支払わなければなりません。
      </p>

      <h3 className={style.title}>■第七章 責任</h3>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (当社の責任)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第二十八条</span>
        当社は、受注型企画旅行契約の履行に当たって、当社又は当社が第四条の規定に基づいて手配を代行させた者(以下「手配代行者」といいます。)が故意又は過失により旅行者に損害を与えたときは、その損害を賠償する責に任じます。ただし、損害発生の翌日から起算して二年以内に当社に対して通知があったときに限ります。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>２</span>
        旅行者が天災地変、戦乱、暴動、運送・宿泊機関等の旅行サービス提供の中止、官公署の命令その他の当社又は当社の手配代行者の関与し得ない事由により損害を被ったときは、当社は、前項の場合を除き、その損害を賠償する責任を負うものではありません。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>３</span>
        当社は、手荷物について生じた第一項の損害については、同項の規定にかかわらず、損害発生の翌日から起算して、国内旅行にあっては十四日以内に、海外旅行にあっては二十一日以内に当社に対して通知があったときに限り、旅行者一名につき十五万円を限度(当社に故意又は重大な過失がある場合を除きます。)として賠償します。
      </p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>(特別補償)</p>
      <p className={style.content}>
        <span className={style.boldmr2}>第二十九条</span>
        当社は、前条第一項の規定に基づく当社の責任が生ずるか否かを問わず、別紙特別補償規程で定めるところにより、旅行者が受注型企画旅行参加中にその生命、身体又は手荷物の上に被った一定の損害について、あらかじめ定める額の補償金及び見舞金を支払います。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>２</span>
        前項の損害について当社が前条第一項の規定に基づく責任を負うときは、その責任に基づいて支払うべき損害賠償金の額の限度において、当社が支払うべき前項の補償金は、当該損害賠償金とみなします。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>３</span>
        前項に規定する場合において、第一項の規定に基づく当社の補償金支払義務は、当社が前条第一項の規定に基づいて支払うべき損害賠償金(前項の規定により損害賠償金とみなされる補償金を含みます。)に相当する額だけ縮減するものとします。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>４</span>
        当社の受注型企画旅行参加中の旅行者を対象として、別途の旅行代金を収受して当社が実施する募集型企画旅行については、受注型企画旅行契約の内容の一部として取り扱います。
      </p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>(旅程保証)</p>
      <p className={style.content}>
        <span className={style.boldmr2}>第三十条</span>
        当社は、別表第二上欄に掲げる契約内容の重要な変更(次の各号に掲げる変更(運送・宿泊機関等が当該旅行サービスの提供を行っているにもかかわらず、運送・宿泊機関等の座席、部屋その他の諸設備の不足が発生したことによるものを除きます。)を除きます。)が生じた場合は、旅行代金に同表下欄に記載する率を乗じた額以上の変更補償金を旅行終了日の翌日から起算して三十日以内に支払います。ただし、当該変更について当社に第二十八条第一項の規定に基づく責任が発生することが明らかである場合には、この限りではありません。
      </p>
      <p className={style.content}>一 次に掲げる事由による変更</p>
      <p className={style.contentml2}>イ 天災地変</p>
      <p className={style.contentml2}>ロ 戦乱</p>
      <p className={style.contentml2}>ハ 暴動</p>
      <p className={style.contentml2}>ニ 官公署の命令</p>
      <p className={style.contentml2}>
        ホ 運送・宿泊機関等の旅行サービス提供の中止
      </p>
      <p className={style.contentml2}>
        ヘ 当初の運行計画によらない運送サービスの提供
      </p>
      <p className={style.contentml2}>
        ト 旅行参加者の生命又は身体の安全確保のため必要な措置
      </p>
      <p className={style.content}>
        二
        第十三条第一項の規定に基づいて受注型企画旅行契約が変更されたときの当該変更された部分及び第十六条から第十八条までの規定に基づいて受注型企画旅行契約が解除されたときの当該解除された部分に係る変更
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>２</span>
        当社が支払うべき変更補償金の額は、旅行者一名に対して一受注型企画旅行につき旅行代金に十五％以上の当社が定める率を乗じた額をもって限度とします。また、旅行者一名に対して一受注型企画旅行につき支払うべき変更補償金の額が千円未満であるときは、当社は、変更補償金を支払いません。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>３</span>
        当社が第一項の規定に基づき変更補償金を支払った後に、当該変更について当社に第二十八条第一項の規定に基づく責任が発生することが明らかになった場合には、旅行者は当該変更に係る変更補償金を当社に返還しなければなりません。この場合、当社は、同項の規定に基づき当社が支払うべき損害賠償金の額と旅行者が返還すべき変更補償金の額とを相殺した残額を支払います。
      </p>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (旅行者の責任)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第三十一条</span>
        旅行者の故意又は過失により当社が損害を被ったときは、当該旅行者は、損害を賠償しなければなりません。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>２</span>
        旅行者は、受注型企画旅行契約を締結するに際しては、当社から提供された情報を活用し、旅行者の権利義務その他の受注型企画旅行契約の内容について理解するよう努めなければなりません。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>３</span>
        旅行者は、旅行開始後において、契約書面に記載された旅行サービスを円滑に受領するため、万が一契約書面と異なる旅行サービスが提供されたと認識したときは、旅行地において速やかにその旨を当社、当社の手配代行者又は当該旅行サービス提供者に申し出なければなりません。
      </p>

      <h3 className={style.title}>■第八章 弁済業務保証金</h3>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        (弁済業務保証金)
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>第三十二条</span>
        当社は、一般社団法人日本旅行業協会(東京都千代田区霞ヶ関三丁目３番３号)の保証社員になっております。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>２</span>
        当社と受注型企画旅行契約を締結した旅行者又は構成者は、その取引によって生じた債権に関し、前項の一般社団法人日本旅行業協会が供託している弁済業務保証金から七千万円に達するまで弁済を受けることができます。
      </p>
      <p className={style.content}>
        <span className={style.boldmr2}>３</span>
        当社は、旅行業法第二十二条の十第一項の規定に基づき、一般社団法人日本旅行業協会に弁済業務保証金分担金を納付しておりますので、同法第七条第一項に基づく営業保証金は供託しておりません。
      </p>

      <h3 className={style.title}>■別表第一　取消料(第十六条第一項関係)</h3>
      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        一 国内旅行に係る取消料
      </p>
      <table className={style.tbl}>
        <thead>
          <tr className={style.tblTR}>
            <th>区分</th>
            <th>取消料</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={2} className={style.tblBgc}>
              一 次項以外の受注型企画旅行契約
            </td>
          </tr>
          <tr className={style.tblTR}>
            <td>
              イ
              ロからヘまでに掲げる場合以外の場合(当社が契約書面において企画料金の金額を明示した場合に限る)
            </td>
            <td>
              企画料金に
              <br className="hidden md:flex" />
              相当する金額
            </td>
          </tr>
          <tr className={style.tblTR}>
            <td>
              ロ
              旅行開始日の前日から起算してさかのぼって二十日目(日帰り旅行にあっては十日目)に当たる日以降に解除する場合(ハからヘまでに掲げる場合を除く)
            </td>
            <td>旅行代金の20％以内</td>
          </tr>
          <tr className={style.tblTR}>
            <td>
              ハ
              旅行開始日の前日から起算してさかのぼって七日目に当たる日以降に解除する場合(ニからヘまでに掲げる場合を除く)
            </td>
            <td>旅行代金の30％以内</td>
          </tr>
          <tr className={style.tblTR}>
            <td>ニ 旅行開始日の前日に解除する場合</td>
            <td>旅行代金の40％以内</td>
          </tr>
          <tr className={style.tblTR}>
            <td>ホ 旅行開始当日に解除する場合(ヘに掲げる場合を除く)</td>
            <td>旅行代金の50％以内</td>
          </tr>
          <tr className={style.tblTR}>
            <td>ヘ 旅行開始後の解除又は無連絡不参加の場合</td>
            <td>旅行代金の100％以内</td>
          </tr>
          <tr className={`${style.tblTR} ${style.tblBgc}`}>
            <td>二 貸切船舶を利用する受注型企画旅行契約</td>
            <td>
              当該船舶に係る取消料の
              <br className="hidden md:flex" />
              規定によります。
            </td>
          </tr>
          <tr>
            <td colSpan={2} className="text-center">
              備考：取消料の金額は、契約書面に明示します。
            </td>
          </tr>
        </tbody>
      </table>

      <p className={`${style.content} ${style.red} ${style.mt4}`}>
        二 海外旅行に係る取消料
      </p>
      <table className={style.tbl}>
        <thead>
          <tr className={style.tblTR}>
            <th>区分</th>
            <th>取消料</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={2} className={style.tblBgc}>
              一 本邦出国時又は帰国時に航空機を利用する受注型企画旅行契約
              <br className="hidden md:flex" />
              (次項に掲げる旅行契約を除く)
            </td>
          </tr>
          <tr className={style.tblTR}>
            <td>
              イ
              ロからニまでに掲げる場合以外の場合(当社が契約書面において企画料金の金額を明示した場合に限る)
            </td>
            <td>
              企画料金に
              <br className="hidden md:flex" />
              相当する金額
            </td>
          </tr>
          <tr className={style.tblTR}>
            <td>
              ロ
              旅行開始日の前日から起算してさかのぼって三十日目に当たる日以降に解除する場合(ハ及びニに掲げる場合を除く)
            </td>
            <td>旅行代金の20％以内</td>
          </tr>
          <tr className={style.tblTR}>
            <td>
              ハ 旅行開始日の前々日以降に解除する場合(ニに掲げる場合を除く)
            </td>
            <td>旅行代金の50％以内</td>
          </tr>
          <tr className={style.tblTR}>
            <td>ニ 旅行開始後の解除又は無連絡不参加の場合</td>
            <td>旅行代金の100％以内</td>
          </tr>
          <tr>
            <td colSpan={2} className={style.tblBgc}>
              二 貸切航空機を利用する受注型企画旅行契約
            </td>
          </tr>
          <tr className={style.tblTR}>
            <td>
              イ
              ロからホまでに掲げる場合以外の場合(当社が契約書面において企画料金の金額を明示した場合に限る)
            </td>
            <td>
              企画料金に
              <br className="hidden md:flex" />
              相当する金額
            </td>
          </tr>
          <tr className={style.tblTR}>
            <td>
              ロ
              旅行開始日の前日から起算してさかのぼって九十日目に当たる日以降に解除する場合(ハからホまでに掲げる場合を除く)
            </td>
            <td>旅行代金の20％以内</td>
          </tr>
          <tr className={style.tblTR}>
            <td>
              ハ
              旅行開始日の前日から起算してさかのぼって三十日目に当たる日以降に解除する場合(ニ及びホに掲げる場合を除く)
            </td>
            <td>旅行代金の50％以内</td>
          </tr>
          <tr className={style.tblTR}>
            <td>
              ニ
              旅行開始日の前日から起算してさかのぼって二十日目に当たる日以降に解除する場合(ホに掲げる場合を除く)
            </td>
            <td>旅行代金の80％以内</td>
          </tr>
          <tr className={style.tblTR}>
            <td>
              ホ
              旅行開始日の前日から起算してさかのぼって三日目に当たる日以降の解除又は無連絡不参加の場合
            </td>
            <td>旅行代金の100％以内</td>
          </tr>
          <tr className={`${style.tblTR} ${style.tblBgc}`}>
            <td>三 本邦出国時及び帰国時に船舶を利用する受注型企画旅行契約</td>
            <td>
              当該船舶に係る取消料の
              <br className="hidden md:flex" />
              規定によります。
            </td>
          </tr>
          <tr>
            <td colSpan={2} className="text-center">
              備考：取消料の金額は、契約書面に明示します。
            </td>
          </tr>
        </tbody>
      </table>

      <p className={`${style.content}`}>
        ■別表第二 変更補償金(第三十条第一項関係)
      </p>
      <table className={style.tbl}>
        <thead>
          <tr className={style.tblTR}>
            <th rowSpan={2}>変更補償金の支払いが必要となる変更</th>
            <th colSpan={2}>1件あたりの率(％)</th>
          </tr>
          <tr>
            <th>旅行開始前</th>
            <th>旅行開始後</th>
          </tr>
        </thead>
        <tbody>
          <tr className={style.tblTR2}>
            <td>一 契約書面に記載した旅行開始日又は旅行終了日の変更</td>
            <td>1.5</td>
            <td>3.0</td>
          </tr>
          <tr className={style.tblTR2}>
            <td>
              二
              契約書面に記載した入場する観光地又は観光施設(レストランを含みます。)その他の旅行の目的地の変更
            </td>
            <td>1.0</td>
            <td>2.0</td>
          </tr>
          <tr className={style.tblTR2}>
            <td>
              三
              契約書面に記載した運送機関の等級又は設備のより低い料金のものへの変更(変更後の等級及び設備の料金の合計額が契約書面に記載した等級及び設備のそれを下回った場合に限ります。)
            </td>
            <td>1.0</td>
            <td>2.0</td>
          </tr>
          <tr className={style.tblTR2}>
            <td>四 契約書面に記載した運送機関の種類又は会社名の変更</td>
            <td>1.0</td>
            <td>2.0</td>
          </tr>
          <tr className={style.tblTR2}>
            <td>
              五
              契約書面に記載した本邦内の旅行開始地たる空港又は旅行終了地たる空港の異なる便への変更
            </td>
            <td>1.0</td>
            <td>2.0</td>
          </tr>
          <tr className={style.tblTR2}>
            <td>
              六
              契約書面に記載した本邦内と本邦外との間における直行便の乗継便又は経由便への変更
            </td>
            <td>1.0</td>
            <td>2.0</td>
          </tr>
          <tr className={style.tblTR2}>
            <td>七 契約書面に記載した宿泊機関の種類又は名称の変更</td>
            <td>1.0</td>
            <td>2.0</td>
          </tr>
          <tr className={style.tblTR2}>
            <td>
              八
              契約書面に記載した宿泊機関の客室の種類、設備、景観その他の客室の条件の変更
            </td>
            <td>1.0</td>
            <td>2.0</td>
          </tr>
        </tbody>
      </table>
      <p className={style.content}>
        (注一)
        「旅行開始前」とは、当該変更について旅行開始日の前日までに旅行者に通知した場合をいい、「旅行開始後」とは、当該変更について旅行開始当日以降に旅行者に通知した場合をいいます。
      </p>
      <p className={style.content}>
        (注二)
        確定書面が交付された場合には、「契約書面」とあるのを「確定書面」と読み替えた上で、この表を適用します。この場合において、契約書面の記載内容と確定書面の記載内容との間又は確定書面の記載内容と実際に提供された旅行サービスの内容との間に変更が生じたときは、それぞれの変更につき一件として取り扱います。
      </p>
      <p className={style.content}>
        (注三)
        第三号又は第四号に掲げる変更に係る運送機関が宿泊設備の利用を伴うものである場合は、一泊につき一件として取り扱います。
      </p>
      <p className={style.content}>
        (注四)
        第四号に掲げる運送機関の会社名の変更については、等級又は設備がより高いものへの変更を伴う場合には適用しません。
      </p>
      <p className={style.content}>
        (注五)
        第四号又は第七号若しくは第八号に掲げる変更が一乗車船等又は一泊の中で複数生じた場合であっても、一乗車船等又は一泊につき一件として取り扱います。
      </p>
    </div>
  );
}
