import { formatISO, differenceInDays, format } from "date-fns";
import { API_URL } from "./constants";

import { Flight, HotelArea, Restaurants } from "./types";

export async function getHotels(
  region: string,
  stayDate: string
): Promise<HotelArea[]> {
  const response = await fetch(
    `${API_URL}/hotelareas?` +
      new URLSearchParams({
        region_id: region,
        stay_date: stayDate,
      }),
    { method: "GET" }
  );
  if (!response.ok) {
    throw new Error(`This is an HTTP error: The status is ${response.status}`);
  }
  let actualData = await response.json();
  return actualData.hotelarea;
}

export async function getFlights(
  date: Date,
  departure: string,
  arrival: string,
  adult: number,
  child: number,
  infant: number,
  depSince?: string,
  depUntil?: string
): Promise<Flight[]> {
  let query = {
    date: formatISO(date, { representation: "date" }),
    departure_region: departure,
    arrival_region: arrival,
    adult: adult.toString(),
    child: child.toString(),
    infant: infant.toString(),
  } as any;

  if (depSince) {
    query.dep_time_since = depSince;
  }

  if (depUntil) {
    query.dep_time_until = depUntil;
  }

  let response = await fetch(
    `${API_URL}/flight-cache?` + new URLSearchParams(query),
    { method: "GET" }
  );

  console.log(response);

  if (response.status === 202) {
    throw new Error("No cache");

    // response = await fetch(`${API_URL}/flight?` + new URLSearchParams(query), {
    //   method: "GET",
    // });
    // console.log(response);
  }

  if (!response.ok) {
    throw new Error(`This is an HTTP error: The status is ${response.status}`);
  }
  let actualData = await response.json();
  return actualData.flights;
}

export async function getRestaurants(region: string): Promise<Restaurants[]> {
  const response = await fetch(
    `${API_URL}/restaurants?` + new URLSearchParams({ region_id: region }),
    { method: "GET" }
  );
  if (!response.ok) {
    throw new Error(`This is an HTTP error: The status is ${response.status}`);
  }
  let actualData = await response.json();
  return actualData.restaurant;
}

export async function getAccount(eid: string) {
  const response = await fetch(`${API_URL}/account `, {
    method: "GET",
    headers: {
      "x-jorudan-eid": eid,
    },
  });
  if (!response.ok) {
    throw new Error(`This is an HTTP error: The status is ${response.status}`);
  }
  let actualData = await response.json();
  return actualData.account;
}

export async function createBasket(order: any) {
  let response = await fetch(`${API_URL}/basket`, {
    method: "POST",
    headers: {
      accept: "application/json",
    },
    body: JSON.stringify(order),
  });
  if (!response.ok) {
    throw new Error(`This is an HTTP error: The status is ${response.status}`);
  }
  let actualData = await response.json();
  return actualData.basket;
}

export async function storeBasketContent(basket: any, order: any) {
  let air = [];

  if (order.departure) {
    air.push({
      flight: order.departure.flight,
      fare: order.departure.fare,
      bound: "outbound",
      adult: order.adults,
      child: order.children,
      infant: order.infants,
    });
  }

  if (order.return) {
    air.push({
      flight: order.return.flight,
      fare: order.return.fare,
      bound: "inbound",
      adult: order.adults,
      child: order.children,
      infant: order.infants,
    });
  }

  let response = await fetch(`${API_URL}/basket/content`, {
    method: "POST",
    headers: {
      "x-jtravel-basket-session": basket.session,
      "Content-Type": "application/json",
      accept: "application/json",
    },
    body: JSON.stringify({
      proposal: "hotelpackage",
      content: {
        adult: order.adults,
        child: order.children,
        infant: 0,
        air,
        hotel: order.hotel ? {
          hotelclass: order.hotel.class.hotelclass_id,
          region_id: order.destination.region_id,
          date: formatISO(order.startDate, { representation: "date" }),
          area: order.hotel?.area.hotelarea_id,
          stay: differenceInDays(order.endDate, order.startDate),
          option: [
            "位置=" + order.hotel?.room.location.label,
            "大浴場=" + order.hotel?.room.bath.label,
            "朝食=" + order.hotel?.room.breakfast.label,
            "たばこ=" + order.hotel?.room.smoking.label,
          ],
        } : null,
        restaurant: order.restaurant
          ? {
              restaurant_id: order.restaurant?.shop.restaurant_id,
              budget_min: order.restaurant?.shop.budget_min,
              region_id: order.restaurant?.shop.region_id,
              name: order.restaurant?.shop.name,
              name_reading: order.restaurant?.shop.name_reading,
              genre: order.restaurant?.shop.genre,
              tag: order.restaurant?.shop.tag,
              address: order.restaurant?.shop.address,
              zipcode: order.restaurant?.shop.zipcode,
              tel: order.restaurant?.shop.tel,
              fax: order.restaurant?.shop.fax,
              email: order.restaurant?.shop.email,
              homepage: order.restaurant?.shop.homepage,
            }
          : null,
        remarks: order.remarks ? order.remarks : "備考なし",
      },
    }),
  });
  if (!response.ok) {
    throw new Error(`This is an HTTP error: The status is ${response.status}`);
  }

  let actualData = await response.json();
  return actualData.basket;
}

export async function makeTransaction(eid: string, order: any, basket: any) {
  let origin = order.origin.label === 'その他' ? order.othersOrigin : order.origin.label;
  let destination = order.destination.label === 'その他' ? order.othersDestination : order.destination.label;
  let subject = `【ジョルダン】${format(order.startDate, 'yyyy年MM月dd日')} ${origin}〜${destination}の件 お申し込み受付`;

  console.log(subject);
  
  let response = await fetch(`${API_URL}/transact`, {
    method: "POST",
    headers: {
      accept: "application/json",
      "x-jorudan-eid": eid,
      "x-jtravel-basket-session": basket.session,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
        subject: subject
    })
  });

  if (!response.ok) {
    throw new Error(`This is an HTTP error: The status is ${response.status}`);
  }

  let actualData = await response.json();

  return actualData.transact;
}

export async function storeBasketContentFree(basket: any, order: any, profile: any) {
  let origin = order.origin.label === "その他" ? order.othersOrigin : order.origin.label;
  let destination = order.destination.label === "その他" ? order.othersDestination : order.destination.label;
  let schedule = `行き：${format(order.startDate, "yyyy年MM月dd日")} ${origin}\n帰り：${format(order.endDate, "yyyy年MM月dd日")} ${destination}`;
  let persons = `大人${order.adults}名 小人${order.children}名\n`;
  let transport = order.transport.id === "air" ? `移動手段：${order.transport.label}\n往路出発：${order.depTimeOrigin.time}\n復路出発：${order.depTimeDestination.time}`
                                               : "移動手段：" + order.transport.label;

  const UNSPECIFIED = "指定なし";

  let originName = order.transportInfo?.originName ?? UNSPECIFIED;
  let originSeat = order.transportInfo?.originSeat ?? UNSPECIFIED;
  let originDep = order.transportInfo?.originDep ?? UNSPECIFIED;
  let originDepTime = order.transportInfo?.originDepTime ?? UNSPECIFIED;
  let originArr = order.transportInfo?.originArr ?? UNSPECIFIED;
  let originArrTime = order.transportInfo?.originArrTime ?? UNSPECIFIED;
  let destinationName = order.transportInfo?.destinationName ?? UNSPECIFIED;
  let destinationSeat = order.transportInfo?.destinationSeat ?? UNSPECIFIED;
  let destinationDep = order.transportInfo?.destinationDep ?? UNSPECIFIED;
  let destinationDepTime = order.transportInfo?.destinationDepTime ?? UNSPECIFIED;
  let destinationArr = order.transportInfo?.destinationArr ?? UNSPECIFIED;
  let destinationArrTime = order.transportInfo?.destinationArrTime ?? UNSPECIFIED;

  let transportInfo = order.transport.id === "train" ? `[往路]\n 出発地：${originDep}\n 到着地：${originArr}\n 出発時間：${originDepTime}\n 到着時間：${originArrTime}\n 利用便：${originName}\n 座席：${originSeat}\n[復路]\n 出発地：${destinationDep}\n 到着地：${destinationArr}\n 出発時間：${destinationDepTime}\n 到着時間：${destinationArrTime}\n 利用便：${destinationName}\n 座席：${destinationSeat}\n`
                                                    : "";

  let stayDate = '';
  let hotel = '';
  let restaurant = '';

  if(order.schedule === 'day') {
    stayDate = '';
    hotel = '';
    restaurant = '';
  } else {
    if(order.destination.prior === 99) {
      hotel = `エリア：${order.HotelArea ? order.HotelArea : "指定なし"} ランク：${order.othersHotelClass.label} 位置：${order.location.label} 大浴場：${order.bath.label} 朝食：${order.breakfast.label} たばこ：${order.smoking.label}`
    } else {
      if(order.hotel) {
        hotel = order.destination.label === "その他" ? `エリア：${order.HotelArea ? order.HotelArea : "指定なし"} ランク：${order.othersHotelClass.label} 位置：${order.location.label} 大浴場：${order.bath.label} 朝食：${order.breakfast.label} たばこ：${order.smoking.label}`
                                                      : `エリア：${order.hotel.area.label} ランク：${order.hotel.class.label} 位置：${order.hotel.room.location.label} 大浴場：${order.hotel.room.bath.label} 朝食：${order.hotel.room.breakfast.label} たばこ：${order.hotel.room.smoking.label}`;
      }
    }

    if(order.freeRestaurant) {
      restaurant = order.freeRestaurant ? `夕食： ${order.freeRestaurant}` : "";
    }

    stayDate = `宿泊日：${format(order.startDate, "yyyy年MM月dd日")}〜${differenceInDays(order.endDate, order.startDate)}泊`;
  }

  let remarks = order.remarks ? `備考：${order.remarks}\n` : "\n";

  let accountInfo = `お客様情報\nお名前：${profile.last_name} ${profile.first_name}\nフリガナ：${profile.last_name_reading} ${profile.first_name_reading}\n性別：${profile.gender === 'male' ? '男性' : '女性'}\n生年月日：${profile.birth}\nお電話番号：${profile.tel}\n`;
  
  console.log(accountInfo);

  let response = await fetch(`${API_URL}/basket/content`, {
    method: "POST",
    headers: {
      "x-jtravel-basket-session": basket.session,
      "Content-Type": "application/json",
      accept: "application/json",
    },
    body: JSON.stringify({
      proposal: "consult",
      content: {
        query: `${schedule}
${persons}
${transport}
${transportInfo}
${stayDate}
${hotel}
${restaurant}
${remarks}
${accountInfo}`,
      },
    }),
  });
  if (!response.ok) {
    throw new Error(`This is an HTTP error: The status is ${response.status}`);
  }

  let actualData = await response.json();
  return actualData.basket;
}
