import { useState } from "react";
import { DarkLine } from "./Lines";
import Price from "./Price";
import { PlusSmIcon, XIcon } from "@heroicons/react/solid";

export function Amountdetails({ state }: any) {
  const diffDays = (state.endDate - state.startDate) / 86400000;
  const totalPeople = state.adults + state.children;

  const depTicket = state.departure?.fare.TicketAdult ?? 0;
  const retTicket = state.return?.fare.TicketAdult ?? 0;
  const depTicketChild =
    state.children !== 0 && state.departure
      ? state.departure?.fare.TicketChild
      : 0;
  const retTicketChild =
    state.children !== 0 && state.return ? state.return?.fare.TicketChild : 0;
  const hotelPrice = state.hotel?.class?.estimate ?? 0;
  const hotelTotal = hotelPrice * diffDays * totalPeople;

  const [totalPrice, _] = useState(
    hotelTotal +
      depTicket * state.adults +
      depTicketChild * state.children +
      retTicket * state.adults +
      retTicketChild * state.children
  );
  return (
    <>
      <DarkLine />
      <div className="flex flex-row justify-between items-end text-lg font-semibold px-4">
        <div>合計金額(概算)</div>
        <Price value={totalPrice} />
      </div>
      <DarkLine />

      <div className="flex justify-end">
        <div className="w-full max-w-xl space-y-5">
          {state.hotel?.class?.estimate && (
            <div className="amountTitles">
              <div className="font-semibold">宿泊施設</div>
              <div className="amountItems">
                <Price value={hotelPrice} />
                <XIcon className="w-3 h-3" />
                <div>{diffDays}泊</div>
                <XIcon className="w-3 h-3" />
                <div>{totalPeople}人</div>
              </div>
            </div>
          )}

          {state.departure && (
            <>
              <div className="amountTitles">
                <div className="font-semibold">
                  フライト
                  <br className="flex md:hidden" />
                  [往路]
                </div>
                <div className="space-y-1">
                  <div className="amountItems">
                    <div>大人</div>
                    <Price value={state.departure.fare.TicketAdult} />
                    <XIcon className="w-3 h-3" />
                    <div>{state.adults}人</div>
                  </div>
                  <div className="amountItems text-xs">
                    <div>[航空券代金]</div>
                    <Price value={state.departure.fare.CarrierAdultPrice} />
                    <PlusSmIcon className="w-4 h-4" />
                    <div className="flex flex-row justify-end items-center">
                      <span>[</span>
                      {state.departure.fare.TicketTypeName ===
                        "株主優待割引運賃" ||
                      state.departure.fare.TicketTypeName === "弊社特価" ? (
                        <span>株主優待券</span>
                      ) : (
                        <span>手配手数料</span>
                      )}
                      <span>]</span>
                    </div>
                    <Price value={state.departure.fare.Charge} />
                  </div>
                  {state.children > 0 && (
                    <div>
                      <div className="amountItems">
                        <div>小人</div>
                        <Price value={state.departure.fare.TicketChild} />
                        <XIcon className="w-3 h-3" />
                        <div>{state.children}人</div>
                      </div>
                      <div className="amountItems text-xs">
                        <div>[航空券代金]</div>
                        <Price value={state.departure.fare.CarrierChildPrice} />
                        <PlusSmIcon className="w-4 h-4" />
                        <div className="flex flex-row justify-end items-center">
                          <span>[</span>
                          {state.departure.fare.TicketTypeName ===
                            "株主優待割引運賃" ||
                          state.departure.fare.TicketTypeName === "弊社特価" ? (
                            <span>株主優待券</span>
                          ) : (
                            <span>手配手数料</span>
                          )}
                          <span>]</span>
                        </div>
                        <Price value={state.departure.fare.Charge} />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
          {state.return && (
            <>
              <div className="amountTitles">
                <div className="font-semibold">
                  フライト
                  <br className="flex md:hidden" />
                  [復路]
                </div>
                <div className="space-y-1">
                  <div className="amountItems">
                    <div>大人</div>
                    <Price value={state.return.fare.TicketAdult} />
                    <XIcon className="w-3 h-3" />
                    <div>{state.adults}人</div>
                  </div>
                  <div className="amountItems text-xs">
                    <div>[航空券代金]</div>
                    <Price value={state.return.fare.CarrierAdultPrice} />
                    <PlusSmIcon className="w-4 h-4" />
                    <div className="flex flex-row justify-end items-center">
                      <span>[</span>
                      {state.return.fare.TicketTypeName ===
                        "株主優待割引運賃" ||
                      state.return.fare.TicketTypeName === "弊社特価" ? (
                        <span>株主優待券</span>
                      ) : (
                        <span>手配手数料</span>
                      )}
                      <span>]</span>
                    </div>
                    <Price value={state.return.fare.Charge} />
                  </div>
                  {state.children > 0 && (
                    <div>
                      <div className="amountItems">
                        <div>小人</div>
                        <Price value={state.return.fare.TicketChild} />
                        <XIcon className="w-3 h-3" />
                        <div>{state.children}人</div>
                      </div>
                      <div className="amountItems text-xs">
                        <div>[航空券代金]</div>
                        <Price value={state.return.fare.CarrierChildPrice} />
                        <PlusSmIcon className="w-4 h-4" />
                        <div className="flex flex-row justify-end items-center">
                          <span>[</span>
                          {state.return.fare.TicketTypeName ===
                            "株主優待割引運賃" ||
                          state.return.fare.TicketTypeName === "弊社特価" ? (
                            <span>株主優待券</span>
                          ) : (
                            <span>手配手数料</span>
                          )}
                          <span>]</span>
                        </div>
                        <Price value={state.return.fare.Charge} />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
