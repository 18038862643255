import { useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import Hotel from "../../components/Hotel";
import CartFree from "../../components/CartFree";
import "../../css/common.css";
import "../../css/order.css";
import { State } from "../../constants";
import Transports from "../../components/Transports";
import HotelFree from "../../components/HotelFree";
import { FormProvider, useForm } from "react-hook-form";
import SelectSearchAreaFree from "../../components/SelectSearchAreaFree";

export default function SelectFree() {
  const [state, setState] = useOutletContext() as any;
  const [expand, setExpand] = useState<State>(State.DEFAULT);
  const [error, setError] = useState<string>();
  const methods = useForm();
  let navigate = useNavigate();

  let onSubmit = () => {
    navigate("/orderFree/confirm");
  };

  return (
    <>
      {expand === State.DEFAULT ? (
        <div className="p-1 grow space-y-4 w-full">
          <div className="commentArea">
            <p>
              各項目の 「<span className="font-semibold">変更</span>
              」ボタンより、お好きな移動手段や宿泊エリアを選択してください。
            </p>
            <p>
              移動手段が「新幹線・特急」または「飛行機」の場合は、往路・復路のご希望の詳細を入力してください。
            </p>
            <p>
              夕食のご相談、その他ご要望等ございましたら、ご希望の内容を入力してください。
            </p>
          </div>

          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <div className="cart">
                <div className="cartTitle">作成中の内容</div>
                <div className="cartContents">
                  <CartFree setExpand={setExpand} />
                </div>
              </div>

              
                <div className="display flex justify-center py-8">
                  <button type="submit" className="btn-enter">
                    内容確認へ進む
                  </button>
                </div>

            </form>
          </FormProvider>
        </div>
      ) : (
        <>
          <div className="commentArea">
            <p>
              ご希望の条件を選択してください。「この内容に変更する」ボタンを押すと、選択した内容が反映されます。
            </p>
          </div>

          <div className="pb-10 md:flex md:flex-row">
            <div className="hidden md:inline space-y-4 w-2/5">
              <div className="cart sticky top-0">
                <div className="cartTitle">作成中の内容</div>
                <div className="cartContents">
                  <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                      <CartFree setExpand={setExpand} />
                    </form>
                  </FormProvider>
                </div>
              </div>
            </div>
            <div className="w-full md:w-3/5 md:ml-4">
              {expand === State.SCHEDULE && (
                <SelectSearchAreaFree setExpand={setExpand} />
              )}
              {expand === State.HOTEL && (
                <HotelFree setExpand={setExpand} />

                /*
                <>
                  {state.destination.region_id === "others" ? (
                    <HotelFree setExpand={setExpand} />
                  ) : (
                    <Hotel setExpand={setExpand} />
                  )}
                </>
                 */
              )}
              {expand === State.TRANSPORT && (
                <Transports setExpand={setExpand} />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}
