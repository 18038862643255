import { RadioGroup } from "@headlessui/react";
import { 
    outwardTimesForTrain, 
    returnTimesForTrain,
    outwardTimes,
    returnTimes,
    departureTimes
} from "../constants";
import { DepartureTimes } from "../types";

export function DepartureTime({
  value,
  onChange,
  schedule,
  transport,
  roundTrip,
}: {
  value: DepartureTimes;
  onChange: (value: DepartureTimes) => void;
  schedule?: string;
  transport?: any;
  roundTrip?: string;
}) {
    let items: any[] = [];

    if(schedule && transport && roundTrip) {
        //列車・日帰り
        if(schedule === 'day') {
            if(transport.id === 'train') {
                if(roundTrip === 'outward') {
                    items = outwardTimesForTrain;
                } else if(roundTrip === 'return') {
                    items = returnTimesForTrain;
                } else {
                    items = outwardTimesForTrain.concat(returnTimesForTrain);
                }
            } else {
                if(roundTrip === 'outward') {
                    items = outwardTimes;
                } else if(roundTrip === 'return') {
                    items = returnTimes;
                } else {
                    items = outwardTimes.concat(returnTimes);
                }
            }
        } else if(schedule === 'stay') {
            if(transport.id === 'train') {
                items = outwardTimesForTrain.concat(returnTimesForTrain);
            } else {
                items = outwardTimes.concat(returnTimes)
            }
        }
    } else {
        items = outwardTimes.concat(returnTimes);
    }

    return (
        <div className="w-full flex flex-col">
            <RadioGroup value={value} onChange={onChange}>
                <RadioGroup.Label className="sr-only">
                    departure time ranges
                </RadioGroup.Label>
                <div className="flex flex-row flex-wrap flex-mbtn md:flex-nowrap justify-between md:justify-start md:space-x-2">
                    {items.map((item) => (
                        <RadioGroup.Option
                            key={item.id}
                            value={item}
                            className={({ checked }) => `${checked ? "bg-orange-light border-dGray" : "bg-white border-gray"} selectBtn`}>
                            <div className="font-medium w-full text-center">
                                <div className="text-base">{item.label}</div>
                                <div className="text-xs">({item.time})</div>
                            </div>
                        </RadioGroup.Option>
                    ))}
                </div>
            </RadioGroup>
        </div>
    );
}
