import style from "../../css/info.module.css";

export function JoukenBosyu() {
  return (
    <div className="w-full max-w-screen-lg mx-auto my-4 md:my-8 px-2">
      <h2 className={style.pageTitle}>[旅行条件書] 国内募集型企画旅行条件書</h2>
      <p className={style.content}>
        お申込みの際は、旅行条件書を印刷やハードディスクにダウンロードするなど、必ず保存してください。
      </p>
      <p className={`${style.content} ${style.mt4}`}>
        この旅行は、ジョルダン株式会社(以下「当社」という)が旅行を企画して実施するものであり、この旅行に参加されるお客様は、当社と募集型企画旅行契約(以下「契約」という)を締結することになります。
      </p>
      <p className={style.content}>
        また契約の内容・条件は、各コースごとに記載されている条件のほか、下記条件、出発前にお渡しする「行程ご案内」と称する確定書面(以下「行程ご案内」という)及び当社旅行業約款募集型企画旅行契約の部(以下「当社約款」という)によります。
      </p>

      <h3 className={style.title}>１．旅行のお申込み方法</h3>
      <p className={style.content}>
        (1)
        所定の旅行申込書(以下「申込書」という)に所定事項を記入の上、次に定める申込金を添えてお申込みいただきます。申込金は、旅行代金又は取消料若しくは違約金のそれぞれ一部又は全部として取り扱います。
      </p>

      <table className={style.tblHalf}>
        <thead>
          <tr className={style.tblHalfTR}>
            <th>旅行代金(お1人様)</th>
            <th>申込金</th>
          </tr>
        </thead>
        <tbody>
          <tr className={style.tblHalfTR}>
            <td>30,000円未満</td>
            <td>6,000円</td>
          </tr>
          <tr className={style.tblHalfTR}>
            <td>30,000円以上60,000円未満</td>
            <td>12,000円</td>
          </tr>
          <tr className={style.tblHalfTR}>
            <td>60,000円以上100,000円未満</td>
            <td>20,000円</td>
          </tr>
          <tr className={style.tblHalfTR}>
            <td>100,000円以上150,000円未満</td>
            <td>30,000円</td>
          </tr>
          <tr className={style.tblHalfTR}>
            <td>150,000円以上</td>
            <td>旅行代金の20％</td>
          </tr>
        </tbody>
      </table>

      <p className={`${style.content} ${style.mt4}`}>
        (2)
        当社は、電話、郵便、ファクシミリその他の通信手段による契約の予約を受付けます。この場合、予約の時点では契約は成立しておらず、お客様は、当社が予約を承諾した日の翌日から起算して３日以内に申込書と申込金を提出していただきます(受付は、当社の営業時間内とし、営業時間終了後に着信したファクシミリ、電子メール等は、翌営業日の受付となります)。この期間内に申込金のお支払いがない場合は、当社は、予約がなかったものとして取り扱います。
      </p>
      <p className={style.content}>
        (3)
        通信契約により契約の締結をご希望されるお客様との旅行条件は次のほか、第２項(2)、第12項(1)及び第17項(2)によります。
      </p>
      <p className={style.contentml2}>
        [1]
        当社は、当社が提携するクレジットカード会社(以下「提携会社」という)のカード会員(以下「会員」という)より所定の伝票への「会員の署名なくして旅行代金や取消料等のお支払いを受ける」とを条件に「電話、ファクシミリ、インターネットその他の通信手段による旅行のお申込み」を受けて契約を締結することがあります(以上を「通信契約」といいます)。なお、受託旅行業者により当該取り扱いができない場合や取り扱いができるカードの種類に制約がある場合があります。
      </p>
      <p className={style.contentml2}>
        [2]
        通信契約の申込みに際し、会員のお客様は申込みをしようとする「募集型企画旅行の名称」、「出発日」等に加えて「カード名」、「会員番号」、「カード有効期限」等を当社にお申し出いただきます。
      </p>
      <p className={style.contentml2}>
        [3]
        通信契約での「カード利用日」とは、お客様及び当社が契約に基づく旅行代金等の支払い又は払戻債務を履行すべき日とし、前者の場合は契約成立日、後者の場合は契約解除のお申し出のあった日となります。
      </p>
      <p className={style.contentml2}>
        [4]
        与信等の理由によりお客様のお申し出のクレジットカードでのお支払いができない場合、当社は通信契約を解除し、第14項の取消料と同額の違約料をお支払いいただきます。ただし、当社が別途指定する期日までに現金による旅行代金のお支払いをいただいた場合はこの限りではありません。
      </p>

      <h3 className={style.title}>２．契約の成立時期</h3>
      <p className={style.content}>
        (1)
        お客様との契約は、当社が契約の締結を承諾し、申込金を受理した時に成立します。具体的には、次によります。
      </p>
      <p className={style.contentml2}>
        [1]
        店頭(及び当社の外務員による訪問販売)の場合は、当社が契約の締結を承諾し、当社が第１項(1)の申込金を受理した時。
      </p>
      <p className={style.contentml2}>
        [2]
        電話等による契約の予約の場合は、当社が予約の承諾の旨を通知した日の翌日から起算して３日目に当たる日までに当社がお客様から第１項(1)の申込金を受理した時。
      </p>
      <p className={style.content}>
        (2)
        通信契約は、当社が通信契約の締結を承諾する旨の通知を発した時に成立します。ただし、当該契約の申込みを承諾する旨の通知を電子メール、ファクシミリ、留守番電話等の電子承諾通知による方法で通知する場合は、当該通知がお客様に到達した時に成立します。
      </p>

      <h3 className={style.title}>３．お申込み条件 </h3>
      <p className={style.content}>
        (1) 未成年の方のみのご旅行の場合、保護者(法定代理人)の同意書が必要です。
      </p>
      <p className={style.content}>
        (2)
        中学校入学前の方のみのご旅行の場合、当社は、お申込みをお断りすることがあります。
      </p>
      <p className={style.content}>
        (3)
        最少催行人員は、特に明示をしていない限り１名様(ただし、コースに１名様での参加ができない旨の表示がある場合は２名様)とします。
      </p>
      <p className={style.content}>
        (4)
        特定旅客層を対象とした旅行、又は特定の目的をもつ旅行については、年齢、資格、技能その他の条件が当社の指定する条件に合致しない場合は、お申込みをお断りすることがあります。
      </p>
      <p className={style.content}>
        (5)
        身体に障害をお持ちの方、健康を害している方、妊娠中の方、補助犬使用者の方、介助者の同行、車椅子の手配等特別な配慮を必要とする方は、その旨をお申し出ください。当社は可能な範囲内でこれに応じます。この場合、当社は、旅行の安全かつ円滑な実施のために介助者若しくは同伴者の同行、医師の診断書の提出、コースの一部について内容を変更すること等を条件とすることがあります。また、お申込みをお断りすることがあります。なお、お客様からのお申し出に基づき、当社がお客様のために講じた特別な措置に要する費用は、お客様のご負担となります。
      </p>
      <p className={style.content}>
        (6)
        お客様が暴力団、暴力団員、暴力団関係者、その他反社会的勢力であると判明した場合は、お申込みをお断りすることがあります。
      </p>
      <p className={style.content}>
        (7)
        その他当社の業務上の都合があるときは、お申込みをお断りする場合があります。
      </p>

      <h3 className={style.title}>４．契約責任者によるお申込み</h3>
      <p className={style.content}>
        (1)
        当社は、団体・グループを構成するお客様の代表者(以下「契約責任者」という)から旅行のお申込みがあった場合、契約の締結及び解除等に関する一切の代理権を契約責任者が有しているものとみなして当該契約に関する取引等を契約責任者との間で行います。
      </p>
      <p className={style.content}>
        (2)
        契約責任者は、当社が定める日までに、構成者の名簿を当社に提出しなければなりません。
      </p>
      <p className={style.content}>
        (3)
        当社は、契約責任者が構成者に対して現に負い、又は将来負うことが予測される債務又は義務については、何らの責任を負うものではありません。
      </p>
      <p className={style.content}>
        (4)
        当社は、契約責任者が団体・グループに同行しない場合、旅行開始後においては、あらかじめ契約責任者が選任した構成者を契約責任者とみなします。
      </p>

      <h3 className={style.title}>５.「行程ご案内」(確定書面)の交付</h3>
      <p className={style.content}>
        当社は、旅行日程、主要な利用運送・宿泊機関等に関する確定旅行内容を契約書面において記載できない場合は、確定情況を記載した「行程ご案内」を遅くとも旅行開始日の前日までにお客様に交付します。また、交付期日前であってもお問い合わせいただければ手配情況についてご説明します。
      </p>

      <h3 className={style.title}>６．旅行代金の適用及びお支払い期限</h3>
      <p className={style.content}>
        (1)
        特に注釈のない限り、満12歳以上の方はおとな旅行代金、満３歳以上12歳未満の方はこども旅行代金となります。
      </p>
      <p className={style.content}>
        (2)
        旅行代金におとな・こどもの区分表示がない場合は、満３歳以上の全ての方に当該旅行代金を適用します。
      </p>
      <p className={style.content}>
        (3)
        旅行代金は、各コースごとに表示しています。出発日と利用人数でご確認ください。
      </p>
      <p className={style.content}>
        (4)
        追加代金とは、航空便の選択、航空機の等級の選択、宿泊施設指定の選択、延泊等による宿泊代金等、基本旅行代金に追加する旅行代金をいいます。
      </p>
      <p className={style.content}>
        (5)
        旅行代金は、第１項(1)の「申込金」、第14項の「取消料」、第13項(1)の「違約料」及び第22項の「変更補償金」の額の算出の際の基準となります。募集広告、パンフレット、ホームページにおける「旅行代金」の計算方法は、「旅行代金として表示した金額」プラス「追加代金として表示した金額」マイナス「割引代金として表示した金額」となります。
      </p>
      <p className={style.content}>
        (6)
        旅行代金(申込金を差し引いた残額)は、旅行開始日の前日から起算してさかのぼって14日前までに全額お支払いいただきます。ただし、旅行開始日の前日から起算してさかのぼって13日前以降にお申込みされた場合は、お申込時に全額お支払いいただきます。
      </p>

      <h3 className={style.title}>７．旅行代金に含まれるもの</h3>
      <p className={style.content}>
        (1)
        旅行日程に明示した運送機関の運賃・料金(注釈のない限り航空機は普通席)、宿泊費、食事代、消費税等の諸税・サービス料金、旅客施設使用料(空港により必要な場合)及び特に明示したその他の費用等。
      </p>
      <p className={style.content}>
        (2) 添乗員が同行するコースの添乗員経費等。
      </p>
      <p className={style.content}>
        (3)
        各コースに表示した「旅行代金に含まれるもの」として明示したその他の費用。
      </p>
      <p className={style.content}>
        上記代金は、お客様のご都合により一部ご利用されなくても払戻しはいたしません。
      </p>

      <h3 className={style.title}>８．旅行代金に含まれないもの</h3>
      <p className={style.content}>
        第７項のほかは旅行代金に含まれません。その一部を例示します。
      </p>
      <p className={style.content}>
        (1) 超過手荷物料金(規定の重量、容積、個数を超える分について)。
      </p>
      <p className={style.content}>
        (2)
        コースに含まれない交通費、飲食代等の諸費用及びクリーニング代、電話料等個人的性質の諸費用及びそれに伴う税・サービス料。
      </p>
      <p className={style.content}>
        (3)
        ご希望者のみご参加されるオプショナルプラン・オプショナルツアーの代金。
      </p>

      <h3 className={style.title}>９．契約内容の変更</h3>
      <p className={style.content}>
        当社は、契約の締結後であっても、天災地変、運送・宿泊機関等の旅行サービス提供の中止、官公署の命令、当初の運行計画によらない運送サービスの提供(遅延、目的地空港の変更等)その他当社の関与し得ない事由が生じた場合において、旅行の安全かつ円滑な実施を図るためやむを得ないときは、お客様にあらかじめ速やかに当該事由が関与し得ないものである理由及び当該事由との因果関係を説明して、旅行日程、旅行サービスの内容その他の契約の内容を変更することがあります。ただし、緊急の場合において、やむを得ないときは、変更後に説明します。
      </p>

      <h3 className={style.title}>10．旅行代金の額の変更</h3>
      <p className={style.content}>
        (1)
        当社は、利用する運送機関の運賃・料金が、著しい経済情勢の変化等により、通常想定される程度を大幅に越えて増額又は減額されるときは、その増減の範囲内で旅行代金を変更することがあります。
      </p>
      <p className={style.content}>
        (2)
        本項(1)により旅行代金を増額するときは、当社は、旅行開始日の前日から起算してさかのぼって16日前までにお客様に通知します。
      </p>
      <p className={style.content}>
        (3)
        本項(1)により旅行代金を減額するときは、運賃・料金の減少額だけ旅行代金を減額します。
      </p>
      <p className={style.content}>
        (4)
        第９項に基づく旅行内容の変更により、旅行の実施に要する費用(当該変更により提供を受けなかった旅行サービスに対する取消料、違約料その他既に支払い、又はこれから支払わなければならない費用を含む)に増額又は減額が生じる場合には、当社は、その差額だけ旅行代金を変更することがあります。ただし、増額の場合においては、運送・宿泊機関等が当該旅行サービスの提供を行っているにもかかわらず、運送・宿泊機関等の座席、部屋その他の諸設備の不足が発生したことによる場合を除きます。
      </p>
      <p className={style.content}>
        (5)
        運送・宿泊機関等の利用人数により旅行代金が異なる旨を契約書面に記載した場合、契約成立後に当社の責に帰すべき事由によらず当該利用人数が変更になったときは、旅行代金を変更します。
      </p>

      <h3 className={style.title}>11．お客様の交替</h3>
      <p className={style.content}>
        お客様は、予め当社の承諾を得て、契約上の地位を第三者に譲渡することができます。この場合、所定の金額の手数料をお支払いいただきます。また、契約上の地位の譲渡は、当社の承諾があった時に効力を生じます。なお、航空便の予約や氏名変更ができない等の理由により、当社は、お客様の交替をお断りすることがあります。
      </p>

      <h3 className={style.title}>12．お客様による契約の解除(旅行開始前)</h3>
      <p className={style.content}>
        (1)
        お客様は、いつでも第14項に定める取消料を当社に支払って契約を解除することができます。ただし、契約解除のお申し出の受付は、お申込みされた当社の営業時間内とします(営業時間終了後に着信したファクシミリ、電子メール等は、翌営業日の受付となります)。通信契約を解除する場合、当社は、提携会社のカードにより所定の伝票への会員の署名なくして取消料の支払いを受けます。
      </p>
      <p className={style.content}>
        (2)
        お客様は、次に掲げる場合は本項(1)の規定にかかわらず、旅行開始前に取消料を支払うことなく契約を解除することができます。
      </p>
      <p className={style.contentml2}>
        [1]
        当社によって契約内容が変更されたとき。ただし、その変更が第22項の表(A)左欄に掲げるものその他重要なものであるときに限ります。
      </p>
      <p className={style.contentml2}>
        [2] 第10項(2)の規定に基づいて旅行代金が増額されたとき。
      </p>
      <p className={style.contentml2}>
        [3]
        天災地変、戦乱、暴動、運送・宿泊機関等の旅行サービス提供の中止、官公署の命令その他の事由が生じた場合において、旅行の安全かつ円滑な実施が不可能となり、又は不可能となるおそれが極めて大きいとき。
      </p>
      <p className={style.contentml2}>
        [4]
        当社がお客様に対し、第５項の期日までに、「行程ご案内」を交付しなかったとき。
      </p>
      <p className={style.contentml2}>
        [5]
        当社の責に帰すべき事由により、契約書面に記載した旅行日程に従った旅行の実施が不可能となったとき。
      </p>

      <h3 className={style.title}>13．当社による契約の解除(旅行開始前)</h3>
      <p className={style.content}>
        (1)
        お客様が第６項(6)の期日までに旅行代金を支払われないときは、当社は、その翌日においてお客様が契約を解除したものとすることがあります。この場合、取消料と同額の違約料をお支払いいただきます。
      </p>
      <p className={style.content}>
        (2)
        当社は、次に掲げる場合、お客様に理由を説明して契約を解除することがあります。
      </p>
      <p className={style.contentml2}>
        [1]
        お客様が当社のあらかじめ明示した性別、年齢、資格、技能その他旅行参加条件を満たしていないことが判明したとき。
      </p>
      <p className={style.contentml2}>
        [2]
        お客様が病気、必要な介助者の不在その他の事由により、当該旅行に耐えられないと認められるとき。
      </p>
      <p className={style.contentml2}>
        [3]
        お客様が他のお客様に迷惑を及ぼし、又は団体行動の円滑な実施を妨げるおそれがあると認められるとき。
      </p>
      <p className={style.contentml2}>
        [4] お客様が契約内容に関し合理的な範囲を超える負担を求めたとき。
      </p>
      <p className={style.contentml2}>
        [5]
        お客様の人数が契約書面に記載した最少催行人員に満たないとき。この場合は、旅行開始日の前日から起算してさかのぼって14日前までに旅行を中止する旨をお客様に通知します。
      </p>
      <p className={style.contentml2}>
        [6]
        スキーを目的とする旅行における降雪量の不足のように、当社があらかじめ明示した旅行実施条件が成就しないとき、あるいはそのおそれが極めて大きいとき。
      </p>
      <p className={style.contentml2}>
        [7]
        天災地変、戦乱、暴動、運送・宿泊機関等の旅行サービスの提供の中止、官公署の命令その他の当社の関与し得ない事由が生じた場合において、契約書面に記載した旅行日程に従った旅行の安全かつ円滑な実施が不可能となり又は不可能となるおそれが極めて大きいとき。
      </p>
      <p className={style.contentml2}>
        [8]
        お客様が暴力団、暴力団員、暴力団関係者、その他反社会的勢力であると判明したとき。
      </p>
      <p className={style.content}>
        (3)
        当社は、本項(2)により契約を解除したときは、既に収受している旅行代金(又は申込金)の全額をお客様に払戻します。
      </p>

      <h3 className={style.title}>14．取消料</h3>
      <p className={style.content}>
        契約成立後、お客様のご都合で契約を解除する場合、旅行代金に対してお客様１名につき下記の料率で取消料をいただきます。なお、複数人数のご参加で、一部のお客様が契約を解除される場合は、ご参加のお客様から運送・宿泊機関等の(１台・１室あたりの)ご利用人数の変更に対する差額代金をそれぞれいただきます。
      </p>

      <table className={style.tblHalf}>
        <thead>
          <tr className={style.tblHalfTR}>
            <th>取消日</th>
            <th>取消料率</th>
          </tr>
        </thead>
        <tbody>
          <tr className={style.tblHalfTR}>
            <td>a) 21日前まで</td>
            <td>無料</td>
          </tr>
          <tr className={style.tblHalfTR}>
            <td>b) 20日～8日前まで</td>
            <td>20％</td>
          </tr>
          <tr className={style.tblHalfTR}>
            <td>c) 7日～2日前まで</td>
            <td>30％</td>
          </tr>
          <tr className={style.tblHalfTR}>
            <td>d) 旅行開始日前日</td>
            <td>40％</td>
          </tr>
          <tr className={style.tblHalfTR}>
            <td>e) 旅行開始日当日(fを除く)</td>
            <td>50％</td>
          </tr>
          <tr className={style.tblHalfTR}>
            <td>f) 旅行開始後又は無連絡不参加</td>
            <td>100％</td>
          </tr>
        </tbody>
      </table>

      <p className={`${style.content} ${style.mt4}`}>
        ※a.b.cの取消日は旅行開始日の前日から起算してさかのぼります。
      </p>
      <p className={style.content}>
        ※出発日・コース・利用便・宿泊施設等行程中の一部を変更される場合も上記取消料の対象となります。ただし、当該パンフレットの「取消料等」に当社の定める期限を記載している場合は、当該期限以降の変更はできません。
      </p>
      <p className={style.content}>
        ※オプショナルプランも上記取消料率による取消料が利用日を基準として別途適用されます。ただし、旅行開始後の取消料は、100％となります。
      </p>

      <h3 className={style.title}>15．お客様による契約の解除(旅行開始後)</h3>
      <p className={style.content}>
        (1)
        お客様のご都合により旅行サービスの一部を受領されず、又は途中で離団された場合は、お客様の権利放棄とみなし、一切の払戻しはいたしません。
      </p>
      <p className={style.content}>
        (2)
        お客様は、旅行開始後において、お客様の責に帰すべき事由によらず契約書面に記載した旅行サービスを受領することができなくなったとき又は当社がその旨を告げたときは、第12項(1)の規定にかかわらず、取消料を支払うことなく、受領できなくなった部分の契約を解除することができます。この場合において、当社は、受領できなくなった当該旅行サービスに対して取消料、違約料その他既に支払い、又はこれから支払わなければならない費用(当社の責に帰すべき事由によるものでないときに限ります)を差し引いた金額をお客様に払戻します。
      </p>

      <h3 className={style.title}>16．当社による契約の解除(旅行開始後)</h3>
      <p className={style.content}>
        (1)
        当社は、次に掲げる場合において、旅行開始後であっても、お客様に理由を説明して契約の一部を解除することがあります。
      </p>
      <p className={style.contentml2}>
        [1]
        お客様が病気、必要な介助者の不在その他の理由により、当該旅行に耐えられないとき。
      </p>
      <p className={style.contentml2}>
        [2]
        お客様が旅行を安全かつ円滑に実施するための添乗員、現地係員その他の者による当社の指示への違背、これらの者又は同行する他の旅行者に対する暴行又は脅迫等などにより団体行動の規律を乱し、当該旅行の安全かつ円滑な実施を妨げるとき。
      </p>
      <p className={style.contentml2}>
        [3]
        天災地変、戦乱、暴動、運送・宿泊機関等の旅行サービスの提供の中止、官公署の命令その他の当社の関与し得ない事由が生じた場合であって、旅行の継続が不可能となったとき。
      </p>
      <p className={style.contentml2}>
        [4]
        お客様が暴力団、暴力団員、暴力団関係者、その他反社会的勢力であると判明したとき。
      </p>
      <p className={style.content}>
        (2)
        当社が本項(1)の規定に基づき契約の解除をしたときであっても、お客様が既に提供を受けた旅行サービスに関する当社の債務は、有効な弁済がなされたものとします。当社は、旅行代金のうちお客様がいまだその提供を受けていない旅行サービスに対して取消料、違約料その他既に支払い、又はこれから支払わなければならない費用を差し引いた金額をお客様に払戻します。
      </p>
      <p className={style.content}>
        (3)
        当社は、本項(1)[1][3]の規定により契約を解除したときは、お客様の求めに応じて、出発地に戻るための必要な手配をします。この場合に要する一切の費用はお客様のご負担となります。
      </p>

      <h3 className={style.title}>17．旅行代金の払戻し</h3>
      <p className={style.content}>
        (1)
        当社は、第10項(3)から(5)までの規定による旅行代金の減額又は第12項から第16項までの規定による契約の解除によってお客様に対し払戻すべき金額が生じたときは、旅行開始前の解除による払戻しにあっては解除の翌日から起算して７日以内に、減額又は旅行開始後の解除による払戻しにあっては契約書面に記載した旅行終了日の翌日から起算して30日以内にお客様に対し当該金額を払戻します。ただし、第18項(1)のクーポン類の引渡し後の払戻しに際して当該クーポン類を当社に提出していただく必要があり、それらの提出がない場合は、旅行代金の払戻しができないことがあります。
      </p>
      <p className={style.content}>
        (2)
        通信契約を締結したお客様に本項(1)の払戻しすべき金額が生じたときは、当社は、提携会社のカード会員規約に従って払戻します。この場合において、当社は、旅行開始前の解除による払戻しにあっては解除の翌日から起算して７日以内に、減額又は旅行開始後の解除による払戻しにあっては契約書面に記載した旅行終了日の翌日から起算して30日以内にお客様に対し払戻すべき額を通知するものとし、お客様に当該通知を行った日をカード利用日とします。
      </p>

      <h3 className={style.title}>18．添乗員等</h3>
      <p className={style.content}>
        (1)
        添乗員同行と記載されたコースを除き、添乗員は同行しません。お客様が旅行サービスを受けるために必要なクーポン類をお渡しいたしますので、旅行サービスを受けるための手続きはお客様ご自身で行っていただきます。なお、現地における当社の連絡先は、「行程ご案内」又は契約書面に明示します。また、天候等不可抗力によって旅行サービスの受領ができなくなった場合は、当該部分の代替サービスの手配や手続きはお客様ご自身で行っていただきます。
      </p>
      <p className={style.content}>
        (2)
        添乗員同行と記載されたコースには添乗員が同行し、原則として契約書面に定められた行程を安全かつ円滑に実施するために必要な業務を行います。添乗員の業務の時間帯は、原則として８時から20時までとします。
      </p>
      <p className={style.content}>
        (3)
        お客様は、団体で行動するときは、旅行を安全かつ円滑に実施するための添乗員又は現地係員等当社の指示に従わなければなりません。
      </p>

      <h3 className={style.title}>19．保護措置の実施</h3>
      <p className={style.content}>
        当社は、旅行中のお客様が疾病、傷害等により保護を要する状態にあると認めたときは、必要な措置を講ずることがあります。この場合において、これが当社の責に帰すべき事由によるものではないときは、当該措置に要した費用はお客様の負担とし、お客様は、当該費用を当社が指定する期日までに当社の指定する方法で支払わなければなりません。)
      </p>

      <h3 className={style.title}>20．当社の責任</h3>
      <p className={style.content}>
        (1)
        当社は、契約の履行に当たって、当社又は手配代行者が故意又は過失によりお客様に損害を与えたときは、損害発生の翌日から２年以内に当社に対して通知があったときに限り、その損害を賠償します。ただし、手荷物の損害は、損害発生の翌日から14日以内に当社に対して通知があったときに限り、お客様１名につき15万円を限度(当社に故意又は重大な過失がある場合を除きます)として賠償します。
      </p>
      <p className={style.content}>
        (2)
        お客様が天災地変、戦乱、暴動、運送、宿泊機関等の旅行サービスの提供の中止、官公署の命令その他の当社又は手配代行者の関与し得ない事由により損害を被ったときは、当社は、本項(1)の場合を除き、その損害を賠償する責任を負うものではありません。
      </p>

      <h3 className={style.title}>21．特別補償</h3>
      <p className={style.content}>
        (1)
        当社は、前項に基づく当社の責任が生ずるか否かを問わず、当社約款「特別補償規程」に従い、お客様が募集型企画旅行参加中に急激かつ偶然な外来の事故により生命、身体に被られた一定の損害について、旅行者１名につき死亡補償金として1,500万円、入院見舞金として入院日数により２万円～20万円、通院見舞金として１万円～５万円、携帯品にかかる損害保証金(15万円を限度、ただし、一個又は一対についての補償限度は10万円)を支払います。ただし、現金、クレジットカード、貴重品、撮影済みのフィルム、CD-ROM、光ディスク等記録媒体に書かれた原稿(記録媒体自体は補償対象)、その他同規程第18条第２項に定める品目については補償しません。
      </p>
      <p className={style.content}>
        (2)
        本項(1)の損害について当社が前項(1)の規程に基づく責任を負うときは、その責任に基づいて支払うべき損害補償金の額の限度において、当社が支払うべき本項(1)の補償金は、当該損害賠償金とみなします。
      </p>
      <p className={style.content}>
        (3)
        お客様が募集型企画旅行参加中に被られた損害が、お客様の故意、酒酔い運転、故意の法令違反、法令に違反するサービス提供の受領、山岳登はん(ピッケル等の登山用具を使用するもの)、スカイダイビング、ハンググライダー搭乗等同規程第３条及び第５条に該当する場合は、本項(1)の補償金及び見舞金を支払いません。ただし、当該運動が募集型企画旅行の日程に含まれているときは、この限りではありません。
      </p>
      <p className={style.content}>
        (4)
        当社の募集型企画旅行参加中のお客様を対象として、別途の旅行代金を収受して当社が実施する募集型企画旅行については、主たる契約の一部として取り扱います。
      </p>
      <p className={style.content}>
        (5)
        契約書面において、当社の手配による旅行サービスの提供が一切行われない旨が明示された日については、当該日にお客様が被った損害について補償金が支払われない旨を明示した場合に限り、募集型企画旅行参加中とはいたしません。
      </p>

      <h3 className={style.title}>22．旅程保証</h3>
      <p className={style.content}>
        (1)
        当社は、表(A)左欄に掲げる契約内容の重要な変更(サービスの提供が行われているにもかかわらず、運送・宿泊機関等の座席、部屋その他の諸設備の不足が発生したことによるもの以外の次の[1][2]の変更を除く)が生じた場合は、旅行代金に同表右欄に記載する率を乗じた額の変更補償金を旅行終了日の翌日から起算して30日以内に支払います。(お客様の同意を得て同等価値以上の品物又はサービスの提供とすることがあります)。ただし、旅行サービスの提供を受けた日時及び順序の変更は対象外となります。
      </p>
      <p className={style.contentml2}>
        [1]
        天災地変、戦乱、暴動、官公署の命令、運送・宿泊機関等の旅行サービス提供の中止、当初の運送計画によらない運送サービスの提供、旅行参加者の生命又は身体の安全確保のため必要な措置としての変更。
      </p>
      <p className={style.contentml2}>
        [2] 第12項から第16項までの規定により契約が解除された部分に係る変更。
      </p>
      <p className={style.content}>
        (2)
        当社が一つの契約に基づきお支払いする変更補償金の額は、旅行代金に15％を乗じた額をもって限度とします。また、お客様１名に対して支払うべき変更補償金の額が1,000円未満であるときは、当社は、変更補償金を支払いません。
      </p>
      <p className={style.content}>
        (3)
        当社が本項の規定に基づき変更補償金を支払った後に、当該変更について第20項の規定に基づく損害賠償責任が明らかになった場合には、当社は、既にお支払いした変更補償金の額を差し引いた額の損害賠償金を支払います。
      </p>

      <table className={style.tbl}>
        <thead>
          <tr className={style.tblTR}>
            <th rowSpan={2}>変更補償金の支払いが必要となる変更</th>
            <th colSpan={2}>1件あたりの率(％)</th>
          </tr>
          <tr>
            <th>旅行開始前</th>
            <th>旅行開始後</th>
          </tr>
        </thead>
        <tbody>
          <tr className={style.tblTR2}>
            <td>[1] 契約書面に記載した旅行開始日又は旅行終了日の変更</td>
            <td>1.5</td>
            <td>3.0</td>
          </tr>
          <tr className={style.tblTR2}>
            <td>
              [2]
              契約書面に記載した入場する観光地又は観光施設(レストランを含みます)その他の旅行の目的地の変更{" "}
            </td>
            <td>1.0</td>
            <td>2.0</td>
          </tr>
          <tr className={style.tblTR2}>
            <td>
              [3]
              契約書面に記載した運送機関の等級又は設備のより低い料金のものへの変更(変更後の等級及び設備の料金の合計額が契約書面に記載した等級及び設備のそれを下回った場合に限ります)
            </td>
            <td>1.0</td>
            <td>2.0</td>
          </tr>
          <tr className={style.tblTR2}>
            <td>[4] 契約書面に記載した運送機関の種類又は会社名の変更</td>
            <td>1.0</td>
            <td>2.0</td>
          </tr>
          <tr className={style.tblTR2}>
            <td>
              [5]
              契約書面に記載した(本邦内の)旅行開始地たる空港(出発空港)又は旅行終了地たる空港(帰着空港)の異なる便への変更
            </td>
            <td>1.0</td>
            <td>2.0</td>
          </tr>
          <tr className={style.tblTR2}>
            <td>[6] 契約書面に記載した宿泊機関の種類又は名称の変更</td>
            <td>1.0</td>
            <td>2.0</td>
          </tr>
          <tr className={style.tblTR2}>
            <td>
              [7]
              契約書面に記載した宿泊機関の客室の種類、設備、景観その他の客室の条件の変更
            </td>
            <td>1.0</td>
            <td>2.0</td>
          </tr>
          <tr className={style.tblTR2}>
            <td>
              [8]
              前各号に掲げる変更のうち契約書面のツアー・タイトル中に記載があった事項の変更
            </td>
            <td>2.5</td>
            <td>5.0</td>
          </tr>
        </tbody>
      </table>

      <p className={`${style.content} ${style.mt4}`}>
        (注1)
        「旅行開始前」とは、当該変更について旅行開始日の前日までにお客様に通知した場合をいい、「旅行開始後」とは、当該変更について旅行開始当日以降にお客様に通知した場合をいいます。
      </p>
      <p className={style.content}>
        (注2)
        「行程ご案内」(確定書面)が交付された場合には、「契約書面」とあるのを「行程ご案内」と読み替えた上で、この表を適用します。この場合において、契約書面の記載内容と「行程ご案内」の記載内容との間又は「行程ご案内」の記載内容と実際に提供された旅行サービスの内容との間に変更が生じたときは、それぞれの変更につき一件として取り扱います。
      </p>
      <p className={style.content}>
        (注3)
        [3]又[4]に掲げる変更に係る運送機関が宿泊設備の利用を伴うものである場合は、一泊につき一件として取り扱います。
      </p>
      <p className={style.content}>
        (注4)
        [4]に掲げる運送機関の会社名の変更については、等級又は設備のより高いものへの変更を伴う場合には適用しません。
      </p>
      <p className={style.content}>
        (注5)
        [4]又は[6]若しくは[7]に掲げる変更が一乗車船又は一泊の中で複数生じた場合であっても、一乗車船又は一泊につき一件として取り扱います。
      </p>
      <p className={style.content}>
        (注6)
        [8]に掲げる変更については、[1]から[7]までの率を適用せず、[8]によります。
      </p>

      <h3 className={style.title}>23．お客様の責任</h3>
      <p className={style.content}>
        (1)
        お客様の故意又は過失により当社が損害を被ったときは、当社は、お客様から損害の賠償を申し受けます。
      </p>
      <p className={style.content}>
        (2)
        お客様は、当社から提供される情報を活用し、お客様の権利・義務その他契約の内容について理解するように努めなければなりません。
      </p>
      <p className={style.content}>
        (3)
        お客様は、旅行開始後に、契約書面に記載された旅行サービスについて、記載内容と異なるものと認識したときは、旅行地において速やかに当社、当社の手配代行者又は旅行サービス提供者にその旨を申し出なければなりません。
      </p>

      <h3 className={style.title}>24．国内旅行傷害保険への加入</h3>
      <p className={style.content}>
        病気、けがをした場合、多額の治療費、移送費等がかかることがあります。また、事故の場合、加害者への賠償金請求や賠償金の回収が大変困難なのが実情です。これらの治療費、移送費、また死亡・後遺障害等を担保するため、お客様ご自身で充分な額の国内旅行傷害保険に加入することをお勧めします。詳細は、お申込み窓口の係員にお問い合わせください。
      </p>

      <h3 className={style.title}>25．事故等のお申し出について</h3>
      <p className={style.content}>
        旅行中に、事故などが生じた場合は、直ちに「行程ご案内」等でお知らせする連絡先にご通知ください(もし、通知できない事情がある場合は、その事情がなくなり次第ご通知ください)。
      </p>

      <h3 className={style.title}>26．旅行条件・旅行代金の基準期日</h3>
      <p className={style.content}>
        この旅行条件の基準日と旅行代金の基準日は、当該パンフレット等に明示した日となります。
      </p>

      <h3 className={style.title}>27．個人情報のお取り扱いについて(重要)</h3>
      <p className={style.content}>
        (1)
        当社ホームページ記載の国内募集型企画旅行への旅行申込みの際に提出された申込書に記載された個人情報について、お客様との間の連絡のために利用させていただくほか、お客様がお申込みいただいた旅行において運送・宿泊機関等(主要なものについては各コース等に記載されています)の提供するサービスの手配及びそれらのサービスの受領のための手続きに必要な範囲内で利用させていただきます。＊このほか、当社では、[1]当社と提携する企業の商品やサービス、キャンペーンのご案内。[2]旅行参加後のご意見やご感想の提供のお願い。[3]アンケートのお願い。[4]特典サービスの提供。[5]統計資料の作成。に、お客様の個人情報を利用させていただくことがあります。
      </p>
      <p className={style.content}>
        (2)
        当社が取得する個人情報は、お客様の氏名、年齢、性別、電話番号、住所、メールアドレス、その他コースにより当社が旅行を実施するうえで必要となる最小限の範囲のお客様の個人情報とします。また介助者の同行、車椅子の手配等特別な配慮を必要とする場合で、当社が可能な範囲内でこれに応ずる(又は応じられない旨の回答をする)目的のため、上記以外の個人情報の取得をさせていただくことがありますが、これは当社が手配等をするうえで必要な範囲内とします。
      </p>
      <p className={style.content}>
        (3)
        当社が本項(2)の個人情報を取得することについてお客様の同意を得られない場合は、当社は、募集型企画旅行契約の締結に応じられないことがあります。また同意を得られないことにより、お客様のご希望される手配等が行えない場合があります。
      </p>
      <p className={style.content}>
        (4)
        当社は、お申し込みいただいた旅行の手配のために、運送・宿泊機関等に対し、お客様の氏名、年齢、性別、電話番号、その他手配をするために必要な範囲内での情報を、あらかじめ電子的方法等で送付することによって提供いたします。
      </p>
      <p className={style.content}>
        (5)
        当社は、旅行先において、お客様の手荷物運送等、免税店でのお買い物等の便宜のため、当社の保有するお客様の個人データを運送業者や免税店に提供することがあります。この場合、お客様の氏名、搭乗日及び航空便名等に係る個人データを、あらかじめ電子的方法等で送付することによって提供いたします。なお、これらの事業者への個人データの提供の停止を希望される場合は、お申し込み時にお申し出ください。
      </p>
      <p className={style.content}>
        (6)
        当社は、当社が保有するお客様の個人データのうち、氏名、住所、電話番号又はメールアドレス等のお客様へのご連絡にあたり必要となる最小限の範囲のものについて、当社グループ企業との間で、共同して利用させていただくことがあります。当社グループ企業は、それぞれの企業の営業案内、催し物内容等のご案内、ご購入いただいた商品の発送のために、これを利用させていただくことがあります。なお、当社グループ企業の名称及び個人データの管理について責任を有する者の氏名、又は名称は、プライバシーポリシー［個人情報に関するお問い合わせ・苦情のお申し出先］をご参照ください。
      </p>
      <p className={style.content}>
        (7)
        当社が保有するお客様の個人データの開示、その内容の訂正、追加若しくは削除、又はその利用の停止、消去若しくは第三者への提供の停止をご希望の方は、必要となる手続きについてご案内いたしますので、下記のお問い合わせ窓口までお申し出ください。その際、法令及び当社内規に従い、遅滞なく必要な措置を取らせていただきます。また、ご希望の全部又は一部に応じられない場合はその理由をご説明します。
      </p>
      <p className={style.content}>
        (8)
        万一、当社の個人情報の流出等の問題が発生した場合は、直ちにお客様にご連絡させていただき、安全の確保が保たれるまで問題が発生したシステムを一時停止いたします。また、速やかにホームページ等で事実関係等を公表させていただきます。
      </p>

      <h3 className={style.title}>28．ご注意</h3>
      <p className={style.content}>
        (1) お客様のご都合による航空便の変更、行程変更はできません。
      </p>
      <p className={style.content}>
        (2)
        交通機関の渋滞等、当社の責に帰すべき事由によらず航空便にお乗り遅れの場合は、別途、航空券のご購入が必要となり、航空券引換証の払戻しもできません。
      </p>
      <p className={style.content}>
        (3)
        悪天候等、お客様の責に帰すべき事由によらず旅行サービスの受領ができなくなった場合、第15項(2)の規定により、当該旅行サービスに対して取消料、違約料等支払うべき費用を差し引いた金額をお客様に払戻します。ただし、代替サービスの宿泊費・交通費等は、お客様のご負担となります。
      </p>
      <p className={style.content}>
        (4)
        お客様の便宜をはかるため、お土産店にご案内することがありますが、お買い物に際しては、お客様ご自身の責任でご購入いただきます。
      </p>
      <p className={style.content}>
        (5)
        お客様が個人的な案内・買い物などを添乗員に依頼された場合のそれに伴う諸費用、お客様の怪我、疾病などの発生に伴う諸費用、お客様の不注意による荷物紛失・忘れ物回収に伴う諸費用、別行動手配に要した諸費用が生じたときには、それらの費用をお客様にご負担いただきます。
      </p>
      <p className={style.content}>
        (6)
        旅行中に、事故などが生じた場合は、直ちに最終旅行日程表でお知らせする連絡先にご通知ください。(もし、通知できない事情がある場合は、その事情がなくなり次第後通知ください。)
      </p>
      <p className={style.content}>
        (7)
        旅館ホテル等において、お客様が酒類・料理・その他のサービスを追加された場合、原則として消費税などの諸税が課せられます。
      </p>
      <p className={style.content}>
        (8)
        お客様が、航空会社が任意で搭乗予定便以外の航空機に搭乗することをお客様に依頼する制度(フレックストラベラー制度)に同意をし、当初が手配した航空機以外に搭乗される場合は、当社の手配債務・旅程管理債務は履行されたとし、また、当該変更部分にかかわる旅程保証責任・特別補償責任は免責となりますので、ご了承ください。
      </p>
      <p className={style.content}>
        (9)
        お客様は、旅行開始後旅行終了までの間、募集型企画旅行参加者として行動していただくときは自由行動時間中を除き、旅行を安全かつ円滑に実施するための当社の指示に従っていただきます。
      </p>

      <h3 className={style.title}>29．旅行企画・実施</h3>
      <p className={style.contentml2}>
        ジョルダン株式会社(観光庁長官登録旅行業第1678号)
      </p>
      <p className={style.contentml2}>一般社団法人日本旅行業協会正会員 </p>
      <p className={style.contentml2}>
        本社：〒160-0022 東京都新宿区新宿2-5-10 成信ビル
      </p>
      <p className={style.contentml2}>
        埼玉オフィス：〒352-0001 埼玉県新座市東北2-31-15 太田ビル2階{" "}
      </p>
    </div>
  );
}
