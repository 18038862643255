import "../css/spinner.css";

export default function Spinner({ message }: { message?: string }) {
  return (
    <>
      <div className="flex flex-col items-center my-28 mx-auto max-w-screen-md space-y-8">
        <div className="text-slate-800 font-semibold text-lg">{message}</div>
        <div className="spinner">
          <div className="cube1"></div>
          <div className="cube2"></div>
        </div>
      </div>
    </>
  );
}
