import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { 
    outwardTimesForTrain, 
    returnTimesForTrain,
    outwardTimes,
    returnTimes,
    departureTimes,
    State,
    transports
} from "../constants";
import { Order } from "../types";
import { DepartureTime } from "./DepartureTime";
import { SelectButton } from "./SelectButton";

export default function Transports({
  setExpand,
}: {
  setExpand: React.Dispatch<React.SetStateAction<State>>;
}) {
  const [state, setState] = useOutletContext() as any;

  const schedule = state.schedule;

  let departTimes: any[] = [];
  let destTimes: any[] = [];

  const initTimes = (transport: any) => {
    if(schedule === 'day') {
        if(transport.id === 'train') {
            departTimes = outwardTimesForTrain.map( list => ({...list}));
            destTimes = returnTimesForTrain.map( list => ({...list}));
        } else {
            departTimes = outwardTimes.map( list => ({...list}));
            destTimes = returnTimes.map( list => ({...list}));
        }
    } else if(schedule === 'stay') {
        if(transport.id === 'train') {
            departTimes = outwardTimesForTrain.concat(returnTimesForTrain);
            destTimes = outwardTimesForTrain.concat(returnTimesForTrain);
        } else {
            departTimes = outwardTimes.concat(returnTimes);
            destTimes = outwardTimes.concat(returnTimes);
        }
    } else {
        departTimes = departureTimes.map( list => ({...list}));
        destTimes = departureTimes.map( list => ({...list}));
    }
  };

  initTimes(state.transport);

  let setTransport = ((transport: any) => {
    setState((state: Order) => ({
      ...state,
      transport,
    }));

    initTimes(transport);
  });
  let selectedTransport = state.transport
    ? transports.find((transport) => transport.id == state.transport.id)
    : state.transport;

  let setDepTimeOrigin = ((depTimeOrigin: any) => {
    setState((state: Order) => ({
      ...state,
      depTimeOrigin,
    }));

    selectedDepTimeOrigin = state.depTimeOrigin;
  });
  let selectedDepTimeOrigin = state.depTimeOrigin
    ? departTimes.find((depTime) => depTime.id == state.depTimeOrigin.id)
    : state.depTimeOrigin;

  let setDepTimeDestination = ((depTimeDestination: any) => {
    setState((state: Order) => ({
      ...state,
      depTimeDestination,
    }));

    selectedDepTimeDestination = state.depTimeDestination;
  });
  let selectedDepTimeDestination = state.depTimeDestination
    ? destTimes.find(
        (depTime) => depTime.id == state.depTimeDestination.id
      )
    : state.depTimeDestination;

  const isSelected =
    state.transport.id === "air"
      ? state.transport && state.depTimeOrigin && state.depTimeDestination
      : state.transport;
  
  return (
    <>
      <div className="boxRight">
        <div className="boxRightTitle">移動手段の変更</div>

        <div className="mt-5 px-2 md:px-4">
          <div className="space-y-5">
            <div className="flex flex-col md:flex-row">
              <div className="boxRightItemName items-center">移動</div>
              <div className="relative w-full md:w-11/12">
                <SelectButton
                  value={selectedTransport}
                  onChange={setTransport}
                  items={transports}
                  schedule={schedule}
                />
              </div>
            </div>
            {state.transport && (
              <>
                <div className="flex flex-col md:flex-row">
                  <div className="boxRightItemName items-center">往路出発</div>
                  <div className="relative w-full md:w-11/12">
                    <DepartureTime
                      value={state.depTimeOrigin}
                      onChange={setDepTimeOrigin}
                      schedule={schedule}
                      transport={state.transport}
                      roundTrip={"outward"}
                    />
                  </div>
                </div>

                <div className="flex flex-col md:flex-row">
                  <div className="boxRightItemName items-center">復路出発</div>
                  <div className="relative w-full md:w-11/12">
                    <DepartureTime
                      value={state.depTimeDestination}
                      onChange={setDepTimeDestination}
                      schedule={schedule}
                      transport={state.transport}
                      roundTrip={"return"}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          {isSelected ? (
            <button
              onClick={() => setExpand(State.DEFAULT)}
              className="btn-enter mx-auto my-10"
            >
              この内容に変更する
            </button>
          ) : (
            <div className="mb-5" />
          )}
        </div>
      </div>
    </>
  );
}
